<template>
  <div class="player" ref="fullscreenRef">
    <video
      ref="videoRef"
      @timeupdate="update"
      @loadeddata="load"
      @pause="playing = false"
      @play="playing = true"
      preload="auto"
      width="700"
      height="500"
      class="video"
      :class="fullscreen ? 'video-class-fullscreen' : 'video-class'"
    >
      <source :src="url" :type="type" />
    </video>
    <div
      class="video-buttons"
      :class="fullscreen ? 'video-buttons-in-fullscreen-mode' : ''"
    >
      <div
        :class="
          fullscreen ? 'player-controls-in-fullscreen-mode' : 'player-controls'
        "
      >
        <div
          class="play"
          @click.prevent="playing = !playing"
          :title="playing ? 'Pause' : 'Play'"
        >
          <div class="play-shape" v-if="!playing">
            <img
              :src="playImg"
              @mouseover="
                playImg = require('@/Common/Icons/playControlLight.png')
              "
              @mouseleave="
                playImg = require('@/Common/Icons/playControlDark.png')
              "
              alt="PlayShape"
              class="play-icon"
            />
          </div>
          <div class="pause-shape" v-else>
            <img
              :src="pauseImg"
              @mouseover="
                pauseImg = require('@/Common/Icons/pauseControlLight.png')
              "
              @mouseleave="
                pauseImg = require('@/Common/Icons/pauseControlDark.png')
              "
              alt="PauseShape"
              class="pause-icon"
            />
          </div>
        </div>
        <div class="player-time">
          <div class="player-time-current">
            {{ convertTimeHHMMSS(currentSeconds) }}
          </div>
          <div class="player-time-total">/</div>
          <div class="player-time-total">
            {{ convertTimeHHMMSS(durationSeconds) }}
          </div>
        </div>
        <div
          id="volume"
          @click.prevent="mute"
          :title="volumeTitle"
          class="play"
        >
          <div class="volume-shape" v-if="volume > 0">
            <img
              :src="volumeOnImg"
              @mouseover="
                volumeOnImg = require('@/Common/Icons/volumeOnControlLight.png')
              "
              @mouseleave="
                volumeOnImg = require('@/Common/Icons/volumeOnControlDark.png')
              "
              alt="PlayShape"
              class="volume-icon"
            />
          </div>
          <div class="mute-shape" v-else>
            <img
              :src="volumeOffImg"
              @mouseover="
                volumeOffImg = require('@/Common/Icons/volumeOffControlLight.png')
              "
              @mouseleave="
                volumeOffImg = require('@/Common/Icons/volumeOffControlDark.png')
              "
              alt="PauseShape"
              class="mute-icon"
            />
          </div>
        </div>
      </div>
      <img
        :src="fullscreenImg"
        @mouseover="
          fullscreenImg = require('@/Common/Icons/fullscreenLight.png')
        "
        @mouseleave="
          fullscreenImg = require('@/Common/Icons/fullscreenDark.png')
        "
        alt="fullScreen"
        @click="toggleFullscreen()"
        class="fullscreen-button pointer"
        v-if="!fullscreen"
      />
      <img
        v-else-if="fullscreen"
        :src="exitFullscreenImg"
        @mouseover="
          exitFullscreenImg = require('@/Common/Icons/exitFullscreenLight.png')
        "
        @mouseleave="
          exitFullscreenImg = require('@/Common/Icons/exitFullscreenDark.png')
        "
        alt="fullScreen"
        @click="toggleFullscreen()"
        class="fullscreen-button pointer button-in-fullscreen-mode"
      />
    </div>
  </div>
</template>
<script>
import { computed, watch, ref } from "vue";

export default {
  name: "AppVideo",
  props: {
    autoPlay: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const currentSeconds = ref(0);
    const durationSeconds = ref(0);
    const durationLeft = ref(0);
    const loaded = ref(false);
    const playing = ref(false);
    const previousVolume = ref(35);
    const showVolume = ref(false);
    const volume = ref(100);
    const videoRef = ref("video");
    const fullscreenRef = ref("video-fullscreen");
    const fullscreen = ref(false);

    const playImg = ref(require("@/Common/Icons/playControlDark.png"));
    const pauseImg = ref(require("@/Common/Icons/pauseControlDark.png"));
    const volumeOnImg = ref(require("@/Common/Icons/volumeOnControlDark.png"));
    const volumeOffImg = ref(
      require("@/Common/Icons/volumeOffControlDark.png")
    );
    const fullscreenImg = ref(require("@/Common/Icons/fullscreenDark.png"));
    const exitFullscreenImg = ref(
      require("@/Common/Icons/exitFullscreenDark.png")
    );

    const muted = computed(() => volume.value === 0);

    const percentComplete = computed(() =>
      parseInt((currentSeconds.value / durationSeconds.value) * 100)
    );
    const progressStyle = computed(() => ({
      width: `${percentComplete.value}%`,
    }));
    const volumeTitle = computed(() => {
      return `Volume (${volume.value}%)`;
    });

    watch(playing, (newValue) => {
      newValue ? videoRef.value.play() : videoRef.value.pause();
    });
    watch(volume, (newValue) => {
      videoRef.value.volume = newValue / 100;
    });

    const convertTimeHHMMSS = (val) => {
      let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);
      return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
    };
    const load = () => {
      if (videoRef.value.readyState >= 2) {
        loaded.value = true;
        durationSeconds.value = parseInt(videoRef.value.duration);
        return (playing.value = props.autoPlay);
      }
      throw new Error("Failed to load sound file.");
    };
    const mute = () => {
      if (muted.value) {
        return (volume.value = previousVolume.value);
      }
      previousVolume.value = volume.value;
      volume.value = 0;
    };

    const toggleFullscreen = () => {
      fullscreenRef.value.requestFullscreen();
      fullscreen.value = true;
      if (document.fullscreenElement == fullscreenRef.value) {
        document.exitFullscreen();
      }
    };

    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
    document.addEventListener("MSFullscreenChange", exitHandler);

    function exitHandler() {
      if (
        !document.fullscreenElement &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        fullscreen.value = false;
      }
    }

    const seek = (e) => {
      if (!loaded.value) return;

      const bounds = e.target.getBoundingClientRect();
      const seekPos = (e.clientX - bounds.left) / bounds.width;

      videoRef.value.currentTime = parseInt(videoRef.value.duration * seekPos);
    };
    const stop = () => {
      playing.value = false;
      videoRef.value.currentTime = 0;
    };
    const update = () => {
      currentSeconds.value = parseInt(videoRef.value.currentTime);
      durationLeft.value = parseInt(
        videoRef.value.duration - videoRef.value.currentTime
      );
    };
    return {
      currentSeconds,
      durationSeconds,
      durationLeft,
      loaded,
      playing,
      previousVolume,
      showVolume,
      volume,
      muted,
      percentComplete,
      progressStyle,
      volumeTitle,
      videoRef,
      convertTimeHHMMSS,
      load,
      mute,
      seek,
      stop,
      update,
      toggleFullscreen,
      playImg,
      pauseImg,
      volumeOnImg,
      volumeOffImg,
      fullscreenImg,
      exitFullscreenImg,
      fullscreen,
      fullscreenRef,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/appVideo.scss"></style>

<template>
  <div class="pools-wrapper" v-if="isAgreed">
    <stepper :steps="stepsArray" :currentStep="currentStep"></stepper>
    <div class="background-pools" :style="backgroundImage"></div>
    <base-form
      id="create-pool"
      method="post"
      :schema="schema"
      :handleSubmit="handleSubmit"
    >
      <component :is="renderComponent" />
    </base-form>
    <pool-preview-modal
      v-if="showPoolPreviewModal"
      :pool="pool"
      :initialNft="initialNft"
      :tags="tags"
    ></pool-preview-modal>
  </div>
  <div class="pools-wrapper" v-else>
    <create-pool></create-pool>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Stepper from "@/components/Stepper";
import usePool from "@/composables/usePool";
import { computed, onUnmounted } from "vue";
import BaseForm from "@/components/BaseForm";
import Cover from "@/components/Pools/Cover";
import { onBeforeRouteLeave } from "vue-router";
import TokenSetup from "@/components/Pools/TokenSetup";
import DepositNft from "@/components/Pools/DepositNft";
import CreatePool from "@/components/Pools/CreatePool";
import SocialCause from "@/components/Pools/SocialCause";
import PoolDetails from "@/components/Pools/PoolDetails";
import PoolPreviewModal from "@/components/Pools/PoolPreviewModal";

export default {
  components: {
    Cover,
    Stepper,
    BaseForm,
    CreatePool,
    TokenSetup,
    DepositNft,
    SocialCause,
    PoolDetails,
    PoolPreviewModal,
  },
  setup() {
    const store = useStore();
    const {
      pool,
      tags,
      schema,
      isAgreed,
      stepsArray,
      initialNft,
      currentStep,
      handleSubmit,
      backgroundUrl,
      resetPoolState,
      showPoolPreview,
      renderComponent,
      showPoolPreviewModal,
      togglePoolPreviewModal,
    } = usePool(store);
    onBeforeRouteLeave(() => {
      store.dispatch("pool/resetState");
    });
    const backgroundImage = computed(() => {
      if (currentStep.value === 2) return "";
      return backgroundUrl.value !== ""
        ? `background: linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)) , url(${backgroundUrl.value}) no-repeat center center/cover`
        : "";
    });
    onUnmounted(() => {
      resetPoolState();
      store.dispatch("pool/resetState");
      store.dispatch("tagsValues/resetState");
      store.dispatch("socialCauses/resetState");
      store.dispatch("nfts/selectNft", {});
    });
    return {
      pool,
      tags,
      schema,
      isAgreed,
      stepsArray,
      initialNft,
      currentStep,
      handleSubmit,
      backgroundUrl,
      showPoolPreview,
      backgroundImage,
      renderComponent,
      showPoolPreviewModal,
      togglePoolPreviewModal,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/poolsView.scss" />

<template>
  <input-form-box
    formTitle="set up"
    formTitleBold="pool token"
    :inputFormBoxStyle="inputFormBoxStyle"
  >
    <section>
      <div class="tags-royalties-wrap">
        <app-input
          type="text"
          name="tokenName"
          labelClass="label"
          labelText="Token name"
          class="tags-royalties"
          v-model="pool.tokenName"
          inputClass="form-box-input"
          placeholder="Enter the name of your pool token"
        />
        <div class="input-switch">
          <app-input
            type="text"
            maxlength="4"
            name="tokenSymbol"
            labelClass="label"
            class="tags-royalties"
            @blur="setFloatingText"
            labelText="Token symbol"
            v-model="pool.tokenSymbol"
            inputClass="form-box-input"
            placeholder="Choose a symbol for your token"
            bottomLabel="Only 3 or 4 characters allowed"
          />
          <div class="advanced-options-switch">
            <label for="tokenSwitch" class="label">Advanced options</label>
            <img
              alt="Switch"
              name="tokenSwitch"
              :src="switchStateImage"
              @click="toggleSwitchState()"
            />
          </div>
        </div>
      </div>
      <div class="tags-royalties-wrap">
        <app-input
          type="number"
          placeholder="0"
          labelClass="label"
          name="nftBasePrice"
          class="tags-royalties"
          labelText="NFT Base price"
          :floatingText="tokenSymbol"
          v-model="pool.nftBasePrice"
          inputClass="form-box-input"
          bottomLabel="1 USD = 1 Token"
          infoText="The amount of pool tokens that a depositor <br/> will receive when depositing an NFT in the  <br/> pool. The original amount will be the same  <br/> in USD"
        />
        <app-input
          type="number"
          maxlength="2"
          placeholder="0"
          labelClass="label"
          name="maximumIPrice"
          class="tags-royalties"
          :floatingTextLeft="'%'"
          labelText="Maximum i Price"
          inputClass="form-box-input"
          v-model="pool.maximumIPrice"
          bottomLabel="Maximum of 50%"
          :floatingText="maxIPriceText"
          infoText="The i Price is an additional amount of <br/> tokens a depositor will get if their NFT gets <br/> bought"
        />
      </div>
      <div class="tags-royalties-wrap" v-if="switchState">
        <app-input
          name="slots"
          type="number"
          labelClass="label"
          v-model="pool.slots"
          class="tags-royalties"
          inputClass="form-box-input"
          labelText="Slots (optional)"
          placeholder="Enter the maximum number of NFTs this pool will hold"
        />
        <div class="tags-royalties" />
      </div>
    </section>
    <div class="form-box-buttons-controls">
      <app-button
        text="Back"
        @click="decrementStep()"
        buttonClass="app-modal-button"
      />
      <app-button
        text="Next"
        type="submit"
        form="create-pool"
        buttonClass="app-modal-button-inverse"
      />
    </div>
  </input-form-box>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import AppInput from "@/elements/AppInput";
import usePool from "@/composables/usePool";
import AppButton from "@/elements/AppButton";
import InputFormBox from "@/elements/InputFormBox";
import useUtilities from "@/composables/useUtilities";
export default {
  components: {
    AppInput,
    AppButton,
    InputFormBox,
  },
  setup() {
    const tokenSymbol = ref("");

    const inputFormBoxStyle = {
      background: "#0B0B0B",
      width: "calc(100% - 160px)",
      borderRadius: "0 36px 36px 36px",
    };
    const setFloatingText = () => {
      tokenSymbol.value = `$${pool.tokenSymbol}`;
    };

    const maxIPriceText = computed(() => {
      if (pool.nftBasePrice && pool.maximumIPrice) {
        const price = (pool.nftBasePrice * pool.maximumIPrice) / 100;
        const fixedPrice = toFixedIfNecessary(price, 2);
        return `= ${fixedPrice} ${tokenSymbol.value}`;
      }
      return "";
    });

    const {
      pool,
      switchState,
      decrementStep,
      switchStateImage,
      toggleSwitchState,
    } = usePool(useStore());

    const { toFixedIfNecessary } = useUtilities();

    return {
      pool,
      tokenSymbol,
      switchState,
      maxIPriceText,
      decrementStep,
      setFloatingText,
      switchStateImage,
      toggleSwitchState,
      inputFormBoxStyle,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/tokenSetup.scss" />

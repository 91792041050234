import {
  depositNftSchema,
  tokenSetupSchema,
  poolDetailsSchema,
  socialCauseSchema,
  poolCoverImageSchema,
} from "@/validationSchemas";
import api from "@/services/api";
import { ethereumService } from "@/main";
import utils from "../utils";

const initialState = () => ({
  currentStep: 1,
  stepsArray: [
    {
      component: "PoolDetails",
      schema: poolDetailsSchema,
      title: "Details",
    },
    {
      component: "Cover",
      schema: poolCoverImageSchema,
      title: "Avatar",
    },
    {
      component: "TokenSetup",
      schema: tokenSetupSchema,
      title: "Token setup",
    },
    {
      component: "SocialCause",
      schema: socialCauseSchema,
      title: "Social cause",
    },
    {
      component: "DepositNft",
      schema: depositNftSchema,
      title: "NFT Deposit",
    },
  ],
  backgroundUrl: "",
  showPoolPreviewModal: false,
  isAgreed: false,
  coverImageFile: {},
  isPoolCreated: false,
  createdPool: {},
  userNfts: [],
  userBalance: 0,
  errors: [],
});
const state = initialState();
const getters = {
  getByKey: (state) => (key) => state[key],
  getUserNfts(state) {
    return state.userNfts;
  },
};
const mutations = {
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  setCoverImageFile(state, file) {
    state.coverImageFile = file;
  },
  setBackgroundUrl(state, url) {
    state.backgroundUrl = url;
  },
  setUserNfts(state, list) {
    state.userNfts = list;
  },
  togglePoolPreviewModal(state) {
    state.showPoolPreviewModal = !state.showPoolPreviewModal;
  },
  selectNft(state, nft) {
    state.selectedNft = nft;
  },
  agree(state, value) {
    state.isAgreed = value;
  },
  incrementStep(state) {
    state.currentStep++;
  },
  decrementStep(state) {
    state.currentStep--;
  },
  setPoolCreated(state, status) {
    state.isPoolCreated = status;
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};
const actions = {
  setBackgroundUrl({ commit }, file) {
    commit("setBackgroundUrl", file);
  },
  togglePoolPreviewModal({ commit }) {
    commit("togglePoolPreviewModal");
  },
  selectNft({ commit }, nft) {
    commit("selectNft", nft);
  },
  async postPool({ commit }, poolData) {
    try {
      const result = await api.postPool(poolData);
      commit("setPoolCreated", true);
      commit("commitByKey", { createdPool: result.data.pool });
    } catch (error) {
      commit("pushError", error);
    }
  },
  async deletePool({ commit }, id) {
    try {
      await api.deletePool(id);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchUserNfts({ commit }, params) {
    try {
      const response = await api.fetchNftList(params);
      commit("setUserNfts", response.data.data);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchUserBalance({ commit }, balanceAddress) {
    try {
      const balance = await ethereumService.fetchBalance(balanceAddress);
      commit("commitByKey", { userBalance: balance["THEOS"] });
      return balance;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async storeCoverImageFile({ commit }, formData) {
    try {
      const result = await api.storeCoverImageFile(formData);
      commit("setCoverImageFile", result.data);
      return result;
    } catch (error) {
      const isUnauthorized = await utils.handleUnauthorizedRequest(error);
      if (isUnauthorized) return null;
      commit("pushError", error);
    }
  },
  agree({ commit }, value) {
    commit("agree", value);
  },
  incrementStep({ commit }) {
    commit("incrementStep");
  },
  decrementStep({ commit }) {
    commit("decrementStep");
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  pushError({ commit }, error) {
    commit("pushError", error);
  },
  resetState({ commit }) {
    commit("resetState");
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import api from "@/services/api";
import { ethereumService } from "@/main";

const initialState = () => ({
  bidFlag: false,
  selectedType: null,
  selectedToken: null,
  nft: {
    id: null,
    royaltyPercentage: null,
    name: "",
  },
  showLastCheckForBid: localStorage.getItem("showLastCheckForBid")
    ? localStorage.getItem("showLastCheckForBid") === "true"
    : true,
  offer: null,
  errors: [],
});

const state = initialState();
const getters = {
  getByKey: (state) => (key) => state[key],
};
const mutations = {
  toggleBidFlag(state) {
    state.bidFlag = !state.bidFlag;

    if (state.bidFlag) {
      document.body.classList.add("stop-scroll");
      return;
    }
    document.body.classList.remove("stop-scroll");
  },
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  removeError(state, removeError) {
    state.errors = state.errors.filter((error) => error !== removeError);
  },
};
const actions = {
  setByKey({ commit }, obj) {
    commit("setByKey", obj);
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  resetState({ commit }) {
    commit("resetState");
  },
  toggleBidFlag({ commit }) {
    try {
      commit("toggleBidFlag");
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchNft({ commit }, id) {
    try {
      const response = await api.fetchNft(id);
      commit("commitByKey", { nft: response.data });
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },

  async signBidderData({ commit }, data) {
    try {
      return await ethereumService.signBidderData(data);
    } catch (error) {
      if ("code" in error && error.code === 4001) {
        return "rejected";
      }
      commit("pushError", error);
    }
  },

  async createBid({ commit }, data) {
    try {
      return await api.createBid(data);
    } catch (error) {
      commit("pushError", error);
    }
  },

  async getSalt({ commit }, { auctionId }) {
    try {
      const res = await api.getSalt(auctionId);
      return res.data.salt;
    } catch (error) {
      commit("pushError", error);
    }
  },

  pushError({ commit }, error) {
    commit("pushError", error);
  },
  clearErrors({ commit }) {
    commit("clearErrors");
  },
  removeError({ commit }, error) {
    commit("removeError", error);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div
    :class="
      !isForMarketplace
        ? 'profile-panel-body-wrapper'
        : 'profile-panel-body-wrapper-no-padding'
    "
  >
    <panel-filters
      :sortOptions="sortOptions"
      :currentPanel="currentPanel"
      :sortFunction="sortFunction"
      :searchFunction="searchFunction"
      :isForMarketplace="isForMarketplace"
      :clearSearchFunction="clearSearchFunction"
    />
    <app-loader :isGreen="true" v-if="loadingNfts || loadingPanels" />
    <template v-else>
      <div v-if="isEmpty && theosMinted">
        <no-data
          v-if="isForMarketplace"
          :image="
            !marketplaceSearch
              ? require('@/Common/Icons/chest.svg')
              : require('@/Common/Icons/spyglass.svg')
          "
          :title="
            !marketplaceSearch
              ? 'Soon, you will find NFTs to buy.'
              : `Sorry, we don't have any results for your search`
          "
          :buttonClickHandler="buttonClickHandlerMarketplace"
          :textBot="marketplaceSearch ? '' : 'and can be purchased.'"
          :textTop="
            marketplaceSearch
              ? 'Try different words or use filters to find what you need'
              : 'Start by listing your NFTs so that they appear in our store'
          "
          :buttonText="marketplaceSearch ? 'Back to all items' : 'See my NFTs'"
        />
        <no-data
          v-else
          :title="'Nothing to see here yet'"
          :image="require('@/Common/Icons/cloud.svg')"
          :textTop="textTop({ currentPanel, isPublic })"
          :buttonText="buttonText({ currentPanel, isPublic })"
          :buttonClickHandler="buttonClickHandler({ currentPanel, isPublic })"
        />
      </div>
      <template v-if="theosMinted || isForMarketplace">
        <div
          :class="
            !isForMarketplace
              ? 'profile-panel-body-content'
              : 'profile-panel-body-content-marketplace'
          "
        >
          <div
            class="profile-panel-big-card"
            v-if="
              randomCardList.length === TOTAL_SLIDES &&
              isForMarketplace &&
              currentPanel === 'all' &&
              !marketplaceSearch &&
              !marketplaceFilterAndSort
            "
          >
            <div class="profile-panel-big-card-header">
              <div
                class="profile-panel-big-card-header-headline"
                ref="bigCardHeadline"
              >
                <span>Those who seek shall</span>
                <span class="profile-panel-big-card-header-second-word">
                  find
                </span>
              </div>
              <slider-tabs
                :totalSlides="TOTAL_SLIDES"
                :currentSlide="currentSlide"
                :setCurrentSlide="setCurrentSlideAndResetSlideInterval"
                style="margin-bottom: 10px; align-items: center"
              />
            </div>
            <div class="big-card-view-port">
              <div
                class="big-card-background"
                :style="{ right: offsetToShowCurrentSlide() }"
              >
                <template
                  v-for="(randomCard, index) in randomCardList"
                  :key="index"
                >
                  <panel-card
                    :isBig="true"
                    :token="randomCard"
                    :isPublic="isPublic"
                    @click="cardClickHandler(randomCard)"
                    :isForMarketplace="isForMarketplace"
                    v-if="
                      randomCard &&
                      !(
                        !isOwner(randomCard) &&
                        randomCard.auction?.status === 'ended'
                      )
                    "
                  />
                </template>
              </div>
            </div>
          </div>
          <template v-for="card in cardData" :key="card.id">
            <panel-card
              :token="card"
              :isPublic="isPublic"
              @click="cardClickHandler(card)"
              :isForMarketplace="isForMarketplace"
              v-if="!(!isOwner(card) && card.auction?.status === 'ended')"
            />
          </template>
        </div>
        <load-more
          :data="data"
          :loadMore="loadMore"
          :loadingMore="loadingMore"
          :currentPanel="currentPanel"
        />
      </template>
      <template v-else>
        <non-theos-minted-nfts :data="data['external'].data" />
        <load-more
          :data="data"
          :loadMore="loadMore"
          :loadingMore="loadingMore"
          :currentPanel="currentPanel"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { eventBus } from "@/main";
import AppLoader from "@/elements/AppLoader";
import useRouter from "@/composables/useRouter";
import useUtilities from "@/composables/useUtilities";
import NoData from "@/components/Profile/Panels/NoData";
import SliderTabs from "@/components/HomePage/SliderTabs";
import useProfileView from "@/composables/useProfileView";
import LoadMore from "@/components/Profile/Panels/LoadMore";
import { computed, onBeforeUnmount, ref, watch } from "vue";
import PanelCard from "@/components/Profile/PanelCard/PanelCard";
import NonTheosMintedNfts from "@/components/Profile/NonTheosMintedNfts";
import PanelFilters from "@/components/Profile/PanelFilters/PanelFilters.vue";
export default {
  components: {
    NoData,
    LoadMore,
    AppLoader,
    SliderTabs,
    PanelCard,
    PanelFilters,
    NonTheosMintedNfts,
  },
  props: {
    currentPanel: {
      type: String,
      default: "",
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    loadingMore: {
      type: Boolean,
      default: false,
    },
    loadingNfts: {
      type: Boolean,
      default: false,
    },
    loadingPanels: {
      type: Boolean,
      default: false,
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
    isForMarketplace: {
      type: Boolean,
      default: false,
    },
    searchFunction: {
      type: Function,
      default: () => {},
    },
    clearSearchFunction: {
      type: Function,
      default: () => {},
    },
    sortFunction: {
      type: Function,
      default: () => {},
    },
    sortOptions: {
      type: Array,
      default: () => [],
    },
    loadMore: {
      type: Function,
      default: () => {},
    },
    cardClickHandlerArray: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => ({ data: [], total: 0 }),
    },
  },
  setup(props) {
    const store = useStore();
    const { goTo } = useRouter();
    const { theosMinted, setCurrentPanel } = useProfileView(store);
    const { getRandomNumbers } = useUtilities();

    const me = computed(() => store.getters["auth/getRegisteredUser"]);
    const isOwner = (token) => me.value.id === token.currentOwner.id;
    const cardData = computed(() => props.data[props.currentPanel].data);

    const TOTAL_SLIDES = 3;
    const currentSlide = ref(1);
    const PADDING_BETWEEN_SLIDES_IN_PIXELS = 0;
    const bigCardHeadline = ref(null);
    const randomNumberArray = cardData.value.length
      ? getRandomNumbers(0, cardData.value.length, TOTAL_SLIDES)
      : [];
    let randomCardList = [];
    randomNumberArray.forEach((num) =>
      randomCardList.push(cardData.value[num])
    );

    const setCurrentSlideAndResetSlideInterval = (value) => {
      currentSlide.value = value;
    };
    const offsetToShowCurrentSlide = () =>
      `calc(${currentSlide.value * 100}% + ${
        currentSlide.value * PADDING_BETWEEN_SLIDES_IN_PIXELS
      }px)`;

    let marketplaceSearch = ref(null);
    eventBus.on("changeMarketplaceSearchFlag", (val) => {
      marketplaceSearch.value = val;
    });

    let marketplaceFilterAndSort = ref(null);
    eventBus.on("changeMarketplaceFilterAndSortFlag", (val) => {
      marketplaceFilterAndSort.value = val;
    });

    const routeName = computed(() =>
      props.cardClickHandlerArray.includes(props.currentPanel)
        ? "NftDetails"
        : "ViewPool"
    );

    const cardClickHandler = (card) =>
      goTo({
        name: routeName.value,
        params: { id: card.id },
      });

    const buttonClickHandler = (config) => () => {
      if (config.isPublic) return goTo({ name: "Marketplace" });
      if (config.currentPanel === "nfts") return goTo({ name: "Minting" });
      if (config.currentPanel === "listed") return setCurrentPanel("nfts");
      if (config.currentPanel === "pooledNfts")
        return goTo({ name: "PoolsGallery" });
    };

    const buttonText = (config) => {
      if (config.isPublic) return "Explore Marketplace";
      if (config.currentPanel === "nfts") return "Mint an NFT";
      if (config.currentPanel === "listed") return "See my NFTs";
      if (config.currentPanel === "pooledNfts") return "Explore Pools";
    };

    const textTop = (config) => {
      if (config.isPublic) return "This user doesn't have NFTs for sale";
      if (config.currentPanel === "nfts")
        return "You don't have NFTs available. Mint one and come back!";
      if (config.currentPanel === "listed")
        return "You don't have NFTs for sale. List one and come back!";
      if (config.currentPanel === "pooledNfts")
        return "You didn't pool any NFTs. Pool one and come back!";
    };

    const buttonClickHandlerMarketplace = () => {
      return marketplaceSearch.value
        ? eventBus.emit("initializePanels")
        : goTo({ name: "ViewProfilePrivate" });
    };

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const contentBoxSize = Array.isArray(entry.contentBoxSize)
          ? entry.contentBoxSize[0].inlineSize + 20
          : entry.contentBoxSize.inlineSize + 20;
        if (bigCardHeadline.value) {
          bigCardHeadline.value.style.fontSize = `${contentBoxSize * 0.0915}px`;
          bigCardHeadline.value.style.lineHeight = `${
            contentBoxSize * 0.1007
          }px`;
        }
      }
    });

    watch(bigCardHeadline, () => {
      if (bigCardHeadline.value) resizeObserver.observe(bigCardHeadline.value);
    });

    onBeforeUnmount(() => {
      if (bigCardHeadline.value) {
        resizeObserver.unobserve(bigCardHeadline.value);
      }
    });
    return {
      textTop,
      isOwner,
      cardData,
      buttonText,
      theosMinted,
      currentSlide,
      TOTAL_SLIDES,
      randomCardList,
      bigCardHeadline,
      cardClickHandler,
      marketplaceSearch,
      buttonClickHandler,
      marketplaceFilterAndSort,
      offsetToShowCurrentSlide,
      buttonClickHandlerMarketplace,
      setCurrentSlideAndResetSlideInterval,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/profilePanels.scss" />

<template>
  <div
    class="pdfPreview"
    ref="pdfPreview"
    :class="{ fullscreen: pdfFullscreen === true, cover: page === 0 }"
    @fullscreenchange="fullScreenChange()"
  >
    <img class="nft-image" v-if="page === 0" :src="cover" />
    <pdf :key="pdfKey" v-if="page > 0" :src="url" :page="page"></pdf>

    <div class="control">
      <img
        :src="prevImg"
        @mouseover="prevImg = require('@/Common/Icons/previousHover.png')"
        @mouseleave="prevImg = require('@/Common/Icons/previous.png')"
        alt="Previous"
        class="previous_button"
        :class="{ disabled: page === 1 }"
        @click="movePage(-1)"
      />

      Page {{ page }} of {{ numPages }}
      <img
        :src="nextImg"
        @mouseover="nextImg = require('@/Common/Icons/nextHover.png')"
        @mouseleave="nextImg = require('@/Common/Icons/next.png')"
        alt="Previous"
        class="next_button"
        :class="{ disabled: page === numPages }"
        @click="movePage()"
      />

      <img
        :src="fullscreenImg"
        @mouseover="
          fullscreenImg = require('@/Common/Icons/fullscreenLight.png')
        "
        @mouseleave="
          fullscreenImg = require('@/Common/Icons/fullscreenDark.png')
        "
        alt="fullScreen"
        v-if="!isFullscreen"
        @click="fullScreen()"
        class="fullscreen-button"
      />
      <img
        v-else
        :src="exitFullscreenImg"
        @mouseover="
          exitFullscreenImg = require('@/Common/Icons/exitFullscreenLight.png')
        "
        @mouseleave="
          exitFullscreenImg = require('@/Common/Icons/exitFullscreenDark.png')
        "
        alt="fullScreen"
        @click="fullScreen()"
        class="fullscreen-button pointer button-in-fullscreen-mode"
      />
    </div>
  </div>
</template>
<script>
import pdf from "vue3-pdf";
import { onMounted, ref } from "vue";

export default {
  name: "AppPdf",
  components: {
    pdf,
  },
  props: {
    url: {
      type: String,
      default: "",
    },
    cover: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    onMounted(async () => {
      const src = pdf.createLoadingTask(props.url);
      src.promise.then((pdf) => {
        numPages.value = pdf.numPages;
      });
    });

    const page = ref(0);
    const pdfKey = ref(0);
    const numPages = ref(0);
    const pdfPreview = ref(null);
    const pdfFullscreen = ref(false);
    const nextImg = ref(require("@/Common/Icons/next.png"));
    const prevImg = ref(require("@/Common/Icons/previous.png"));
    const fullscreenImg = ref(require("@/Common/Icons/fullscreenDark.png"));
    const exitFullscreenImg = ref(
      require("@/Common/Icons/exitFullscreenDark.png")
    );
    const isFullscreen = ref(false);

    const movePage = (step = 1) => {
      if (step === -1 && page.value === 0) {
        return;
      }
      if (step === 1 && numPages.value === page.value) {
        return;
      }
      page.value += step;
    };

    const fullScreen = () => {
      if (!document.fullscreenElement) {
        pdfPreview.value.requestFullscreen();
        isFullscreen.value = true;
      }

      if (document.fullscreenElement === pdfPreview.value) {
        document.exitFullscreen();
      }
    };

    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
    document.addEventListener("MSFullscreenChange", exitHandler);

    function exitHandler() {
      if (
        !document.fullscreenElement &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        isFullscreen.value = false;
      }
    }

    const fullScreenChange = async () => {
      if (pdfFullscreen.value === false) {
        pdfFullscreen.value = true;
        pdfKey.value++;
        return;
      }
      pdfFullscreen.value = false;
      pdfKey.value--;
    };

    return {
      page,
      pdfKey,
      prevImg,
      nextImg,
      numPages,
      movePage,
      pdfPreview,
      fullScreen,
      isFullscreen,
      fullscreenImg,
      pdfFullscreen,
      fullScreenChange,
      exitFullscreenImg,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/appPdf.scss"></style>

<template>
  <app-modal
    showCancel
    :closeButton="true"
    :closeModal="closeModal"
    :returnToPreviousModal="closeModal"
  >
    <template v-slot:app-modal-header>
      <img
        alt="TheosLogo"
        class="app-modal-list-logo"
        src="@/Common/Icons/TheosLogoYellow.svg"
      />
      <h3 class="app-modal-list-title">GIFT NFT</h3>
      <p class="app-modal-list-text">Choose an address to send this NFT</p>
    </template>
    <template v-slot:app-modal-body>
      <div class="input-wrapper">
        <div>
          <app-input
            type="string"
            placeholder="E.g.: 0xf462...z72"
            labelText="Polygon address"
            name="GiftingAddress"
            labelClass="label"
            v-model="ethereumAddress"
            class="input-matic-price"
            inputClass="form-box-input"
            @focusout="setGiftingAddress(ethereumAddress)"
          />
        </div>
      </div>
    </template>
    <template v-slot:app-modal-footer>
      <app-button
        type="submit"
        text="Next"
        form="gift-nft"
        style="margin-bottom: 16px"
        buttonClass="app-modal-button-inverse"
      />
      <app-button
        text="Cancel"
        class="app-modal-button"
        @click="closeModal()"
      />
    </template>
  </app-modal>
</template>

<script>
import { useStore } from "vuex";
import AppModal from "@/elements/AppModal";
import { onMounted, onUnmounted, ref } from "vue";
import AppInput from "@/elements/AppInput.vue";
import AppButton from "@/elements/AppButton.vue";
import useGiftNft from "../../composables/useGiftNft";

export default {
  name: "GiftNftModal",
  props: {
    nft: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    AppInput,
    AppButton,
    AppModal,
  },
  setup() {
    const store = useStore();
    const ethereumAddress = ref("");
    const { closeModal, setGiftingAddress } = useGiftNft(store);

    onMounted(() => document.body.classList.add("stop-scroll"));
    onUnmounted(() => document.body.classList.remove("stop-scroll"));

    return {
      closeModal,
      ethereumAddress,
      setGiftingAddress,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/giftNftModal.scss" />

import { computed, ref } from "vue";

const usePoolsGallery = (store) => {
  const searchString = ref("");

  const pools = computed(() => store.getters["poolsGallery/getPools"]);
  const lastPool = computed(() => store.getters["poolsGallery/getLastPool"]);

  const poolGalleryStats = computed(
    () => store.getters["poolsGallery/getPoolGalleryStats"]
  );

  const fetchGalleryStats = async (pools) => {
    let poolsIds = "";
    pools.forEach((pool) => (poolsIds += pool.id + ","));
    await store.dispatch(
      "poolsGallery/fetchPoolGalleryStats",
      poolsIds.slice(0, -1)
    );
  };
  return {
    pools,
    searchString,
    lastPool,
    poolGalleryStats,
    fetchGalleryStats,
  };
};

export default usePoolsGallery;

<template>
  <div class="app-modal-overlay">
    <div class="app-modal-wrapper app-buy-nft">
      <div @click="closeModal" v-if="closeButton" class="app-modal-leave-modal">
        <img src="@/Common/Icons/XmarkModal.png" alt="Logo" />
      </div>
      <div class="app-modal-header">
        <slot name="app-modal-header" />
      </div>
      <div class="app-modal-body">
        <slot name="app-modal-body" />
      </div>
      <div class="app-modal-footer" v-if="showCloseLink">
        <app-button
          text="Cancel"
          @click="closeModal"
          buttonClass="app-modal-button"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@/elements/AppButton";
export default {
  name: "AppBuyNft",
  components: {
    AppButton,
  },
  props: {
    showCloseLink: {
      type: Boolean,
      default: false,
    },
    closeModal: {
      type: Function,
      default: () => {},
    },
    closeButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/buyNftModal.scss" />

<template>
  <app-deposit-nft-modal :closeButton="true" :returnToPreviousModal="close">
    <template v-slot:app-modal-header>
      <div class="app-modal-logo">
        <img
          alt="TheosLogo"
          class="logo-gif"
          src="@/Common/Icons/LOGO_GIF.gif"
        />
      </div>
      <h3 class="app-modal-deposit-title">REQUEST SENT</h3>
    </template>
    <template v-slot:app-modal-body>
      <p class="app-modal-body-text">
        You will be notified once your submission is reviewed by a pool owner.
      </p>
    </template>
    <template v-slot:app-modal-footer>
      <app-button
        text="Got it"
        @click="close()"
        buttonClass="app-modal-button-inverse app-modal-close-button"
      />
    </template>
  </app-deposit-nft-modal>
</template>

<script>
import AppDepositNftModal from "@/elements/AppDepositNftModal";
import AppButton from "@/elements/AppButton";
import { onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import useRouter from "@/composables/useRouter";
import useProfilePanels from "@/composables/useProfilePanels";
export default {
  name: "RequestSent",
  components: {
    AppButton,
    AppDepositNftModal,
  },
  setup() {
    const store = useStore();

    const { goTo } = useRouter();
    const { show } = useProfilePanels(store);

    const close = async () => {
      await goTo({ name: "ViewProfilePrivate" });
      show("submissions");
    };

    onMounted(() => document.body.classList.add("stop-scroll"));

    onUnmounted(() => document.body.classList.remove("stop-scroll"));

    return {
      goTo,
      close,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/requestSent.scss" />

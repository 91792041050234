<template>
  <div class="deposit-connected-wrapper" ref="nftListScroll">
    <div class="deopsit-connected-header-text">
      <span class="choose">CHOOSE NFT</span
      ><span class="to-deposit">TO SUBMIT</span>
    </div>
    <div class="deposit-info-text">
      Make sure that your NFTs are loaded on
      <span class="deposit-go-to" @click="goTo({ name: 'ViewProfilePrivate' })"
        >your profile</span
      >
      or go
      <span class="deposit-go-to" @click="goTo({ name: 'Minting' })"
        >mint one!</span
      >
    </div>
    <!-- <div class="list-top-bar" v-if="nftsList?.total !== 0">
      <div
        class="nfts-panel"
        :class="{ active: showing === 'theosNfts' }"
        @click="
          show('theosNfts');
          setTheosMinted(true);
        "
      >
        <p>Theos-minted NFTs</p>
      </div> -->
    <!--      <div-->
    <!--        class="nfts-panel"-->
    <!--        :class="{ active: showing === 'otherPlatfotmNfts' }"-->
    <!--        @click="-->
    <!--          show('otherPlatfotmNfts');-->
    <!--          setTheosMinted(false);-->
    <!--        "-->
    <!--      >-->
    <!--        <p>Minted in other platform</p>-->
    <!--      </div>-->
    <!-- </div> -->
    <div
      class="deposit-connected-filter-search"
      :class="[!address ? 'not-connected' : '']"
    >
      <div class="deposit-panel-search">
        <app-input
          :value="searchString"
          v-model="searchString"
          @keyup.enter="search()"
          :disabled="!theosMinted"
          inputStyle="padding-left: 35px"
          inputClass="app-modal-input-search"
          placeholder="Enter NFT name or contract address"
        />
        <img
          alt="search"
          class="deposit-panel-search-icon"
          src="@/Common/Icons/search_icon.png"
        />
        <img
          alt="delete"
          v-show="searchString != ''"
          @click="resetSearchString()"
          class="deposit-panel-search-cross"
          src="@/Common/Icons/search_cross.png"
        />
      </div>
      <div
        class="deposit-panel-filter"
        :class="[!address ? 'not-connected' : '']"
      >
        <div class="deposit-panel-filter-wrapper">
          <div
            class="deposit-panel-filter-overlay"
            @click="toggle(filtersFlag.showFilters)"
          />
          <app-input
            disabled
            placeholder="Sort"
            inputClass="app-modal-input"
            inputIconLeftClass="filter-icon"
            :inputStyle="'padding-left: 35px;'"
            :inputIconRightClass="{
              'chevron-icon': true,
              active: filtersFlag.showFilters.value,
            }"
          />
        </div>
        <teleport to="body">
          <div
            class="filtering-options-overlay"
            v-if="filtersFlag.showFilters.value"
            @click="toggle(filtersFlag.showFilters)"
          />
        </teleport>
        <div class="filtering-options" v-if="filtersFlag.showFilters.value">
          <h3 class="filtering-options-heading">Sort by</h3>
          <p
            class="filtering-option"
            @click="
              toggleFilter('filterNewest');
              setFiltersFlag(true);
            "
          >
            Newest first
          </p>
          <p
            class="filtering-option"
            @click="
              toggleFilter('filterOldest');
              setFiltersFlag(false);
            "
          >
            Oldest first
          </p>
        </div>
      </div>
    </div>
    <login-to-deposit v-if="!address" />
    <component :is="currentShowingPanel(showing)" v-else />
  </div>
</template>
<script>
import { useStore } from "vuex";
import { onMounted, computed } from "vue";
import AppInput from "@/elements/AppInput";
import useRouter from "@/composables/useRouter";
import useDeposit from "@/composables/useDeposit";
import LoginToDeposit from "./LoginComponent/LoginToDeposit.vue";
import DepositTheosMintedNft from "./ChooseNftComponents/DepositTheosMintedNft.vue";
import DepositOtherPlatformNft from "./ChooseNftComponents/DepositOtherPlatformNft.vue";
export default {
  components: {
    AppInput,
    LoginToDeposit,
    DepositTheosMintedNft,
    DepositOtherPlatformNft,
  },
  setup() {
    const store = useStore();
    const { goTo } = useRouter();

    const {
      show,
      toggle,
      search,
      showing,
      nftsList,
      filtersFlag,
      theosMinted,
      searchString,
      toggleFilter,
      setTheosMinted,
      setFiltersFlag,
    } = useDeposit(store);

    const address = computed(() => store.getters["contracts/getAddress"]);

    const currentShowingPanel = (showing) =>
      showing === "theosNfts"
        ? "DepositTheosMintedNft"
        : "DepositOtherPlatformNft";

    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    onMounted(async () => {
      await store.dispatch("deposit/fetchMe");
      setTheosMinted();
      scrollToTop();
    });

    const resetSearchString = () => {
      searchString.value = "";
    };

    return {
      goTo,
      show,
      search,
      toggle,
      address,
      showing,
      nftsList,
      theosMinted,
      filtersFlag,
      toggleFilter,
      searchString,
      setTheosMinted,
      setFiltersFlag,
      resetSearchString,
      currentShowingPanel,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/chooseToDepositNft.scss" />

<template>
  <app-deposit-review
    :closeButton="false"
    :styleClass="'app-depositing-modal-wrapper app-deposit-sign'"
  >
    <template v-slot:app-modal-header>
      <div class="spinner file-input new">
        <app-loader :show="true" :isGreen="true" />
      </div>
      <h3 class="app-modal-deposit-title">DEPOSITING</h3>
    </template>
    <template v-slot:app-modal-body>
      <p class="app-modal-body-text" v-if="!isAllowedTheos">
        Before proceeding you have to allow the Theos Protocol to use your
        THEOS. You only need to do this once.
      </p>
      <p class="app-modal-body-text" v-else>
        Sign the transaction in your wallet.
      </p>
    </template>
  </app-deposit-review>
</template>

<script>
import AppDepositReview from "@/elements/AppDepositReview";
import AppLoader from "@/elements/AppLoader";
import useDepositReviewModal from "@/composables/useDepositReviewModal";
import { useStore } from "vuex";
import { onMounted, onUnmounted } from "vue";

export default {
  name: "DepositingInProcess",
  components: {
    AppDepositReview,
    AppLoader,
  },
  props: {
    nft: {
      type: Object,
      default: () => {},
    },
    pool: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const store = useStore();
    const { isAllowedTheos } = useDepositReviewModal(store);
    onMounted(() => document.body.classList.add("stop-scroll"));
    onUnmounted(() => document.body.classList.remove("stop-scroll"));
    return {
      isAllowedTheos,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/depositReview.scss"></style>

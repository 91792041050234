import { computed } from "vue";

const useChyronData = (store) => {
  const monthlyMintedNfts = computed(() =>
    store.getters["chyronData/getByKey"]("monthlyMintedNfts")
  );
  const fetchMonthlyMintedNfts = () => {
    store.dispatch("chyronData/fetchMonthlyMintedNfts");
  };
  return {
    monthlyMintedNfts,
    fetchMonthlyMintedNfts,
  };
};
export default useChyronData;

import { computed, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import useGoogleTag from "@/composables/useGoogleTag";

const user = reactive({
  displayName: "",
  description: "",
  email: "",
  websiteUrl: "",
  twitterUsername: "",
});

let startTime = ref(0);
let endTime = ref(0);
let globalStartTime = ref(0);

const useRegistrationModal = (myStore) => {
  const router = useRouter();

  const { registerEvent } = useGoogleTag();

  const registrationFlag = computed(() =>
    myStore.getters["registration/getByKey"]("registrationFlag")
  );

  const updateSelectedFile = (file) => {
    myStore.dispatch("registration/setSelectedFile", file);
  };

  const toggleRegistrationFlag = () => {
    const address = computed(() => myStore.getters["contracts/getAddress"]);
    const registrationFromProfileFlag = myStore.getters[
      "registration/getByKey"
    ]("registrationFromProfileFlag");
    const registrationFlag =
      myStore.getters["registration/getByKey"]("registrationFlag");

    if (!registrationFlag && localStorage.getItem(`${address.value}`)) return;

    localStorage.setItem(`${address.value}`, true);

    myStore.dispatch("registration/toggleRegistrationFlag");
    if (registrationFromProfileFlag && registrationFlag) {
      router.push({ name: "ViewProfilePrivate" });
    }
  };

  const currentStep = computed(() =>
    myStore.getters["registration/getByKey"]("currentStep")
  );

  const currentStepsArray = computed(() =>
    myStore.getters["registration/getByKey"]("currentStepsArray")
  );

  const profileImageUrl = computed(() => {
    return myStore.getters["registration/getProfileImageURL"];
  });

  const commitToStore = (data) => {
    myStore.dispatch("registration/commitByKey", data);
  };

  const resetState = () => myStore.dispatch("registration/resetState");

  const storeProfileImageFile = async () => {
    const fd = new FormData();
    const profileImageBlob =
      myStore.getters["registration/getProfileImageBlob"];
    fd.append("file", profileImageBlob);
    return await myStore.dispatch("registration/storeProfileImageFile", fd);
  };

  const updateUser = async () => {
    await storeProfileImageFile();
    const userData = myStore.getters["registration/getUser"];
    const profileImageId = myStore.getters["registration/getProfileImageId"];
    userData.profileImageId = profileImageId;
    return myStore.dispatch("registration/updateUser", userData);
  };

  const finishRegistration = async () => {
    commitToStore(user);
    toggleRegistrationFlag();
    await updateUser();
    // setUser();
    await myStore.dispatch("userProfile/fetchMe");
    router.push({ name: "ViewProfilePrivate" });
    registerEvent("registration-completed");

    const delta = (Date.now() - globalStartTime.value) / 1000;
    registerEvent("registration-total-time", { time: delta });
    resetState();
  };
  const incrementStep = () => {
    setEndTime();
    let delta = endTime.value - startTime.value;
    registerEvent(`registration-step-${currentStep.value}-completed`);
    registerEvent(`registration-step-${currentStep.value}-time`, {
      time: delta / 1000,
    });
    myStore.dispatch("registration/incrementStep");
    registerEvent(`registration-step-${currentStep.value}-started`);
    setStartTime();
  };
  const decrementStep = () => {
    myStore.dispatch("registration/decrementStep");
    registerEvent(`registration-returned-to-step-${currentStep.value}`);
    setStartTime();
  };

  const setStartTime = () => {
    startTime.value = Date.now();
  };

  const setEndTime = () => {
    endTime.value = Date.now();
  };

  const setGlobalStartTime = () => {
    globalStartTime.value = Date.now();
  };
  return {
    registrationFlag,
    toggleRegistrationFlag,
    currentStep,
    incrementStep,
    decrementStep,
    updateSelectedFile,
    profileImageUrl,
    finishRegistration,
    commitToStore,
    currentStepsArray,
    user,
    setStartTime,
    setEndTime,
    setGlobalStartTime,
  };
};

export default useRegistrationModal;

<template>
  <base-form
    style="width: 100%; height: 100%"
    id="timed-auction-nft"
    method="post"
    :handleSubmit="checkForExistingSubmissions"
    :schema="validationSchema"
  >
    <div class="fixed-panel-wrapper">
      <div class="body">
        <div class="panel-title panel-title-timed">
          <span class="panel-title-number">2</span>
          <span class="panel-title-text">SET AUCTION</span>
        </div>
        <div class="expiration-input-wrapper">
          <app-input
            componentClass="expiration-input-class left"
            name="days"
            v-model="timedAuction.days"
            placeholder="1"
            labelClass="label"
            inputClass="app-auction-input"
            labelText="Expiration date*"
            bottomLabel="Auctions last a minimum of one day and a maximum of one month."
            floatingText="Days"
            @keypress.enter.prevent
            @keydown="filterKey"
          />
          <app-input
            componentClass="expiration-input-class"
            name="hours"
            labelClass="label opacity0"
            v-model="timedAuction.hours"
            placeholder="00"
            inputClass="app-auction-input"
            floatingText="Hours"
            labelText="Hours "
            @keydown="filterKey"
          />
        </div>
        <app-input-with-select
          style="width: 100%; position: relative"
          componentClass="input-margin"
          type="number"
          name="startPrice"
          labelClass="label"
          labelText="Starting price*"
          :inputValue="timedAuction.startPrice"
          :selectedToken="selectedToken"
          inputClass="form-box-input-with-select"
          placeholder="000.000"
          :floatingText="`≈ $${startPriceUsd} USD`"
          bottomLabel="Without minimum or maximum"
          infoText="The amount you suggest to open<br> the bidding."
          :selectOptions="tokenOptions"
          :updateValue="setStartPrice"
          :updateSelect="setSelectedToken"
          :enableToggle="false"
          :enableSelect="false"
          :disabledFlag="false"
          disabledClass="input-with-select-disabled"
          @keydown="filterKey"
        />
        <app-input-with-select
          style="width: 100%; position: relative"
          type="number"
          name="reservedPrice"
          labelClass="label"
          :inputValue="timedAuction.reservedPrice"
          :updateValue="setReservedPrice"
          labelText="Reserve price (optional)"
          v-model:selectedToken="selectedToken"
          inputClass="form-box-input-with-select"
          placeholder="000.000"
          :floatingText="`≈ $${reservedPriceUsd} USD`"
          infoText="The minimum price you are <br>
                    willing to accept. If it is reached, <br>
                    it will be sold automatically at the end <br>
                    of the setted time. If it is not reached you <br>
                    will have to accept the offer."
          bottomLabel="You need to set it up to the starting price"
          :disabledFlag="false"
          :enableToggle="true"
          :enableSelect="false"
          disabledClass="input-with-select-disabled"
          @keydown="filterKey"
        />
      </div>
      <div class="footer footer-buttons">
        <app-button
          :style="'margin-right: 1rem'"
          text="Cancel"
          buttonClass="app-modal-button"
          @click="closeModal"
          @keypress.enter.prevent
          @submit.prevent
        />
        <app-button
          type="submit"
          text="List auction"
          :disabled="listNftErrors.length"
          buttonClass="app-modal-button-inverse"
        />
      </div>
    </div>
  </base-form>
</template>

<script>
import * as Yup from "yup";
import { useStore } from "vuex";
import AppInput from "@/elements/AppInput";
import AppButton from "@/elements/AppButton";
import BaseForm from "@/components/BaseForm.vue";
import useListNft from "@/composables/useListNft";
import useUtilities from "@/composables/useUtilities";
import { computed, onMounted, reactive, ref } from "vue";
import AppInputWithSelect from "@/elements/AppInputWithSelect";

export default {
  name: "FixedPricePanel",
  components: {
    AppInput,
    BaseForm,
    AppButton,
    AppInputWithSelect,
  },
  setup() {
    const inputValue = ref(null);
    const showDetails = ref(false);

    const { filterKey, toFixedIfNecessary, parseTokenList } = useUtilities();
    const tokenOptions = reactive({});
    const {
      nft,
      closeModal,
      timedAuction,
      selectedToken,
      startPriceUsd,
      listNftErrors,
      setSelectedToken,
      reservedPriceUsd,
      confirmTimedAuction,
      checkForExistingSubmissions,
    } = useListNft(useStore());

    const toggleDetails = () => {
      showDetails.value = !showDetails.value;
    };

    const setStartPrice = (value) => {
      timedAuction.value.startPrice = value;
    };

    const setReservedPrice = (value) => {
      if (value === "") {
        value = null;
      }
      timedAuction.value.reservedPrice = value;
    };
    const validationSchema = computed(() => {
      return Yup.object().shape({
        startPrice: Yup.number()
          .moreThan(0, "Start price must be greater than 0.")
          .lessThan(99999999, "Start price must be lower than 99999999")
          .typeError("Start price must be greater than 0."),
        reservedPrice: Yup.number()
          .nullable()
          .moreThan(
            timedAuction.value.startPrice,
            "Reserved price must be greater than starting price."
          )
          .lessThan(99999999, "Reserved price must be lower than 99999999")
          .typeError("Reserved price must be greater than 0."),
        days: Yup.number()
          .min(1, "Days must be between 1 and 30")
          .max(30, "Days must be between 1 and 30")
          .typeError("Days must be between 1 and 30"),
        hours: Yup.number()
          .min(0, "Hours must be between 0 and 24")
          .max(24, "Hours must be between 0 and 24")
          .typeError("Hours must be between 0 and 24"),
      });
    });
    onMounted(async () => {
      tokenOptions.value = await parseTokenList();
      setSelectedToken(tokenOptions.value[0]);
    });

    return {
      nft,
      filterKey,
      closeModal,
      inputValue,
      showDetails,
      tokenOptions,
      timedAuction,
      setStartPrice,
      selectedToken,
      startPriceUsd,
      toggleDetails,
      listNftErrors,
      setSelectedToken,
      validationSchema,
      setReservedPrice,
      reservedPriceUsd,
      toFixedIfNecessary,
      confirmTimedAuction,
      checkForExistingSubmissions,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/auctionModal.scss"></style>

import { ref } from "vue";

const useMultiSelectCheckbox = (store) => {
  const allSelected = ref(false);
  let selectedSubmissions = ref([]);

  const resetSelectedList = () => {
    // gde se koristi dodati status ="" i disabled = true
    selectedSubmissions.value = [];
    allSelected.value = false;
  };

  const selectAll = (values) => {
    allSelected.value = !allSelected.value;
    if (allSelected.value) {
      selectedSubmissions.value = values;
    } else {
      resetSelectedList();
    }
  };

  const selectAllAndStore = (values, tableData) => {
    allSelected.value = !allSelected.value;
    if (allSelected.value) {
      selectedSubmissions.value = values;
      let result = tableData.filter((obj) => {
        return selectedSubmissions.value.includes(obj.id);
      });
      store.dispatch("deposit/commitByKey", {
        chosenPools: result,
      });
    } else {
      resetSelectedList();
    }
  };

  const toggleSelection = (id) => {
    if (selectedSubmissions.value.includes(id)) {
      const index = selectedSubmissions.value.indexOf(id);
      selectedSubmissions.value.splice(index, 1);
      allSelected.value = false;
    } else {
      selectedSubmissions.value.push(id);
    }

    if (selectedSubmissions.value.length == 0) {
      resetSelectedList();
    }
  };
  const toggleSelectionAndStore = (id, tableData) => {
    toggleSelection(id);
    let result = tableData.filter((obj) => {
      return selectedSubmissions.value.includes(obj.id);
    });
    store.dispatch("deposit/commitByKey", {
      chosenPools: result,
    });
  };
  const isChecked = (id) => {
    return selectedSubmissions.value.includes(id);
  };

  return {
    allSelected,
    selectedSubmissions,
    resetSelectedList,
    selectAll,
    selectAllAndStore,
    isChecked,
    toggleSelection,
    toggleSelectionAndStore,
  };
};

export default useMultiSelectCheckbox;

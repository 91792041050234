<template>
  <div class="card-wrapper">
    <img
      :src="require(`@/Common/Icons/viralcure-logo.svg`)"
      alt="Social Cause"
      class="card-img"
    />
    <div class="card-info">
      <p class="card-title">{{ socialCause.name }}</p>
      <p class="card-address" v-if="socialCause.address">
        {{ socialCause.address }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    socialCause: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const coverImage = () =>
      !!props.socialCause && !!props.socialCause.coverImage
        ? props.socialCause.coverImage.thumbnail
        : require("@/Common/Icons/Medusa_avatar.png");

    return {
      coverImage,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/socialCauseCard.scss" />

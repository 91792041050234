import utils from "../utils";
import api from "../../services/api";
// import { unsubscribeFromPushNotifications } from "@/registerServiceWorker";

const initialState = () => ({
  errors: [],
  isLoggedIn: false,
  registeredUser: {
    id: "",
    publicAddress: "",
    nonce: "",
    updatedAt: "",
    createdAt: "",
    email: "",
    password: "",
    displayName: "",
    description: "",
    websiteUrl: "",
    twitterUsername: "",
    profileImageUrl: "",
    coverImageUrl: "",
    badges: [],
  },
  isBeginConnectionModalOpen: false,
  installMetamaskFlag: false,
  switchNetworkModalFlag: false,
});

const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
  getRegisteredUser(state) {
    return state.registeredUser;
  },
  getErrors(state) {
    return state.errors;
  },
  getPublicAddress(state) {
    return state.registeredUser.publicAddress;
  },
};
const mutations = {
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  setRegisteredUser(state, user) {
    state.registeredUser = user;
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async fetchNonce({ commit }, address) {
    try {
      const result = await api.fetchNonce(address);
      return result.data;
    } catch (error) {
      if (
        error.response.data.message === "User with this address doesn't exist."
      ) {
        return null;
      }
      commit("pushError", error);
    }
  },
  async verifyNonce({ commit }, { signature, publicAddress }) {
    try {
      const res = await api.verifyNonce(signature, publicAddress);
      console.log(res);
      localStorage.setItem("socketsJwt", res.data.socketsJwt);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async signUp({ commit }, publicAddress) {
    try {
      const result = await api.signup(publicAddress);
      commit("setRegisteredUser", result.data.data);
      return result.data;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchMe({ commit }) {
    try {
      const result = await api.fetchMe();
      commit("setRegisteredUser", result.data.data);
    } catch (error) {
      const isUnauthorized = await utils.handleUnauthorizedRequest(error);
      if (isUnauthorized) return "unauthorized";
      commit("pushError", error);
    }
  },
  async updateUser({ commit }, { updateUser, id }) {
    try {
      const result = await api.updateUser(updateUser, id);
      return result;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async disconnect({ commit }) {
    try {
      // await unsubscribeFromPushNotifications();
      await api.disconnect();
      commit("commitByKey", { isLoggedIn: false });
    } catch (error) {
      commit("pushError", error);
    }
  },
  async resetState({ commit }) {
    commit("resetState");
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};

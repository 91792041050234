<template>
  <div class="box">
    <div class="headline" :class="hideText">
      <span class="first-text-line">{{
        formTitleBold || "tell us about"
      }}</span>
      <span class="second-text-line">{{ formTitle || "your nft" }}</span>
    </div>
    <div
      class="input-box-wrapper"
      :class="[noWrapInputFile === true ? 'hide-box-in-input-file' : '']"
      :style="inputFormBoxStyle"
    >
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: "InputFormBox",
  props: {
    noWrapInputFile: {
      type: Boolean,
      default: false,
    },
    hideText: {
      type: String,
      default: "",
    },
    formTitle: {
      type: String,
    },
    formTitleBold: {
      type: String,
    },
    inputFormBoxStyle: {
      type: Object,
      default: () => {},
    },
  },
  setup() {},
};
</script>
<style lang="scss" scoped src="@/Common/Styles/inputFormBox.scss"></style>

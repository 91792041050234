<template>
  <base-form
    method="post"
    id="edit-profile"
    :schema="editProfileSchema"
    :handleSubmit="handleSubmit"
  >
    <div class="avatar">
      <div class="avatar-image-wrapper" @click="uploadProfileAvatar()">
        <div class="change-photo-hover">
          <div class="change-photo-wrapper">
            <input
              type="file"
              ref="avatarInput"
              name="avatarProfileImage"
              class="display-none"
              id="edit-profile-avatar"
              @change="avatarUploaded()"
              accept=".png, .jpg, .jpeg"
            />
            <p>Change photo</p>
          </div>
        </div>
        <app-loader v-show="spinner" :isGreen="true" />
        <img
          alt="Avatar"
          v-show="!spinner"
          ref="avatarImageSrc"
          :src="avatarImage()"
        />
      </div>
    </div>

    <div class="user-info">
      <div class="edit-profile-name">
        <app-input
          type="text"
          id="edit-name"
          labelClass="label"
          name="displayName"
          labelText="Display name *"
          inputClass="app-modal-input"
          infoText="Make your profile look more professional<br> and unique by setting your name."
          componentClass="section-inputs"
          :value="userToUpdate.displayName"
          v-model="userToUpdate.displayName"
        />
      </div>
      <div class="edit-profile-email">
        <app-input
          type="text"
          name="email"
          id="edit-email"
          labelText="E-mail"
          labelClass="label"
          infoText="Get noticed when there are movements related to your NFTs.<br>Buy, sell, royalties, submission and much more!"
          :value="userToUpdate.email"
          v-model="userToUpdate.email"
          inputClass="app-modal-input"
          componentClass="section-inputs"
        />
      </div>
    </div>
    <div class="edit-profile-description">
      <app-textarea
        cols="1"
        rows="4"
        type="text"
        maxlength="200"
        labelText="Bio"
        labelClass="label"
        name="description"
        id="edit-description"
        componentClass="section-inputs"
        :value="userToUpdate.description"
        v-model="userToUpdate.description"
        textAreaClass="form-box-textarea-edit-profile"
      />
      <span class="description-num-chars"
        >{{
          userToUpdate.description ? userToUpdate.description.length : 0
        }}/200
      </span>
    </div>

    <div class="social-website">
      <div class="edit-twitter">
        <app-input
          type="text"
          id="edit-twitter"
          labelClass="label"
          name="twitterUsername"
          inputClass="app-modal-input"
          labelText="Twitter username"
          componentClass="section-inputs"
          :value="userToUpdate.twitterUsername"
          v-model="userToUpdate.twitterUsername"
        />
      </div>
      <div class="edit-website">
        <app-input
          type="text"
          id="edit-website"
          name="websiteUrl"
          labelClass="label"
          inputClass="app-modal-input"
          componentClass="section-inputs"
          :value="userToUpdate.websiteUrl"
          v-model="userToUpdate.websiteUrl"
          labelText="Personal site or portfolio"
        />
      </div>
    </div>
    <div class="app-modal-button-controls">
      <app-button
        text="Back"
        buttonClass="app-modal-button"
        @click.prevent="historyBack()"
      />
      <app-button
        type="submit"
        text="Save changes"
        :disabled="formChangeDataCheck"
        :class="{ loading: buttonFlag }"
        buttonClass="app-modal-button-inverse"
      />
    </div>
  </base-form>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import AppInput from "@/elements/AppInput";
import BaseForm from "@/components/BaseForm";
import AppButton from "@/elements/AppButton";
import AppLoader from "@/elements/AppLoader";
import AppTextarea from "@/elements/AppTextarea";
import useUserProfile from "@/composables/useUserProfile";
import { editProfileSchema } from "@/validationSchemas/editProfileSchema";

export default {
  components: {
    BaseForm,
    AppInput,
    AppButton,
    AppLoader,
    AppTextarea,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const {
      copy,
      spinner,
      buttonFlag,
      avatarInput,
      avatarImage,
      userToUpdate,
      handleSubmit,
      commitToStore,
      avatarImageSrc,
      avatarUploaded,
      initialFormValues,
      uploadProfileAvatar,
    } = useUserProfile(store, router);

    Object.assign(initialFormValues, userToUpdate);

    const formChangeDataCheck = computed(
      () => JSON.stringify(initialFormValues) === JSON.stringify(userToUpdate)
    );

    const historyBack = () => history.back();

    return {
      copy,
      spinner,
      buttonFlag,
      historyBack,
      avatarInput,
      avatarImage,
      userToUpdate,
      handleSubmit,
      commitToStore,
      avatarImageSrc,
      avatarUploaded,
      editProfileSchema,
      uploadProfileAvatar,
      formChangeDataCheck,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/editProfileForm.scss" />

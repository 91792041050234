<template>
  <div>
    <div class="checkbox-wrapper">
      <div class="checkbox" :class="{ checked: checked }"></div>
      <p v-if="labelText.length" class="label" :class="styleClass">
        {{ labelText }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppCheckbox",
  props: {
    id: {
      type: String,
    },
    labelText: {
      type: [String],
      default: "",
    },
    checked: {
      type: [Boolean],
      default: false,
    },
    styleClass: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/appCheckbox.scss"></style>

<template>
  <div class="app-card-content" ref="cardContentBox">
    <div class="app-card-content-description">
      <div
        class="card-title-text"
        :class="[
          isStringTooLong(token.name, MAX_NFT_NAME_LENGTH) ? 'is-too-long' : '',
        ]"
      >
        <div class="nft-name-tooltip">
          {{ token.name }}
        </div>
        <p
          ref="cardTitle"
          class="app-card-title"
          :class="[
            isStringTooLong(token.name, MAX_NFT_NAME_LENGTH)
              ? 'is-too-long'
              : '',
          ]"
        >
          {{ formatTitleName(token.name, MAX_NFT_NAME_LENGTH) }}
        </p>
      </div>
      <div
        ref="poolPrice"
        class="app-card-pool"
        v-if="isPooled || (isForMarketplace && !isAuctioned)"
      >
        <p
          v-if="isPooled"
          ref="cardPoolName"
          :class="[
            isForMarketplace
              ? 'app-card-pool-name-marketplace'
              : 'app-card-pool-name',
            isStringTooLong(token.pools[0].name, 20) ? 'is-too-long' : '',
          ]"
        >
          <span class="nft-name-tooltip">{{ token.pools[0].name }}</span>
          <span
            :class="
              isStringTooLong(token.pools[0].name, 20) ? 'is-too-long' : ''
            "
          >
            {{ token.pools[0].name }}
          </span>
        </p>
      </div>
      <div class="app-card-pool" v-if="isAuctioned" ref="auctionedName">
        <p class="app-card-pool-name">{{ auctionStatus }}</p>
      </div>
      <div
        class="app-card-pool"
        v-else-if="!isForMarketplace && !isPooled"
        ref="poolPlaceholder"
      />
      <div
        ref="marketplacePlaceholder"
        v-else-if="isForMarketplace && !isPooled"
      />
    </div>
    <div
      ref="cardPrice"
      v-if="isPooled"
      class="app-card-price-marketplace"
      :class="
        isForMarketplace ? 'app-card-price-marketplace' : 'app-card-price'
      "
    >
      <span>
        {{ numberHasMoreThanThreeDecimals(token.sellPrice) ? "~" : "" }}
        {{ toFixedIfNecessary(token.price || token.sellPrice, 3) }}
        {{ token.pools[0].tokenSymbol }}
        <span class="app-card-token-price">
          ≈ ${{ toFixedIfNecessary(token.priceUSD || token.sellPriceUSD, 3) }}
        </span>
      </span>
    </div>
    <div
      ref="listedPrice"
      v-else-if="token.status === 'listed'"
      class="app-card-price-marketplace"
      :class="
        isForMarketplace ? 'app-card-price-marketplace' : 'app-card-price'
      "
    >
      <span>
        {{ numberHasMoreThanThreeDecimals(token.sellPrice) ? "~" : "" }}
        {{ toFixedIfNecessary(token.sellPrice, 3) }}

        {{ token.marketplace.buyingTokenSymbol || "MATIC" }}
        <!-- MATIC -->
        <span class="app-card-token-price">
          ≈ ${{ toFixedIfNecessary(token.sellPriceUSD, 3) }}
        </span>
      </span>
    </div>
    <div
      v-else-if="isAuctioned"
      class="app-card-price app-card-price-marketplace"
      ref="auctionedPrice"
    >
      <span>
        {{
          numberHasMoreThanThreeDecimals(token.auction.nextOffer) ||
          numberHasMoreThanThreeDecimals(token.auction.startingPrice)
            ? "~"
            : ""
        }}
        {{
          toFixedIfNecessary(token.auction.nextOffer, 3) ||
          toFixedIfNecessary(token.auction.startingPrice, 3)
        }}
        {{ token.auction.token }}
        <span class="app-card-token-price">
          ≈ ${{
            toFixedIfNecessary(token.auction.nextOfferUsd, 3) ||
            toFixedIfNecessary(token.auction.startingPriceUsd, 3)
          }}
        </span>
      </span>
    </div>
    <div class="app-card-price" v-else />
  </div>
</template>

<script>
const MAX_NFT_NAME_LENGTH = 18;

import { useStore } from "vuex";
import useListNft from "@/composables/useListNft";
import useUtilities from "@/composables/useUtilities";
import { computed, ref, onMounted, onBeforeUnmount } from "vue";
export default {
  props: {
    isBig: {
      type: Boolean,
      default: false,
    },
    token: {
      type: Object,
      default: () => {},
    },
    isForMarketplace: {
      type: Boolean,
      default: false,
    },
    currentPanel: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const cardTitle = ref(null);
    const cardPrice = ref(null);
    const poolPrice = ref(null);
    const listedPrice = ref(null);
    const cardPoolName = ref(null);
    const auctionedName = ref(null);
    const cardContentBox = ref(null);
    const auctionedPrice = ref(null);
    const poolPlaceholder = ref(null);
    const marketplacePlaceholder = ref(null);

    const store = useStore();
    const {
      isStringTooLong,
      toFixedIfNecessary,
      numberHasMoreThanThreeDecimals,
    } = useUtilities();
    const { maticPriceUsd } = useListNft(store);
    //   TODO: Return total NFTs in pool from backend
    const spanCapacity = computed(() => "full-capacity");

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        let contentBoxSize = Array.isArray(entry.contentBoxSize)
          ? entry.contentBoxSize[0].inlineSize
          : entry.contentBoxSize.inlineSize;

        if (props.isBig) contentBoxSize /= 2;

        if (cardTitle.value) {
          cardTitle.value.style.fontSize = `${Math.floor(
            contentBoxSize * 0.0758
          )}px`;
          cardTitle.value.style.marginBottom = `${Math.floor(
            contentBoxSize * 0.019
          )}px`;
          cardTitle.value.style.marginTop = `${Math.floor(
            contentBoxSize * 0.0948
          )}px`;
          cardTitle.value.style.marginLeft = `${Math.floor(
            contentBoxSize * 0.057
          )}px`;
        }
        if (cardPoolName.value) {
          cardPoolName.value.style.fontSize = `${Math.floor(
            contentBoxSize * 0.057
          )}px`;
          cardPoolName.value.style.marginBottom = `${Math.floor(
            contentBoxSize * 0.057
          )}px`;
        }
        if (auctionedName.value) {
          auctionedName.value.style.fontSize = `${Math.floor(
            contentBoxSize * 0.057
          )}px`;
          auctionedName.value.style.marginBottom = `${Math.floor(
            contentBoxSize * 0.057
          )}px`;
          auctionedName.value.style.marginLeft = `${Math.floor(
            contentBoxSize * 0.057
          )}px`;
        }
        if (cardPrice.value) {
          cardPrice.value.style.fontSize = `${Math.floor(
            contentBoxSize * 0.066
          )}px`;
          cardPrice.value.style.marginLeft = `${Math.floor(
            contentBoxSize * 0.057
          )}px`;
        }
        if (poolPrice.value) {
          poolPrice.value.style.fontSize = `${Math.floor(
            contentBoxSize * 0.066
          )}px`;
          poolPrice.value.style.marginLeft = `${Math.floor(
            contentBoxSize * 0.057
          )}px`;
        }
        if (listedPrice.value) {
          listedPrice.value.style.fontSize = `${Math.floor(
            contentBoxSize * 0.066
          )}px`;
          listedPrice.value.style.marginLeft = `${Math.floor(
            contentBoxSize * 0.057
          )}px`;
        }
        if (auctionedPrice.value) {
          auctionedPrice.value.style.fontSize = `${Math.floor(
            contentBoxSize * 0.066
          )}px`;
          auctionedPrice.value.style.marginLeft = `${Math.floor(
            contentBoxSize * 0.057
          )}px`;
        }
        if (poolPlaceholder.value) {
          poolPlaceholder.value.style.height = `${Math.floor(
            contentBoxSize * 0.123223
          )}px`;
        }
        if (marketplacePlaceholder.value) {
          marketplacePlaceholder.value.style.height = `${Math.floor(
            contentBoxSize * 0.123223
          )}px`;
        }
      }
    });

    onMounted(() => {
      resizeObserver.observe(cardContentBox.value);
    });

    onBeforeUnmount(() => resizeObserver.unobserve(cardContentBox.value));

    const reservedPriceReached = computed(
      () => props.token.auction.nextOffer > props.token.auction.reservedPrice
    );

    const auctionStatus = computed(() => {
      if (!props.token.status === "auctioned") return;
      if (!props.token.auction.highestBidder.id) return "No bids yet";
      if (
        props.token.auction.reservedPrice &&
        props.token.auction.status === "active"
      ) {
        if (reservedPriceReached.value) {
          return "Reserve price reached";
        }
        return "RP not met yet";
      }
      return "Highest bid";
    });

    const formatTitleName = (name, maxLength) =>
      name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;

    return {
      poolPrice,
      cardPrice,
      cardTitle,
      listedPrice,
      cardPoolName,
      spanCapacity,
      auctionedName,
      auctionStatus,
      maticPriceUsd,
      auctionedPrice,
      cardContentBox,
      poolPlaceholder,
      formatTitleName,
      isStringTooLong,
      toFixedIfNecessary,
      MAX_NFT_NAME_LENGTH,
      reservedPriceReached,
      marketplacePlaceholder,
      numberHasMoreThanThreeDecimals,
      isPooled: computed(() => props.token.status === "deposited"),
      isAuctioned: computed(() => props.token.auction.type === "time-limited"),
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/nftCard.scss" />

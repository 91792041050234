<template>
  <base-form
    style="width: 100%; height: 100%"
    id="list-nft"
    method="post"
    :handleSubmit="confirmNftListing"
    :schema="nftListSchema"
  >
    <div class="last-check-wrapper">
      <div class="body">
        <div class="last-check-title">
          RULES <span class="title-span">LAST CHECK!</span>
        </div>
      </div>
      <div class="ul-wrapper">
        <ul>
          <li>
            In order to maintain your bid active you should keep the offered
            amount on your address.
          </li>
          <li>Bids cannot be canceled.</li>
        </ul>

        <app-checkbox
          id="1"
          labelText="I understand and I don’t need to read this again"
          :styleClass="'last-check'"
          class="checkbox"
          :checked="isChecked"
          @click="toggleChecked()"
        />
      </div>
      <div class="footer footer-buttons">
        <app-button
          :style="'margin-right: 1rem'"
          text="Back to review"
          buttonClass="app-modal-button"
          @click="backToReview()"
          @keypress.enter.prevent
          @submit.prevent
        />
        <app-button
          type="submit"
          text="Place a bid"
          buttonClass="app-modal-button-inverse"
          @click="closeLastCheck(isChecked)"
        />
      </div>
    </div>
  </base-form>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import AppButton from "@/elements/AppButton";
import BaseForm from "@/components/BaseForm.vue";
import useBid from "@/composables/useBid";
import AppCheckbox from "@/elements/AppCheckbox";
export default {
  name: "LastCheckBidder",
  components: {
    BaseForm,
    AppButton,
    AppCheckbox,
  },
  setup() {
    const isChecked = ref(false);

    const { nft, backToReview, closeLastCheck } = useBid(useStore());

    const toggleChecked = () => {
      isChecked.value = !isChecked.value;
    };

    return {
      nft,
      isChecked,
      backToReview,
      toggleChecked,
      closeLastCheck,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/auctionModal.scss"></style>

const initialState = () => ({
  tags: [],
  suggestions: ["Video", "Audio", "Gif", "Image"],
  usedSuggestions: [],
});

const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
};

const mutations = {
  pushTag(state, tag) {
    state.tags.push(tag);
  },
  removeTag(state, index) {
    state.usedSuggestions.map((val) => {
      if (val == state.tags[index]) {
        state.suggestions.push(state.tags[index]);
      }
    });
    const removedTag = state.usedSuggestions.indexOf(state.tags[index]);
    if (removedTag > -1) {
      state.usedSuggestions.splice(removedTag, 1);
    }
    state.usedSuggestions.splice();
    state.tags.splice(index, 1);
  },
  removeLastTag(state, event) {
    const index = state.tags.length - 1;
    if (event.target.value.length === 0) {
      state.usedSuggestions.map((val) => {
        if (val == state.tags[index]) {
          state.suggestions.push(state.tags[index]);
        }
      });
      const removedTag = state.usedSuggestions.indexOf(state.tags[index]);
      if (removedTag > -1) {
        state.usedSuggestions.splice(removedTag, 1);
      }
      state.tags.splice(index, 1);
    }
  },
  newSuggestionsTag(state, { val, index }) {
    state.tags.push(val);
    state.suggestions.splice(index, 1);
    state.usedSuggestions.push(val);
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};
const actions = {
  newTag({ commit }, tag) {
    commit("pushTag", tag);
  },
  remove({ commit }, index) {
    commit("removeTag", index);
  },
  removeLast({ commit }, event) {
    commit("removeLastTag", event);
  },
  addSuggestionsTag({ commit }, { val, index }) {
    commit("newSuggestionsTag", { val, index });
  },
  resetState({ commit }) {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

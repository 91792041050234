<template>
  <div class="app-card-aspect-ratio-guard">
    <div class="three-dots-icon" v-if="pool.originator.id === user.id">
      <img
        class=""
        @click.stop="toggle(token.id)"
        src="@/Common/Icons/ThreeDotsIcon.png"
      />
      <div class="popup" v-if="showPopup">
        <div
          v-if="!token.basePrice"
          class="popup-item share"
          v-click-outside="hidePopup"
          @click.stop="copyToClipboard(`${url}/nft-details/${token.id}`)"
        >
          Share
        </div>
        <div class="copy-link-hover nft-hover">Copy NFT link</div>
        <div
          class="popup-item"
          v-if="token.basePrice"
          v-click-outside="hidePopup"
          @click.stop="tradePoolToken()"
        >
          Trade Pool Token
        </div>
        <div
          class="popup-item"
          @click.stop="buyBack()"
          v-if="user.id === token.owners[token.owners.length - 1].id"
        >
          Buy Back
        </div>
        <div class="popup-item" v-else @click.stop="buyNft()">Buy</div>
      </div>
    </div>
    <div class="three-dots-icon" v-else>
      <img
        class=""
        @click.stop="toggle(token.id)"
        src="@/Common/Icons/ThreeDotsIcon.png"
      />
      <div class="popup" v-if="showPopup && parseInt(token.id) !== 406">
        <div
          class="popup-item"
          @click.stop="buyBack()"
          v-if="isBuyBackVisible()"
        >
          Buy Back
        </div>
        <!-- hotfix for genesis pool-->
        <div
          class="popup-item"
          @click.stop="buyNft()"
          v-else-if="parseInt(token.id) !== 406"
        >
          Buy
        </div>
      </div>
    </div>
    <div class="app-card-wrapper">
      <div class="avatar-img-wrapper">
        <img
          alt="Thumbnail"
          class="app-card-image"
          :src="coverImage(token.coverImage)"
        />
      </div>
      <div class="app-card-content">
        <div class="app-card-content-description">
          <div :class="[isStringTooLong(token.name, 20) ? 'is-too-long' : '']">
            <div class="nft-name-tooltip">{{ token.name }}</div>
            <p
              class="app-card-title"
              :class="[isStringTooLong(token.name, 20) ? 'is-too-long' : '']"
            >
              {{ token.name }}
            </p>
          </div>
          <div
            class="app-card-pool"
            :class="isStringTooLong(pool.name, 20) ? 'is-too-long' : ''"
            v-if="pool.originator.id === user.id"
          >
            <div class="nft-name-tooltip">{{ pool.name }}</div>
            <p
              class="app-card-pool-name"
              :class="isStringTooLong(pool.name, 20) ? 'is-too-long' : ''"
              v-if="token.status === 'deposited'"
            >
              {{ pool.name }}
            </p>
            <p class="app-card-pool-price" v-if="token.status === 'deposited'">
              {{ toFixedIfNecessary(pool.basePrice, 4) }}
            </p>
          </div>
        </div>
        <div class="app-card-price" v-if="token.status === 'deposited'">
          <span>
            {{ toFixedIfNecessary(price, 4) }} {{ pool.tokenSymbol }}
            <span class="app-card-token-price">
              ${{ toFixedIfNecessary(sellPriceUSD, 2) }}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { eventBus } from "@/main";
import { ethereumService } from "@/main";
import { ref, onMounted, computed } from "vue";
import vClickOutside from "click-outside-vue3";
import useUtilities from "@/composables/useUtilities";
import imageTransformer from "../plugins/imageTransformer";
import useWithdrawModal from "@/composables/useWithdrawModal";
import useNetworkData from "../composables/useNetworkData";
import useInsufficientFunds from "../composables/useInsufficientFunds";
export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    token: {
      type: Object,
      default: () => {},
    },
    pool: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const { copyToClipboard, toFixedIfNecessary, isStringTooLong } =
      useUtilities();
    const { isNftOnCurrentNetwork } = useNetworkData(ethereumService);
    const showPopup = ref(false);
    const url = ref(process.env.VUE_APP_BASE_PATH);
    const theosAddress = ref(ethereumService.theosTokenContractAddress);
    const me = computed(() => store.getters["auth/getRegisteredUser"]);
    const isOwner = computed(
      () => props.token.currentOwner?.id === me.value.id
    );

    const price = computed(() =>
      isOwner.value ? props.token.pools[0].basePrice : props.token.sellPrice
    );

    const sellPriceUSD = computed(() =>
      isOwner.value ? props.token.basePriceUSD : props.token.sellPriceUSD
    );

    const ownerAvatarImage = (owner) => {
      if (owner.owners)
        return imageTransformer.transform(
          owner.owners[owner.owners.length - 1].avatarFileName?.filename,
          "user.avatar"
        );
      if (owner.owner) return owner.owner.avatarImageUrl;
      return require("@/Common/Icons/Medusa_avatar.png");
    };
    const coverImage = (coverImage) =>
      !!coverImage && !!coverImage.filename
        ? imageTransformer.transform(coverImage.filename, "nft.list")
        : null;

    const toggle = (value) => {
      showPopup.value = !showPopup.value;
      eventBus.emit("isIdEqual", value);
    };
    const hidePopup = () => {
      showPopup.value = false;
    };
    const tradePoolToken = () => {
      window.open(
        `https://app.sushi.com/swap?inputCurrency=${theosAddress.value}&outputCurrency=${props.pool.tokenAddress}`
      );
    };

    const { userBalance, fetchUserBalance, toggleWithdrawFlag } =
      useWithdrawModal(store);

    const { toggleInsufficientFundsModal } =
      useInsufficientFunds(ethereumService);

    const buyBack = async () => {
      if (isNftOnCurrentNetwork(props.pool)) {
        await fetchUserBalance(props.pool.tokenAddress, me.value.publicAddress);
        if (Number(userBalance.value) >= Number(price.value)) {
          await store.dispatch("withdraw/fetchNft", props.token.id);
          return toggleWithdrawFlag();
        } else {
          return await toggleInsufficientFundsModal(
            props.token,
            userBalance.value
          );
        }
      }
      return store.dispatch("auth/commitByKey", {
        switchNetworkModalFlag: true,
      });
    };

    const buyNft = async () => {
      if (isNftOnCurrentNetwork(props.token)) {
        await fetchUserBalance(props.pool.tokenAddress, me.value.publicAddress);
        if (Number(userBalance.value) >= Number(price.value)) {
          await store.dispatch("buyNft/fetchNft", props.token.id);
          return await store.dispatch("buyNft/toggleBuyFlag");
        } else {
          return await toggleInsufficientFundsModal(
            props.token,
            userBalance.value
          );
        }
      }
      return store.dispatch("auth/commitByKey", {
        switchNetworkModalFlag: true,
      });
    };

    onMounted(() => {
      eventBus.on("isIdEqual", (val) => {
        if (val !== props?.token?.id) {
          showPopup.value = false;
        }
      });
    });

    const isBuyBackVisible = () => {
      return (
        props.pool.originator.id !== props.user.id &&
        props.user.id === props.token.owners[props.token.owners.length - 1].id
      );
    };
    return {
      url,
      price,
      buyNft,
      toggle,
      buyBack,
      showPopup,
      hidePopup,
      coverImage,
      sellPriceUSD,
      tradePoolToken,
      copyToClipboard,
      isStringTooLong,
      ownerAvatarImage,
      isBuyBackVisible,
      toFixedIfNecessary,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/appCard.scss" />

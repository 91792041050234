const initialState = () => ({
  tokenToBuy: null,
  userBalance: null,
  bidValue: null,
});

const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
};

const mutations = {
  setTokenToBuy(state, token) {
    state.tokenToBuy = token;
  },

  setUserBalance(state, balance) {
    state.userBalance = balance;
  },

  setBidValue(state, bidValue) {
    state.bidValue = bidValue;
  },

  resetState(state) {
    Object.assign(state, initialState());
  },
};

export default {
  state,
  getters,
  // actions,
  mutations,
  namespaced: true,
};

<template>
  <div>
    <label :for="id" :class="labelClass">{{ labelText }}</label>
    <div class="tag-input" :class="tagInputWrap">
      <div
        :key="index"
        class="tag-input-tag"
        :class="tagInputTag"
        v-for="(tag, index) in tags"
      >
        {{ tag }}
        <span @click="removeTag(index)">
          <img
            width="15"
            height="15"
            alt="Avatar"
            :src="require(`@/Common/Icons/Close_tag.png`)"
        /></span>
      </div>
      <input
        :id="id"
        :name="name"
        @blur="addTag"
        v-bind="$attrs"
        v-model="inputValue"
        :disabled="disabled"
        :class="inputTagClass"
        :style="inputTagStyle"
        class="tag-input-text"
        :placeholder="placeholder"
        @keydown="addTagOnKeypress"
        @keydown.delete="removeLastTag"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </div>
    <div class="suggestions-box">
      <p v-if="tagSuggestionText">{{ tagSuggestionText }}</p>
      <p v-else>Suggestions:</p>
      <div
        v-for="(suggestion, index) in suggestions"
        :key="index"
        class="suggestions"
        :class="tagInputTag"
      >
        <div @click="addSuggestedTag(suggestion, index)">{{ suggestion }},</div>
      </div>
    </div>
    <p :class="messageClass" v-show="errorMessage || !meta.valid">
      {{ errorMessage }}
    </p>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useField } from "vee-validate";
export default {
  name: "AppInputTag",
  props: {
    id: {
      type: String,
    },
    successMessage: {
      type: String,
      default: "",
    },
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    labelText: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    inputTagClass: {
      type: String,
      default: "",
    },
    messageClass: {
      type: String,
      default: "",
    },
    tagInputWrap: {
      type: String,
      default: "",
    },
    tagInputTag: {
      type: String,
      default: "",
    },
    inputTagStyle: {
      type: Object,
      default: () => {},
    },
    suggestions: {
      type: Array,
      default: () => [],
    },
    tagSuggestionText: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useStore();
    const tags = computed(() => store.getters["tagsValues/getByKey"]("tags"));

    const {
      meta,
      errorMessage,
      value: inputValue,
    } = useField(props.name, undefined, {
      initialValue: props.modelValue,
    });

    const addTag = (event) => {
      event.preventDefault();
      let val = event.target.value.trim();
      if (val.length > 0) {
        if (tags.value.length > 0 && tags.value.some((tag) => tag === val))
          return false;
        store.dispatch("tagsValues/newTag", val);
        event.target.value = "";
        inputValue.value = "";
      }
    };

    const removeTag = (index) => {
      store.dispatch("tagsValues/remove", index);
    };

    const removeLastTag = (event) => {
      store.dispatch("tagsValues/removeLast", event);
    };

    const addSuggestedTag = (val, index) => {
      store.dispatch("tagsValues/addSuggestionsTag", { val, index });
      inputValue.value = "";
    };

    const addTagOnKeypress = (event) => {
      let key = event.key;
      if (key === "," || key === ";" || key === "Enter") {
        addTag(event);
      }
    };

    return {
      tags,
      meta,
      addTag,
      removeTag,
      inputValue,
      errorMessage,
      removeLastTag,
      addSuggestedTag,
      addTagOnKeypress,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/appInputTag.scss" />

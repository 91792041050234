<template>
  <div class="card-wrapper">
    <img :src="nft.file.path" alt="IMG" class="card-img" />
    <div class="card-info">
      <p class="card-title">{{ nft.name }}</p>
      <p class="card-address" v-if="nft.contractAddress">
        {{ nft.contractAddress }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nft: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/poolNftCard.scss" />

<template>
  <div class="marketplace-wrapper">
    <div class="marketplace-top-bar">
      <div class="marketplace-panel-group">
        <template v-for="panel in marketplacePanelsList" :key="panel.name">
          <div
            class="marketplace-panel"
            @click="setCurrentPanel(panel.name)"
            :class="{ active: currentPanel === panel.name }"
          >
            <p>
              {{ panel.title }}
              <span class="profile-panel-total-items">
                {{ data[panel.name].total || "" }}
              </span>
            </p>
          </div>
        </template>
      </div>
    </div>
    <app-loader :isGreen="true" v-if="loadingPanels" />
    <div class="profile-panel-body" v-else>
      <card-panel
        :data="data"
        :query="query"
        :loadMore="loadMore"
        :sortFunction="sortBy"
        :searchFunction="search"
        :isForMarketplace="true"
        :loadingMore="loadingMore"
        :loadingNfts="loadingNfts"
        :currentPanel="currentPanel"
        :loadingPanels="loadingPanels"
        :clearSearchFunction="clearSearch"
        :isEmpty="data[currentPanel].total < 1"
        :cardClickHandlerArray="['all', 'pooled', 'listed']"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted } from "vue";
import { eventBus } from "@/main";
import AppLoader from "@/elements/AppLoader";
import useMarketplace from "@/composables/useMarketplace";
import CardPanel from "@/components/Profile/Panels/CardPanel";
import marketplacePanelsList from "@/json/marketplacePanelsList.json";
export default {
  name: "Marketplace",
  components: {
    AppLoader,
    CardPanel,
  },
  setup() {
    const store = useStore();
    const {
      data,
      sortBy,
      search,
      loadMore,
      loadingNfts,
      clearSearch,
      loadingMore,
      currentPanel,
      loadingPanels,
      setCurrentPanel,
      initializePanels,
    } = useMarketplace(store);

    onMounted(async () => {
      await initializePanels();
      await store.dispatch("contracts/fetchCoinPrice", {});
    });

    eventBus.on("initializePanels", () => {
      initializePanels();
    });

    return {
      data,
      sortBy,
      search,
      loadMore,
      clearSearch,
      loadingMore,
      loadingNfts,
      currentPanel,
      loadingPanels,
      setCurrentPanel,
      marketplacePanelsList,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/marketplace.scss" />

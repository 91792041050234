import api from "@/services/api";
import queryString from "query-string";

const initialQueries = () => ({
  page: 1,
  media: [],
  perPage: 8,
  orderBy: "id",
  orderDirection: "DESC",
  networks: [],
  priceRange: [],
});

const initialData = () => ({ data: [], total: 0 });

const initialState = () => ({
  searchString: "",
  theosMinted: true,
  loadingNfts: false,
  loadingMore: false,
  loadingPools: false,
  loadingPanels: false,
  isWhitelisted: false,
  queries: initialQueries(),

  data: {
    nfts: initialData(),
    pools: initialData(),
    listed: initialData(),
    external: initialData(),
    externalApi: initialData(),
    pooledNfts: initialData(),
    submissions: initialData(),
  },

  errors: [],
});

const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
};

const mutations = {
  pushError(state, error) {
    state.errors.push(error);
  },

  resetDataArray(state, dataName) {
    state.data[dataName].data = [];
    if (dataName === "external") {
      state.data["externalApi"] = initialData();
    }
  },

  resetPage(state) {
    state.queries.page = 1;
  },
  resetState(state) {
    Object.assign(state, initialState());
  },

  incrementPage(state) {
    state.queries.page = state.queries.page + 1;
  },

  resetQueries(state) {
    Object.assign(state.queries, initialQueries());
    state.searchString = "";
  },

  resetData(state, dataName) {
    Object.assign(state.data[dataName], initialData());
  },

  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  commitPoolData(state, object) {
    Object.assign(state.data["pools"], object);
  },
  commitSubmissionsData(state, object) {
    Object.assign(state.data["submissions"], object);
  },
};

const actions = {
  async isWhitelisted({ commit }, data) {
    try {
      const response = await api.fetchIsWhitelisted(data);
      commit("commitByKey", { isWhitelisted: response.data });
    } catch (error) {
      commit("pushError", error);
    }
  },

  async fetchSubmissionsCount({ commit, state }) {
    try {
      const response = await api.fetchMySubmissions();
      commit("commitByKey", {
        data: {
          ...state.data,
          submissions: { total: response.data.count },
        },
      });
    } catch (error) {
      commit("pushError", error);
    }
  },

  async fetchPooledNftList({ commit, state }, params) {
    try {
      const response = await api.fetchUserPooledNftList(
        queryString.stringify({ ...params, ...state.queries })
      );
      const { data, total } = response.data;
      commit("commitByKey", {
        data: {
          ...state.data,
          pooledNfts: {
            total,
            data: state.data.pooledNfts.data.concat(data),
          },
        },
      });
    } catch (error) {
      commit("pushError", error);
    }
  },

  async sortBy({ commit, state }, params) {
    try {
      const queries = {
        ...state.queries,
        orderBy: params.orderBy,
        orderDirection: params.orderDirection,
        page: 1,
      };
      commit("commitByKey", {
        queries,
      });
      commit("commitByKey", {
        data: {
          ...state.data,
          [params.tab]: {
            total: 0,
            data: [],
          },
        },
      });
    } catch (error) {
      commit("pushError", error);
    }
  },

  async fetchPoolsList({ commit, state }, params) {
    try {
      const response = await api.fetchPools(
        queryString.stringify({ ...params, ...state.queries })
      );
      const { data, total } = response.data;
      commit("commitByKey", {
        data: {
          ...state.data,
          pools: { data: state.data.pools.data.concat(data), total },
        },
      });
    } catch (error) {
      commit("pushError", error);
    }
  },

  async setFilter({ commit, state }, params) {
    try {
      commit("commitByKey", {
        queries: {
          ...state.queries,
          ...params,
        },
      });
    } catch (error) {
      commit("pushError", error);
    }
  },

  async fetchNftList({ commit, state }, params) {
    try {
      const tab = params.tab;
      delete params.tab;
      if (tab !== "external") {
        const response = await api.fetchNftList(
          queryString.stringify({
            ...params,
            ...state.queries,
            media: state.queries.media.toString(),
            networks: state.queries.networks.toString(),
          })
        );
        const { data, total } = response.data;
        commit("commitByKey", {
          data: {
            ...state.data,
            [tab]: {
              total,
              data: state.data[tab].data.concat(data),
            },
          },
        });
        return;
      }

      if (
        !state.data.externalApi.total ||
        (state.data.externalApi.data.length < 6 &&
          state.data.external.data.length + state.data.externalApi.data.length <
            state.data.externalApi.total)
      ) {
        const response = await api.fetchExternalNftList({
          id: params.id,
          page: state.queries.page,
        });
        const { data, total = 0 } = response.data;
        commit("commitByKey", {
          data: {
            ...state.data,
            externalApi: {
              data: state.data.externalApi.data.concat(data),
              total,
            },
          },
        });
      }

      commit("commitByKey", {
        data: {
          ...state.data,
          external: {
            data: state.data.external.data.concat(
              state.data.externalApi.data.splice(0, 6)
            ),
            total: state.data.externalApi.total,
          },
        },
      });
    } catch (error) {
      commit("pushError", error);
    }
  },
  resetPage({ commit }) {
    commit("resetPage");
  },

  resetState({ commit }) {
    commit("resetState");
  },

  async resetQueries({ commit }) {
    commit("resetQueries");
  },

  incrementPage({ commit }) {
    commit("incrementPage");
  },

  pushError({ commit }, error) {
    commit("pushError", error);
  },

  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },

  commitPoolData({ commit }, object) {
    commit("commitPoolData", object);
  },

  commitSubmissionsData({ commit }, object) {
    commit("commitSubmissionsData", object);
  },

  resetData({ commit }, dataName) {
    commit("resetData", dataName);
  },

  resetDataArray({ commit }, dataName) {
    commit("resetDataArray", dataName);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};

import useUtilities from "@/composables/useUtilities";
import queryString from "query-string";
import { ref, computed } from "vue";

const loadingNfts = ref(false);
const theosMinted = ref(true);
const filteringFlag = ref(true);

const useDeposit = (store) => {
  const walletAddress = computed(() => store.getters["contracts/getAddress"]);

  const userId = computed(() => store.getters["deposit/getUser"].id);
  const showing = computed(() => store.getters["deposit/getByKey"]("showing"));

  let params = {
    owner: walletAddress.value,
    page: 1,
    perPage: 15,
    orderBy: "id",
    orderDirection: "DESC",
    onlyWithContractId: true,
    status: "approved",
    networks: "polygon,mumbai,goerli,ethereum",
  };

  const { toggle } = useUtilities();
  const showFilters = ref(false);

  // const showing = ref("theosNfts");

  const toggleFilter = async (filter) => {
    const publicAddress = await store.dispatch("contracts/fetchAddress");
    let isAnythingSelected = false;

    switch (filter) {
      case "filterNewest":
        params.owner = publicAddress;
        params.page = 1;
        params.orderDirection = "DESC";
        store.dispatch("deposit/commitByKey", { currentPage: 1 });
        break;
      case "filterOldest":
        params.owner = publicAddress;
        params.page = 1;
        params.orderDirection = "ASC";
        store.dispatch("deposit/commitByKey", { currentPage: 1 });
        break;
    }
    if (!isAnythingSelected) params.owner = publicAddress;
    store.dispatch("deposit/clearNftDataList");
    store.dispatch("deposit/clearExternalNftDataList");
    theosMinted.value
      ? await fetchNftList(params)
      : await fetchExternalNftList({ id: userId.value });
  };
  const searchString = ref("");

  const show = (value) =>
    store.dispatch("deposit/commitByKey", { showing: value });

  const nftsList = computed(() =>
    store.getters["deposit/getByKey"]("nftsList")
  );
  const externalNftsList = computed(() =>
    store.getters["deposit/getByKey"]("externalNftsList")
  );
  const nftDataList = computed(() =>
    store.getters["deposit/getByKey"]("nftDataList")
  );

  const externalNftDataList = computed(() =>
    store.getters["deposit/getByKey"]("externalNftDataList")
  );

  const currentPage = computed(() =>
    store.getters["deposit/getByKey"]("currentPage")
  );
  const chosenNft = computed(() =>
    store.getters["deposit/getByKey"]("chosenNft")
  );
  const chosenPools = computed(() =>
    store.getters["deposit/getByKey"]("chosenPools")
  );
  const currentStep = computed(() =>
    store.getters["deposit/getByKey"]("currentStep")
  );
  const currentStepsArray = computed(() =>
    store.getters["deposit/getByKey"]("currentStepsArray")
  );
  const submissionSuccess = computed(() =>
    store.getters["deposit/getByKey"]("submissionSuccess")
  );
  const currentStepsArrayLength = computed(
    () => store.getters["deposit/getByKey"]("currentStepsArray").length
  );
  const fetchNftList = async (params) => {
    if (loadingNfts.value) return;
    if (chosenPools?.value[0]?.network) {
      params.networks = chosenPools?.value[0]?.network;
    }
    loadingNfts.value = true;
    setTimeout(async () => {
      await store.dispatch("deposit/fetchNftList", {
        params: queryString.stringify(params),
      });
      loadingNfts.value = false;
    }, 1000);
  };

  const externalPage = ref(1);

  const setExternalPage = (value) => (externalPage.value = value);

  const fetchExternalNftList = async () => {
    loadingNfts.value = true;
    await store.dispatch("deposit/fetchExternalNftList", {
      id: userId.value,
      page: externalPage.value,
    });
    loadingNfts.value = false;
  };
  const clearNftList = () => store.dispatch("deposit/clearNftList");

  const search = async () => {
    if (params.search !== searchString.value) {
      store.dispatch("deposit/clearNftDataList");
      params.search = searchString.value;
      if (searchString.value === "") params.owner = walletAddress.value;
      theosMinted.value
        ? await fetchNftList(params)
        : await fetchExternalNftList(userId.value);
    }
  };

  const loadMore = async () => {
    params.page = currentPage.value;
    params.page += 1;
    store.dispatch("deposit/commitByKey", { currentPage: params.page });
    filteringFlag.value
      ? (params.orderDirection = "DESC")
      : (params.orderDirection = "ASC");
    await fetchNftList(params);
  };

  const loadMoreExternal = async () => {
    externalPage.value += 1;
    await fetchExternalNftList({ id: userId.value, page: externalPage.value });
  };

  const setTheosMinted = (val = true) => {
    if (!loadingNfts.value) theosMinted.value = val;
  };

  const updateChosenNft = (nft) => {
    store.dispatch("deposit/commitByKey", { chosenNft: nft });
  };
  const updateChosenPools = (pool) => {
    store.dispatch("deposit/updateChosenPools", pool);
  };
  const setFiltersFlag = (val = true) => (filteringFlag.value = val);

  const incrementStep = (step = 1) => {
    store.dispatch("deposit/incrementStep", step);
  };
  const decrementStep = (step = 1) => {
    store.dispatch("deposit/decrementStep", step);
  };
  const changeCurrentStepArray = (queryKey) => {
    store.dispatch("deposit/changeCurrentStepsArray", queryKey);
  };
  const submissionToPool = (senderId, poolIds, nftId) => {
    store.dispatch("deposit/submissionToPool", { senderId, poolIds, nftId });
  };

  return {
    toggle,
    filtersFlag: {
      showFilters,
    },
    showing,
    show,
    searchString,
    toggleFilter,
    nftsList,
    externalNftsList,
    fetchNftList,
    fetchExternalNftList,
    clearNftList,
    search,
    loadMore,
    loadMoreExternal,
    params,
    nftDataList,
    externalNftDataList,
    loadingNfts,
    theosMinted,
    setTheosMinted,
    externalPage,
    setExternalPage,
    updateChosenNft,
    setFiltersFlag,
    filteringFlag,
    currentPage,
    chosenNft,
    chosenPools,
    updateChosenPools,
    currentStep,
    currentStepsArray,
    incrementStep,
    decrementStep,
    changeCurrentStepArray,
    submissionToPool,
    userId,
    submissionSuccess,
    currentStepsArrayLength,
  };
};

export default useDeposit;

<template>
  <div v-if="hasSubmission">
    <teleport to="body">
      <has-submission-modal />
    </teleport>
  </div>
</template>

<script>
import { useStore } from "vuex";
import useListNft from "@/composables/useListNft";
import HasSubmissionModal from "@/components/ListNft/HasSubmissionModal";
export default {
  components: {
    HasSubmissionModal,
  },
  setup() {
    const { hasSubmission } = useListNft(useStore());
    return {
      hasSubmission,
    };
  },
};
</script>

<template>
  <teleport to="body">
    <app-modal
      :isOpenConnectAndPolygonModal="isBeginConnectionModalOpen"
      :closeButton="true"
      :returnToPreviousModal="closeModal"
    >
      <template v-slot:app-modal-body>
        <div class="app-modal-body-wrapper">
          <img src="@/Common/Icons/LOGO_GIF.gif" alt="Logo" class="logo-gif" />
          <div class="text-wrap">
            <p class="text-polygon">We use Polygon and Ethereum</p>
            <p class="info-text">
              Now we are multichain! Connect your wallet to start using Theos.
            </p>
          </div>
          <div class="modal-buttons">
            <app-button
              @click="connect"
              text="Connect Wallet"
              buttonClass="app-modal-button-inverse"
            />
          </div>
          <a
            class="link-text"
            href="https://medium.com/theos-fi/theos-to-initially-deploy-on-polygon-then-ethereum-and-finally-cardano-429608cf76bf"
            target="_blank"
          >
            New to Theos?
          </a>
        </div>
      </template>
    </app-modal>
  </teleport>
</template>
<script>
import { useStore } from "vuex";
import { onMounted, onUnmounted } from "vue";
import { ethereumService } from "../main";
import useAuth from "@/composables/useAuth";
import AppButton from "@/elements/AppButton";
import AppModal from "@/elements/AppModal";
export default {
  components: {
    AppButton,
    AppModal,
  },
  setup() {
    const store = useStore();
    const { loginOrSignUp, isBeginConnectionModalOpen } = useAuth(
      store,
      ethereumService
    );
    const connect = () => {
      loginOrSignUp();
      store.dispatch("auth/commitByKey", {
        isBeginConnectionModalOpen: false,
      });
    };

    const closeModal = () => {
      store.dispatch("auth/commitByKey", {
        isBeginConnectionModalOpen: false,
      });
    };
    onMounted(() => {
      document.body.classList.add("stop-scroll");
    });
    onUnmounted(() => document.body.classList.remove("stop-scroll"));
    return {
      connect,
      closeModal,
      loginOrSignUp,
      isBeginConnectionModalOpen,
    };
  },
};
</script>
<style
  lang="scss"
  scoped
  src="@/Common/Styles/connectionAndPolygonModal.scss"
></style>

<template>
  <div class="app-modal-overlay">
    <div class="app-modal-wrapper app-buy-nft">
      <div
        v-if="closeButton"
        class="app-modal-leave-modal"
        @click="returnToPreviousModal()"
      >
        <img src="@/Common/Icons/XmarkModal.png" alt="Logo" />
      </div>
      <div class="app-modal-header">
        <slot name="app-modal-header" />
      </div>
      <div class="app-modal-body-wrapper">
        <div class="app-modal-body">
          <slot name="app-modal-body" />
        </div>
      </div>
      <div class="app-modal-footer" v-if="showCloseLink">
        <slot name="app-modal-footer">
          <app-button
            text="Cancel"
            @click="closeModal"
            class="app-modal-button"
          />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@/elements/AppButton";
export default {
  name: "AppBuyListedNft",
  components: {
    AppButton,
  },
  props: {
    closeButton: {
      type: Boolean,
      default: false,
    },
    closeModal: {
      type: Function,
      default: () => {},
    },
    returnToPreviousModal: {
      type: Function,
      default: () => {},
    },
    showCloseLink: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/buyListedNftModal.scss" />

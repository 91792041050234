import { reactive } from "vue";
import Web3Modal from "web3modal";
import { wait } from "../utilities/wait";
import { BigNumber, ethers } from "ethers";
import { parseEther } from "../utilities/parseEther";

// Constants and network data
import NETWORKS from "../contracts/networks.json";
import { ADDRESSES } from "../contracts/addresses.js";
import AVAILABLE_CHAINS from "../contracts/availableChains.json";
import AVAILABLE_NETWORK_NAMES from "../contracts/availableNetworksNames.json";

//ABIs
import ABI from "../contracts/NftMint.json";
import ERC721ABI from "../contracts/placeholderABI721.json";
import ERC1155ABI from "../contracts/placeholderERC1155ABI.json";
import PoolFactoryABI from "../contracts/NFTizeFactory.json";
import THEOS_POLYGON from "../contracts/TheosPolygon.json";
import POOLS_POLYGON from "../contracts/NftizePool.json";
import ERC20ABI from "../contracts/erc20.json";
import MarketplaceABI from "../contracts/NftMarketplaceAbi.json";

const { VUE_APP_ENV } = process.env;

const env = VUE_APP_ENV;
const whitelister =
  process.env.VUE_APP_BACKEND_ADDRESS ||
  "0x47018AFB47875b92f4C652838157248449ff9623";

const CHAINS = AVAILABLE_CHAINS[env];

const REJECT_TX_CODE = 4001;

class EthereumService {
  constructor(chainId = 1) {
    this.chainId = chainId;
    this.networkParams = reactive({ ...NETWORKS[env][chainId] });
    this.walletLink = {};
    this.networkProvider = {};
    this.availableNetworks = AVAILABLE_NETWORK_NAMES[env];
    this.providerOptions = {};
    this.mintingAbi = ABI["abi"];
    this.theosTokenAbi = THEOS_POLYGON["abi"];
    this.poolFactoryAbi = PoolFactoryABI["abi"];
    this.poolAbi = POOLS_POLYGON["abi"];
    this.marketplaceAbi = MarketplaceABI["abi"];
    this.erc721Abi = ERC721ABI;
    this.erc1155Abi = ERC1155ABI;
    this.erc20abi = ERC20ABI["abi"];
    this.mintingContractAddress = "";
    this.theosTokenContractAddress = "";
    this.wmaticTokenContractAddress = "";
    this.poolFactoryContractAddress = "";
    this.marketplaceContractAddress = "";
    this.web3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions: this.providerOptions,
      theme: "dark",
    });
    this.abiCoder = new ethers.utils.AbiCoder();

    this.auctionTypes = {
      Asset: [
        { name: "typ", type: "uint256" },
        { name: "data", type: "bytes" },
      ],
      Order: [
        { name: "maker", type: "address" },
        { name: "makeAsset", type: "Asset" },
        { name: "takeAsset", type: "Asset" },
        { name: "salt", type: "uint256" },
        { name: "deadline", type: "uint256" },
      ],
    };
    this.ERC721 = 0;
    this.ERC20 = 1;
  }
  createPoolTheosPrice = 1300000;

  parseChainId = (id) => {
    return CHAINS[id] || "Selected network is not supported.";
  };

  parseChainName = (name) => {
    return name === "Matic Mumbai" ? "mumbai" : "mainnet";
  };

  auctionDomain = () => {
    return {
      name: "TheosMarketplace",
      version: "2",
      chainId: this.chainId,
      verifyingContract: this.marketplaceContractAddress,
    };
  };

  setContractData = async () => {
    this.chainId = (await this.getNetwork()).chainId;
    Object.assign(this.networkParams, NETWORKS[env][this.chainId]);
    this.theosTokenContractAddress = ADDRESSES[this.chainId]["THEOS"];
    this.wmaticTokenContractAddress = ADDRESSES[this.chainId]["WMATIC"];
    this.mintingContractAddress = ADDRESSES[this.chainId]["NftMintContract"];
    this.poolFactoryContractAddress = ADDRESSES[this.chainId]["PoolFactory"];
    this.marketplaceContractAddress = ADDRESSES[this.chainId]["NftMarketplace"];
    this.theosTokenAbi = ERC20ABI["abi"];
  };

  isMetamaskNotInstalled() {
    return (
      typeof window.ethereum === "undefined" ||
      typeof window.web3 === "undefined"
    );
  }

  async connectToProvider() {
    this.networkProvider = await this.web3Modal.connect();
    if (!(await this.isValidNetwork())) {
      const chainId = env === "development" ? "0x13881" : "0x89";
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId }],
        });
      } catch (error) {
        await this.addPolygonNetwork(chainId);
        await this.switchToPolygonNetwork();
      }
    }
    return await this.setContractData();
  }

  async switchNetwork() {
    const switchTo = Object.keys(CHAINS).find(
      (id) => this.chainId !== Number(id)
    );
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${Number(switchTo).toString(16)}` }],
      });
    } catch (error) {
      if (error.code !== REJECT_TX_CODE) {
        await this.addPolygonNetwork();
        await this.switchToPolygonNetwork();
      }
    }
  }

  async checkCachedProvider() {
    if (this.web3Modal.cachedProvider) {
      this.networkProvider = await this.web3Modal.connect();
    } else {
      this.networkProvider = {};
    }
  }

  async onChainChanged(handleChainChange) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      this.networkProvider.on("chainChanged", async (info) => {
        await handleChainChange(this.parseChainId(parseInt(info, 16)));
      });
    }
  }

  async onAccountChanged(handleAccountChange) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      this.networkProvider.on(
        "accountsChanged",
        async () => await handleAccountChange()
      );
    }
  }

  async isProviderConnected() {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const accounts = await provider.listAccounts();
      return accounts.length > 0;
    }
    return false;
  }

  async getAddress() {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const accounts = await provider.listAccounts();
      return accounts[0];
    }
    return null;
  }

  async getNetwork() {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      return await provider.getNetwork();
    }
    return {};
  }

  async getNetworkName() {
    const network = await this.getNetwork();
    return this.availableNetworks[network.chainId];
  }

  async isValidNetwork() {
    const network = await this.getNetwork();
    return Boolean(CHAINS[network.chainId]);
  }

  async disconnectProvider() {
    this.web3Modal.clearCachedProvider();
    if (this.networkProvider._relay?.appName === "Theos") {
      this.walletLink.disconnect();
    }
  }

  async nftMint(metadata, royalties) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        this.mintingContractAddress,
        this.mintingAbi,
        signer
      );
      const signerAddress = await signer.getAddress();
      const data = royalties
        ? [[[signerAddress, (royalties * 100).toFixed()]]]
        : [[]];
      let tx = null;
      try {
        tx = await contract.mintToken(data, signerAddress, metadata);
        await tx.wait();
        return tx;
      } catch (error) {
        if (error.reason === "repriced") {
          const replacement = await provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        return {
          hash: "rejected",
        };
      }
    }
    throw new Error("No network provider found!");
  }

  async createPool(metadata) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        this.poolFactoryContractAddress,
        this.poolFactoryAbi,
        signer
      );

      const {
        tokenName,
        symbol,
        poolSlots,
        basePrice,
        nftContractAddress,
        tokenId,
        i,
        iValueNft,
        socialCauseFeeAddress,
        socialCauseFeePercentage,
      } = metadata;

      try {
        const tx = await contract.createPool(
          tokenName,
          symbol,
          poolSlots,
          ethers.utils.parseUnits(basePrice.toString(), "ether"),
          whitelister,
          nftContractAddress,
          tokenId,
          (iValueNft * 100).toFixed(),
          (i * 100).toFixed(),
          socialCauseFeeAddress,
          (socialCauseFeePercentage * 100).toFixed()
        );
        await tx.wait();
        return tx;
      } catch (error) {
        if (error.reason === "repriced") {
          const replacement = await provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        throw error;
      }
    }
  }

  async depositNft(contractAddress, contractId, iPrice, poolAddress) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        poolAddress,
        this.poolAbi,
        provider.getSigner()
      );

      try {
        await this.approveNft(contractId, contractAddress, poolAddress);
        const tx = await contract.depositNFT(
          contractAddress,
          contractId,
          (iPrice * 100).toFixed()
        );
        await tx.wait();
        return true;
      } catch (error) {
        if (error.reason === "repriced") {
          const replacement = await provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return true;
        }
        throw error;
      }
    }
    throw new Error("No network provider found!");
  }

  async withdrawNftFromPool(nftContractAddress, poolAddress, nftContractId) {
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        poolAddress,
        this.poolAbi,
        provider.getSigner()
      );
      try {
        const tx = await contract.withdrawNFT(
          nftContractAddress,
          nftContractId
        );
        await tx.wait();
        return tx;
      } catch (error) {
        if (error.reason === "repriced") {
          const replacement = await provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        throw error;
      }
    }
    throw new Error("No network provider found!");
  }

  async fetchAddress() {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const accounts = await provider.listAccounts();
      return accounts[0];
    }
    throw new Error("No network provider found!");
  }

  async fetchBalance(address) {
    await this.checkCachedProvider();
    await this.setContractData();
    const balances = {};
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const balance = await provider.getBalance(address);
      balances["default"] = ethers.utils.formatEther(balance);

      const contract = new ethers.Contract(
        this.theosTokenContractAddress,
        ERC20ABI["abi"],
        provider.getSigner()
      );
      const balanceTHEOS = await contract.balanceOf(address);
      balances["THEOS"] = ethers.utils.formatEther(balanceTHEOS);
      return balances;
    }
    throw new Error("No network provider found!");
  }

  async signSellerData(data) {
    // data setup
    const { nftId, nftAddress, sellingToken, price, salt } = data;
    console.log({ nftId, nftAddress, sellingToken, price, salt });
    const zero = BigNumber.from("0");

    const sellerData = this.abiCoder.encode(
      ["address", "uint"],
      [nftAddress, BigNumber.from(nftId.toString())]
    );
    const sellerMinimumAskData = this.abiCoder.encode(
      ["address", "uint"],
      [sellingToken, ethers.utils.parseUnits(price)]
    );

    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = provider.getSigner();

      const sellerDataToSign = {
        maker: await signer.getAddress(),
        makeAsset: { typ: this.ERC721, data: sellerData },
        takeAsset: { typ: this.ERC20, data: sellerMinimumAskData },
        salt,
        deadline: zero,
      };
      console.log(this.auctionDomain());
      return signer._signTypedData(
        this.auctionDomain(),
        this.auctionTypes,
        sellerDataToSign
      );
    }
    throw new Error("No network provider found!");
  }

  async signBidderData(data) {
    // data setup
    const { nftId, nftAddress, sellingToken, price, salt } = data;

    const zero = BigNumber.from("0");

    const amount = parseEther(price.toFixed(5).toString());

    const makerData = this.abiCoder.encode(
      ["address", "uint"],
      [nftAddress, BigNumber.from(nftId.toString())]
    );

    const bidderData = this.abiCoder.encode(
      ["address", "uint"],
      [sellingToken, BigNumber.from(amount)]
    );

    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = provider.getSigner();

      const takerDataToSign = {
        maker: await signer.getAddress(),
        makeAsset: { typ: this.ERC20, data: bidderData },
        takeAsset: { typ: this.ERC721, data: makerData },
        salt,
        deadline: zero,
      };

      return signer._signTypedData(
        this.auctionDomain(),
        this.auctionTypes,
        takerDataToSign
      );
    }
    throw new Error("No network provider found!");
  }

  async signNonce(nonce) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = provider.getSigner();
      return await signer.signMessage(nonce);
    }
    throw new Error("No network provider found!");
  }

  async fetchUserBalance(tokenAddress, userAddress) {
    await this.checkCachedProvider();
    const provider = new ethers.providers.Web3Provider(this.networkProvider);
    const contract = new ethers.Contract(
      tokenAddress,
      this.erc20abi,
      provider.getSigner()
    );
    const balance = await contract.balanceOf(userAddress);
    return ethers.utils.formatEther(balance);
  }

  async sendTransaction(transaction) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = provider.getSigner();
      transaction.gasLimit = transaction.gas;
      transaction.data = transaction.input;
      delete transaction.input;
      delete transaction.gas;
      delete transaction.__typename;
      const result = await signer.sendTransaction(transaction);
      return await result.wait();
    }
    throw new Error("No network provider found!");
  }

  async addTokenToMetamask(token, address = "") {
    const options = {
      address: address !== "" ? address : this.theosTokenContractAddress,
      symbol: token,
      decimals: 18,
    };
    if (token === "THEOS") {
      options.image = "https://theos.fi/assets/img/logo_theos.svg";
    }
    return await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: options,
      },
    });
  }

  async allowTheos() {
    await this.checkCachedProvider();
    const amount = parseEther("10000000000000000000000000");
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        this.theosTokenContractAddress,
        this.theosTokenAbi,
        provider.getSigner()
      );
      const tx = await contract.approve(
        this.poolFactoryContractAddress,
        amount
      );
      await tx.wait();
      await wait(10);
      return tx;
    }
    throw new Error("No network provider found!");
  }

  async approveERC20(erc20Address, beneficiary, am) {
    await this.checkCachedProvider();
    const amount = parseEther(am);
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        erc20Address,
        this.erc20abi,
        provider.getSigner()
      );
      const tx = await contract.approve(beneficiary, amount);
      await tx.wait();
      await wait(10);
      return tx;
    }
    throw new Error("No network provider found!");
  }

  async getAllowance(erc20Address, beneficiary) {
    const address = await this.fetchAddress();
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);

      const contract = new ethers.Contract(
        erc20Address,
        this.erc20abi,
        provider.getSigner()
      );
      const amount = await contract.allowance(address, beneficiary);
      return amount;
    }
    throw new Error("No network provider found!");
  }

  async increaseAllowance(erc20Address, beneficiary, am) {
    try {
      await this.checkCachedProvider();
      const allowance = await this.getAllowance(erc20Address, beneficiary);
      const amount = parseEther(am).add(allowance);

      if (Object.keys(this.networkProvider).length > 0) {
        const provider = new ethers.providers.Web3Provider(
          this.networkProvider
        );

        const contract = new ethers.Contract(
          erc20Address,
          this.erc20abi,
          provider.getSigner()
        );
        const tx = await contract.approve(beneficiary, amount);
        await tx.wait();
        await wait(10);
        return tx;
      }
      throw new Error("No network provider found!");
    } catch (error) {
      if (error.code === 4001) {
        return {
          reason: "rejected",
        };
      }
    }
  }

  async allowIndexPoolToken(indexPoolTokenAddress, poolAddress) {
    await this.checkCachedProvider();
    const amount = parseEther("10000000000000000000000000");
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        indexPoolTokenAddress,
        this.erc20abi,
        provider.getSigner()
      );
      const tx = await contract.approve(poolAddress, amount);
      await tx.wait();
      await wait(10);
      return tx;
    }
  }

  async isAllowedTheos() {
    const address = await this.fetchAddress();
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);

      const contract = new ethers.Contract(
        this.theosTokenContractAddress,
        this.theosTokenAbi,
        provider.getSigner()
      );
      const amount = await contract.allowance(
        address,
        this.poolFactoryContractAddress
      );
      return parseInt(amount) > 0;
    }
    throw new Error("No network provider found!");
  }

  async approveNft(
    nftId,
    tokenAddress = this.mintingContractAddress,
    poolAddress = this.poolFactoryContractAddress
  ) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        tokenAddress,
        this.erc721Abi,
        provider.getSigner()
      );
      try {
        const tx = await contract.approve(poolAddress, nftId);
        await tx.wait();
        await wait(10);
        return tx;
      } catch (error) {
        if (error.code === 4001) {
          return {
            reason: "rejected",
          };
        }
        if (error.reason === "repriced") {
          const replacement = await provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          await wait(10);
          return replacement;
        }
        throw error;
      }
    }
    throw new Error("No network provider found!");
  }

  async addPolygonNetwork() {
    return await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [NETWORKS[env][env === "development" ? "80001" : "137"]],
    });
  }

  async switchToPolygonNetwork() {
    try {
      return await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: env === "development" ? "80001" : "137" }],
      });
    } catch (error) {
      await this.addPolygonNetwork();
      await this.switchToPolygonNetwork();
    }
  }

  isEthereumAddress(address) {
    try {
      ethers.utils.getAddress(address);
    } catch (e) {
      return false;
    }
    return true;
  }

  async fetchNftMetadata(tokenId, tokenAddress, contractType) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        tokenAddress,
        contractType === "ERC1155" ? this.erc1155Abi : this.erc721Abi,
        provider
      );
      if (contractType === "ERC1155") {
        return await contract.uri(tokenId);
      }

      return await contract.tokenURI(tokenId);
    }
    throw new Error("No network provider found!");
  }

  //should transfer amount to owner
  async buyNft() {}

  async getNftPriceForBuyer(
    buyerAddress,
    nftContractAddress,
    tokenId,
    poolAddress
  ) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        poolAddress,
        this.poolAbi,
        provider.getSigner()
      );
      const result = await contract.getNftPriceForBuyer(
        buyerAddress,
        nftContractAddress,
        tokenId
      );
      return ethers.utils.formatEther(result);
    }
  }

  async isWhitelisted(nftAddress, nftId, poolAddress) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        poolAddress,
        this.poolAbi,
        provider.getSigner()
      );
      try {
        return contract.whitelist(nftAddress, nftId);
      } catch (error) {
        return false;
      }
    }
    throw new Error("No network provider found!");
  }

  async initContract(contractAddress, contractAbi) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      return new ethers.Contract(
        contractAddress,
        contractAbi,
        provider.getSigner()
      );
    }
    throw new Error("No network provider found!");
  }

  async listNft(nftAddress, nftId, listingPrice) {
    const price = listingPrice?.toString();
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        this.marketplaceContractAddress,
        this.marketplaceAbi,
        provider.getSigner()
      );
      try {
        const tx = await contract.listItem(
          nftAddress,
          nftId,
          ethers.utils.parseUnits(price, "ether")
        );

        await tx.wait();
        return tx;
      } catch (error) {
        if (error.code === 4001) {
          return {
            reason: "rejected",
          };
        }
        if (error.reason === "repriced") {
          const replacement = await provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        throw error;
      }
    }
    throw new Error("No network provider found!");
  }

  async approveForAll() {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const mintingContract = new ethers.Contract(
        this.mintingContractAddress,
        this.mintingAbi,
        provider.getSigner()
      );
      try {
        const tx = await mintingContract.setApprovalForAll(
          this.marketplaceContractAddress,
          true
        );
        await tx.wait();
        return tx;
      } catch (error) {
        if (error.code === 4001) {
          return {
            reason: "rejected",
          };
        }
        if (error.reason === "repriced") {
          const replacement = await provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        throw error;
      }
    }
    throw new Error("No network provider found!");
  }

  async unlistNft(nftAddress, nftId) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);

      try {
        const contract = new ethers.Contract(
          this.marketplaceContractAddress,
          this.marketplaceAbi,
          provider.getSigner()
        );

        const tx = await contract.unlistItem(nftAddress, nftId);
        await tx.wait();
        return tx;
      } catch (error) {
        if (error.code == 4001) {
          return {
            reason: "rejected",
          };
        }
        if (error.reason === "repriced") {
          const replacement = await provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        throw error;
      }
    }
    throw new Error("No network provider found!");
  }

  async buyListedNft(listedPrice, nftAddress, nftId) {
    const price = listedPrice.toString();
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        this.marketplaceContractAddress,
        this.marketplaceAbi,
        provider.getSigner()
      );
      try {
        const tx = await contract.buyItem(nftAddress, nftId, {
          value: parseEther(price),
        });
        await tx.wait();
        return tx;
      } catch (error) {
        if (error.code === 4001) {
          return {
            reason: "rejected",
          };
        }
        if (error.reason === "repriced") {
          const replacement = await provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        throw error;
      }
    }
    throw new Error("No network provider found!");
  }

  async giftNft(to, nftId, nftAddress) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(nftAddress, this.mintingAbi, signer);
      try {
        const from = await signer.getAddress();
        const tx = await contract.transferFrom(from, to, nftId);
        await tx.wait();
        return tx;
      } catch (error) {
        if (error.reason === "repriced") {
          const replacement = await provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        return {
          hash: "rejected",
        };
      }
    }
  }

  async isMetaMaskConnected() {
    const { ethereum } = window;
    if (ethereum) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const accounts = await provider.listAccounts();
      return accounts.length > 0;
    }
  }
}

export default EthereumService;

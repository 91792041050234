<template>
  <section>
    <div class="headline-cover" v-if="!isFileVideo">
      <span class="headline-upload-cover"
        >YOU’LL TOKENIZE A {{ selectedFileExtension }}</span
      >
      <span class="second-headline-upload-cover">CHOOSE A COVER IMAGE</span>
    </div>

    <file-input
      id="coverImageFile"
      :isImageOnly="true"
      name="coverImageFile"
      :on-change="onFileChanged"
      :showPreviewMessage="false"
      :shouldPickCoverImage="true"
      v-model="localCoverImageFile"
      accept="image/png,image/jpeg,image/gif"
    />
    <div
      v-show="localCoverImageFileSelected && fileType === 'image'"
      class="form-box-buttons-controls"
    >
      <app-button
        text="Back"
        buttonClass="app-modal-button"
        @click.prevent="removeCoverImage()"
      />
      <app-button
        text="Next"
        type="submit"
        form="mint-nft"
        buttonClass="app-modal-button-inverse"
      />
    </div>
  </section>
</template>

<script>
import store from "@/store";
import { computed, ref } from "vue";
import AppButton from "@/elements/AppButton";
import FileInput from "@/elements/FileInput.vue";
import useMinting from "../../composables/useMinting";
import useUtilities from "@/composables/useUtilities";

export default {
  name: "MintNftStepTwo",
  components: {
    FileInput,
    AppButton,
  },
  setup(_props, context) {
    const localCoverImageFile = ref({});
    const {
      decrementStep,
      coverImageFile,
      setBackgroundUrl,
      selectedFileType,
      updateSelectedFile,
      updateCoverImageFile,
      isCoverAutogenerated,
      selectedFileMimeType,
      selectedFileExtension,
    } = useMinting(store);
    const isFileVideo = ref(false);

    const { getFileType } = useUtilities();
    const fileType = computed(() => getFileType(localCoverImageFile.value));

    const localCoverImageFileSelected = computed(() =>
      Boolean(localCoverImageFile.value.type)
    );
    fileType.value === "video"
      ? (isFileVideo.value = true)
      : (isFileVideo.value = false);
    if (coverImageFile.value.name)
      localCoverImageFile.value = coverImageFile.value;

    const removeCoverImage = async () => {
      store.dispatch("minting/clearErrors");
      updateCoverImageFile({});
      setBackgroundUrl("");

      if (
        selectedFileType.value === "video" ||
        selectedFileType.value === "application"
      ) {
        await updateSelectedFile({});
        context.emit("clearFile:selectedImage");
      }
      decrementStep();
    };

    const onFileChanged = (file) => {
      updateCoverImageFile(file);
      if (file.type && !isCoverAutogenerated.value) {
        setBackgroundUrl(URL.createObjectURL(file));
      }
    };

    return {
      fileType,
      isFileVideo,
      onFileChanged,
      removeCoverImage,
      localCoverImageFile,
      selectedFileMimeType,
      selectedFileExtension,
      localCoverImageFileSelected,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/fileInput.scss" />

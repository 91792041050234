<template>
  <input-form-box>
    <section>
      <app-input
        type="text"
        v-model="localName"
        labelText="Nft Title"
        labelClass="label"
        name="NftTitle"
        inputClass="form-box-input"
        componentClass="section-inputs"
      />
      <app-textarea
        type="text"
        v-model="localDescription"
        labelText="Description"
        labelClass="label"
        name="Description"
        textAreaClass="form-box-textarea"
        componentClass="section-inputs"
        maxLength="201"
        cols="1"
        rows="4"
      />
    </section>
    <div class="form-box-buttons-controls">
      <app-button
        text="Back"
        @click.prevent="decrementStep()"
        buttonClass="app-modal-button"
      />
      <app-button
        text="Next"
        type="submit"
        :disabled="errors.length"
        buttonClass="app-modal-button-inverse"
        @click="commitToStore()"
      />
    </div>
  </input-form-box>
</template>
<script>
import AppTextarea from "@/elements/AppTextarea.vue";
import AppInput from "@/elements/AppInput.vue";
import AppButton from "@/elements/AppButton.vue";
import InputFormBox from "@/elements/InputFormBox.vue";
import { ref } from "vue";
import store from "@/store";
import useMinting from "../../composables/useMinting";

export default {
  name: "MintDescription",
  components: {
    AppTextarea,
    AppInput,
    AppButton,
    InputFormBox,
  },
  setup() {
    const { name, errors, description, updateBulk, decrementStep } =
      useMinting(store);

    const localName = ref("");
    const localDescription = ref("");

    localName.value = name.value;
    localDescription.value = description.value;

    const commitToStore = () => {
      updateBulk({
        name: localName,
        description: localDescription,
      });
    };
    return {
      errors,
      localName,
      commitToStore,
      decrementStep,
      localDescription,
    };
  },
};
</script>

import * as Yup from "yup";
import { ethereumService } from "../../main";

export const depositNftSchema = Yup.object().shape({
  contractAddress: Yup.string()
    .nullable()
    .typeError("This field is required")
    .test(
      "ETH_ADDRESS",
      "This is not a valid Ethereum address.",
      (value) => !value || ethereumService.isEthereumAddress(value)
    ),
  tokenId: Yup.number()
    .typeError("You must specify a number")
    .positive()
    .typeError("You must specify a positive number")
    .nullable(),
});

import { eventBus } from "@/main";
import { ethereumService } from "@/main";
import { computed, reactive, ref } from "vue";
import useToast from "@/composables/useToast";
import { useRouter } from "vue-router";
import * as queryString from "query-string";
import useProfilePanels from "@/composables/useProfilePanels";

const pool = reactive({
  name: "",
  slots: null,
  tokenName: "",
  tokenSymbol: "",
  description: "",
  royalties: null,
  socialCause: null,
  nftBasePrice: null,
  tokensPerNft: null,
  maximumIPrice: null,
  iValuePercentage: 0,
});

const newSocialCause = reactive({
  name: "",
  ethAddress: "",
});

const usePool = (store, myEventBus = eventBus) => {
  const { nftsList, fetchNftList } = useProfilePanels(store);
  const walletAddress = computed(() => store.getters["contracts/getAddress"]);

  const loadAllNfts = async () => {
    const network = await ethereumService.getNetworkName();

    const params = {
      page: 1,
      perPage: 5000,
      orderBy: "id",
      orderDirection: "DESC",
      status: ["approved"],
      networks: network,
    };

    if (walletAddress.value) {
      params.owner = walletAddress.value;
    }

    await fetchNftList(queryString.stringify(params));
  };

  const router = useRouter();
  store.dispatch("userProfile/fetchMe");

  const { showToast } = useToast();
  const showPoolPreview = ref(true);
  const showSetPriceModal = ref(false);
  const isCreatingPool = ref(false);
  const poolNftBasePrice = computed(() =>
    pool.tokensPerNft ? pool.tokensPerNft : pool.nftBasePrice
  );

  const createPool = async () => {
    const network = await ethereumService.getNetworkName();
    isCreatingPool.value = true;
    showPoolPreview.value = false;
    let poolId = null;

    const metadata = {
      symbol: pool.tokenSymbol.toUpperCase(),
      tokenName: pool.tokenName,
      basePrice: poolNftBasePrice.value,
      i: Number(pool.maximumIPrice),
      iValueNft: Number(pool.iValuePercentage),
      poolSlots: pool.slots || 10000,
      tokenId: selectedNft.value.contractId,
      nftContractAddress: selectedNft.value.contractAddress,
      socialCauseFeeAddress:
        pool.socialCause?.ethAddress ||
        "0x0000000000000000000000000000000000000000",
      socialCauseFeePercentage: pool.royalties ? pool.royalties : 0,
    };
    const poolToPost = {
      name: pool.name,
      tags: tags.value,
      coverImageId: null,
      tokenName: pool.tokenName,
      slots: Number(pool.slots || 10000),
      nftId: selectedNft.value.id,
      description: pool.description,
      tokenSymbol: pool.tokenSymbol.toUpperCase(),
      contribution: Number(pool.royalties),
      basePrice: Number(poolNftBasePrice.value),
      maximumIPrice: Number(pool.maximumIPrice),
      tokensPerNft: Number(pool.tokensPerNft),
      causeId: Number(pool.socialCause?.id) || null,
      iValuePercentage: Number(pool.iValuePercentage),
      network: network,
    };

    try {
      if (poolCoverImg.value.type) {
        const result = await storeCoverImageFile();
        poolToPost.coverImageId = result.data.id;
      }
      await store.dispatch("pool/postPool", poolToPost);
      const isPoolCreated = computed(() =>
        store.getters["pool/getByKey"]("isPoolCreated")
      );
      const createdPool = computed(() =>
        store.getters["pool/getByKey"]("createdPool")
      );
      poolId = createdPool.value.id;
      if (isPoolCreated.value) {
        console.log(metadata.tokenId, metadata.nftContractAddress);
        try {
          await ethereumService.approveNft(
            metadata.tokenId,
            metadata.nftContractAddress
          );
          const isAllowed = await ethereumService.isAllowedTheos();

          if (!isAllowed) {
            await ethereumService.allowTheos();
          }
          await ethereumService.createPool(metadata);
        } catch (error) {
          await store.dispatch("pool/deletePool", createdPool.value.id);
          await showToast(
            "MetaMask error",
            "Your pool was not created.",
            "Error"
          );
          return await store.dispatch("pool/resetState");
        }
      }
    } catch (error) {
      store.dispatch("pool/pushError", error);
      return await store.dispatch("pool/resetState");
    }
    isCreatingPool.value = false;
    showPoolPreview.value = true;
    await store.dispatch("pool/resetState");
    return await router.push({
      name: "ViewPool",
      params: { id: poolId },
    });
  };

  const storeCoverImageFile = async () => {
    const fd = new FormData();
    fd.append("file", poolCoverImg.value);
    fd.append("type", "cover");
    return store.dispatch("pool/storeCoverImageFile", fd);
  };

  const fetchUserBalanceAndNftList = async () => {
    const publicAddress = await store.dispatch("contracts/fetchAddress");
    await store.dispatch("pool/fetchUserBalance", publicAddress);
    const params = {
      owner: publicAddress,
      orderBy: "id",
      page: 1,
      perPage: 1,
      orderDirection: "DESC",
      status: ["approved"],
    };
    await store.dispatch("pool/fetchUserNfts", queryString.stringify(params));
  };

  const returnToPreviousModal = () => {
    showPoolPreview.value = true;
    isCreatingPool.value = false;
  };
  const selectedNft = computed(() =>
    store.getters["nfts/getByKey"]("selectedNft")
  );

  const contractAddress = ref("");
  const tokenId = ref("");

  const removeSelectedNft = () => store.dispatch("nfts/selectNft", {});

  const previewImgSrc = ref(undefined);

  const showPoolPreviewModal = computed(() =>
    store.getters["pool/getByKey"]("showPoolPreviewModal")
  );

  const togglePoolPreviewModal = () =>
    store.dispatch("pool/togglePoolPreviewModal");

  const toggleSetPriceModal = () =>
    (showSetPriceModal.value = !showSetPriceModal.value);

  const closeSetPriceModal = () => (showSetPriceModal.value = false);

  const togglePoolPreview = () =>
    (showPoolPreview.value = !showPoolPreview.value);

  const initialNft = computed(() =>
    store.getters["nfts/getByKey"]("selectedNft")
  );

  const poolCoverImg = computed(() =>
    store.getters["pool/getByKey"]("coverImageFile")
  );
  const updateCoverImageFile = (file) => {
    store.dispatch("pool/commitByKey", { coverImageFile: file });
  };

  const setBackgroundUrl = (url) => {
    store.dispatch("pool/setBackgroundUrl", url);
  };

  const isAgreed = computed(() => store.getters["pool/getByKey"]("isAgreed"));

  const agree = (value) => store.dispatch("pool/agree", value);

  const tags = computed(() => store.getters["tagsValues/getByKey"]("tags"));

  const schema = computed(() => stepsArray.value[currentStep.value - 1].schema);

  const stepsArray = computed(() =>
    store.getters["pool/getByKey"]("stepsArray")
  );

  const currentStep = computed(() =>
    store.getters["pool/getByKey"]("currentStep")
  );

  const renderComponent = computed(
    () => stepsArray.value[currentStep.value - 1].component
  );

  const filteredNfts = computed(() =>
    nftsList.value.data.filter((nft) =>
      nft.name.toUpperCase().includes(searchString.value.toUpperCase())
    )
  );

  const selectNft = (nft) => {
    myEventBus.emit("changingSelectedNft", nft);
    store.dispatch("nfts/selectNft", nft);
  };

  myEventBus.on("changingSelectedCause", (cause) => {
    pool.socialCause = cause;
  });

  const selectedSocialCause = computed(() =>
    store.getters["socialCauses/getByKey"]("selectedSocialCause")
  );

  const removeSelectedSocialCause = () => {
    store.dispatch("socialCauses/setSelectedSocialCause", {});
    pool.socialCause = {};
  };

  const setIValuePercentage = (iValuePercentage) => {
    pool.iValuePercentage = iValuePercentage;
  };

  const searchString = ref("");

  const showDropdown = ref(false);

  const toggleShowDropdown = () => (showDropdown.value = !showDropdown.value);

  const switchState = ref(false);

  const toggleSwitchState = () => (switchState.value = !switchState.value);

  const switchStateImage = computed(() =>
    switchState.value === true
      ? require("@/Common/Icons/SwitchOn.png")
      : require("@/Common/Icons/SwitchOff.png")
  );

  const incrementStep = () => store.dispatch("pool/incrementStep");
  const decrementStep = () => store.dispatch("pool/decrementStep");

  const handleSubmit = () => {
    incrementStep();
  };

  const backgroundUrl = computed(() =>
    store.getters["pool/getByKey"]("backgroundUrl")
  );

  const isCauseCreated = computed(() =>
    store.getters["socialCauses/getByKey"]("isCreated")
  );

  const createSocialCause = async () => {
    const result = await store.dispatch(
      "socialCauses/createSocialCause",
      newSocialCause
    );
    if (isCauseCreated.value) {
      await showToast(
        "Social Cause Created",
        "You have successfully created the social cause.",
        "Success"
      );
      pool.socialCause = result;
    }
  };

  const hasUserNft = computed(() => {
    const userNftsList = store.getters["pool/getUserNfts"];
    return userNftsList.length > 0;
  });

  const hasUserTheos = computed(() => {
    const balance = store.getters["pool/getByKey"]("userBalance");
    return balance >= ethereumService.createPoolTheosPrice;
  });

  const poolOriginalState = () => ({
    name: "",
    slots: null,
    tokenName: "",
    description: "",
    tokenSymbol: "",
    royalties: null,
    socialCause: null,
    nftBasePrice: null,
    tokensPerNft: null,
    maximumIPrice: null,
  });

  const resetPoolState = () => Object.assign(pool, poolOriginalState());

  return {
    pool,
    tags,
    agree,
    schema,
    tokenId,
    isAgreed,
    selectNft,
    createPool,
    initialNft,
    stepsArray,
    hasUserNft,
    loadAllNfts,
    selectedNft,
    switchState,
    currentStep,
    handleSubmit,
    hasUserTheos,
    poolCoverImg,
    filteredNfts,
    searchString,
    showDropdown,
    incrementStep,
    decrementStep,
    previewImgSrc,
    backgroundUrl,
    isCauseCreated,
    newSocialCause,
    isCreatingPool,
    resetPoolState,
    showPoolPreview,
    renderComponent,
    contractAddress,
    setBackgroundUrl,
    switchStateImage,
    createSocialCause,
    togglePoolPreview,
    showSetPriceModal,
    toggleSwitchState,
    removeSelectedNft,
    poolOriginalState,
    toggleShowDropdown,
    closeSetPriceModal,
    toggleSetPriceModal,
    selectedSocialCause,
    setIValuePercentage,
    showPoolPreviewModal,
    updateCoverImageFile,
    returnToPreviousModal,
    togglePoolPreviewModal,
    removeSelectedSocialCause,
    fetchUserBalanceAndNftList,
    poolNftBasePrice,
  };
};

export default usePool;

<template>
  <div class="background" :style="{ right: showSlide }">
    <img
      :key="index"
      :alt="image.alt"
      :class="image.class"
      v-for="(image, index) in bgImagesList"
      :src="require(`@/Common/Icons/${image.src}.png`)"
    />
    <div class="slide">
      <div class="title-wrapper">
        <div class="headline">
          <span class="condensed">Buy, sell & trade on our </span>
          <span class="editorial">Marketplace</span>
        </div>
        <p class="sub-headline">
          Experience the
          <span class="primary">next-gen NFT platform</span>
          while supporting a cause.
        </p>
      </div>
    </div>
    <div class="slide">
      <div class="title-wrapper">
        <div class="headline">
          <span class="condensed">Tokenize </span>
          <span class="editorial">Anything</span>
        </div>
        <p class="sub-headline">
          Turn <span class="primary">anything you want into an NFT</span> with
          our easy to use drag & drop tool
        </p>
      </div>
    </div>
    <div class="slide">
      <div class="title-wrapper">
        <div class="headline">
          <span class="condensed">Get liquidity </span>
          <span class="editorial"
            >For your NFT<span class="lowercase">s</span></span
          >
        </div>
        <p class="sub-headline">
          Deposit your NFT in a pool and
          <span class="primary">get tradeable tokens!</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import SliderBackgroundImages from "@/utilities/SliderBackgroundImages";
export default {
  props: {
    showSlide: {
      default: "",
      type: String,
    },
  },
  setup() {
    const bgImagesList = SliderBackgroundImages;

    return {
      bgImagesList,
    };
  },
};
</script>

<style lang="scss" src="@/Common/Styles/sliderBackground.scss" scoped />

<template>
  <div class="share-popup" v-if="show">
    <teleport to="body">
      <div @click="fn()" class="share-popup-overlay" v-click-outside="fn" />
    </teleport>
    <p class="share-popup-text">{{ text }}</p>
    <div class="social-icons">
      <ShareNetwork
        :url="`${shareUrl}`"
        :title="getTitle(network.network)"
        :quote="sharing.quote"
        :key="network.network"
        :network="network.network"
        v-for="network in networks"
        :hashtags="network.network === 'facebook' ? '' : sharing.hashtags"
        @click="analytics(network.name)"
        :description="sharing.description"
        :twitterUser="sharing.twitterUser"
      >
        <span class="icon-for-share">
          <img
            :alt="network.name"
            :src="network.iconProperty.value"
            @mouseover="network.iconProperty.value = imgSrc(network.lightIcon)"
            @mouseleave="network.iconProperty.value = imgSrc(network.darkIcon)"
          />
        </span>
      </ShareNetwork>
      <span>
        <img
          alt="Share"
          :src="copyImg"
          class="copy-link pointer"
          @click="copyToClipboard(`${shareUrl}`)"
          @mouseover="copyImg = require('@/Common/Icons/CopyLight.svg')"
          @mouseleave="copyImg = require('@/Common/Icons/CopyDark.svg')"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import vClickOutside from "click-outside-vue3";
import useGoogleTag from "@/composables/useGoogleTag";
import useUtilities from "@/composables/useUtilities";
import networks from "@/json/socialMediaNetworks.json";
import socialTexts from "@/json/socialMediaTexts.json";
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    collectAnalytics: {
      type: Boolean,
      default: false,
    },
    fn: {
      type: Function,
      default: () => ({}),
    },
    url: {
      type: String,
      default: null,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup(props) {
    const { registerEvent } = useGoogleTag();
    const shareUrl = computed(() => (props.url ? props.url : location.href));
    const copyImg = ref(require("@/Common/Icons/CopyDark.svg"));
    const imgSrc = (image) => require(`@/Common/Icons/${image}.svg`);

    const { copyToClipboard } = useUtilities();

    const analytics = (name) => {
      if (!props.collectAnalytics) return;
      registerEvent(`Profile shared on ${name}`);
    };

    const sharing = ref({
      quote: "",
      description: socialTexts[props.type]["description"],
      hashtags: "THEOSNFT",
      twitterUser: "",
      title: socialTexts[props.type]["title"],
      titleTelegram: socialTexts[props.type]["titleTelegram"]
        ? socialTexts[props.type]["titleTelegram"]
        : "",
    });

    onMounted(() => {
      networks.forEach((network) => {
        network.iconProperty = ref(imgSrc(network.icon));
      });
    });

    const getTitle = (network) => {
      switch (network) {
        case "telegram":
          return sharing.value.title + " " + shareUrl.value;
        case "twitter":
          return [
            "personal_nft",
            "public_nft",
            "pooled_nft",
            "listed_nft",
          ].includes(props.type)
            ? "🧩🗻🗝 " + sharing.value.title
            : sharing.value.title;
        case "reddit":
          return [
            "personal_nft",
            "public_nft",
            "pooled_nft",
            "listed_nft",
          ].includes(props.type)
            ? "🧩🗻🗝 " + sharing.value.title + " #THEOSNFT"
            : sharing.value.title + " #THEOSNFT";
        default:
          return sharing.value.title;
      }
    };

    return {
      imgSrc,
      copyImg,
      sharing,
      networks,
      shareUrl,
      getTitle,
      analytics,
      copyToClipboard,
    };
  },
};
</script>

<style
  scoped
  lang="scss"
  src="@/Common/Styles/Share/socialMediaSharePopup.scss"
/>

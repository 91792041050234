import api from "@/services/api";

const initialState = () => ({
  isCreated: false,
  socialCausesArray: [],
  selectedSocialCause: {},
  errors: [],
});
const state = initialState();
const getters = {
  getByKey: (state) => (key) => state[key],
};
const mutations = {
  setSelectedSocialCause(state, cause) {
    state.selectedSocialCause = cause;
  },
  setSocialCauses(state, socialCauses) {
    state.socialCausesArray = socialCauses;
  },
  setIsCreated(state, status) {
    state.isCreated = status;
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
  pushError(state, error) {
    state.errors.push(error);
  },
};
const actions = {
  setSelectedSocialCause({ commit }, cause) {
    commit("setSelectedSocialCause", cause);
  },
  async fetchSocialCauses({ commit }) {
    try {
      const response = await api.fetchSocialCauses();
      commit("setSocialCauses", response.data.data);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async createSocialCause({ commit }, cause) {
    try {
      const result = await api.storeSocialCause(cause);
      commit("setIsCreated", true);
      return result.data;
    } catch (error) {
      commit("pushError", error);
    }
  },
  resetState({ commit }) {
    commit("resetState");
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div
    @click="fn()"
    :style="{
      borderColor: ['personal_pool', 'public_pool'].includes(type)
        ? '#434343'
        : 'inherit',
    }"
    :class="text.length > 0 ? 'share-button' : 'share-button-no-text'"
  >
    <img src="@/Common/Icons/share.png" alt="Share" v-if="shareIcon" />
    <p class="share-button-text" v-if="text.length > 0">{{ text }}</p>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    shareIcon: {
      type: Boolean,
      default: true,
    },
    fn: {
      type: Function,
      default: () => ({}),
    },
  },
};
</script>

<style
  scoped
  lang="scss"
  src="@/Common/Styles/Share/socialMediaShareButton.scss"
/>

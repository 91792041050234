<template>
  <app-buy-nft :showCloseLink="false">
    <template v-slot:app-modal-header>
      <div class="spinner file-input new">
        <app-loader :show="true" :isGreen="true" />
      </div>
      <h3 class="app-modal-buy-title">COMPLETING...</h3>
    </template>
    <template v-slot:app-modal-body>
      <p class="app-modal-body-text">
        To purchase the NFT complete this final transaction
      </p>
    </template>
  </app-buy-nft>
</template>

<script>
import AppBuyNft from "@/elements/AppBuyNft";
import AppLoader from "@/elements/AppLoader";
export default {
  name: "Completing",
  components: {
    AppBuyNft,
    AppLoader,
  },
  props: {
    nft: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/buyNft.scss" />

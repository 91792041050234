<template>
  <div
    v-if="
      nft.contractId &&
      !(['approved', 'pending-sale'].includes(nft.status) && !isOwner)
    "
    class="nft-details-section"
  >
    <div style="width: 100%">
      <p
        class="main-actions-highlight-text"
        style="margin-top: 32px"
        v-if="nft.status === 'approved'"
      >
        how do you want to sell your NFT?
      </p>
      <div class="main-actions-pooled-header" v-else>
        <div class="main-actions-price" v-if="!isAuctioned && !isPendingSale">
          <div class="price-container">
            <p class="main-actions-bold-text">
              {{ mainActionsList[nft.status].priceText }}
            </p>
            <app-tooltip :text="mainActionsList[nft.status].tooltipText" />
          </div>
          <div class="main-actions-highlight-text" style="margin-top: 16px">
            {{ toFixedIfNecessary(nft.sellPrice, 3) }}

            {{
              nft.auction.token ||
              nft.marketplace.buyingTokenSymbol ||
              pool.tokenSymbol ||
              "MATIC"
            }}
          </div>
          <div class="usd-price-text">
            ≈ ${{ toFixedIfNecessary(nft.sellPriceUSD, 3) }} USD
          </div>
        </div>
        <div class="main-actions-price" v-else>
          <div style="column-gap: 10px">
            <p class="main-actions-bold-text">
              {{ mainActionsList[nft.status].priceText }}
            </p>
            <app-tooltip
              :text="
                mainActionsList[nft.status][
                  hasBids ? 'with-bids' : 'without-bids'
                ].tooltipText
              "
            />
          </div>
          <div class="main-actions-highlight-text" style="margin-top: 16px">
            {{
              numberHasMoreThanThreeDecimals(
                hasBids ? nft.auction?.nextOffer : nft.auction?.startingPrice
              )
                ? "~"
                : ""
            }}
            {{ minimumBid() }}
            {{
              nft.auction.token ||
              nft.marketplace.buyingTokenSymbol ||
              pool.tokenSymbol ||
              "MATIC"
            }}
          </div>
          <div class="usd-price-text">
            ≈ ${{ minimumBid(true) }}
            USD
          </div>
        </div>
        <div class="main-actions-pool" v-if="isDeposited">
          <div class="main-actions-bold-text">Pool</div>
          <div class="pool-name">
            <div class="pool-thumbnail pointer">
              <img
                alt="Pool Image"
                :src="pool.coverImage?.thumbnail"
                @click="goTo({ name: 'ViewPool', params: { id: pool.id } })"
              />
            </div>
            <div class="tooltip-wrapper">
              <div
                class="pool-name-text"
                :class="pool.name?.length > 10 ? 'is-too-long' : 'no-tool-tip'"
              >
                <p
                  class="pointer"
                  @click="goTo({ name: 'ViewPool', params: { id: pool.id } })"
                >
                  {{ pool.name?.substring(0, 10) }}
                </p>
                <div class="tooltip-wrap">
                  <p class="tooltip">{{ pool.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-actions-pool" v-if="isAuctioned || isPendingSale">
          <div class="main-actions-bold-text" v-if="!isFinished">
            Ending {{ isEndingSoon ? "soon" : "in" }}
          </div>
          <div class="main-actions-bold-text" v-else>Ended</div>
          <div
            class="main-actions-timer"
            :class="
              isFinished
                ? 'main-actions-timer-finished'
                : isEndingSoon
                ? 'main-actions-timer-highlight'
                : ''
            "
          >
            <div class="timer-section">
              <p class="timer-number">
                {{ padZero(displayTimeRemaining.days) }}
              </p>
              <p class="timer-text">Days</p>
            </div>
            <div class="timer-section">
              <p class="timer-number">
                {{ padZero(displayTimeRemaining.hours) }}
              </p>
              <p class="timer-text">Hours</p>
            </div>
            <div class="timer-section">
              <p class="timer-number">
                {{ padZero(displayTimeRemaining.minutes) }}
              </p>
              <p class="timer-text">Minutes</p>
            </div>
            <div class="timer-section">
              <p class="timer-number">
                {{ padZero(Math.floor(displayTimeRemaining.seconds)) }}
              </p>
              <p class="timer-text">Seconds</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-actions-buttons" v-if="isDeposited">
      <button v-if="isOwner" @click="withdrawNft()" class="main-actions-button">
        Buy back from pool
      </button>
      <!-- hotfix for genesis pool-->
      <button
        @click="buyNft(nft)"
        v-show="!isBuyClicked"
        class="app-modal-button-inverse"
        v-else-if="parseInt(nft.id) !== 406"
      >
        Buy NFT
      </button>
    </div>
    <div
      class="main-actions-buttons main-actions-border"
      v-if="isAuctioned || isPendingSale"
    >
      <div class="auction-without-bids" v-if="!hasBids">
        <div class="auction-without-bids" v-if="!isFinished">
          <div class="bids-info">
            <img alt="Cloud" src="@/Common/Icons/cloudIcon.png" />
            <span>Without bids yet</span>
          </div>
          <button
            v-if="isOwner"
            @click="cancelAuction(nft)"
            class="main-actions-button-inverse"
          >
            Unlist NFT
          </button>
          <button v-else @click="bidOnNft(nft)" class="main-actions-button">
            Place Bid
          </button>
        </div>
        <div class="auction-without-bids" v-else>
          <button
            v-if="isOwner"
            @click="cancelAuction(nft)"
            style="margin-top: 16px"
            class="main-actions-button-inverse"
          >
            Finish auction without sell
          </button>
        </div>
      </div>
      <div class="auction-without-bids" v-else>
        <div class="auction-top-bidder-info">
          <div class="creator-owner-image">
            <div class="img-container" :class="'avatar-img-gray'">
              <img
                alt="CreatorImage"
                :src="topBidderImage(nft.auction?.highestBidder)"
                class="owner-img pointer"
                @click="goToProfileOfTopBidder(nft)"
              />
            </div>
            <div class="top-bid-container">
              <span class="by-text">
                Top bid by
                <span class="pointer" @click="goToProfileOfTopBidder(nft)">
                  {{ displayName(nft.auction?.highestBidder) }}
                </span>
              </span>
              <div class="top-bid-price">
                <span>
                  {{ numberHasMoreThanThreeDecimals(nft.sellPrice) ? "~" : "" }}
                  {{ toFixedIfNecessary(nft.sellPrice, 3) }}
                  {{ nft.auction?.token }}
                </span>
                <span class="by-text">
                  ≈ ${{ parseUsdValue(nft.sellPrice) }} USD
                </span>
              </div>
            </div>
          </div>
          <button
            v-if="isOwner"
            @click.prevent="toggleAcceptOfferFlag(nft)"
            class="top-bidder-button"
          >
            Sell NFT to Top Bidder
          </button>
        </div>
        <div>
          <div
            v-show="!isFinished && nft.auction?.reservedPrice"
            class="reserved-price-info"
          >
            <img alt="Cloud" :src="reservePriceImage" />
            <span>{{
              mainActionsList[nft.status]["with-bids"][
                reservePriceReached
                  ? "reservedPriceReachedText"
                  : "reservedPriceText"
              ]
            }}</span>
            <app-tooltip
              :text="
                mainActionsList[nft.status]['with-bids']
                  .reservedPriceTooltipText
              "
            />
          </div>
        </div>
        <button
          v-if="isOwner && isFinished && !reservePriceReached"
          @click="cancelAuction(nft)"
          class="main-actions-button-inverse"
        >
          Finish auction without sell
        </button>
        <button
          v-else-if="isOwner && !isFinished"
          class="disabled-unlist-button"
        >
          This auction already has bidders, you can't unlist it
        </button>
        <button
          v-else-if="!isOwner && !isFinished"
          @click="bidOnNft(nft)"
          style="margin-top: 32px"
          class="main-actions-button"
        >
          Place Bid
        </button>
      </div>
    </div>
    <div
      class="main-actions-buttons"
      v-else-if="isOwner && !isListed && !isDeposited"
    >
      <button @click="listNft(nft.id)" class="main-actions-button">
        List on marketplace
      </button>
      <button @click="depositNftSubmission()" class="main-actions-button">
        Submit into a Pool
      </button>
    </div>
    <div class="main-actions-buttons-div" v-if="isListed">
      <button
        v-if="isOwner"
        @click="unlistNft(nft)"
        class="main-actions-button-inverse"
      >
        Unlist NFT
      </button>
      <button
        v-else
        class="app-modal-button-inverse"
        @click="toggleBuyListed()"
      >
        Buy NFT
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { DateTime } from "luxon";
import { ethereumService } from "@/main";

import useBid from "@/composables/useBid";
import AppTooltip from "@/elements/AppTooltip";
import useRouter from "@/composables/useRouter";
import useBuyNft from "@/composables/useBuyNft";
import useListNft from "@/composables/useListNft";
import useDeposit from "@/composables/useDeposit";
import useUtilities from "@/composables/useUtilities";
import useNftDetails from "@/composables/useNftDetails";
import useBuyNftModal from "@/composables/useBuyNftModal";
import useNetworkData from "@/composables/useNetworkData";
import mainActionsList from "@/json/nftDetailsMainActions";
import useBuyListedNft from "@/composables/useBuyListedNft";
import useTimeUtilities from "@/composables/useTimeUtilities";

export default {
  components: { AppTooltip },
  props: {
    pool: {
      type: Object,
      default: () => {},
    },
  },
  setup: function () {
    const TIME_UNITS_ARRAY = ["days", "hours", "minutes", "seconds"];
    const ENDING_SOON_THRESHOLD = { days: 0, hours: 4, minutes: 0, seconds: 0 };

    const store = useStore();
    const { goTo } = useRouter();
    const { unlistNft } = useBuyNft(store);
    const {
      toFixedIfNecessary,
      isStringTooLong,
      numberHasMoreThanThreeDecimals,
    } = useUtilities();
    const { toggleAcceptOfferFlag } = useBuyNftModal(store);
    const {
      nft,
      buyNft,
      isOwner,
      hasBids,
      isListed,
      avatarImage,
      isAuctioned,
      isDeposited,
      withdrawNft,
      isBuyClicked,
      isPendingSale,
      topBidderImage,
      goToUserProfile,
      insufficientFunds,
      redirectToSushiSwap,
      goToProfileOfTopBidder,
    } = useNftDetails(store);

    const { listNft, cancelAuction } = useListNft(store);
    const { updateChosenNft } = useDeposit(store);
    const { bidOnNft } = useBid(store);
    const { isNftOnCurrentNetwork } = useNetworkData(ethereumService);

    const endingTime = computed(() =>
      DateTime.fromISO(nft.value.auction.endingTime)
    );
    const { fetchNftToBuy, toggleBuyListedNftFlag } = useBuyListedNft(store);
    const { getDateDiff, TIME_NOW, isRemainingLessThan } = useTimeUtilities();

    const toggleBuyListed = async () => {
      await fetchNftToBuy(nft.value.id);
      toggleBuyListedNftFlag();
    };

    const depositNftSubmission = () => {
      if (isNftOnCurrentNetwork(nft.value)) {
        goTo({ name: "DepositNft", query: { profileNftId: nft.value.id } });
        return updateChosenNft(nft.value);
      }
      return store.dispatch("auth/commitByKey", {
        switchNetworkModalFlag: true,
      });
    };

    const padZero = (value) => `0${value}`.slice(-2);

    const isFinished = computed(() =>
      isRemainingLessThan(endingTime.value, TIME_NOW.value, { seconds: 1 })
    );

    const isEndingSoon = computed(() =>
      isRemainingLessThan(
        endingTime.value,
        TIME_NOW.value,
        ENDING_SOON_THRESHOLD
      )
    );

    const finished = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    const displayTimeRemaining = computed(() =>
      isFinished.value
        ? finished
        : getDateDiff(endingTime.value, TIME_NOW.value, TIME_UNITS_ARRAY)
    );

    const displayName = (user) => {
      if (user.displayName) return user.displayName;
      if (!user.publicAddress) return "";
      return `${user.publicAddress?.slice(0, 6)}...${user.publicAddress.slice(
        -4
      )}`;
    };

    const reservePriceReached = computed(() =>
      nft.value.auction?.reservedPrice
        ? nft.value.sellPrice >= nft.value.auction?.reservedPrice
        : null
    );

    const reservePriceImage = computed(() =>
      reservePriceReached.value
        ? require("@/Common/Icons/bolt.png")
        : require("@/Common/Icons/cloudIcon.png")
    );

    const maticPriceUsd = computed(() =>
      store.getters["contracts/getCoinPrice"]("matic-network", "usd")
    );

    const ethPriceUsd = computed(() =>
      store.getters["contracts/getCoinPrice"]("ethereum", "usd")
    );

    const parseUsdValue = (price) => {
      if (!price) return 0;
      price = toFixedIfNecessary(price, 3);
      if (["polygon", "mumbai"].includes(nft.value.network)) {
        return toFixedIfNecessary(+price * maticPriceUsd.value.value, 3);
      }
      return toFixedIfNecessary(+price * ethPriceUsd.value.value, 3);
    };

    const minimumBid = (isUsd = false) => {
      if (isFinished.value) {
        return 0;
      }
      const bid = isUsd
        ? parseUsdValue(nft.value.auction?.nextOffer)
        : nft.value.auction?.nextOffer;
      const price = isUsd
        ? parseUsdValue(nft.value.auction?.startingPrice)
        : nft.value.auction?.startingPrice;
      return toFixedIfNecessary(hasBids.value ? bid : price, 3);
    };

    return {
      nft,
      goTo,
      buyNft,
      hasBids,
      listNft,
      isOwner,
      padZero,
      bidOnNft,
      isListed,
      unlistNft,
      minimumBid,
      isFinished,
      displayName,
      avatarImage,
      isAuctioned,
      withdrawNft,
      isDeposited,
      isEndingSoon,
      isBuyClicked,
      parseUsdValue,
      cancelAuction,
      isPendingSale,
      topBidderImage,
      goToUserProfile,
      mainActionsList,
      toggleBuyListed,
      isStringTooLong,
      insufficientFunds,
      reservePriceImage,
      toFixedIfNecessary,
      reservePriceReached,
      redirectToSushiSwap,
      displayTimeRemaining,
      depositNftSubmission,
      toggleAcceptOfferFlag,
      goToProfileOfTopBidder,
      numberHasMoreThanThreeDecimals,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/nftDetailsMainActions.scss" />

const initialState = () => ({
  swapFlag: false,
  defaultInputToken: "",
  defaultOutputToken: "",
  congratsModalText: "",
  congratsModalButtonText: "",
  openModalSrc: "",
  errors: [],
});

const state = initialState();
const getters = {
  getByKey: (state) => (key) => state[key],
};
const mutations = {
  setByKey(state, obj) {
    state[obj.key] = obj.value;
  },
  toggleSwapFlag(state) {
    state.swapFlag = !state.swapFlag;
  },
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
};

const actions = {
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  resetState({ commit }) {
    commit("resetState");
  },
  toggleSwapFlag({ commit }) {
    try {
      commit("toggleSwapFlag");
    } catch (error) {
      commit("pushError", error);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { ethereumService } from "@/main";
import AppModal from "@/elements/AppModal";
import AppButton from "@/elements/AppButton";
import useNetworkData from "@/composables/useNetworkData";

export default {
  name: "SwitchNetworkModal",
  components: {
    AppModal,
    AppButton,
  },
  setup() {
    const store = useStore();
    const closeModal = () => {
      store.dispatch("auth/commitByKey", {
        switchNetworkModalFlag: false,
      });
    };
    const { parsedNetworkName } = useNetworkData(ethereumService);

    const switchNetworkModalFlag = computed(() =>
      store.getters["auth/getByKey"]("switchNetworkModalFlag")
    );

    const switchNetwork = async () => {
      await ethereumService.switchNetwork();
      closeModal();
    };

    const switchToNetworkName = computed(() =>
      parsedNetworkName.value === "Polygon" ? "Ethereum" : "Polygon"
    );

    return {
      closeModal,
      switchNetwork,
      parsedNetworkName,
      switchToNetworkName,
      switchNetworkModalFlag,
    };
  },
};
</script>

<template>
  <teleport to="body">
    <app-modal
      v-if="switchNetworkModalFlag"
      :closeButton="true"
      :returnToPreviousModal="closeModal"
    >
      <template v-slot:app-modal-body>
        <div class="app-modal-body-wrapper">
          <img src="@/Common/Icons/TheosLogo.png" alt="Logo" class="logo-gif" />
          <div class="text-wrap">
            <p class="text-polygon">Switch Networks</p>
            <p class="info-text">
              It seems that you are on the wrong network. Switch to a
              {{ switchToNetworkName }} wallet
            </p>
          </div>
          <div class="modal-buttons">
            <app-button
              @click="switchNetwork"
              text="Switch Networks"
              buttonClass="app-modal-button-inverse"
            />
          </div>
          <app-button
            @click="closeModal"
            text="Go back"
            buttonClass="app-modal-button"
          />
        </div>
      </template>
    </app-modal>
  </teleport>
</template>

<style
  lang="scss"
  scoped
  src="@/Common/Styles/connectionAndPolygonModal.scss"
></style>

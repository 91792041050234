<template>
  <div>
    <div class="title">
      FIRST, <span class="bold">CHOOSE A LISTING METHOD</span>
    </div>
    <div class="moto">
      <img src="@/Common/Icons/rightArrow.svg" alt="->" class="arrows" />
      <p>WE ARE THE BEST</p>
      <img src="@/Common/Icons/leftArrow.svg" alt="<-" class="arrows" />
    </div>

    <div class="description">
      <p>You can set a fixed price or create an auction.</p>
      <p>When you are done you will see your NFT on our marketplace.</p>
    </div>
  </div>
</template>
<style lang="scss" scoped src="@/Common/Styles/auctionModal.scss"></style>

<template>
  <div class="deposit-panel-filter" :class="[address ? 'not-connected' : '']">
    <div class="deposit-panel-filter-wrapper">
      <div class="deposit-panel-filter-overlay" @click="toggle('sort')" />
      <app-input
        disabled
        placeholder="Filter"
        inputClass="app-modal-input-sort"
        inputIconLeftClass="filter-icon-1"
        :inputStyle="'padding-left: 35px;'"
        :inputIconRightClass="{
          'chevron-icon': true,
          active: showSort,
        }"
      />
    </div>

    <teleport to="body">
      <div
        class="filtering-options-overlay"
        v-if="showSort"
        @click="toggle('sort')"
      />
    </teleport>

    <div class="filtering-options" v-if="showSort">
      <h3 class="filtering-options-heading">Network</h3>
      <app-checkbox
        id="4"
        class="filtering-option-checkbox"
        :styleClass="'marketplace'"
        labelText="Ethereum"
        :checked="isChecked('network', 'ethereum')"
        @click="filter({ type: 'network', value: 'ethereum' })"
      />
      <app-checkbox
        id="5"
        labelText="Polygon"
        :styleClass="'marketplace'"
        class="filtering-option-checkbox"
        :checked="isChecked('network', 'polygon')"
        @click="filter({ type: 'network', value: 'polygon' })"
      />
      <h3 class="filtering-options-heading">Type of media</h3>
      <app-checkbox
        v-for="(item, index) in sortFilterOptions.filterMediaArray"
        :key="index"
        :id="index"
        :labelText="item.labelText"
        :styleClass="'marketplace'"
        class="filtering-option-checkbox"
        :checked="isChecked(item.type, item.value)"
        @click="filter({ type: item.type, value: item.value })"
      />
      <h3 class="filtering-options-heading">Price range</h3>
      <app-checkbox
        v-for="(item, index) in sortFilterOptions.filterPriceRangeArray"
        :key="index"
        :id="index"
        :labelText="item.labelText"
        :styleClass="'marketplace'"
        class="filtering-option-checkbox"
        :checked="isChecked(item.type, item.priceRange)"
        @click="filter({ type: item.type, priceRange: item.priceRange })"
      />
      <p class="clear-filter pointer" @click="filter({ type: 'clear' })">
        Clear filters
      </p>
    </div>
  </div>
  <div class="deposit-panel-filter" :class="[address ? 'not-connected' : '']">
    <div class="deposit-panel-filter-wrapper">
      <div class="deposit-panel-filter-overlay" @click="toggle('filter')" />
      <app-input
        disabled
        placeholder="Sort by"
        inputClass="app-modal-input-sort"
        inputIconLeftClass="filter-icon"
        :inputStyle="'padding-left: 35px;'"
        :inputIconRightClass="{
          'chevron-icon': true,
          active: showFilters,
        }"
      />
    </div>
    <teleport to="body">
      <div
        class="filtering-options-overlay"
        v-if="showFilters"
        @click="toggle('filter')"
      />
    </teleport>
    <div class="filtering-options" v-if="showFilters">
      <p
        v-for="item in sortFilterOptions.sortArray"
        :key="generateSortId(item)"
        class="filtering-option"
        :class="{ active: activeSort === generateSortId(item) }"
        @click="
          sortFunction({
            orderBy: item.orderBy,
            orderDirection: item.orderDirection,
          });
          setFiltersFlag(false);
        "
      >
        {{ item.title }}
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import AppInput from "@/elements/AppInput";
import AppCheckbox from "@/elements/AppCheckbox";
import useMarketplace from "@/composables/useMarketplace";
import sortFilterOptions from "@/json/marketplaceSortFilterOptions";
export default {
  components: {
    AppInput,
    AppCheckbox,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    sortFunction: {
      type: Function,
      default: () => {},
    },
    sortOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const store = useStore();
    const { filter, isChecked, activeSort } = useMarketplace(store);
    const showFilters = ref(false);
    const showSort = ref(false);
    const filtersFlag = ref(false);
    const toggle = (el) => {
      if (el === "filter") {
        showFilters.value = !showFilters.value;
        showSort.value = false;
        return;
      }
      if (el === "sort") {
        showSort.value = !showSort.value;
        showFilters.value = false;
        return;
      }
      showSort.value = !showSort.value;
    };
    const setFiltersFlag = (flag) => {
      filtersFlag.value = flag;
    };
    const generateSortId = (obj) =>
      `${obj.orderBy}_${obj.orderDirection.toLowerCase()}`;
    return {
      filter,
      toggle,
      showSort,
      isChecked,
      activeSort,
      showFilters,
      setFiltersFlag,
      generateSortId,
      sortFilterOptions,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/Sort.scss" />

import { computed } from "vue";
import { ethereumService } from "@/main";
import useAuth from "@/composables/useAuth";
import useToast from "@/composables/useToast";
import useInsufficientFunds from "@/composables/useInsufficientFunds";
import useNetworkData from "./useNetworkData";

const useBuyListedNft = (store) => {
  const { toggleWalletConnectionRequired } = useAuth(store, ethereumService);

  const { toggleInsufficientFundsModal } = useInsufficientFunds(
    store,
    ethereumService
  );

  const { isNftOnCurrentNetwork } = useNetworkData(ethereumService);

  const { showToast } = useToast();
  const buyListedNftFlag = computed(() =>
    store.getters["buyListedNft/getByKey"]("buyListedNftFlag")
  );

  const me = computed(() => store.getters["auth/getRegisteredUser"]);

  const toggleBuyListedNftFlag = async () => {
    const address = computed(() => store.getters["contracts/getAddress"]);
    if (!address.value) {
      try {
        await toggleWalletConnectionRequired();
      } catch (_error) {
        await showToast("MetaMask error", "Wallet connection error", "Error");
      }
      return;
    }
    await store.dispatch("buyListedNft/toggleBuyListedNftFlag");
  };

  const nft = computed(() => store.getters["buyListedNft/getByKey"]("nft"));
  const currentStep = computed(() =>
    store.getters["buyListedNft/getByKey"]("currentStep")
  );
  const currentStepsArray = computed(() =>
    store.getters["buyListedNft/getByKey"]("currentStepsArray")
  );
  const commitToStore = (data) => {
    store.dispatch("buyListedNft/commitByKey", data);
  };
  const resetState = () => store.dispatch("buyListedNft/resetState");
  const incrementStep = () => {
    store.dispatch("buyListedNft/incrementStep");
  };
  const maticPriceUsd = computed(() =>
    store.getters["contracts/getCoinPrice"]("matic-network", "usd")
  );
  const fetchNftToBuy = async (id) => {
    await store.dispatch("buyListedNft/fetchNft", id);
  };
  const closeModal = () => {
    toggleBuyListedNftFlag();
    resetState();
  };

  const decrementStep = () => store.dispatch("buyListedNft/decrementStep");

  const buyListedNft = async (tokensRequired = null) => {
    incrementStep();
    try {
      const tx = await ethereumService.buyListedNft(
        nft.value.marketplace.price,
        nft.value.contractAddress,
        nft.value.contractId
      );
      if (tx.reason === "rejected") {
        await showToast("Rejected", "Transaction rejected", "Error");
        decrementStep();
        return false;
      }
      await showToast(
        "NFT Bought",
        "You have successfully bought the NFT",
        "Success"
      );
      location.reload();
    } catch (error) {
      decrementStep();
      if (error.data.message.includes("insufficient funds")) {
        await toggleBuyListedNftFlag();
        toggleInsufficientFundsModal(tokensRequired);
        return;
      }
      await showToast("MetaMask error", "NFT was not purchased", "Error");
    }
  };

  const toggleBuyListed = async (token) => {
    if (isNftOnCurrentNetwork(token)) {
      if (!me.value.publicAddress) {
        toggleWalletConnectionRequired();
        return;
      }
      await fetchNftToBuy(token.id);
      const userBalance = computed(() => store.getters["contracts/getBalance"]);
      if (Number(userBalance.value.default) >= Number(token.sellPrice)) {
        return await toggleBuyListedNftFlag(token);
      }
      return await toggleInsufficientFundsModal(
        token,
        userBalance.value.default
      );
    }
    return store.dispatch("auth/commitByKey", {
      switchNetworkModalFlag: true,
    });
  };

  return {
    nft,
    closeModal,
    resetState,
    currentStep,
    buyListedNft,
    commitToStore,
    decrementStep,
    incrementStep,
    maticPriceUsd,
    fetchNftToBuy,
    toggleBuyListed,
    buyListedNftFlag,
    currentStepsArray,
    toggleBuyListedNftFlag,
  };
};

export default useBuyListedNft;

<template>
  <app-modal
    :closeButton="true"
    :returnToPreviousModal="toggleInsufficientFundsModal"
  >
    <template v-slot:app-modal-header>
      <img src="@/Common/Icons/TheosLogoYellow.png" alt="Theos" />
      <h3 class="app-modal-welcome-title-smaller connect-wallet-title">
        Insufficient Funds
      </h3>
    </template>
    <template v-slot:app-modal-body>
      <p class="app-modal-body-text connect-wallet-text">You need to buy</p>
      <p class="index-pool-token">
        <span class="primary"
          >{{ missingFunds() }} {{ currencySymbol(tokenToBuy) }}
        </span>
        <span class="secondary"> ≈${{ missingFundsToUSD() }} </span>
      </p>
      <p class="app-modal-body-subtext">
        You can buy it with most tokens traded on sushiswap-polygon
      </p>
      <app-button
        :text="`Buy ${currencySymbol(tokenToBuy)}`"
        @click="redirectToSushiSwap(tokenAddress())"
        class="app-modal-button-inverse"
      />
    </template>
  </app-modal>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { ethereumService } from "@/main";
import AppModal from "@/elements/AppModal";
import AppButton from "@/elements/AppButton";
import useInsufficientFunds from "@/composables/useInsufficientFunds";

export default {
  components: {
    AppModal,
    AppButton,
  },
  setup() {
    const store = useStore();
    const {
      bidValue,
      tokenToBuy,
      userBalance,
      fetchBalance,
      redirectToSushiSwap,
      toggleInsufficientFundsModal,
    } = useInsufficientFunds(ethereumService, store);

    const currencySymbol = (token) => {
      if (!token) return;

      if (
        token.status === "listed" &&
        ["mumbai", "polygon"].includes(token.network)
      )
        return "MATIC";
      if (
        token.status === "auctioned" &&
        ["mumbai", "polygon"].includes(token.network)
      )
        return "wMATIC";
      if (
        token.status === "listed" &&
        ["goerli", "ethereum"].includes(token.network)
      )
        return "ETH";
      if (
        token.status === "auctioned" &&
        ["goerli", "ethereum"].includes(token.network)
      )
        return "wETH";
      return token?.pools[0].tokenSymbol;
    };

    const balance = computed(() => store.getters["contracts/getBalance"]);

    const coinPrice = () => {
      if (tokenToBuy?.value?.status === "deposited")
        return (
          tokenToBuy.value.poolTokenPrice ||
          tokenToBuy.value.pools[0]?.tokenPrice
        );
      if (["mumbai", "polygon"].includes(tokenToBuy?.value?.network))
        return store.getters["contracts/getCoinPrice"]("matic-network", "usd")
          .value;
      if (["goerli", "ethereum"].includes(tokenToBuy?.value?.network))
        return store.getters["contracts/getCoinPrice"]("ethereum", "usd").value;
    };

    const userPoolTokenBalance = computed(() =>
      store.getters["checkFunds/getByKey"]("userBalance")
    );

    const tokenAddress = () => {
      if (tokenToBuy?.value?.status === "listed") return "ETH";
      if (tokenToBuy?.value?.status === "deposited")
        return tokenToBuy?.value?.pools[0].tokenAddress;
    };

    const missingFunds = () => {
      if (tokenToBuy?.value?.status === "listed")
        return parseFloat(
          tokenToBuy?.value?.marketplace.price - balance?.value.default
        ).toFixed(5);

      if (tokenToBuy?.value?.status === "deposited") {
        return parseFloat(
          tokenToBuy?.value?.price ||
            tokenToBuy?.value?.sellPrice - userPoolTokenBalance?.value
        ).toFixed(5);
      }

      if (tokenToBuy?.value?.status === "auctioned")
        return parseFloat(
          (bidValue.value - userBalance.value).toString()
        ).toFixed(5);
    };

    const missingFundsToUSD = () => {
      return parseFloat((missingFunds() * coinPrice()).toString()).toFixed(5);
    };

    return {
      tokenToBuy,
      tokenAddress,
      missingFunds,
      fetchBalance,
      currencySymbol,
      missingFundsToUSD,
      redirectToSushiSwap,
      toggleInsufficientFundsModal,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/commonModal.scss" />

import * as Yup from "yup";

export const mintNftDetailsScheme = Yup.object().shape({
  Royalties: Yup.number()
    .transform((number) => (isNaN(number) ? undefined : number))
    .positive()
    .min(0, "Min value must be larger then 0")
    .max(20, "Max value must be less than 20")
    .nullable()
    .test(
      "MAX_DECIMALS",
      "Royalties field must have 2 decimals or less",
      (number) =>
        new RegExp(/^[0-9]\d*?(?:\.\d{0,2})?$|^null$|^undefined$/g).test(number)
    ),
  AlternativeText: Yup.string().max(
    200,
    "The maximum allowed length is 200 characters"
  ),
});

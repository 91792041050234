<template>
  <base-form
    style="width: 100%; height: 100%"
    id="list-nft"
    method="post"
    :handleSubmit="confirmNftListing"
    :schema="nftListSchema"
  >
    <div class="last-check-wrapper">
      <div class="body">
        <div class="last-check-title">
          LAST CHECK <span class="title-span">OF ACTION RULES!</span>
        </div>
      </div>
      <div class="ul-wrapper">
        <ul>
          <li>
            The auction only can be cancelled if reserve price hasn't been
            reached.
          </li>
          <li>
            The auction will only end at the expiration date you set, but you
            can accept the top bid and sell at any time.
          </li>
          <li>
            Once finished: <br />
            • If the reserve price has been reached or you do not set it, the
            transaction will be automatically executed to the top bidder. <br />
            • If the reserve price has not been reached, you'll have to confirm
            the sale to the highest bidder within 5 days of completion. If you
            do not do so within this period the auction will be cancelled.
          </li>
        </ul>

        <app-checkbox
          id="1"
          labelText="I understand and I don’t need to read this again"
          :styleClass="'last-check'"
          class="checkbox"
          :checked="isChecked"
          @click="toggleChecked()"
        />
      </div>
      <div class="footer footer-buttons">
        <app-button
          :style="'margin-right: 1rem'"
          text="Back to review"
          buttonClass="app-modal-button"
          @click="backToReview()"
          @keypress.enter.prevent
          @submit.prevent
        />
        <app-button
          type="submit"
          text="List auction"
          buttonClass="app-modal-button-inverse"
          @click="closeLastCheck(isChecked)"
        />
      </div>
    </div>
  </base-form>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import AppButton from "@/elements/AppButton";
import BaseForm from "@/components/BaseForm.vue";
import useListNft from "@/composables/useListNft";
import AppCheckbox from "@/elements/AppCheckbox";
export default {
  name: "LastCheckLister",
  components: {
    BaseForm,
    AppButton,
    AppCheckbox,
  },
  setup() {
    const isChecked = ref(false);

    const { nft, backToReview, setSelectedType, closeLastCheck } = useListNft(
      useStore()
    );

    const toggleChecked = () => {
      isChecked.value = !isChecked.value;
    };

    return {
      nft,
      isChecked,
      backToReview,
      toggleChecked,
      closeLastCheck,
      setSelectedType,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/auctionModal.scss"></style>

import * as Yup from "yup";

const URL =
  "(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})";

const email = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const registrationDetailsSchema = Yup.object().shape({
  email: Yup.string().required().matches(email, {
    message: "Please enter a valid email address",
  }),
  websiteUrl: Yup.string().nullable().notRequired().matches(URL, {
    message: "Please enter a valid url",
    excludeEmptyString: true,
  }),
});

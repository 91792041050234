import { ref, computed } from "vue";
import useStoreFunctions from "./useStoreFunctions";
import { eventBus } from "@/main";
import { ethereumService } from "../main";

const currentPanel = ref("nfts");
const MILLISECONDS_MINIMUM_CARD_LOADING = 0;

const statusMapper = {
  nfts: ["approved", "deposited", "listed", "auctioned", "pending-sale"],
  listed: ["listed", "auctioned"],
  pooledNfts: ["deposited"],
};

const useProfileView = (store, storeFunctions = useStoreFunctions) => {
  const {
    computeStore: profileGet,
    dispatchStore: profileDispatch,
    commitByKey: profileCommitByKey,
  } = storeFunctions("profilePanels");

  const publicUser = computed(() =>
    store.getters["userProfile/getByKey"]("publicUser")
  );

  const data = profileGet("data");
  const theosMinted = profileGet("theosMinted");
  const loadingNfts = profileGet("loadingNfts");
  const loadingMore = profileGet("loadingMore");
  const loadingPools = profileGet("loadingPools");
  const loadingPanels = profileGet("loadingPanels");
  const myId = computed(() => store.getters["userProfile/getByKey"]("user").id);
  const walletAddress = computed(() =>
    store.getters["userProfile/getByKey"]("publicUser").id != null
      ? store.getters["userProfile/getByKey"]("publicUser").publicAddress
      : store.getters["contracts/getAddress"]
  );

  const searchQuery = computed(() =>
    store.getters["profilePanels/getByKey"]("searchString")
  );

  const setCurrentPanel = async (value) => {
    if (loadingNfts.value || loadingPools.value) return;
    currentPanel.value = value;
    await switchPanel(value);
  };

  const resetQueries = async () => await profileDispatch("resetQueries");

  const theosMintedCheckboxIcon = (value) =>
    value
      ? require("@/Common/Icons/radio-minting-checked.png")
      : require("@/Common/Icons/radio-minting-unchecked.png");

  const switchPanel = async (nextPanel) => {
    localStorage.setItem("persistedProfilePanel", nextPanel);
    await profileCommitByKey({ loadingNfts: true });
    await profileCommitByKey({ theosMinted: true });
    await resetQueries();
    eventBus.emit("resetSearchString");
    await profileDispatch("resetDataArray", nextPanel);

    if (!["pools", "submissions", "external"].includes(nextPanel)) {
      await profileDispatch("fetchNftList", {
        owner: walletAddress.value,
        tab: nextPanel,
        status: statusMapper[nextPanel].join(","),
      });
    }
    if (nextPanel === "external") {
      await profileCommitByKey({ theosMinted: false });
      await profileDispatch("fetchNftList", {
        owner: walletAddress.value,
        tab: "external",
        id: publicUser.value.id ? publicUser.value.id : myId.value,
      });
    }
    if (nextPanel === "pools") {
      await profileDispatch("fetchPoolsList", {
        originatorId: publicUser.value.id ? publicUser.value.id : myId.value,
      });
    }

    if (nextPanel === "submissions") {
      await profileDispatch("fetchSubmissionsCount");
    }
    setTimeout(async () => {
      await profileCommitByKey({ loadingNfts: false });
    }, MILLISECONDS_MINIMUM_CARD_LOADING);
  };

  const setTheosMinted = async (val = true) => {
    await resetQueries();
    await profileCommitByKey({ theosMinted: val });
    if (loadingNfts.value) return;
    await profileCommitByKey({ loadingNfts: true });
    setTimeout(async () => {
      if (theosMinted.value) {
        await profileDispatch("resetDataArray", "nfts");
        await profileDispatch("fetchNftList", {
          status: "approved",
          owner: walletAddress.value,
        });
        await profileCommitByKey({ loadingNfts: false });
        return;
      }
      await profileDispatch("resetDataArray", "external");
      await profileDispatch("fetchNftList", { id: myId.value });
      await profileCommitByKey({ loadingNfts: false });
    }, MILLISECONDS_MINIMUM_CARD_LOADING);
  };

  const initializePanels = async (isPublic = false) => {
    const persistedPanel = localStorage.getItem("persistedProfilePanel");
    currentPanel.value = persistedPanel || "nfts";
    await profileDispatch("resetState");
    if (persistedPanel === "external") {
      await profileCommitByKey({ theosMinted: false });
    }
    await profileCommitByKey({ loadingPanels: true });
    await profileDispatch("fetchPoolsList", {
      originatorId: publicUser.value.id ? publicUser.value.id : myId.value,
    });
    await profileDispatch("fetchNftList", {
      tab: "nfts",
      status: statusMapper["nfts"].join(","),
      owner: walletAddress.value,
    });
    await profileDispatch("fetchNftList", {
      tab: "pooledNfts",
      status: statusMapper["pooledNfts"].join(","),
      owner: walletAddress.value,
    });
    await profileDispatch("fetchNftList", {
      tab: "listed",
      status: statusMapper["listed"].join(","),
      owner: walletAddress.value,
    });
    if (!isPublic) {
      await profileDispatch("fetchNftList", {
        tab: "external",
        status: "",
        id: publicUser.value.id ? publicUser.value.id : myId.value,
      });
    }
    await profileDispatch("fetchSubmissionsCount");
    await profileCommitByKey({ loadingPanels: false });
  };

  const refreshPanels = async () => {
    await profileCommitByKey({ loadingPanels: true });
    await profileDispatch("fetchPoolsList", { originatorId: myId.value });
    await profileDispatch("fetchNftList", {
      tab: "nfts",
      status: statusMapper["nfts"].join(","),
      owner: walletAddress.value,
    });
    await profileDispatch("fetchNftList", {
      tab: "pooledNfts",
      status: statusMapper["pooledNfts"].join(","),
      owner: walletAddress.value,
    });
    await profileDispatch("fetchNftList", {
      tab: "listed",
      status: statusMapper["listed"].join(","),
      owner: walletAddress.value,
    });

    await profileDispatch("fetchNftList", {
      tab: "external",
      status: "",
      id: publicUser.value.id ? publicUser.value.id : myId.value,
    });

    await profileDispatch("fetchSubmissionsCount");
    await profileCommitByKey({ loadingPanels: false });
  };

  const search = async (searchStringInput) => {
    await profileCommitByKey({ searchString: searchStringInput });
    if (loadingNfts.value) return;
    await resetQueries();
    await profileCommitByKey({ loadingNfts: true });
    await profileDispatch("resetDataArray", currentPanel.value);
    if (currentPanel.value === "pools") {
      await profileDispatch("fetchPoolsList", {
        search: searchStringInput,
        originatorId: publicUser.value.id ? publicUser.value.id : myId.value,
      });
      return await profileCommitByKey({ loadingNfts: false });
    }

    if (["pooledNfts", "listed", "nfts"].includes(currentPanel.value)) {
      await profileDispatch("fetchNftList", {
        tab: currentPanel.value,
        search: searchStringInput,
        owner: walletAddress.value,
        status: statusMapper[currentPanel.value].join(","),
      });
    }
    await profileCommitByKey({ loadingNfts: false });
  };

  const loadMore = async () => {
    if (loadingMore.value || currentPanel.value === "external") return;
    await profileCommitByKey({ loadingMore: true });
    const loadParams = {
      tab: currentPanel.value,
      status: statusMapper[currentPanel.value].join(","),
      owner: walletAddress.value,
    };

    setTimeout(async () => {
      await profileDispatch("incrementPage");
      if (searchQuery.value) {
        loadParams["search"] = searchQuery.value;
      }
      if (currentPanel.value === "pools") {
        await profileDispatch("fetchPoolsList", {
          originatorId: publicUser.value.id ? publicUser.value.id : myId.value,
        });
        return await profileCommitByKey({ loadingMore: false });
      }
      await profileDispatch("fetchNftList", loadParams);
      await profileCommitByKey({ loadingMore: false });
    }, MILLISECONDS_MINIMUM_CARD_LOADING);
  };

  const loadMoreExternal = async () => {
    await profileDispatch("incrementPage");
    await profileCommitByKey({ loadingMore: true });

    setTimeout(async () => {
      await profileDispatch("incrementPage");
      await profileDispatch("fetchNftList", {
        tab: "external",
        status: "",
        id: publicUser.value.id ? publicUser.value.id : myId.value,
      });
      await profileCommitByKey({ loadingMore: false });
    }, MILLISECONDS_MINIMUM_CARD_LOADING);
  };

  const sortBy = async (sort) => {
    await profileDispatch("sortBy", {
      orderDirection: sort.orderDirection,
      orderBy: sort.orderBy,
      tab: currentPanel.value,
    });

    await profileDispatch("fetchNftList", {
      tab: currentPanel.value,
      status: statusMapper[currentPanel.value].join(","),
      owner: walletAddress.value,
      search: searchQuery.value,
    });
  };

  const sortOptions = () => {
    if (currentPanel.value === "nfts") {
      return [
        {
          title: "Newest",
          value: {
            orderDirection: "DESC",
            orderBy: "id",
          },
        },
        {
          title: "Oldest",
          value: {
            orderDirection: "ASC",
            orderBy: "id",
          },
        },
      ];
    }

    if (currentPanel.value === "pooled")
      return [
        {
          title: "Newest",
          value: {
            orderDirection: "DESC",
            orderBy: "id",
          },
        },
        {
          title: "Oldest",
          value: {
            orderDirection: "ASC",
            orderBy: "id",
          },
        },
        {
          title: "Highest price",
          value: {
            orderDirection: "DESC",
            orderBy: "PRICE_USD",
          },
        },
        {
          title: "Lowest price",
          value: {
            orderDirection: "ASC",
            orderBy: "PRICE_USD",
          },
        },
      ];
  };

  const query = computed(() =>
    store.getters["profilePanels/getByKey"]("queries")
  );

  const isChecked = (type, value) => {
    if (type === "price" && query.value.priceRange.includes(value)) {
      return true;
    }
    if (type === "network") {
      const networkFilter = ethereumService.availableNetworks[value];
      return query.value.networks.includes(networkFilter);
    }
    return false;
  };

  const toggleFilterOption = async (prevQuery, filter) => {
    let indexOf = prevQuery.indexOf(filter);
    if (indexOf >= 0) {
      return prevQuery.splice(indexOf, 1);
    }
    if (indexOf === -1) {
      return prevQuery.push(filter);
    }
    return false;
  };

  const filter = async (filter) => {
    const prevQuery = { ...query.value };
    await profileDispatch("resetPage");
    await profileDispatch("resetDataArray", currentPanel.value);
    if (filter.type === "price") {
      await toggleFilterOption(prevQuery.priceRange, filter.priceRange);
      await profileDispatch("setFilter", {
        priceRange: prevQuery.priceRange,
      });
    }
    if (filter.type === "media") {
      await toggleFilterOption(prevQuery.media, filter.value);
      await profileDispatch("setFilter", {
        media: prevQuery.media,
      });
    }
    if (filter.type === "network") {
      const networkFilter = ethereumService.availableNetworks[filter.value];
      await toggleFilterOption(prevQuery.networks, networkFilter);
      await profileDispatch("setFilter", {
        networks: prevQuery.networks,
      });
    }
    await profileCommitByKey({ loadingNfts: true });
    await profileDispatch("fetchNftList", {
      tab: currentPanel.value,
      status: statusMapper[currentPanel.value].join(","),
      owner: walletAddress.value,
      search: searchQuery.value,
    });
    await profileCommitByKey({ loadingNfts: false });
  };

  return {
    data,
    search,
    filter,
    sortBy,
    loadMore,
    isChecked,
    sortOptions,
    theosMinted,
    loadingNfts,
    loadingMore,
    loadingPools,
    currentPanel,
    loadingPanels,
    refreshPanels,
    setTheosMinted,
    setCurrentPanel,
    loadMoreExternal,
    initializePanels,
    theosMintedCheckboxIcon,
  };
};

export default useProfileView;

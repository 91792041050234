<template>
  <app-modal
    :closeButton="true"
    :returnToPreviousModal="toggleWalletConnectionRequired"
  >
    <template v-slot:app-modal-header>
      <img src="@/Common/Icons/TheosLogo.svg" alt="Theos" />
      <h3 class="app-modal-welcome-title connect-wallet-title">
        Connect<br />Your Wallet
      </h3>
    </template>
    <template v-slot:app-modal-body>
      <p class="app-modal-body-text connect-wallet-text">
        You need to connect your wallet to continue
      </p>

      <div v-if="metamaskNotInstalled" class="modal-buttons">
        <a
          class="app-modal-button-gray-thick-border"
          href="https://metamask.io/"
          target="_blank"
        >
          <div class="metamask-button">
            <img
              src="@/Common/Icons/MetaMaskLogo.svg"
              alt="Metamask"
              class="logo-metamask"
            />
            <span class="metamask-text">Add MetaMask extension</span>
          </div>
        </a>
      </div>
      <div v-else class="modal-buttons">
        <div
          class="app-modal-button-gray-thick-border"
          @click="connectToAWallet()"
        >
          <div class="metamask-button">
            <img
              src="@/Common/Icons/MetaMaskLogo.svg"
              alt="Metamask"
              class="logo-metamask"
            />
            <span class="metamask-text">Metamask</span>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import { useStore } from "vuex";
import { ethereumService } from "@/main";
import AppModal from "@/elements/AppModal";
import useAuth from "@/composables/useAuth";
import { onMounted, ref } from "@vue/runtime-core";
export default {
  components: {
    AppModal,
  },
  setup() {
    const store = useStore();
    const {
      toggleWalletConnectionRequired,
      loginOrSignUp,
      isMetamaskNotInstalled,
      getIsMetamaskNotInstalled,
    } = useAuth(store, ethereumService);

    const metamaskNotInstalled = ref(false);
    onMounted(async () => {
      metamaskNotInstalled.value = await isMetamaskNotInstalled();
    });

    const connectToAWallet = () => {
      toggleWalletConnectionRequired();
      loginOrSignUp(false, true);
    };

    return {
      connectToAWallet,
      metamaskNotInstalled,
      toggleWalletConnectionRequired,
      getIsMetamaskNotInstalled,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/commonModal.scss" />

import { ethereumService } from "../main";
import { computed } from "vue";

const useGiftNft = (store) => {
  const nft = computed(() => store.getters["giftNft/getByKey"]("nft"));
  const giftingAddress = computed(() =>
    store.getters["giftNft/getByKey"]("giftingAddress")
  );

  const fetchNft = async (id) => {
    await store.dispatch("giftNft/fetchNft", id);
  };

  const setGiftingAddress = async (address) => {
    await store.dispatch("giftNft/commitByKey", {
      giftingAddress: address,
    });
  };

  const giftFlag = computed(() =>
    store.getters["giftNft/getByKey"]("giftFlag")
  );

  const giftListedNftFlag = computed(() =>
    store.getters["giftNft/getByKey"]("giftListedNftFlag")
  );

  const toggleGiftFlag = () => store.dispatch("giftNft/toggleGiftFlag");
  const toggleGiftingListedNftFlag = () =>
    store.dispatch("giftNft/toggleGiftingListedNftFlag");
  const togglePreviewGiftingFlag = () =>
    store.dispatch("giftNft/togglePreviewGiftingFlag");

  const currentStep = computed(() =>
    store.getters["giftNft/getByKey"]("currentStep")
  );
  const currentStepsArray = computed(() =>
    store.getters["giftNft/getByKey"]("currentStepsArray")
  );
  const incrementStep = async () => {
    await store.dispatch("giftNft/incrementStep");
  };

  const decrementStep = () => store.dispatch("giftNft/decrementStep");

  const resetState = () => store.dispatch("giftNft/resetState");

  const closeModal = () => {
    toggleGiftFlag();
    if (window.location.href.includes("minting")) {
      window.location.href = "/nft-details/" + nft.value.id;
    }
    resetState();
  };

  const closePreviewModal = () => {
    togglePreviewGiftingFlag();
    if (window.location.href.includes("minting")) {
      window.location.href = "/nft-details/" + nft.value.id;
    }
    resetState();
  };

  const closeGiftingListedNftModal = () => {
    toggleGiftingListedNftFlag();
    resetState();
  };

  const initializeGiftModal = async (id) => {
    await fetchNft(id);
    if (nft.value.status === "listed") {
      toggleGiftingListedNftFlag();
    }
    toggleGiftFlag();
  };

  const giftNft = async () => {
    await incrementStep();
    const result = await ethereumService.giftNft(
      giftingAddress.value,
      nft.value.contractId,
      nft.value.contractAddress
    );
    if (result.hash === "rejected") return decrementStep();
    if (result) {
      await store.dispatch("giftNft/sendEmail", {
        nftId: nft.value.id,
        receiverAddress: giftingAddress.value,
      });
      return await incrementStep();
    }
    closeModal();
  };

  const shortGiftingAddress = computed(() => {
    return giftingAddress.value
      ? `${giftingAddress.value.slice(0, 6)}...${giftingAddress.value.slice(
          -4
        )}`
      : "...";
  });

  return {
    nft,
    giftNft,
    giftFlag,
    fetchNft,
    closeModal,
    currentStep,
    incrementStep,
    decrementStep,
    giftingAddress,
    toggleGiftFlag,
    giftListedNftFlag,
    closePreviewModal,
    currentStepsArray,
    setGiftingAddress,
    initializeGiftModal,
    shortGiftingAddress,
    togglePreviewGiftingFlag,
    toggleGiftingListedNftFlag,
    closeGiftingListedNftModal,
  };
};

export default useGiftNft;

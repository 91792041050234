import { ethereumService } from "@/main";
import exchange from "../../services/exchange";
import nftStorage from "../../services/ntf-storage";

const initialState = () => ({
  nftMetadata: {},
  nftData: {},
  errors: [],
  coinPrices: [],
  coinPriceTime: "",
  address: "",
  balance: {},
  network: {},
  networkName: "",
  hasSigned: false,
  isValidNetwork: false,
});
const state = initialState();

const getters = {
  getCoinPrices(state) {
    return state.coinPrices;
  },
  getNftMetadata(state) {
    return state.nftMetadata;
  },
  getNftData(state) {
    return state.nftData;
  },
  getErrors(state) {
    return state.errors;
  },
  getAddress(state) {
    return state.address;
  },
  getBalance(state) {
    return state.balance;
  },
  getIsValidNetwork(state) {
    return state.isValidNetwork;
  },
  getBalanceByCurrency: (state) => (currency) => {
    return state.balance[currency];
  },
  getCoinPrice: (state) => (coin, currency) => {
    return state.coinPrices
      .filter(
        (coinPricePair) =>
          coinPricePair.coin === coin && coinPricePair.currency === currency
      )
      .pop();
  },
  getCoinPriceTime(state) {
    return state.coinPriceTime;
  },
  getNetwork(state) {
    return state.network;
  },
  getNetworkName(state) {
    return state.networkName;
  },
  getHasSigned(state) {
    return state.hasSigned;
  },
};
const mutations = {
  setNftMetadata(state, metadata) {
    state.nftMetadata = metadata;
  },
  setNftData(state, data) {
    state.nftData = data;
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  setAddress(state, address) {
    state.address = address;
  },
  setBalance(state, balance) {
    state.balance = balance;
  },
  setIsValidNetwork(state, isValidNetwork) {
    state.isValidNetwork = isValidNetwork;
  },
  setCoinPrice(state, coinPricePair) {
    const exists = state.coinPrices.find(
      (pair) =>
        coinPricePair.coin === pair.coin &&
        coinPricePair.currency === pair.currency
    );
    if (exists) {
      state.coinPrices.map((pair) => {
        if (
          coinPricePair.coin === pair.coin &&
          coinPricePair.currency === pair.currency
        ) {
          pair.value = coinPricePair.value;
          pair.change = coinPricePair.change;
        }
        return pair;
      });
    } else {
      state.coinPrices.push(coinPricePair);
    }
  },
  setCoinPriceTime(state, coinPriceTime) {
    state.coinPriceTime = coinPriceTime;
  },
  setNetwork(state, network) {
    state.network = network;
  },
  setNetworkName(state, networkName) {
    state.networkName = networkName;
  },
  setHasSigned(state, status) {
    state.hasSigned = status;
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async storeNft({ commit }, nftData) {
    try {
      const result = await nftStorage.store(
        nftData.file,
        nftData.name,
        nftData.description
      );
      commit("setNftMetadata", result);
      return result;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async mintNft({ commit }, { nftMetadata, royalties }) {
    try {
      const result = await ethereumService.nftMint(nftMetadata, royalties);
      commit("setNftData", result);
    } catch (error) {
      if ("code" in error && error.code === 4001) {
        return "rejected";
      }
      commit("pushError", error);
    }
  },
  async fetchAddress({ commit }) {
    try {
      const address = await ethereumService.fetchAddress();
      commit("setAddress", address);
      return address;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchBalance({ commit }, balanceAddress) {
    try {
      const balance = await ethereumService.fetchBalance(balanceAddress);
      commit("setBalance", balance);
      return balance;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchIsValidNetwork({ commit }) {
    try {
      const isValidNetwork = await ethereumService.isValidNetwork();
      commit("setIsValidNetwork", isValidNetwork);
      return isValidNetwork;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async signNonce({ commit }, nonce) {
    try {
      const result = await ethereumService.signNonce(nonce);
      commit("setHasSigned", true);
      return result;
    } catch (error) {
      if ("code" in error && error.code === 4001) {
        return "rejected";
      }
      commit("pushError", error);
    }
  },
  async fetchCoinPrice(
    { commit, getters },
    { coins = "ethereum,theos,matic-network,wmatic", currency = "usd" }
  ) {
    try {
      if (
        new Date() - new Date(getters.getCoinPriceTime) > 60 * 1000 ||
        getters.getCoinPriceTime === ""
      ) {
        const response = await exchange.fetchPriceInFiat(coins, currency);
        commit("setCoinPrice", {
          coin: "ethereum",
          currency,
          value: response.data["ethereum"][currency],
          change: response.data["ethereum"]["usd_24h_change"],
        });
        commit("setCoinPrice", {
          coin: "theos",
          currency,
          value: response.data["theos"][currency],
          change: response.data["theos"]["usd_24h_change"],
        });
        commit("setCoinPrice", {
          coin: "matic-network",
          currency,
          value: response.data["matic-network"][currency],
          change: response.data["matic-network"]["usd_24h_change"],
        });
        commit("setCoinPrice", {
          coin: "wmatic",
          currency,
          value: response.data["wmatic"][currency],
          change: response.data["wmatic"]["usd_24h_change"],
        });
        commit("setCoinPriceTime", new Date());
        return response.data;
      }
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchNetwork({ commit }) {
    try {
      const result = await ethereumService.getNetwork();
      commit("setNetwork", result);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchNetworkName({ commit }) {
    try {
      const result = await ethereumService.getNetworkName();
      commit("setNetworkName", result);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async allowTheos({ commit }) {
    try {
      return await ethereumService.allowTheos();
    } catch (error) {
      if (error.code === 4001) {
        return "rejected";
      }
      commit("pushError", error);
    }
  },
  async isAllowedTheos({ commit }) {
    try {
      return await ethereumService.isAllowedTheos();
    } catch (error) {
      commit("pushError", error);
    }
  },
  async depositNft({ commit }, data) {
    try {
      return await ethereumService.depositNft(
        data.contractAddress,
        data.contractId,
        data.iPrice,
        data.poolAddress
      );
    } catch (error) {
      if (error.code === 4001) {
        return "rejected";
      }
      commit("pushError", error);
    }
  },
  async withdrawNft({ commit }, data) {
    try {
      return await ethereumService.withdrawNftFromPool(
        data.contractAddress,
        data.poolAddress,
        data.contractId
      );
    } catch (error) {
      commit("pushError", error);
    }
  },
  async resetState({ commit }) {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import api from "@/services/api";

const stepsDefault = [
  {
    component: "NftChooseToSubmit",
    title: "Deposit NFT",
  },
  {
    component: "PoolChooseToSubmitNft",
    title: "Select Pools",
  },
  {
    component: "DepositSubmissionPreview",
    title: "Submit",
  },
];
const stepsFromProfile = [
  {
    component: "PoolChooseToSubmitNft",
    title: "Select Pools",
  },
  {
    component: "DepositSubmissionPreview",
    title: "Submit",
  },
];
const stepsFromPool = [
  {
    component: "NftChooseToSubmit",
    title: "Deposit NFT",
  },
  {
    component: "DepositSubmissionPreview",
    title: "Submit",
  },
];

const initialState = () => ({
  user: {
    id: "",
    displayName: "",
    address: "",
  },
  errors: [],
  chosenNft: {},
  currentPage: 1,
  currentStep: 1,
  chosenPools: [],
  nftDataList: [],
  nftsList: { total: 0 },
  externalNftDataList: [],
  submissionSuccess: false,
  externalNftsToShowList: [],
  externalNftsList: { total: 0 },
  currentStepsArray: stepsDefault,
  showing: "theosNfts",
});
const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
  getUser: (state) => state.user,
};

const mutations = {
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  changeCurrentStepsArray(state, queryKey) {
    let key = Object.keys(queryKey);
    switch (key[0]) {
      case "nftId":
        state.currentStepsArray = stepsDefault;
        state.currentStep = 2;
        break;
      case "profileNftId":
        state.currentStepsArray = stepsFromProfile;
        state.currentStep = 1;
        break;
      case "poolPollId":
        state.currentStepsArray = stepsFromPool;
        state.currentStep = 1;
        break;
      default:
        state.currentStepsArray = stepsDefault;
        state.currentStep = 1;
    }
  },
  updateChosenPools(state, pool) {
    state.chosenPools.push(pool);
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearNftList(state) {
    state.nftsList = { total: 0 };
  },
  clearExternalNftList(state) {
    state.externalNftsList = { total: 0 };
  },
  setNftDataList(state, list) {
    state.nftDataList = state.nftDataList.concat(list);
  },
  setExternalNftDataList(state, list) {
    state.externalNftDataList = state.externalNftDataList.concat(list);
  },
  clearExternalNftsToShowList(state) {
    state.externalNftsToShowList = [];
  },
  clearNftDataList(state) {
    state.nftDataList = [];
  },
  clearExternalNftDataList(state) {
    state.externalNftDataList = [];
  },
  incrementStep(state, step) {
    state.currentStep += step;
  },
  decrementStep(state, step) {
    state.currentStep -= step;
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  async fetchNftList({ commit }, { params }) {
    try {
      const response = await api.fetchNftList(params);
      commit("commitByKey", { nftsList: response.data });
      commit("setNftDataList", response.data.data);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchExternalNftList({ commit, state }, parameters) {
    try {
      if (!state.externalNftsList.data || state.externalNftsList.hasNextPage) {
        const response = await api.fetchExternalNftList(parameters);
        commit("commitByKey", { externalNftsList: response.data });
        commit("setExternalNftDataList", response.data.data);
      }
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchMe({ commit }) {
    try {
      const response = await api.fetchMe();
      commit("commitByKey", { user: response.data.data });
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async submissionToPool({ commit }, { senderId, poolIds, nftId }) {
    try {
      const response = await api.submissionToPool(senderId, poolIds, nftId);
      commit("commitByKey", { submissionSuccess: true });
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
  resetState({ commit }) {
    commit("resetState");
  },
  clearNftList({ commit }) {
    commit("clearNftList");
  },
  clearNftDataList({ commit }) {
    commit("clearNftDataList");
  },
  clearExternalNftList({ commit }) {
    commit("clearExternalNftList");
  },
  incrementStep({ commit }, step = 1) {
    commit("incrementStep", step);
  },
  decrementStep({ commit }, step = 1) {
    commit("decrementStep", step);
  },
  updateChosenPools({ commit }, pool) {
    commit("updateChosenPools", pool);
  },
  clearExternalNftDataList({ commit }) {
    commit("clearExternalNftDataList");
    commit("clearExternalNftsToShowList");
  },
  changeCurrentStepsArray({ commit }, queryKey) {
    commit("changeCurrentStepsArray", queryKey);
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};

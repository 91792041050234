import * as Yup from "yup";
import { ethereumService } from "../main";

export const giftNftSchema = Yup.object().shape({
  GiftingAddress: Yup.string()
    .required("This field is required")
    .test(
      "ETH_ADDRESS",
      "Invalid address.",
      (value) => !value || ethereumService.isEthereumAddress(value)
    ),
});

<template>
  <div v-if="flag.alertFlag.value">
    <teleport to="body">
      <div class="alert-background">
        <div class="alert-wrapper">
          <div class="alert-icon" v-if="alertProps.icon">
            <img
              :src="require(`@/Common/Icons/${alertProps.icon}.png`)"
              alt="Icon"
            />
          </div>
          <div class="alert-content">
            <p class="alert-title" v-if="alertProps.title">
              {{ alertProps.title }}
            </p>
            <p class="alert-text" v-if="alertProps.text">
              {{ alertProps.text }}
            </p>
          </div>
          <div class="alert-close-button" @click="closeAlert(flag.alertFlag)">
            <img src="@/Common/Icons/Vector.png" alt="Close" />
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import useAlert from "@/composables/useAlert";
import useUtilities from "@/composables/useUtilities";
import { inject } from "vue";
export default {
  name: "AppAlert",
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
  emits: ["closeAlert"],
  setup() {
    const eventBus = inject("eventBus");
    const { toggle } = useUtilities();
    const { closeAlert, alertFlag, alertProps } = useAlert(eventBus, toggle);
    return {
      closeAlert,
      alertProps,
      flag: {
        alertFlag,
      },
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/appAlert.scss"></style>

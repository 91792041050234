<template>
  <div :class="wrapperClass">
    <card-menu
      :token="token"
      :isPublic="isPublic"
      :iconSize="iconSize"
      :isForMarketplace="isForMarketplace"
    />
    <div
      :class="[{ pointer: !external }]"
      class="card-wrapper"
      ref="cardWrapper"
    >
      <div class="card">
        <network-badge :token="token" :cardSize="cardSize" :isBig="isBig" />
        <card-image :token="token" :external="external" :isBig="isBig" />
        <card-content
          :isBig="isBig"
          :token="token"
          :currentPanel="currentPanel"
          :isForMarketplace="isForMarketplace"
        />
        <card-creator-owner-image
          :token="token"
          :cardSize="cardSize"
          :isForMarketplace="isForMarketplace"
        />
        <card-auction-time
          :token="token"
          v-if="token.auction.type === 'time-limited'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import CardMenu from "./CardMenu";
import CardImage from "./CardImage";
import CardContent from "./CardContent";
import NetworkBadge from "./NetworkBadge";
import CardAuctionTime from "./CardAuctionTime";
import { onBeforeUnmount, onMounted, ref } from "vue";
import CardCreatorOwnerImage from "./CardCreatorOwnerImage";
export default {
  components: {
    CardMenu,
    CardImage,
    CardContent,
    NetworkBadge,
    CardAuctionTime,
    CardCreatorOwnerImage,
  },
  props: {
    currentPanel: {
      type: String,
      default: "",
    },
    external: {
      type: Boolean,
      default: false,
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
    isForMarketplace: {
      type: Boolean,
      default: false,
    },
    token: {
      type: Object,
      default: () => {},
    },
    isBig: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const cardSize = ref(null);
    const iconSize = ref(null);
    const cardWrapper = ref(null);
    const wrapperClass = computed(() =>
      props.isBig ? "card-icon-wrapper-big" : "card-icon-wrapper"
    );

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        let contentBoxSize = Array.isArray(entry.contentBoxSize)
          ? entry.contentBoxSize[0].inlineSize
          : entry.contentBoxSize.inlineSize;

        if (props.isBig) contentBoxSize /= 2;

        cardSize.value = Math.floor(contentBoxSize * 0.142);
        iconSize.value = Math.floor(contentBoxSize * 0.157);
      }
    });

    onMounted(() => {
      resizeObserver.observe(cardWrapper.value);
    });

    onBeforeUnmount(() => resizeObserver.unobserve(cardWrapper.value));

    return {
      cardSize,
      iconSize,
      cardWrapper,
      wrapperClass,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/nftCard.scss" />

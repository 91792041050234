import { computed } from "vue";
import { useStore } from "vuex";

const useStoreFunctions = (module = null, store = useStore()) => {
  if (module == null) throw "Passing a store module as a String is required!";

  const getByKey = (key) => store.getters[`${module}/getByKey`](key);

  const commitByKey = (key) => store.dispatch(`${module}/commitByKey`, key);

  const commitStore = (mutation, parameters) =>
    store.commit(`${module}/${mutation}`, parameters);

  const dispatchStore = (action, parameters) =>
    store.dispatch(`${module}/${action}`, parameters);

  const computeStore = (key) => computed(() => getByKey(key));

  return {
    commitStore,
    commitByKey,
    computeStore,
    dispatchStore,
  };
};

export default useStoreFunctions;

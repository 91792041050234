<template>
  <app-buy-nft
    :closeButton="true"
    :showCloseLink="true"
    :closeModal="closeModal"
  >
    <template v-slot:app-modal-header>
      <img
        :alt="nft.name"
        class="buy-nft-logo"
        src="@/Common/Icons/TheosLogoYellow.png"
      />
      <p class="buy-nft-title">Buy NFT</p>
      <img class="buy-modal-img" :src="nft.coverImage.path" :alt="nft.name" />
    </template>
    <template v-slot:app-modal-body>
      <p class="app-modal-body-text text-color text-margin">
        You are about to buy
      </p>
      <p class="app-modal-listed-nft-data">
        <span class="nft-name"> {{ nft.name }}</span>
        <span class="price-color">by</span>
        <span class="owner-name"> {{ nft.currentOwner.displayName }}</span>
      </p>
      <p class="app-modal-body-text text-color text-margin">Total cost is</p>
      <p class="app-modal-buy-price">
        <span
          >{{ toFixedIfNecessary(nft.sellPrice, 4) }} {{ pool.tokenName }}</span
        >
        <span class="price-color"
          >≈${{ toFixedIfNecessary(nft.sellPriceUSD, 2) }}</span
        >
      </p>
      <div class="button-wrapper-buy">
        <app-button
          text="Buy"
          @click="buyNft()"
          buttonClass="app-modal-button-inverse"
        />
      </div>
    </template>
  </app-buy-nft>
</template>

<script>
import { useStore } from "vuex";
import AppBuyNft from "@/elements/AppBuyNft";
import AppButton from "@/elements/AppButton";
import { onMounted, onUnmounted } from "vue";
import useUtilities from "@/composables/useUtilities";
import useBuyNftModal from "@/composables/useBuyNftModal";
export default {
  name: "BuyNft",
  components: {
    AppBuyNft,
    AppButton,
  },
  props: {},
  setup() {
    const store = useStore();
    const { buyNft, nft, closeModal, pool } = useBuyNftModal(store);
    const { toFixedIfNecessary } = useUtilities();

    onMounted(() => document.body.classList.add("stop-scroll"));
    onUnmounted(() => document.body.classList.remove("stop-scroll"));

    return {
      nft,
      pool,
      buyNft,
      closeModal,
      toFixedIfNecessary,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/buyNft.scss" />

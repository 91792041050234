<template>
  <div :class="[selectBoxStyle, 'selectBox', { disabled: disabled }]">
    <div class="selectBoxInputWrapper" @click="displayResults()">
      <div
        :class="[
          'selectBoxDropdownTop',
          { selectBoxDropdownOpened: resultsVisible },
        ]"
      >
        <div v-if="selectedValue.length">
          <div :class="['selectBoxSelectedItem']">
            {{ capitalize(selectedValue) }}
          </div>
        </div>
        <div v-else :class="['selectBoxLabel']">
          {{ labelText }}
        </div>
        <div class="selectBoxIcon"></div>
      </div>
      <input type="hidden" :value="selectedValue" />
    </div>

    <transition name="results">
      <ul
        v-if="showResults"
        v-click-outside="hideResults"
        :class="['selectBoxResults']"
      >
        <li
          v-for="option in options"
          :key="option"
          @click="selectOption(option)"
          :class="['selectBoxItem']"
        >
          <div class="selectBoxItemTitle">{{ capitalize(option) }}</div>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";
import { computed, ref } from "vue";

export default {
  name: "AppSelect",
  props: {
    id: {
      type: String,
    },
    labelText: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [],
      default: [],
    },
    options: {
      type: [],
      default: [],
    },
    selectBoxStyle: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup(props, context) {
    const resultsVisible = ref(false);
    const selectedValue = computed({
      get: () => props.modelValue,
      set: (value) => context.emit("update:modelValue", value),
    });

    function hideResults() {
      resultsVisible.value = false;
    }

    const displayResults = () => {
      if (!props.disabled) {
        resultsVisible.value = true;
      }
    };

    function selectOption(option) {
      selectedValue.value = option;
      resultsVisible.value = false;
    }

    const showResults = computed(function () {
      return props.options && resultsVisible.value;
    });

    const capitalize = (value) => {
      if (value == "") {
        return "Bulk Actions";
      }
      return value.charAt(0).toUpperCase() + value.slice(1);
    };

    return {
      resultsVisible,
      selectedValue,
      hideResults,
      displayResults,
      selectOption,
      showResults,
      capitalize,
    };
  },
};
</script>

<style scoped lang="scss" src="@/Common/Styles/appSelect.scss"></style>

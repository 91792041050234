<template>
  <div>
    <teleport to="body">
      <app-modal
        :closeButton="true"
        :returnToPreviousModal="toggleRegistrationFlag"
      >
        <template v-slot:app-modal-header>
          <img src="@/Common/Icons/TheosLogo.svg" alt="TheosLogo" />
          <h3 class="app-modal-welcome-title">Welcome</h3>
        </template>
        <template v-slot:app-modal-body>
          <p class="app-modal-body-text">
            Since this is your first time on Theos - we'll help you set up your
            profile
          </p>
          <div class="app-modal-button-controls">
            <app-button
              text="Let's start"
              @click="goToEditProfile()"
              buttonClass="app-modal-button-inverse"
            />
            <app-button
              text="I'll do this later"
              buttonClass="app-modal-button"
              @click="toggleRegistrationFlag()"
            />
          </div>
        </template>
      </app-modal>
    </teleport>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted, onUnmounted } from "vue";
import AppModal from "@/elements/AppModal.vue";
import AppButton from "@/elements/AppButton.vue";
import useGoogleTag from "@/composables/useGoogleTag";
import useRegistrationModal from "@/composables/useRegistrationModal";
import useRouter from "@/composables/useRouter";
export default {
  name: "RegistrationModal",
  components: {
    AppModal,
    AppButton,
  },
  setup() {
    const { registerEvent } = useGoogleTag();

    const store = useStore();
    const { goTo } = useRouter();

    const { setStartTime, setGlobalStartTime, toggleRegistrationFlag } =
      useRegistrationModal(store);

    onMounted(() => {
      registerEvent("registration-started");
      setStartTime();
      setGlobalStartTime();
      document.body.classList.add("stop-scroll");
    });

    onUnmounted(() => {
      document.body.classList.remove("stop-scroll");
    });

    const goToEditProfile = () => {
      toggleRegistrationFlag();
      goTo({ name: "EditProfile" });
    };
    return {
      goToEditProfile,
      toggleRegistrationFlag,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/commonModal.scss" />

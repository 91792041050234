import { computed, ref } from "vue";
import useUtilities from "@/composables/useUtilities";
import queryString from "query-string";
import sushiswapTokens from "@/json/SupportedSushiswapTokens.json";

const loadingNfts = ref(false);
const activeClass = ref("newestFirst");
const modalFlag = ref(false);

const usePools = (store) => {
  const user = computed(() => store.getters["pools/getUser"]);
  let params = {
    page: 1,
    perPage: 8,
    orderDirection: "DESC",
  };
  const showing = ref("nfts");
  const { toggle } = useUtilities();
  const showFilters = ref(false);

  const pool = computed(() => store.getters["pools/getPool"]);
  const poolSubmissions = computed(() =>
    store.getters["pools/getByKey"]("poolSubmissions")
  );
  const pooledNftsTotal = computed(() =>
    store.getters["pools/getByKey"]("pooledNftsTotal")
  );
  const poolId = computed(() => store.getters["pools/getByKey"]("poolId"));
  const pooledNftList = computed(() =>
    store.getters["pools/getByKey"]("pooledNftList")
  );
  const poolStatistics = computed(() =>
    store.getters["pools/getByKey"]("poolStatistics")
  );
  const fetchPool = async (id) => await store.dispatch("pools/fetchPool", id);

  const fetchAllPoolsListTokens = async () => {
    await store.dispatch("pools/fetchAllPools");
    const poolTokenAddresses = [
      //{ contractAddress: '0xDb982a636807B3ACD9FD28a99410B68c2159Fb32' },
      { contractAddress: process.env.VUE_APP_THEOS_TOKEN_CONTRACT_ADDRESS },
    ];
    const supportedTokens = sushiswapTokens[process.env.VUE_APP_ENV];
    supportedTokens.forEach((token) => {
      poolTokenAddresses.push({ contractAddress: token.contractAddress });
    });
    const allPools = await store.getters["pools/getAllPools"];
    allPools.forEach((pool) =>
      poolTokenAddresses.push({ contractAddress: pool.tokenAddress })
    );
    return poolTokenAddresses;
  };

  const toggleFilter = async (filter) => {
    params = {
      page: 1,
      perPage: 8,
      orderDirection: "DESC",
    };
    switch (filter) {
      case "newestFirst":
        params.page = 1;
        params.orderDirection = "DESC";
        activeClass.value = "newestFirst";
        break;
      case "oldestFirst":
        params.page = 1;
        params.orderDirection = "ASC";
        activeClass.value = "oldestFirst";
        break;
    }
    await store.dispatch("pools/clearPooledNftList");
    await fetchPoolDepositedNfts(poolId.value, queryString.stringify(params));
  };

  const searchString = ref("");

  const fetchSubmissions = async (poolId, params) => {
    await store.dispatch("pools/fetchSubmissions", { poolId, params });
  };

  const fetchPoolDepositedNfts = async (poolId, params) => {
    if (loadingNfts.value) return;
    loadingNfts.value = true;
    await store.dispatch("pools/fetchPoolDepositedNfts", { poolId, params });
    loadingNfts.value = false;
  };
  const fetchPoolStatistic = async (poolId) => {
    await store.dispatch("pools/fetchPoolStatistic", poolId);
  };
  const search = async () => {
    if (params.search !== searchString.value) {
      params = {
        page: 1,
        perPage: 8,
        orderDirection: "DESC",
      };
      store.dispatch("pools/clearPooledNftList");
      params.search = searchString.value;
      await fetchPoolDepositedNfts(
        pool.value.id,
        queryString.stringify(params)
      );
    }
  };
  const loadMore = async () => {
    params.page += 1;
    await fetchPoolDepositedNfts(pool.value.id, queryString.stringify(params));
  };
  const show = (value) => (showing.value = value);
  const toggleModalFlag = () => {
    modalFlag.value = !modalFlag.value;
  };
  return {
    fetchPool,
    fetchPoolDepositedNfts,
    fetchPoolStatistic,
    fetchSubmissions,
    pool,
    pooledNftsTotal,
    pooledNftList,
    loadMore,
    toggleFilter,
    loadingNfts,
    show,
    showing,
    modalFlag,
    filtersFlag: {
      showFilters,
    },
    toggle,
    search,
    searchString,
    poolSubmissions,
    poolId,
    user,
    poolStatistics,
    activeClass,
    toggleModalFlag,
    fetchAllPoolsListTokens,
  };
};
export default usePools;

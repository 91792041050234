<template>
  <div class="pop-up-container">
    <div class="container" v-if="networkParams?.chainName" :key="networkParams">
      <div
        class="icons-button icon-button-add-theos pointer"
        @click="addTheosToMetamask()"
      >
        <div class="tooltip">Add Theos to your MetaMask wallet.</div>
        <div class="icons-button-div"></div>
      </div>
      <div class="switch-buttons">
        <div
          class="networkButton"
          :class="[!isEthereumNetwork ? 'active' : 'pointer']"
          @click="!isEthereumNetwork ? '' : networkSwitch()"
        >
          <div class="icons-button-polygon-grey-switch"></div>
          <div class="network-name">Polygon</div>
        </div>

        <span style="color: rgba(247, 249, 246, 0.08)">|</span>
        <div
          class="networkButton"
          :class="[isEthereumNetwork ? 'active' : 'pointer']"
          @click="isEthereumNetwork ? '' : networkSwitch()"
        >
          <div class="icons-button-eth-grey-switch"></div>
          <div class="network-name">Ethereum</div>
        </div>
      </div>
      <div class="balance" @click="showPopUp = true">
        <p class="text-balance-main">
          {{ balance }} <span>{{ networkParams?.nativeCurrency.symbol }}</span>
        </p>
        <div class="address pointer">
          <div class="loader-container" v-if="isLoading">
            <span class="text-address">Pending...</span>
            <app-loader
              style="padding-top: 6px"
              :show="isLoading"
              :isGreen="true"
              :is-small="true"
            />
          </div>
          <div class="address" v-else>
            <p class="text-address">
              {{ reduceAddress(6, 4) }}
            </p>
          </div>
        </div>
      </div>
      <img
        alt="user-profile-image"
        class="address-icons pointer"
        :src="userProfileImage()"
        @click="goTo({ name: 'ViewProfilePrivate' })"
      />
    </div>
    <transition name="result">
      <div v-if="showPopUp" v-click-outside="hideResults" class="pop-up">
        <div class="pop-up-header">
          <h6>Wallet</h6>
          <div class="copy-icon-wrap">
            <p class="text-address-wallet">{{ reduceAddress(10, 11) }}</p>
            <div
              class="copy-icon-box pointer"
              @mouseleave="hideCopyTooltip"
              @click="copy"
            >
              <i class="copy-icon"></i>
              <input id="selectedAddress" hidden v-model="address" />
              <div v-if="copyTooltip" class="tooltip-copy">Copied!</div>
            </div>
          </div>
          <div class="active-network" v-show="!isLoading">
            <div class="active-network-icon"></div>
            <div class="active-network-text">
              {{ networkParams.chainName }}
            </div>
          </div>
        </div>
        <div class="pop-up-body">
          <h6>Balance</h6>
          <div class="balance-theos" v-show="!isLoading">
            <i :class="currencyIcon"></i>
            <p class="text-balance">{{ balance }} {{ coinName }}</p>
            <p class="text-converter">≈${{ coinPriceDefaultNetwork }}</p>
          </div>
          <div
            class="balance-theos"
            v-show="!isLoading && Number(balanceTHEOS) !== 0"
          >
            <i class="icon-theos"></i>
            <p class="text-balance">{{ balanceTHEOS }} THEOS</p>
            <p class="text-converter">≈${{ coinPriceTHEOS }}</p>
          </div>
          <!--          <div class="button-stake"><p class="button-stake-text">Stake</p></div>-->
        </div>
        <div class="pop-up-footer">
          <a class="disconnect" @click="disconnect">Disconnect</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import store from "../store";
import { ethereumService } from "@/main";
import useAuth from "@/composables/useAuth";
import AppLoader from "../elements/AppLoader";
import { computed, onMounted, ref } from "vue";
import vClickOutside from "click-outside-vue3";
import useRouter from "@/composables/useRouter";
import imageTransformer from "../plugins/imageTransformer";

export default {
  components: {
    AppLoader,
  },
  props: {
    id: String,
    labelText: {
      type: String,
      default: "",
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup: function () {
    const { goTo } = useRouter();

    const isLoading = computed(
      () => store.getters["users/getPendingTransaction"]
    );
    const addTheosToMetamask = () => {
      return ethereumService.addTokenToMetamask("THEOS");
    };
    const isEthereumNetwork = computed(() =>
      ["0x5", "0x1"].includes(networkParams.value.chainId)
    );

    const { openRegistrationModal } = useAuth(store, ethereumService);
    let copyTooltip = ref(false);
    let showPopUp = ref(false);
    const address = computed(() => store.getters["contracts/getAddress"]);
    const balance = computed(() =>
      parseFloat(
        store.getters["contracts/getBalanceByCurrency"]("default") || "0"
      ).toFixed(5)
    );
    const balanceTHEOS = computed(() =>
      parseFloat(
        store.getters["contracts/getBalanceByCurrency"]("THEOS") || "0"
      ).toFixed(3)
    );

    const currencyIcon = computed(() => {
      return isEthereumNetwork.value ? "icon-eth" : "icon-matic";
    });

    const networkParams = ref(ethereumService.networkParams);

    const coinPriceTHEOS = computed(() => {
      const price = store.getters["contracts/getCoinPrice"]("theos", "usd");
      return price ? (price.value * balanceTHEOS.value).toFixed(2) : 0;
    });

    const coinPriceDefaultNetwork = computed(() => {
      let price;
      price = isEthereumNetwork.value
        ? store.getters["contracts/getCoinPrice"]("ethereum", "usd")
        : store.getters["contracts/getCoinPrice"]("matic-network", "usd");
      return price.value ? (price.value * balance.value).toFixed(2) : 0;
    });

    onMounted(async () => {
      await store.dispatch("contracts/fetchAddress");
      await store.dispatch("contracts/fetchBalance", address.value);
    });
    const disconnect = async () => {
      await store.dispatch("auth/disconnect");
      await store.dispatch("contracts/resetState");
      await ethereumService.disconnectProvider();
      window.localStorage.removeItem("me");
      location.reload();
      await goTo({ name: "Home" });
    };
    const copy = () => {
      copyTooltip.value = true;
      navigator.clipboard.writeText(address.value);
    };
    const reduceAddress = (lengthLeft, lengthRight) => {
      if (address.value) {
        const addressLeftPart = address.value.substring(0, lengthLeft);
        const addressRightPart = address.value.substring(42 - lengthRight, 42);
        return `${addressLeftPart}...${addressRightPart}`;
      }
      return "Not connected";
    };
    const hideCopyTooltip = () => {
      copyTooltip.value = false;
    };
    const hideResults = () => {
      showPopUp.value = false;
    };
    const userProfileImage = () => {
      const profileImage = store.getters["userProfile/getUser"].profileImage;
      return profileImage
        ? imageTransformer.transform(profileImage.filename, "user.avatar")
        : require("@/Common/Icons/Medusa_avatar.png");
    };

    const userIsRegistered = () =>
      store.getters["userProfile/getUser"].displayName;

    const closePopUp = () => (showPopUp.value = false);

    const networkSwitch = () => {
      return ethereumService.switchNetwork();
    };

    return {
      copy,
      goTo,
      address,
      balance,
      isLoading,
      showPopUp,
      closePopUp,
      disconnect,
      hideResults,
      copyTooltip,
      balanceTHEOS,
      currencyIcon,
      networkSwitch,
      networkParams,
      reduceAddress,
      coinPriceTHEOS,
      hideCopyTooltip,
      userIsRegistered,
      userProfileImage,
      isEthereumNetwork,
      addTheosToMetamask,
      openRegistrationModal,
      coinPriceDefaultNetwork,
    };
  },
};
</script>

<style
  lang="scss"
  scoped
  src="@/Common/Styles/UserBalanceAndAddress.scss"
></style>

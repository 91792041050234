<template>
  <div class="wrapper-total">
    <div class="profile-wrapper">
      <div class="profile-top-bar">
        <div class="profile-panel-group">
          <template v-for="panel in panelsList">
            <div
              :key="panel.name"
              class="profile-panel"
              v-if="renderPanel(panel)"
              @click="setCurrentPanel(panel.name)"
              :class="{ active: currentPanel === panel.name }"
            >
              <p>
                {{ isPublic ? panel.publicTitle : panel.title }}
                <span class="profile-panel-total-items">
                  {{ panelTotal(panel.name) || "" }}
                </span>
              </p>
            </div>
            <div
              :key="panel.title"
              v-if="panel.name === 'pooledNfts'"
              class="profile-panel profile-panel-divider"
            >
              |
            </div>
          </template>
        </div>
      </div>
      <app-loader :isGreen="true" v-if="loadingPanels" />
      <div class="profile-panel-body" v-else>
        <table-panel
          :data="data"
          :currentPanel="currentPanel"
          v-if="currentPanel === 'submissions'"
          :isEmpty="data[currentPanel].total < 1"
        />

        <pools-panel
          :data="data"
          :isPublic="isPublic"
          :currentPanel="currentPanel"
          v-else-if="currentPanel === 'pools'"
          :isEmpty="data[currentPanel].total < 1"
        />

        <card-panel
          v-else
          :data="data"
          :isPublic="isPublic"
          :searchFunction="search"
          :sortFunction="sortBy"
          :sortOptions="sortOptions()"
          :loadingNfts="loadingNfts"
          :loadingMore="loadingMore"
          :currentPanel="currentPanel"
          :loadingPanels="loadingPanels"
          :isEmpty="data[currentPanel].total < 1"
          :loadMore="currentPanel !== 'external' ? loadMore : loadMoreExternal"
          :cardClickHandlerArray="['nfts', 'pooledNfts', 'listed', 'external']"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import pl from "@/json/panelsList.json";
import AppLoader from "@/elements/AppLoader";
import { provide, onMounted, ref } from "vue";
import useProfileView from "@/composables/useProfileView";
import CardPanel from "@/components/Profile/Panels/CardPanel";
import TablePanel from "@/components/Profile/Panels/TablePanel";
import PoolsPanel from "@/components/Profile/Panels/PoolsPanel";
export default {
  components: {
    AppLoader,
    CardPanel,
    TablePanel,
    PoolsPanel,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    provide("isPublic", props.isPublic);
    const panelsList = ref([]);

    onMounted(() => {
      panelsList.value = pl;
      if (useRoute().query.tab) setCurrentPanel(useRoute().query.tab);
      if (props.isPublic) {
        panelsList.value = pl.filter((elem) => elem.name !== "external");
      }
    });

    const panelTotal = (panel) => data.value[panel].total;

    const renderPanel = (panel) => {
      if (panel.name === "pools" && data.value["pools"].total < 1) return false;
      if (!props.isPublic || panel.name !== "submissions") return true;
    };

    const {
      data,
      search,
      sortBy,
      loadMore,
      sortOptions,
      loadingNfts,
      loadingMore,
      currentPanel,
      loadingPanels,
      setCurrentPanel,
      loadMoreExternal,
    } = useProfileView(useStore());

    return {
      data,
      search,
      sortBy,
      loadMore,
      panelTotal,
      panelsList,
      sortOptions,
      loadingMore,
      loadingNfts,
      renderPanel,
      currentPanel,
      loadingPanels,
      setCurrentPanel,
      loadMoreExternal,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/profilePanels.scss" />

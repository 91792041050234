import * as Yup from "yup";

export const timedAuctionSchema = Yup.object().shape({
  startPrice: Yup.number()
    .moreThan(0, "Start price must be greater than 0.")
    .positive("Start price must be greater than 0.")
    .lessThan(99999999, "Start price must be lower than 99999999")
    .typeError("Start price must be greater than 0."),
  reservedPrice: Yup.number()
    .nullable()
    .moreThan(0, "Reserved price must be greater than 0.")
    .positive("Reserved price must be greater than 0.")
    .lessThan(99999999, "Reserved price must be lower than 99999999")
    .typeError("Reserved price must be greater than 0."),
  days: Yup.number()
    .min(1, "Days must be between 1 and 30")
    .max(30, "Days must be between 1 and 30")
    .typeError("Days must be between 1 and 30"),
  hours: Yup.number()
    .min(0, "Hours must be between 0 and 24")
    .max(24, "Hours must be between 0 and 24")
    .typeError("Hours must be between 0 and 24"),
});

<template>
  <div class="pools-gallery">
    <div class="pools-gallery-content" v-if="poolGalleryStats">
      <div class="pools-gallery-heading">ODYSSEY POOLS GALLERY</div>
      <div class="pools-gallery-text">
        We offer a quick way to receive liquidity for your NFTs.
      </div>
      <div class="pools-gallery-text">
        Choose a pool, submit and get funded with tradeable tokens!
      </div>
      <div class="pools-gallery-search-and-button">
        <div class="pools-gallery-search">
          <app-input
            v-model="searchString"
            placeholder="Search by name, tag or cause"
            inputClass="app-modal-input-search"
            inputStyle="padding-left: 35px"
            @keydown.enter.stop="updateSearchString"
          />
          <img
            src="@/Common/Icons/search_icon.png"
            alt="search"
            class="profile-panel-search-icon"
          />
          <img
            v-show="searchString != ''"
            alt="delete"
            @click="resetSearchString()"
            class="profile-panel-search-cross"
            src="@/Common/Icons/search_cross.png"
          />
        </div>
        <div class="pools-gallery-button">
          <app-button
            @click="createPool()"
            buttonClass="app-modal-button"
            text="Create a pool"
          />
        </div>
      </div>
      <featured-pool
        v-model="lastPool"
        :stats="poolGalleryStats.find((item) => item.id === lastPool.id)"
      ></featured-pool>
      <div class="pools-gallery-table">
        <pool-list-table
          :isCheckbox="false"
          singlePoolImage="single-pool-image"
        ></pool-list-table>
      </div>
    </div>
  </div>
</template>

<script>
import PoolListTable from "@/components/DepositNft/PoolsTable/PoolListTable";
import AppInput from "@/elements/AppInput";
import AppButton from "@/elements/AppButton";
import FeaturedPool from "@/components/PoolGallery/FeaturedPool";
import { useStore } from "vuex";
import { onMounted } from "vue";
import usePoolsGallery from "@/composables/usePoolsGallery";
import { eventBus } from "@/main";
import { useRouter } from "vue-router";

export default {
  components: {
    AppInput,
    AppButton,
    FeaturedPool,
    PoolListTable,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const {
      pools,
      searchString,
      poolGalleryStats,
      lastPool,
      fetchGalleryStats,
    } = usePoolsGallery(store);
    onMounted(async () => {
      await store.dispatch("poolsGallery/fetchPools");
      await fetchGalleryStats(pools.value);
    });

    const createPool = () => router.push({ name: "Pools" });

    const updateSearchString = () => {
      eventBus.emit("updateSearchString", searchString.value);
    };
    const resetSearchString = () => {
      searchString.value = "";
    };
    return {
      pools,
      lastPool,
      createPool,
      searchString,
      poolGalleryStats,
      updateSearchString,
      resetSearchString,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/poolsGallery.scss"></style>

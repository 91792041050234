import api from "../../services/api";
import utils from "../utils";

const initialState = () => ({
  errors: [],
  nftFile: {},
  ipfsData: {},
  selectedNft: {},
  coverImageFile: {},
  nftData: {},
});

const errorMessage = "Something went wrong.";

const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
  getIpfsData(state) {
    return state.ipfsData;
  },
  getNftFile(state) {
    return state.nftFile;
  },
  getNftCoverImageFile(state) {
    return state.coverImageFile;
  },
  getErrors(state) {
    return state.errors;
  },
  getNftData(state) {
    return state.nftData;
  },
};
const mutations = {
  selectNft(state, nft) {
    state.selectedNft = nft;
  },
  setIpfsData(state, data) {
    state.ipfsData = data;
  },
  setNftFile(state, file) {
    state.nftFile = file;
  },
  setCoverImageFile(state, file) {
    state.coverImageFile = file;
  },
  setNftData(state, data) {
    state.nftData = data;
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  clearFileData(state) {
    state.coverImageFile = {};
    state.nftFile = {};
    state.ipfsData = {};
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  selectNft({ commit }, nft) {
    commit("selectNft", nft);
  },
  async pinFile({ commit }, { file, name, description }) {
    try {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("name", name);
      formData.append("description", description);
      const result = await api.pinFile(formData);
      commit("setIpfsData", result.data);
    } catch (error) {
      commit("pushError", errorMessage);
    }
  },
  async clearFileData({ commit }) {
    commit("clearFileData");
  },
  async storeNft({ commit }, nftData) {
    try {
      const result = await api.storeNft(nftData);
      commit("setNftData", result.data);
    } catch (error) {
      const isUnauthorized = await utils.handleUnauthorizedRequest(error);
      if (isUnauthorized) return null;
      commit("pushError", errorMessage);
    }
  },
  async updateNft({ commit }, { id, nftData }) {
    try {
      await api.updateNft(id, nftData);
    } catch (error) {
      const isUnauthorized = await utils.handleUnauthorizedRequest(error);
      if (isUnauthorized) return null;
      commit("pushError", errorMessage);
    }
  },
  async storeNftFile({ commit }, formData) {
    try {
      const result = await api.storeNftFile(formData);
      commit("setNftFile", result.data);
      return result;
    } catch (error) {
      const isUnauthorized = await utils.handleUnauthorizedRequest(error);
      if (isUnauthorized) return null;
      commit("pushError", errorMessage);
    }
  },
  async storeCoverImageFile({ commit }, formData) {
    try {
      const result = await api.storeCoverImageFile(formData);
      commit("setCoverImageFile", result.data);
      return result;
    } catch (error) {
      const isUnauthorized = await utils.handleUnauthorizedRequest(error);
      if (isUnauthorized) return null;
      commit("pushError", errorMessage);
    }
  },

  async autogenerateCoverImage({ commit }, formData) {
    try {
      const result = await api.autogenerateCoverImage(formData);
      commit("setCoverImageFile", result.data);
      return result.data;
    } catch (error) {
      const isUnauthorized = await utils.handleUnauthorizedRequest(error);
      if (isUnauthorized) return null;
      commit("pushError", errorMessage);
    }
  },

  async deleteNft({ commit }, id) {
    try {
      return await api.deleteNft(id);
    } catch (error) {
      commit("pushError", errorMessage);
    }
  },

  pushError({ commit }, error) {
    commit("pushError", error);
  },
  resetState({ commit }) {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import api from "@/services/api";
import queryString from "query-string";

const initialState = () => ({
  pools: [],
  errors: [],
  lastPool: null,
  poolGalleryStats: null,
  lastPoolStats: null,
});

const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
  getPools(state) {
    return state.pools;
  },
  getLastPool(state) {
    return state.lastPool;
  },
  getPoolGalleryStats(state) {
    return state.poolGalleryStats;
  },
};

const mutations = {
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  commitPools(state, object) {
    state.pools = object;
    if (object != null && object.length != 0) state.lastPool = object[0];
  },
  commitStats(state, object) {
    state.poolGalleryStats = object;
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};
const actions = {
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  resetState({ commit }) {
    commit("resetState");
  },
  async fetchPools({ commit }) {
    try {
      const params = {
        orderBy: "id",
        orderDirection: "DESC",
      };
      const response = await api.fetchPools(queryString.stringify(params));
      commit("commitPools", response.data.data);
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchPoolGalleryStats({ commit }, params) {
    try {
      const response = await api.fetchPoolGalleryStats(params);
      commit("commitStats", response.data.data);
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

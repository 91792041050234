import { computed, reactive } from "vue";
import { useRouter } from "vue-router";
import useToast from "@/composables/useToast";
import { ethereumService } from "../main";
import useInsufficientFunds from "@/composables/useInsufficientFunds";

const depositData = reactive({
  price: 0,
  nftId: "",
  poolId: "",
});

const useWithdrawModal = (store) => {
  const router = useRouter();
  const { showToast } = useToast();

  const { toggleInsufficientFundsModal } =
    useInsufficientFunds(ethereumService);

  const withdrawFlag = computed(() =>
    store.getters["withdraw/getByKey"]("withdrawFlag")
  );
  const toggleWithdrawFlag = () =>
    store.dispatch("withdraw/toggleWithdrawFlag");
  const currentStep = computed(() =>
    store.getters["withdraw/getByKey"]("currentStep")
  );
  const currentStepsArray = computed(() =>
    store.getters["withdraw/getByKey"]("currentStepsArray")
  );
  const commitToStore = (data) => {
    store.dispatch("withdraw/commitByKey", data);
  };
  const resetState = () => store.dispatch("withdraw/resetState");
  const incrementStep = () => {
    store.dispatch("withdraw/incrementStep");
  };
  const decrementStep = () => store.dispatch("withdraw/decrementStep");

  const closeModal = () => {
    toggleWithdrawFlag();
    resetState();
  };

  const confirmWithdrawal = async (nft) => {
    incrementStep();
    const success = await withdrawNft(nft);
    if (success) {
      await store.dispatch("nftDetails/fetchNft", nft.id);
      await router.push({
        path: `/nft-details/${nft.id}`,
      });
      return closeModal();
    }
    await showToast(
      "Withdraw error",
      "Nft withdrawal failed. Please try again",
      "Error"
    );
    return closeModal();
  };
  const isAllowedTheos = computed(() =>
    store.getters["withdraw/getByKey"]("theosAllowed")
  );
  //TODO: implement
  const goToPool = () => {};
  const withdrawNft = async (nft) => {
    try {
      await ethereumService.allowIndexPoolToken(
        nft.pools[0].tokenAddress,
        nft.pools[0].ethAddress
      );
      const withdrawTransactionData = {
        contractAddress: nft.contractAddress,
        poolAddress: nft.pools[0].ethAddress,
        contractId: nft.contractId,
      };
      const withdrawTransaction = await store.dispatch(
        "contracts/withdrawNft",
        withdrawTransactionData
      );
      await withdrawTransaction.wait();
      await store.dispatch("withdraw/withdrawNft", {
        pool: nft.pools[0],
        nft,
      });
      return true;
    } catch (error) {
      return false;
    }
  };
  const nft = computed(() => store.getters["withdraw/getByKey"]("nft"));
  const pool = computed(() => store.getters["withdraw/getByKey"]("pool"));

  const fetchUserBalance = async (tokenAddress, userAddress) => {
    const userBalance = await ethereumService.fetchUserBalance(
      tokenAddress,
      userAddress
    );
    store.dispatch("withdraw/commitUserBalance", userBalance);
    return userBalance;
  };
  const userBalance = computed(() =>
    store.getters["withdraw/getByKey"]("userBalance")
  );

  const showNotEnoughAssetsToast = async () => {
    toggleInsufficientFundsModal();
  };

  return {
    nft,
    pool,
    goToPool,
    resetState,
    currentStep,
    userBalance,
    depositData,
    withdrawNft,
    withdrawFlag,
    commitToStore,
    incrementStep,
    decrementStep,
    isAllowedTheos,
    fetchUserBalance,
    currentStepsArray,
    confirmWithdrawal,
    toggleWithdrawFlag,
    showNotEnoughAssetsToast,
  };
};

export default useWithdrawModal;

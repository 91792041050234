import api from "@/services/api";
import { ethereumService } from "@/main";
import { nftListSchema } from "@/validationSchemas";

const initialState = () => ({
  listFlag: false,
  selectedType: null,
  hasSubmission: false,
  selectedToken: null,
  nft: {
    id: null,
    royaltyPercentage: null,
    name: String,
  },
  showLastCheckForAuction: localStorage.getItem("showLastCheckForAuction")
    ? localStorage.getItem("showLastCheckForAuction") === "true"
    : true,
  listingPrice: null,
  currentStep: 1,
  currentStepsArray: [
    {
      component: "ListNftModal",
      scheme: nftListSchema,
    },
    {
      component: "CompletingModal",
      scheme: "",
    },
  ],
  errors: [],
});

const state = initialState();
const getters = {
  getByKey: (state) => (key) => state[key],
};
const mutations = {
  setByKey(state, obj) {
    Object.keys(obj).forEach((key) => (state[key] = obj[key]));
  },
  toggleListFlag(state) {
    state.listFlag = !state.listFlag;
    if (state.listFlag) {
      document.body.classList.add("stop-scroll");
      return;
    }
    document.body.classList.remove("stop-scroll");
  },
  toggleHasSubmission(state) {
    state.hasSubmission = !state.hasSubmission;
  },
  incrementStep(state) {
    state.currentStep++;
  },
  decrementStep(state) {
    state.currentStep--;
  },
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  removeError(state, removeError) {
    state.errors = state.errors.filter((error) => error !== removeError);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
};
const actions = {
  async checkNftSubmissions({ commit }, id) {
    try {
      return await api.countNftSubmissions(id);
    } catch (error) {
      commit("pushError", error);
    }
  },
  setByKey({ commit }, obj) {
    commit("setByKey", obj);
  },
  incrementStep({ commit }) {
    commit("incrementStep");
  },
  decrementStep({ commit }) {
    commit("decrementStep");
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  resetState({ commit }) {
    commit("resetState");
  },
  toggleListFlag({ commit }) {
    try {
      commit("toggleListFlag");
    } catch (error) {
      commit("pushError", error);
    }
  },
  toggleHasSubmission({ commit }) {
    commit("toggleHasSubmission");
  },
  async fetchNft({ commit }, id) {
    try {
      const response = await api.fetchNft(id);
      commit("commitByKey", { nft: response.data });
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },

  async signSellerData({ commit }, data) {
    try {
      return await ethereumService.signSellerData(data);
    } catch (error) {
      console.log(error);
      if ("code" in error && error.code === 4001) {
        return "rejected";
      }
      commit("pushError", error);
    }
  },

  async createTimedAuction({ commit }, data) {
    try {
      return await api.createTimedAuction(data);
    } catch (error) {
      commit("pushError", error);
    }
  },

  pushError({ commit }, error) {
    commit("pushError", error);
  },
  clearErrors({ commit }) {
    commit("clearErrors");
  },
  removeError({ commit }, error) {
    commit("removeError", error);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="pool-panel-body-wrapper">
    <div class="pool-panel-filter-search">
      <div class="pool-panel-search">
        <app-input
          :value="searchString"
          v-model="searchString"
          @keyup.enter="search()"
          placeholder="Search by name, tag or cause"
          inputClass="app-modal-input-search-pool-detail"
        />
        <img
          alt="search"
          v-show="searchString === '' || searchString == null"
          class="profile-panel-search-icon"
          src="@/Common/Icons/search_icon.png"
        />
        <img
          alt="delete"
          v-show="searchString != ''"
          @click="resetSearchString()"
          class="profile-panel-search-cross"
          src="@/Common/Icons/search_cross.png"
        />
      </div>
      <div class="pool-panel-filter">
        <div class="pool-panel-filter-wrapper">
          <div
            class="pool-panel-filter-overlay"
            @click="toggle(filtersFlag.showFilters)"
          />
          <app-input
            disabled
            inputClass="app-modal-input-sort"
            placeholder="Filter and sort"
            inputIconLeftClass="filter-icon"
            :inputStyle="'padding-left: 35px;'"
            :inputIconRightClass="{
              'chevron-icon': true,
              active: filtersFlag.showFilters.value,
            }"
          />
        </div>
        <teleport to="body">
          <div
            class="filtering-options-overlay"
            v-if="filtersFlag.showFilters.value"
            @click="toggle(filtersFlag.showFilters)"
          />
        </teleport>
        <div class="filtering-options" v-if="filtersFlag.showFilters.value">
          <h3 class="filtering-options-heading">Sort by</h3>
          <p
            class="filtering-option"
            @click="toggleFilter('newestFirst')"
            :class="[activeClass === 'newestFirst' ? 'active' : '']"
          >
            Newest first
          </p>
          <p
            class="filtering-option"
            @click="toggleFilter('oldestFirst')"
            :class="[activeClass === 'oldestFirst' ? 'active' : '']"
          >
            Oldest first
          </p>
        </div>
      </div>
    </div>
    <div
      class="pool-panel-no-items"
      v-if="pooledNftsTotal.total === 0 && !loadingNfts"
    >
      <h3 class="pool-panel-no-items-title">No Items Found</h3>
      <p class="pool-panel-no-items-text">
        {{ profilePanelNoItemsText }}
      </p>
      <p class="pool-panel-no-items-text">
        {{ profilePanelNoItemsTextLineTwo }}
      </p>
      <app-button
        text="Create new NFT"
        buttonClass="create-nft-button"
        @click.prevent="goTo({ name: 'Minting' })"
      />
    </div>
    <div class="pool-panel-body-content" v-else>
      <div v-for="(card, index) in pooledNftList" :key="index">
        <app-card
          :pool="pool"
          :user="user"
          :token="card"
          @click="$router.push({ name: 'NftDetails', params: { id: card.id } })"
        />
      </div>
    </div>
    <load-more
      :loadMore="loadMore"
      v-if="isPooledNftsLength"
      :loadingMore="loadingNfts"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted, computed } from "vue";
import AppInput from "@/elements/AppInput";
import AppCard from "@/components/AppCard";
import AppButton from "@/elements/AppButton";
import usePools from "@/composables/usePools";
import useRouter from "@/composables/useRouter";
import LoadMore from "@/components/Profile/Panels/LoadMore";

export default {
  components: {
    AppCard,
    LoadMore,
    AppInput,
    AppButton,
  },
  setup() {
    const store = useStore();
    const { goTo } = useRouter();

    const {
      pool,
      user,
      toggle,
      search,
      loadMore,
      loadingNfts,
      activeClass,
      filtersFlag,
      toggleFilter,
      searchString,
      pooledNftList,
      pooledNftsTotal,
    } = usePools(store);

    const isPooledNftsLength = computed(
      () => pooledNftsTotal.value.total >= pooledNftList.value.length + 1
    );

    onMounted(() => {
      toggleFilter("newestFirst");
    });

    const resetSearchString = () => {
      searchString.value = "";
    };

    return {
      goTo,
      user,
      pool,
      toggle,
      search,
      loadMore,
      filtersFlag,
      activeClass,
      loadingNfts,
      searchString,
      toggleFilter,
      pooledNftList,
      pooledNftsTotal,
      isPooledNftsLength,
      resetSearchString,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/poolPanels.scss" />

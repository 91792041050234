<template>
  <div class="filter-wrapper">
    <label for="filter" class="label" v-if="!noLabel">Social cause</label>
    <div id="filter" class="filter-button">
      <div class="filter-button-overlay" @click="toggleShowDropdown()" />
      <div class="filter-button-content">
        <p class="text">{{ placeholderText }}</p>
        <i class="chevron-icon-free" :class="{ active: showDropdown }" />
      </div>
      <div class="filters-dropdown" v-if="showDropdown">
        <!-- WILL BE USED IN THE FUTURE - LEAVE COMMENTED -->
        <!-- <div class="social-cause-search">
          <app-input
            id="searchInput"
            name="searching"
            labelText="Search"
            labelClass="label"
            v-model="searchString"
            inputClass="app-modal-input"
            inputIconLeftClass="search-icon"
            placeholder="Search by name or tag"
          />
        </div> -->
        <!-- <p class="browse-label">Browse</p> -->
        <div class="causes-list">
          <social-cause-card
            :key="index"
            :socialCause="cause"
            @click="selectSocialCause(cause)"
            v-for="(cause, index) in filteredCauses"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/main";
import { useStore } from "vuex";
// import AppInput from "@/elements/AppInput";
import useSocialCauses from "@/composables/useSocialCauses";
import SocialCauseCard from "@/components/Pools/SocialCauseCard";
export default {
  components: {
    // AppInput,
    SocialCauseCard,
  },
  props: {
    noLabel: {
      type: Boolean,
      default: false,
    },
    placeholderText: {
      type: String,
    },
  },
  setup() {
    const {
      searchString,
      showDropdown,
      filteredCauses,
      selectSocialCause,
      socialCausesArray,
      toggleShowDropdown,
    } = useSocialCauses(useStore(), eventBus);

    return {
      searchString,
      showDropdown,
      filteredCauses,
      selectSocialCause,
      socialCausesArray,
      toggleShowDropdown,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/selectSocialCauses.scss" />

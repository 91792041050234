import api from "../../services/api";

const initialState = () => ({
  user: {
    id: null,
    publicAddress: "",
    nonce: "",
    email: "",
  },
  pendingTransaction: false,
  users: [],
  errors: [],
});

const state = initialState();

const getters = {
  getUser(state) {
    return state.user;
  },
  getUsers(state) {
    return state.users;
  },
  getPendingTransaction(state) {
    return state.pendingTransaction;
  },
  getErrors(state) {
    return state.errors;
  },
};
const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setUsers(state, users) {
    state.users = users;
  },
  setPendingTransaction(state, status) {
    state.pendingTransaction = status;
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async fetchUser({ commit }, id) {
    try {
      const result = await api.fetchUser(id);
      commit("setUser", result.data.data);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchUsers({ commit }) {
    try {
      const result = await api.fetchUsers();
      commit("setUsers", result.data.data);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async updateUser({ commit }, { userData, id }) {
    try {
      const result = await api.updateUser(userData, id);
      commit("setUser", result.data.data);
    } catch (error) {
      commit("pushError", error);
    }
  },
  updatePendingTransaction({ commit }, status) {
    commit("setPendingTransaction", status);
  },
  async resetState({ commit }) {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import * as Yup from "yup";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const MAX_FILE_SIZE = 41943040;

export const mintNftCoverImageScheme = Yup.object().shape({
  coverImageFile: Yup.mixed()
    .nullable()
    .required()
    .test(
      "FILE_SIZE",
      "The File Size should be less then 40MB",
      (value) => value.size <= MAX_FILE_SIZE
    )
    .test(
      "FILE_FORMAT",
      "Unsupported file, try again",
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    ),
});

<template>
  <div class="stepper-wrapper">
    <div
      class="stepper-item"
      v-for="(step, index) in steps"
      :key="index"
      :class="{
        completed: isStepCompleted(index),
      }"
    >
      <div
        class="step-counter"
        :class="{
          active: isStepActive(index),
          completed: isStepCompleted(index),
        }"
      >
        {{ index + 1 }}
      </div>
      <div
        class="step-name"
        :class="{
          active: isStepActive(index),
          completed: isStepCompleted(index),
        }"
      >
        <div class="stepper-text" v-if="step.title === ''"></div>
        {{ step.title }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Stepper",
  props: {
    steps: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const isStepCompleted = (index) => index + 1 < props.currentStep;
    const isStepActive = (index) => index + 1 == props.currentStep;
    return {
      isStepActive,
      isStepCompleted,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/stepper.scss"></style>

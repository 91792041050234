<template>
  <div
    class="network-badge"
    :style="{ width: `${cardSize}px`, height: `${cardSize}px` }"
    :class="isBig ? 'big' : ''"
  >
    <img :src="networkBadgeUrl()" :alt="token.network" />
  </div>
</template>

<script>
export default {
  props: {
    token: {
      type: Object,
      default: () => {},
    },
    cardSize: {
      type: Number,
      default: 32,
    },
    isBig: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const networkBadgeUrl = () =>
      ["mumbai", "polygon"].includes(props.token.network)
        ? require("@/Common/Icons/polygonBadge.svg")
        : require("@/Common/Icons/ethereumBadge.svg");

    return {
      networkBadgeUrl,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/networkBadge.scss" />

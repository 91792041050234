import { eventBus } from "@/main";
import { ref, computed } from "vue";
import { ethereumService } from "@/main";
import useAuth from "@/composables/useAuth";
import useNetworkData from "./useNetworkData";
import useWithdrawModal from "@/composables/useWithdrawModal";
import useStoreFunctions from "@/composables/useStoreFunctions";
import useInsufficientFunds from "@/composables/useInsufficientFunds";

const currentPanel = ref("all");
const MILLISECONDS_MINIMUM_CARD_LOADING = 0;

const useMarketplace = (store, storeFunctions = useStoreFunctions) => {
  const {
    computeStore: marketplaceGet,
    dispatchStore: marketplaceDispatch,
    commitByKey: marketplaceCommitByKey,
  } = storeFunctions("marketplace", store);
  const { isNftOnCurrentNetwork } = useNetworkData(ethereumService);

  const { toggleWalletConnectionRequired } = useAuth(store, ethereumService);

  const { toggleInsufficientFundsModal } =
    useInsufficientFunds(ethereumService);

  const data = marketplaceGet("data");
  const loadingPanels = marketplaceGet("loadingPanels");
  const loadingNfts = marketplaceGet("loadingNfts");
  const loadingMore = marketplaceGet("loadingMore");
  const me = computed(() => store.getters["auth/getRegisteredUser"]);

  const setCurrentPanel = async (value) => {
    await resetCurrentPanel();
    currentPanel.value = value;
    await switchPanel(value);
    eventBus.emit("changeMarketplaceSearchFlag", false);
    eventBus.emit("changeMarketplaceFilterAndSortFlag", false);
  };

  const searchQuery = marketplaceGet("searchString");

  const switchPanel = async (nextPanel) => {
    localStorage.setItem("persistedPanel", nextPanel);
    await marketplaceCommitByKey({ loadingNfts: true });
    await marketplaceDispatch("resetDataArray", nextPanel);
    await marketplaceDispatch("resetQueries");

    eventBus.emit("resetSearchString");
    if (nextPanel === "all") await marketplaceDispatch("fetchAllNfts");
    if (nextPanel === "pooled")
      await marketplaceDispatch("fetchPooledNfts", { status: "deposited" });
    if (nextPanel === "listed") await marketplaceDispatch("fetchListedNfts");
    setTimeout(async () => {
      await marketplaceCommitByKey({ loadingNfts: false });
    }, MILLISECONDS_MINIMUM_CARD_LOADING);
  };

  const initializePanels = async () => {
    await marketplaceDispatch("resetState");
    const persistedPanel = localStorage.getItem("persistedPanel");
    currentPanel.value = persistedPanel || "all";
    await marketplaceCommitByKey({ loadingPanels: true });
    await marketplaceDispatch("fetchAllNfts");
    await marketplaceDispatch("fetchPooledNfts", { status: "deposited" });
    await marketplaceDispatch("fetchListedNfts");
    await marketplaceCommitByKey({ loadingPanels: false });
    await marketplaceCommitByKey({ loadingMore: false });
  };

  const search = async (searchStringInput) => {
    if (loadingNfts.value) return;
    await marketplaceDispatch("resetPage");
    await marketplaceCommitByKey({ loadingNfts: true });
    await marketplaceCommitByKey({ searchString: searchStringInput });
    await marketplaceDispatch("resetDataArray", currentPanel.value);
    const searchParams = {
      search: searchStringInput,
    };
    if (currentAction.value === "fetchPooledNfts") {
      searchParams["status"] = "deposited";
    }
    await marketplaceDispatch(currentAction.value, searchParams);
    await marketplaceCommitByKey({ loadingNfts: false });
    if (searchStringInput === "") {
      eventBus.emit("changeMarketplaceSearchFlag", false);
    }
  };

  const resetCurrentPanel = async () => {
    await marketplaceDispatch("resetDataArray", currentPanel.value);
    await marketplaceDispatch("resetQueries");
    await marketplaceDispatch("resetPage");
    await marketplaceCommitByKey({ loadingNfts: true });
    await marketplaceDispatch(currentAction.value);
    await marketplaceCommitByKey({ loadingNfts: false });
  };

  const clearSearch = async () => {
    await marketplaceDispatch("resetDataArray", currentPanel.value);
    await marketplaceDispatch("resetPage");
    await marketplaceDispatch("clearSearch");
    await marketplaceCommitByKey({ loadingNfts: true });
    await marketplaceDispatch(currentAction.value);
    await marketplaceCommitByKey({ loadingNfts: false });
  };

  const sortBy = async (sort) => {
    await marketplaceDispatch("resetPage");
    await marketplaceDispatch("resetDataArray", currentPanel.value);
    await marketplaceDispatch("setSort", sort);
    await marketplaceCommitByKey({ loadingNfts: true });
    await marketplaceDispatch(currentAction.value);
    await marketplaceCommitByKey({ loadingNfts: false });
  };

  const elementExist = async (prevQuery, filter) => {
    let indexOf = prevQuery.indexOf(filter);
    if (indexOf >= 0) {
      return prevQuery.splice(indexOf, 1);
    }
    if (indexOf === -1) {
      return prevQuery.push(filter);
    }
    return false;
  };

  const query = computed(() =>
    store.getters["marketplace/getByKey"]("queries")
  );

  const filter = async (filter) => {
    const prevQuery = { ...query.value };
    eventBus.emit("changeMarketplaceFilterAndSortFlag", true);
    await marketplaceDispatch("resetPage");
    await marketplaceDispatch("resetDataArray", currentPanel.value);

    if (filter.type === "price") {
      await elementExist(prevQuery.priceRange, filter.priceRange);
      await marketplaceDispatch("setFilter", {
        priceRange: prevQuery.priceRange,
      });
    }

    if (filter.type === "media") {
      await elementExist(prevQuery.media, filter.value);
      await marketplaceDispatch("setFilter", {
        media: prevQuery.media,
      });
    }

    if (filter.type === "network") {
      const networkFilter = ethereumService.availableNetworks[filter.value];
      await elementExist(prevQuery.networks, networkFilter);
      await marketplaceDispatch("setFilter", {
        networks: prevQuery.networks,
      });
    }

    if (filter.type === "clear") {
      await marketplaceDispatch("setFilter", {
        media: [],
        priceRange: [],
        networks: [],
      });
    }
    await marketplaceCommitByKey({ loadingNfts: true });
    await marketplaceDispatch(currentAction.value);
    await marketplaceCommitByKey({ loadingNfts: false });

    if (
      prevQuery.media.length === 0 &&
      prevQuery.priceRange.length === 0 &&
      prevQuery.networks.length === 0
    ) {
      eventBus.emit("changeMarketplaceFilterAndSortFlag", false);
    }
  };

  const isChecked = (type, value) => {
    if (type === "price" && query.value.priceRange.includes(value)) {
      return true;
    }

    if (type === "network") {
      const networkFilter = ethereumService.availableNetworks[value];
      return query.value.networks.includes(networkFilter);
    }

    return type === "media" && query.value.media.includes(value);
  };

  const activeSort = computed(
    () => `${query.value.orderBy}_${query.value.orderDirection.toLowerCase()}`
  );

  const currentAction = computed(() => {
    let currentAction = ref("fetchAllNfts");
    switch (currentPanel.value) {
      case "all":
        currentAction = "fetchAllNfts";
        break;
      case "pooled":
        currentAction = "fetchPooledNfts";
        break;
      case "listed":
        currentAction = "fetchListedNfts";
        break;
    }
    return currentAction;
  });

  const loadMore = async () => {
    if (loadingMore.value) return;
    await marketplaceCommitByKey({ loadingMore: true });
    const loadParams = {};
    if (searchQuery.value) {
      loadParams["search"] = searchQuery.value;
    }
    setTimeout(async () => {
      await marketplaceDispatch("incrementPage");
      await marketplaceDispatch(currentAction.value, loadParams);
      await marketplaceCommitByKey({ loadingMore: false });
    }, MILLISECONDS_MINIMUM_CARD_LOADING);
  };

  const { fetchUserBalance } = useWithdrawModal(store);

  const buyNft = async (token) => {
    console.log("BUY NFT");
    console.log(token);
    if (isNftOnCurrentNetwork(token)) {
      if (!me.value.publicAddress) {
        toggleWalletConnectionRequired();
        return;
      }
      const userBalance = await fetchUserBalance(
        token.pools[0].tokenAddress,
        me.value.publicAddress
      );

      const isOwner = token.currentOwner?.id === me.value.id;
      const price = isOwner ? token.pools[0].basePrice : token.sellPrice;

      await fetchUserBalance(
        token.pools[0].tokenAddress,
        me.value.publicAddress
      );

      if (Number(userBalance) >= Number(price)) {
        await store.dispatch("buyNft/fetchNft", token.id);
        return await store.dispatch("buyNft/toggleBuyFlag");
      }
      return await toggleInsufficientFundsModal(token, userBalance);
    }
    return store.dispatch("auth/commitByKey", {
      switchNetworkModalFlag: true,
    });
  };

  const share = () => {
    // TODO: implement me
  };

  return {
    data,
    share,
    filter,
    buyNft,
    sortBy,
    search,
    loadMore,
    isChecked,
    activeSort,
    clearSearch,
    loadingMore,
    loadingNfts,
    currentPanel,
    loadingPanels,
    setCurrentPanel,
    initializePanels,
  };
};

export default useMarketplace;

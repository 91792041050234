<template>
  <teleport to="body">
    <app-modal v-if="showPoolPreview">
      <template v-slot:app-modal-body>
        <div class="app-modal-body-wrapper">
          <img src="@/Common/Icons/LOGO_GIF.gif" alt="Logo" class="logo-gif" />
          <p class="app-modal-welcome-text">NFTize Pool Preview</p>
          <img
            id="preview-image-pool"
            :src="backgroundUrl || require('@/Common/Icons/Avatar.png')"
            alt="Preview"
          />
          <div class="preview-description">
            <p class="preview-title">
              {{ pool.name }}
            </p>
            <p class="preview-text">{{ pool.description }}</p>
          </div>
          <div class="preview-description">
            <ul>
              <li
                :class="
                  index % 2 == 0 ? 'preview-tag' : 'preview-tag-second-color'
                "
                v-for="(tag, index) in tags"
                :key="index"
              >
                {{ tag }}
              </li>
            </ul>
          </div>
          <hr v-if="pool.socialCause" class="preview-horizontal" />
          <div v-if="pool.socialCause" class="preview-social-cause-wrapper">
            <div class="preview-social-cause">
              <p class="preview-text">Social Cause</p>
              <div class="social-cause-display">
                <img
                  :src="require(`@/Common/Icons/viralcure-logo.svg`)"
                  alt="socialCauseImage"
                  class="cause-img"
                />
                <p class="cause-name">{{ pool.socialCause.name }}</p>
              </div>
            </div>
          </div>
          <hr class="preview-horizontal" />
          <div class="preview-royalties-wrapper">
            <div class="preview-royalties">
              <p class="preview-text">Royalties</p>
              <span class="royalties">{{ pool.royalties }}%</span>
            </div>
          </div>
          <hr class="preview-horizontal" />
          <div class="preview-token-wrapper">
            <div class="token-row">
              <div class="token-group">
                <p class="token-label">Token name</p>
                <p class="token-value">{{ pool.tokenName }}</p>
              </div>
              <div class="token-group">
                <p class="token-label">Token symbol</p>
                <p class="token-value">{{ pool.tokenSymbol }}</p>
              </div>
              <div class="token-group">
                <p class="token-label">NFT Base price</p>
                <p class="token-value">${{ pool.nftBasePrice }}</p>
              </div>
            </div>
            <div class="token-row">
              <div class="token-group">
                <p class="token-label">Maximum i Price</p>
                <p class="token-value">
                  {{
                    toFixedIfNecessary(
                      (pool.maximumIPrice * pool.nftBasePrice) / 100,
                      2
                    )
                  }}
                  {{ pool.tokenSymbol }}
                </p>
              </div>
              <div class="token-group">
                <p class="token-label">NFT Slots</p>
                <p class="token-value">{{ pool.slots || "/" }}</p>
              </div>
              <div class="token-group"></div>
            </div>
          </div>
          <hr class="preview-horizontal" />
          <div class="preview-initial-nft-wrapper">
            <div class="initial-nft-hash">
              <p class="label">Initial NFT</p>
            </div>
            <div class="show-initial-nft">
              <img
                :src="initialNft.coverImage.path"
                class="initial-nft-img"
                alt="Img"
              />
              <p class="initial-token-name">
                {{ selectedNft.name }}
              </p>
              <p class="nft-contract-address">
                {{ selectedNft.contractAddress.slice(0, 10) }}...{{
                  selectedNft.contractAddress.slice(-14)
                }}
              </p>
            </div>
          </div>
          <hr class="preview-horizontal" />
          <div class="preview-buttons">
            <app-button
              @click.prevent="togglePoolPreviewModal()"
              buttonClass="app-modal-button"
              text="Back"
            />
            <app-button
              @click="createPool()"
              buttonClass="app-modal-button-inverse"
              text="Create pool"
              type="submit"
            />
          </div>
        </div>
      </template>
    </app-modal>
    <app-modal
      v-if="isCreatingPool"
      :closeButton="true"
      :returnToPreviousModal="returnToPreviousModal"
    >
      <template v-slot:app-modal-body>
        <div class="app-modal-body-wrapper">
          <loader :isGreen="true" />
          <p class="app-modal-pool-creating-text">Creating</p>
          <p class="app-modal-info-text">
            Sending transaction to create your NFTize pool...
          </p>
        </div>
      </template>
    </app-modal>
  </teleport>
</template>

<script>
import { useStore } from "vuex";
import { onMounted, onUnmounted } from "vue";
import Loader from "@/elements/AppLoader.vue";
import AppButton from "@/elements/AppButton";
import AppModal from "@/elements/AppModal";
import usePool from "@/composables/usePool";
import useUtilities from "@/composables/useUtilities";

export default {
  components: {
    AppModal,
    AppButton,
    Loader,
  },
  props: {
    pool: {
      type: Object,
      default: () => {},
    },
    initialNft: {
      type: Object,
      default: () => {},
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore();
    const {
      selectedNft,
      createPool,
      backgroundUrl,
      previewImgSrc,
      isCreatingPool,
      showPoolPreview,
      returnToPreviousModal,
      togglePoolPreviewModal,
    } = usePool(store);

    const coverImage = () =>
      !!props.pool.socialCause && !!props.pool.socialCause.coverImage
        ? props.pool.socialCause.coverImage.thumbnail
        : require("@/Common/Icons/Medusa_avatar.png");

    onMounted(() => document.body.classList.add("stop-scroll"));
    onUnmounted(() => document.body.classList.remove("stop-scroll"));
    const { toFixedIfNecessary } = useUtilities();
    return {
      createPool,
      coverImage,
      selectedNft,
      backgroundUrl,
      previewImgSrc,
      isCreatingPool,
      showPoolPreview,
      toFixedIfNecessary,
      returnToPreviousModal,
      togglePoolPreviewModal,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/previewModal.scss"></style>

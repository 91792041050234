<template>
  <div class="profile">
    <div class="profile-cover-image" />
    <div class="profile-content">
      <div class="profile-description">
        <profile-description :user="user" />
      </div>
      <div class="profile-data">
        <!-- <private-statistics /> -->
        <div class="profile-panels">
          <profile-panels />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted } from "vue";
import useProfileView from "@/composables/useProfileView";
import useUserProfile from "@/composables/useUserProfile";
import ProfilePanels from "@/components/Profile/ProfilePanels";
// import PrivateStatistics from "@/components/Profile/PrivateStatistics";
import ProfileDescription from "@/components/Profile/ProfileDescription";
export default {
  components: {
    ProfilePanels,
    // PrivateStatistics,
    ProfileDescription,
  },
  setup() {
    const store = useStore();
    const { initializePanels } = useProfileView(store);

    const { user, fetchUsersProfileStats } = useUserProfile(store);

    onMounted(async () => {
      await store.dispatch("userProfile/clearPublicUser");
      await store.dispatch("userProfile/fetchMe");
      fetchUsersProfileStats(user.value.id);
      await initializePanels(false);
    });

    return {
      user,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/profile.scss" />

import * as Yup from "yup";
import { ethereumService } from "../main";

export const createSocialCauseSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .min(3, "Name must be at least 3 characters long"),
  ethAddress: Yup.string()
    .required()
    .typeError("This field is required")
    .test(
      "ETH_ADDRESS",
      "This is not a valid Ethereum address.",
      (value) => !value || ethereumService.isEthereumAddress(value)
    ),
});

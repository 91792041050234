<template>
  <div class="menu-bar" :class="[isLoading ? 'disable-items' : '']">
    <div class="logo-and-menu">
      <router-link to="/">
        <img
          src="@/Common/Icons/theos_logo.svg"
          alt="Logo"
          class="theos-logo"
        />
      </router-link>
      <div class="list-items">
        <router-link
          tag="button"
          class="nav-swap"
          to="/deposit"
          @click="resetDepositState()"
        >
          Submit
        </router-link>
        <router-link
          :disabled="isBeginConnectionModalOpen"
          tag="button"
          class="nav-swap"
          to="/minting"
        >
          Mint
        </router-link>
        <!--        TODO: when pools-gallery is back - revert in first route.path-->
        <div
          v-if="isWhitelisted"
          @click="togglePoolsMenu()"
          v-click-outside="hideDropdown"
          class="main-tab"
          :class="{
            active:
              $route.path === '/pools/22' || $route.path === '/create-pool',
          }"
        >
          <div
            class="pools-item pointer"
            :class="{
              'clicked-pools': isClickedPool,
            }"
          >
            <img v-show="showPoolsBadge" src="@/Common/Icons/badge.svg" />
            Pools
            <img
              src="@/Common/Icons/arrow_down.svg"
              :style="`transform: rotate(${isClickedPool ? '180deg' : '0'})`"
            />
          </div>
          <div v-show="isClickedPool" class="pools-menu">
            <div>
              <router-link
                class="pools-menu-item pointer"
                to="/create-pool"
                @click="resetCreatePoolState()"
                >Create a pool
              </router-link>
            </div>
            <div>
              <router-link class="pools-menu-item pointer" to="/pools/22"
                >Genesis pool
              </router-link>
            </div>
          </div>
        </div>
        <router-link tag="button" class="nav-swap" to="/pools/22" v-else>
          Genesis pool
        </router-link>
        <router-link tag="button" class="nav-swap" to="/marketplace">
          Marketplace
        </router-link>
        <a
          class="nav-swap liquidity-mining-item"
          href="https://liquidity-mining.theos.fi/"
          target="_blank"
        >
          Liquidity Mining
        </a>
      </div>
    </div>
    <div class="button-container">
      <app-button
        v-if="!networkParams?.chainName"
        buttonClass="button-primary-default pointer"
        text="Connect to a wallet"
        @click="loginOrSignUp(false, true)"
      />
      <user-balance-and-address v-else />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ethereumService } from "@/main";
import usePool from "@/composables/usePool";
import useAuth from "@/composables/useAuth";
import AppButton from "@/elements/AppButton.vue";
import useUserProfile from "@/composables/useUserProfile";
import useDeposit from "@/composables/useDeposit";
import UserBalanceAndAddress from "../components/UserBalanceAndAddress.vue";
import vClickOutside from "click-outside-vue3";

export default {
  components: { AppButton, UserBalanceAndAddress },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const {
      loginOrSignUp,
      openRegistrationModal,
      isBeginConnectionModalOpen,
      showBadge,
      setBadge,
    } = useAuth(store, ethereumService);
    const { toggleFilter, setTheosMinted, show } = useDeposit(store);
    const address = computed(() => store.getters["contracts/getAddress"]);
    const isLoading = computed(() => store.getters["minting/getIsLoading"]);

    const { isWhitelisted } = useUserProfile(store, router);

    const switchToPolygonNetwork = () => {
      return ethereumService.switchToPolygonNetwork();
    };

    const networkParams = ref(ethereumService.networkParams);

    let isClickedPool = ref(false);

    onMounted(async () => {
      if (address.value !== "") await store.dispatch("auth/fetchMe");
    });

    const togglePoolsMenu = async () => {
      isClickedPool.value = !isClickedPool.value;
      await setPoolsBadge();
    };
    const hideDropdown = () => {
      isClickedPool.value = false;
    };

    const resetDepositState = () => {
      store.dispatch("deposit/resetState");
      store.dispatch("deposit/fetchMe");
      setTheosMinted();
      show("theosNfts");
      toggleFilter("filterNewest");
    };

    const { resetPoolState, fetchUserBalanceAndNftList } = usePool(store);

    const resetCreatePoolState = async () => {
      await store.dispatch("pool/resetState");
      await store.dispatch("tagsValues/resetState");
      resetPoolState();
      await fetchUserBalanceAndNftList();
    };

    const setPoolsBadge = async () => {
      if (address.value != "") await setBadge("pools");
    };

    const showPoolsBadge = computed(() => {
      const result = showBadge("pools");
      return result;
    });

    return {
      address,
      isLoading,
      hideDropdown,
      networkParams,
      setPoolsBadge,
      isWhitelisted,
      isClickedPool,
      loginOrSignUp,
      showPoolsBadge,
      togglePoolsMenu,
      resetDepositState,
      resetCreatePoolState,
      openRegistrationModal,
      switchToPolygonNetwork,
      isBeginConnectionModalOpen,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/AppMenuBarStyles.scss"></style>
<style
  lang="scss"
  scoped
  src="@/Common/Styles/UserBalanceAndAddress.scss"
></style>

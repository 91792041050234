<template>
  <div class="app-modal-overlay">
    <div class="app-modal-wrapper app-buy-nft" :class="styleClass">
      <div
        v-if="closeButton"
        class="app-modal-leave-modal"
        @click="returnToPreviousModal()"
      >
        <img src="@/Common/Icons/XmarkModal.png" alt="Logo" />
      </div>
      <div class="app-modal-header">
        <slot name="app-modal-header" />
      </div>
      <div class="app-modal-body-wrapper">
        <div class="app-modal-body">
          <slot name="app-modal-body" />
        </div>
      </div>
      <div class="app-modal-footer">
        <slot name="app-modal-footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppDepositReview",
  props: {
    closeButton: {
      type: Boolean,
      default: false,
    },
    returnToPreviousModal: {
      type: Function,
      default: () => {},
    },
    styleClass: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/depositReviewModal.scss" />

<template>
  <app-deposit-review :closeButton="false">
    <template v-slot:app-modal-header>
      <div class="spinner file-input new">
        <app-loader :show="true" :isGreen="true" />
      </div>
      <h3 class="app-modal-deposit-title-completing">COMPLETING...</h3>
    </template>
    <template v-slot:app-modal-body>
      <p class="app-modal-body-text-completing" v-if="!isAllowedTheos">
        To purchase the NFT complete this final transaction...
      </p>
    </template>
  </app-deposit-review>
</template>

<script>
import { useStore } from "vuex";
import { onMounted, onUnmounted } from "vue";
import AppLoader from "@/elements/AppLoader";
import AppDepositReview from "@/elements/AppDepositReview";
import useDepositReviewModal from "@/composables/useDepositReviewModal";

export default {
  name: "WithdrawingInProcess",
  components: {
    AppLoader,
    AppDepositReview,
  },
  props: {
    nft: {
      type: Object,
      default: () => {},
    },
    pool: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const store = useStore();
    const { isAllowedTheos } = useDepositReviewModal(store);

    onMounted(() => document.body.classList.add("stop-scroll"));

    onUnmounted(() => document.body.classList.remove("stop-scroll"));

    return {
      isAllowedTheos,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/depositReview.scss" />

import {
  registrationUploadAvatarSchema,
  registrationNameAndDescriptionSchema,
  registrationDetailsSchema,
} from "@/validationSchemas";
import api from "@/services/api";

const initialState = () => ({
  registrationFlag: false,
  currentStep: 1,
  currentStepsArray: [
    {
      component: "RegistrationStart",
      schema: {},
    },
    {
      component: "RegistrationUploadAvatar",
      schema: registrationUploadAvatarSchema,
    },
    {
      component: "RegistrationNameAndDescription",
      schema: registrationNameAndDescriptionSchema,
    },
    {
      component: "RegistrationDetails",
      schema: registrationDetailsSchema,
    },
  ],
  user: {
    profileImageId: null,
    displayName: "",
    description: "",
    email: "",
    websiteUrl: "",
    twitterUsername: "",
  },
  errors: [],
  registrationFromProfileFlag: false,
});

const errorMessage = "Something went wrong.";

const state = initialState();
const getters = {
  getByKey: (state) => (key) => state[key],
  getUserByKey: (state) => (key) => state.user[key],
  getProfileImageBlob: (state) => state.selectedFile,
  getProfileImageURL: (state) => URL.createObjectURL(state.selectedFile),
  getProfileImageId: (state) => state.profileImageId,
  getUser(state) {
    return state.user;
  },
};
const mutations = {
  setByKey(state, obj) {
    Object.keys(obj).forEach((key) => (state[key] = obj[key]));
  },
  setSelectedFile(state, file) {
    state.selectedFile = file;
  },
  setProfileImageId(state, id) {
    state.profileImageId = id;
  },
  toggleRegistrationFlag(state) {
    state.registrationFlag = !state.registrationFlag;
  },
  incrementStep(state) {
    state.currentStep++;
  },
  decrementStep(state) {
    state.currentStep--;
  },
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state.user[key] = object[key]));
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
};
const actions = {
  setByKey({ commit }, obj) {
    commit("setByKey", obj);
  },
  setSelectedFile({ commit }, file) {
    try {
      commit("setSelectedFile", file);
    } catch (error) {
      commit("pushError", error);
    }
  },
  setProfileImageUrl({ commit }, image) {
    try {
      commit("setProfileImageUrl", image);
    } catch (error) {
      commit("pushError", error);
    }
  },
  incrementStep({ commit }) {
    commit("incrementStep");
  },
  decrementStep({ commit }) {
    commit("decrementStep");
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  resetState({ commit }) {
    commit("resetState");
  },
  toggleRegistrationFlag({ commit }) {
    try {
      commit("toggleRegistrationFlag");
    } catch (error) {
      commit("pushError", error);
    }
  },
  async storeProfileImageFile({ commit }, formData) {
    try {
      const result = await api.storeUserProfileImageFile(formData);
      commit("setProfileImageId", result.data.id);
      return result.data.id;
    } catch (error) {
      commit("pushError", errorMessage);
    }
  },

  async updateUser({ commit }, updateUser) {
    try {
      const me = await api.fetchMe();
      const result = await api.updateUser(updateUser, me.data.data.id);
      return result;
    } catch (error) {
      commit("pushError", errorMessage);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

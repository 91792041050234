import { computed, reactive, ref } from "vue";
import useToast from "../composables/useToast";
import useUtilities from "@/composables/useUtilities";
import imageTransformer from "../plugins/imageTransformer";

const useUserProfile = (store) => {
  const buttonFlag = ref(false);
  const { showToast } = useToast();
  const initialFormValues = reactive({});
  const user = computed(() => store.getters["userProfile/getUser"]);
  const publicUser = computed(() => store.getters["userProfile/getPublicUser"]);

  const getUser = () => {
    return publicUser.value.id != null ? publicUser : user;
  };
  const isWhitelisted = computed(() =>
    store.getters["userProfile/getByKey"]("isWhitelisted")
  );

  const userToUpdate = reactive({
    email: user.value.email,
    websiteUrl: user.value.websiteUrl,
    displayName: user.value.displayName,
    description: user.value.description,
    profileImageId: user.value.profileImageId,
    twitterUsername: user.value.twitterUsername,
  });

  const walletAddress = computed(() => store.getters["contracts/getAddress"]);

  const walletBalance = computed(() =>
    store.getters["contracts/getBalanceByCurrency"]("THEOS")
  );

  const defaultAvatar = require("@/Common/Icons/Medusa_avatar.png");

  const data = computed(() => store.getters["userProfile/getByKey"]("data"));

  const userStats = computed(() =>
    store.getters["userProfile/getByKey"]("userStats")
  );

  const avatarImage = () => {
    const user = getUser();
    return user.value.profileImage
      ? imageTransformer.transform(
          user.value.profileImage.filename,
          "user.avatar"
        )
      : defaultAvatar;
  };

  const shortenedWalletAddress = (address = null) => {
    const fullAddress = address ? address : walletAddress.value;
    return `${fullAddress.slice(0, 6)}...${fullAddress.slice(-4)}`;
  };

  const shortenedUserAddress = () => {
    const user = getUser();
    return `${user.value.publicAddress.slice(
      0,
      6
    )}...${user.value.publicAddress.slice(-4)}`;
  };

  const getUserProperty = (key) =>
    computed(() => store.getters["userProfile/getByKey"](key));

  const handleSubmit = async () => {
    buttonFlag.value = true;
    const updatedUser = await store.dispatch(
      "registration/updateUser",
      userToUpdate
    );
    await store.dispatch("userProfile/commitByKey", {
      user: updatedUser.data.data,
    });

    buttonFlag.value = false;
    Object.assign(initialFormValues, userToUpdate);
    window.localStorage.removeItem("me");
    showToast("Changes saved!", "You can check them below", "Success");
    // window.location.reload();
  };

  const commitToStore = (object) =>
    store.dispatch("userProfile/commitByKey", object);

  const fetchUsersProfileStats = (id) =>
    store.dispatch("userProfile/fetchUsersProfileStats", id);

  const spinner = ref(false);
  const avatarInput = ref(null);
  const avatarImageSrc = ref(null);
  const uploadProfileAvatar = () => avatarInput.value.click();
  const avatarUploaded = async () => {
    spinner.value = true;
    const formData = new FormData();
    formData.append(
      "file",
      avatarInput.value.files[0],
      avatarInput.value.files[0].name
    );
    if (avatarInput.value.files[0].size > 41943040) {
      showToast(
        "The size is too big, we support files up to 40MB",
        "Try uploading a smaller file or compress it",
        "Error"
      );
      spinner.value = false;
      return;
    }
    userToUpdate.profileImageId = await store.dispatch(
      "registration/storeProfileImageFile",
      formData
    );
    spinner.value = false;
    const uploadedImageUrl = URL.createObjectURL(avatarInput.value.files[0]);
    avatarImageSrc.value.src = uploadedImageUrl;
  };

  const theosPrice = computed(() =>
    store.getters["contracts/getCoinPrice"]("theos", "usd")
  );

  const totalBalance = computed(
    () => store.getters["userProfile/getUserStatsTotalBalance"]
  );

  const maticPrice = computed(() =>
    store.getters["contracts/getCoinPrice"]("matic-network", "usd")
  );

  const balance = computed(() => store.getters["contracts/getBalance"]);

  const userHasPoolTokens = computed(
    () => userStats.value.indexPoolTokens.length > 0
  );

  const theosPriceChanged = computed(() => theosPrice?.value.change > 0);

  let copyAddressText = ref("Copy address");
  let copyWebsiteText = ref("Copy website");
  let copyTwitterText = ref("Copy twitter");

  const { copyToClipboard } = useUtilities();

  const copy = (data, type) => {
    copyToClipboard(data);
    switch (type) {
      case "address":
        copyAddressText.value = "Copied!";
        break;
      case "website":
        copyWebsiteText.value = "Copied!";
        break;
      case "twitter":
        copyTwitterText.value = "Copied!";
        break;
    }
  };

  const resetCopyText = (type) => {
    switch (type) {
      case "address":
        copyAddressText.value = "Copy address";
        break;
      case "website":
        copyWebsiteText.value = "Copy website";
        break;
      case "twitter":
        copyTwitterText.value = "Copy twitter";
        break;
    }
  };

  // const descriptionCopyLinks = reactive([
  //   {
  //     info: "address",
  //     imgSrc: "CopyAddress",
  //     data: getUser().value.publicAddress,
  //     copyText: copyAddressText.value,
  //   },
  //   {
  //     info: "website",
  //     imgSrc: "Website",
  //     data: getUser().value.websiteUrl,
  //     copyText: copyWebsiteText.value,
  //   },
  //   {
  //     info: "twitter",
  //     imgSrc: "TwitterAvatar",
  //     data: getUser().value.twitterUsername,
  //     copyText: copyTwitterText.value,
  //   },
  // ]);

  const descriptionCopyLinks = computed(() => {
    const user = getUser();
    return [
      {
        info: "address",
        imgSrc: "CopyAddress",
        data: user.value.publicAddress,
        copyText: copyAddressText.value,
      },
      {
        info: "website",
        imgSrc: "Website",
        data: user.value.websiteUrl,
        copyText: copyWebsiteText.value,
      },
      {
        info: "twitter",
        imgSrc: "TwitterAvatar",
        data: user.value.twitterUsername,
        copyText: copyTwitterText.value,
      },
    ];
  });

  return {
    user,
    data,
    copy,
    getUser,
    balance,
    spinner,
    userStats,
    publicUser,
    buttonFlag,
    maticPrice,
    theosPrice,
    avatarInput,
    avatarImage,
    handleSubmit,
    totalBalance,
    userToUpdate,
    walletAddress,
    walletBalance,
    resetCopyText,
    commitToStore,
    isWhitelisted,
    avatarImageSrc,
    avatarUploaded,
    copyAddressText,
    getUserProperty,
    theosPriceChanged,
    initialFormValues,
    userHasPoolTokens,
    uploadProfileAvatar,
    descriptionCopyLinks,
    shortenedUserAddress,
    shortenedWalletAddress,
    fetchUsersProfileStats,
  };
};

export default useUserProfile;

<template>
  <div class="footer-wrapper" style="padding: 0 3rem 0 3rem">
    <div class="grid-wrapper">
      <div style="grid-column: 1/4; display: flex">
        <div class="logo-wrapper">
          <img class="logo" src="@/Common/Icons/MenuBarButtonLogo.svg" />
        </div>
        <div class="moto">
          <p class="title">THEOS</p>
          <p class="text">Smart liquidity for your NFTs</p>
        </div>
      </div>
      <div style="">
        <div class="group">
          <a class="top" href="https://theos.fi/#Team" target="_blank"
            >About us</a
          >
          <a
            target="_blank"
            href="https://storage.googleapis.com/theoscms/papers/THEOS_Whitepaper_25082021.pdf"
            >Whitepaper</a
          >
        </div>
      </div>
      <div style="">
        <div class="group right-group">
          <a class="top" href="https://theos.fi/media.html" target="_blank"
            >Media</a
          >
          <a href="mailto:contact@theos.fi" target="_blank">Contact</a>
        </div>
      </div>
      <div style="">
        <div class="group right-group">
          <a
            target="_blank"
            class="nav-swap custom-padding"
            href="https://theos.fi/faq.html"
          >
            FAQs
          </a>
        </div>
      </div>
      <div style="grid-column: 8/13">
        <base-form
          method="post"
          class="form-grid-wrapper"
          :schema="footerEmailSchema"
          :handleSubmit="submitEmail"
        >
          <div style="grid-column: 1/4">
            <app-input
              name="email"
              v-model="email"
              placeholder="E-mail address"
              inputClass="app-modal-input email-input"
            />
          </div>
          <div style="grid-column: 4/6">
            <app-button
              type="submit"
              text="I'm in"
              v-if="!submitted"
              buttonClass="app-modal-button email-button"
            />
            <app-button
              v-else
              type="submit"
              text="You're in"
              style="pointer-events: none"
              buttonClass="app-modal-button email-button-in"
            />
          </div>
        </base-form>
      </div>
    </div>
  </div>

  <div class="footer-wrapper">
    <div class="social-wrapper">
      <p class="right-reserved">© THEOS.FI. ALL RIGHTS RESERVED</p>
      <div class="social-icon-wrapper">
        <a href="https://www.instagram.com/theos.fi/?hl=en" target="_blank">
          <img
            alt="Instagram"
            class="social-icon"
            src="@/Common/Icons/footer-instagram.png"
          />
        </a>
        <a href="https://medium.com/theos-fi" target="_blank">
          <img
            alt="Medium"
            class="social-icon"
            src="@/Common/Icons/footer-medium.png"
          />
        </a>
        <a href="https://twitter.com/theos_fi" target="_blank">
          <img
            alt="Twitter"
            class="social-icon"
            src="@/Common/Icons/footer-twitter.png"
          />
        </a>
        <a href="https://discord.com/invite/RR9UcCvjUH" target="_blank">
          <img
            alt="Discord"
            class="social-icon"
            src="@/Common/Icons/footer-discord.png"
          />
        </a>
        <a href="https://t.me/theos_fi" target="_blank">
          <img
            alt="Telegram"
            class="social-icon"
            src="@/Common/Icons/footer-telegram.png"
          />
        </a>
        <a href="https://www.reddit.com/r/TheosNFT/" target="_blank">
          <img
            alt="Reddit"
            class="social-icon"
            src="@/Common/Icons/footer-reddit.png"
          />
        </a>
        <a href="https://www.linkedin.com/company/theosfi/" target="_blank">
          <img
            alt="Linkedin"
            class="social-icon"
            src="@/Common/Icons/footer-linkedin.png"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import AppInput from "@/elements/AppInput";
import AppButton from "@/elements/AppButton";
import BaseForm from "@/components/BaseForm";
import useFooter from "@/composables/useFooter";
import { footerEmailSchema } from "../validationSchemas";
export default {
  components: { AppButton, AppInput, BaseForm },
  setup() {
    const { email, submitEmail, submitted } = useFooter();

    return {
      email,
      submitted,
      submitEmail,
      footerEmailSchema,
    };
  },
};
</script>

<style lang="scss" src="@/Common/Styles/AppFooter.scss" scoped />

import { eventBus } from "../main";
import ReconnectingWebSocket from "reconnecting-websocket";

export const getWebSocketServer = () => {
  return new ReconnectingWebSocket(process.env.VUE_APP_SOCKETS_URL);
};

export const startWebSocket = () => {
  const ws = getWebSocketServer();

  ws.onmessage = ({ data }) => {
    const { event, message } = JSON.parse(data);
    eventBus.emit(event, message);
  };
  ws.onerror = (error) => console.error(error);
  ws.onopen = () => ws.send(localStorage.getItem("socketsJwt"));
};

import api from "@/services/api";
const initialState = () => ({
  withdrawFlag: false,
  currentStep: 1,
  theosAllowed: false,
  currentStepsArray: [
    {
      component: "ConfirmWithdraw",
    },
    {
      component: "WithdrawingInProcess",
    },
  ],
  nft: {
    contractAddress: "",
    contractId: null,
  },
  pool: {
    name: "",
    description: "",
    tokenName: "",
    tokenSymbol: "",
    tokensPerNft: 0,
    ethAddress: "",
    maximumIPrice: 0,
    basePrice: 0,
    originator: {
      id: "",
    },
    royalties: "",
    cause: "",
    coverImage: {
      id: "",
      filename: "",
      path: "",
    },
  },
  userBalance: 0,
  errors: [],
});

const state = initialState();
const getters = {
  getByKey: (state) => (key) => state[key],
};
const mutations = {
  setByKey(state, obj) {
    state[obj.key] = obj.value;
  },
  toggleWithdrawFlag(state) {
    state.withdrawFlag = !state.withdrawFlag;
  },
  incrementStep(state) {
    state.currentStep++;
  },
  decrementStep(state) {
    state.currentStep--;
  },
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  commitUserBalance(state, userBalance) {
    state.userBalance = userBalance;
  },
};
const actions = {
  setByKey({ commit }, obj) {
    commit("setByKey", obj);
  },
  incrementStep({ commit }) {
    commit("incrementStep");
  },
  decrementStep({ commit }) {
    commit("decrementStep");
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  commitUserBalance({ commit }, userBalance) {
    commit("commitUserBalance", userBalance);
  },
  resetState({ commit }) {
    commit("resetState");
  },
  toggleWithdrawFlag({ commit }) {
    try {
      commit("toggleWithdrawFlag");
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchNft({ commit }, id) {
    try {
      const response = await api.fetchNft(id);
      commit("commitByKey", { nft: response.data });
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchPool({ commit }, id) {
    try {
      const response = await api.fetchPool(id);
      commit("commitByKey", { pool: response.data });
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async withdrawNft({ commit }, data) {
    try {
      const depositData = {
        poolId: data.pool.id,
        nftId: data.nft.id,
      };
      await api.withdrawNftFromPool(depositData);
    } catch (error) {
      commit("pushError", error);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

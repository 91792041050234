<template>
  <div class="pools-list-table">
    <v-server-table
      :url="url"
      :columns="columns"
      :options="options"
      ref="poolListTable"
      @row-click="handleRowClick"
    >
      <template v-slot:select="{ row }">
        <div class="checkbox-slot">
          <app-checkbox :value="row.id" :checked="isChecked(row.id)" />
        </div>
      </template>
      <template v-slot:name="{ row }">
        <div class="field-with-thumbnail">
          <div class="pool-thumbnail">
            <img
              alt=""
              :src="row.coverImage.path"
              @click="goTo({ name: 'ViewPool', params: { id: row.id } })"
            />
          </div>
          <div class="pool-panel-column-name">
            <div
              class="pool-panel-name"
              :class="[
                row.nftAlreadySubmitted.isSubmitted ? 'disabled-row' : '',
                isStringTooLong(row.name, 30) ? 'is-too-long' : '',
              ]"
              @click="goTo({ name: 'ViewPool', params: { id: row.id } })"
            >
              <div class="tooltip">{{ row.name }}</div>
              {{ row.name.substring(0, 30) }}
            </div>
            <div
              class="pool-panel-originator"
              :class="row.nftAlreadySubmitted.isSubmitted ? 'disabled-row' : ''"
            >
              By
              <a
                class="link"
                :href="`/profile/${row.originator.id}`"
                :class="
                  row.nftAlreadySubmitted.isSubmitted ? 'disabled-row' : ''
                "
                >{{ row.originator.displayName }}</a
              >
            </div>
          </div>
        </div>
      </template>
      <template v-slot:basePrice="{ row }">
        <div
          class="base-price"
          :class="row.nftAlreadySubmitted.isSubmitted ? 'disabled-row' : ''"
        >
          ${{
            toFixedIfNecessary(
              poolGalleryStats?.find((item) => item.id === row.id)
                ?.basePriceUSD,
              2
            )
          }}
        </div>
      </template>
      <template v-slot:maximumIPrice="{ row }">
        <div class="max-price" v-if="!row.nftAlreadySubmitted.isSubmitted">
          +{{ toFixedIfNecessary(row.maximumIPrice, 2) }}%
        </div>
      </template>
      <template v-slot:tvl="{ row }">
        <div class="tvl" v-if="!row.nftAlreadySubmitted.isSubmitted">
          ${{
            toFixedIfNecessary(
              poolGalleryStats?.find((item) => item.id === row.id)
                ?.totalValueLockedUSD,
              2
            )
          }}
        </div>
      </template>
      <template v-slot:volume24h="{ row }">
        <div class="volume24h" v-if="!row.nftAlreadySubmitted.isSubmitted">
          {{
            toFixedIfNecessary(
              poolGalleryStats?.find((item) => item.id === row.id)
                ?.volume24hUSD,
              2
            )
          }}
        </div>
      </template>
      <template v-slot:cause="{ row }">
        <div class="cause-column-wrapper">
          <div
            class="pools-panel-column-with-icon"
            v-if="!row.nftAlreadySubmitted.isSubmitted && row.cause?.name"
          >
            <div class="pools-panel-icon">
              <img alt="" :src="require(`@/Common/Icons/viralcure-logo.svg`)" />
            </div>
            <div class="pool-panel-text-small">{{ row.contribution }}%</div>
            <div class="pool-panel-cause-tooltip" v-if="row.cause?.name">
              <span class="cause-name">{{ row.cause.name }}</span>
            </div>
          </div>
          <div
            v-else-if="statusMessages[row.nftAlreadySubmitted.status]"
            class="already-submitted"
          >
            <img :src="require(`@/Common/Icons/Warning.svg`)" alt="" />
            <p class="already-submitted-text">
              {{ statusMessages[row.nftAlreadySubmitted.status].message }}
            </p>
            <p class="tooltip-message">
              {{ statusMessages[row.nftAlreadySubmitted.status].tooltip }}
            </p>
          </div>
        </div>
      </template>
    </v-server-table>
    <load-more v-if="showLoadMoreButton" :loadMore="loadMore" currentPanel="" />
  </div>
</template>

<script>
import { ref, h } from "vue";
import { useStore } from "vuex";
import useRouter from "@/composables/useRouter";
import AppCheckbox from "@/elements/AppCheckbox";
import useDeposit from "@/composables/useDeposit";
import useUtilities from "@/composables/useUtilities";
import usePoolsGallery from "@/composables/usePoolsGallery";
import LoadMore from "@/components/Profile/Panels/LoadMore";
import statusMessages from "@/json/submissionTableMessages.json";
import useMultiSelectCheckbox from "@/composables/useMultiSelectCheckbox";

export default {
  components: {
    LoadMore,
    AppCheckbox,
  },
  props: {
    isCheckbox: {
      type: Boolean,
      default: false,
    },
    singlePoolImage: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useStore();
    const { goTo } = useRouter();

    const { poolGalleryStats, fetchGalleryStats } = usePoolsGallery(store);

    const {
      isChecked,
      allSelected,
      selectAllAndStore,
      selectedSubmissions,
      toggleSelectionAndStore,
    } = useMultiSelectCheckbox(store);

    const { chosenPools, chosenNft } = useDeposit(store);

    if (chosenPools?.value) {
      selectedSubmissions.value = chosenPools.value.map((elem) => elem.id);
    }

    const { toFixedIfNecessary, isStringTooLong } = useUtilities();

    const columns = props.isCheckbox
      ? [
          "select",
          "name",
          "basePrice",
          "maximumIPrice",
          "tvl",
          "Sales",
          "cause",
        ]
      : ["name", "basePrice", "maximumIPrice", "tvl", "Sales", "cause"];
    const url = process.env.VUE_APP_API_URL + "/pools";
    const poolListTable = ref(null);
    const showLoadMoreButton = ref(false);
    const loadMore = () => {
      options.value.perPage += 5;
      poolListTable.value.getData();
    };

    const allValues = () => {
      return poolListTable.value.data.map((row) => {
        if (!row.nftAlreadySubmitted.isSubmitted) {
          return row.id;
        }
      });
    };

    const options = ref({
      perPage: 8,
      headings: {
        select: function () {
          return h(
            "div",
            {
              class: "vue-table-checkbox-slot",
            },
            [
              h(AppCheckbox, {
                onClick: () => {
                  selectAllAndStore(allValues(), poolListTable.value.data);
                },
                checked: allSelected.value,
              }),
            ]
          );
        },
        name: "Pool name",
        basePrice: "Base price",
        maximumIPrice: "Max iprice",
        tvl: "TVL",
        volume24h: "Sales",
        cause: "Social Cause",
      },
      perPageValues: [],
      sortable: ["name", "basePrice", "maximumIPrice"],
      texts: {
        loadingError: "Loading...",
      },
      requestAdapter(data) {
        const sortOrder =
          data.orderBy != null ? (data.ascending ? "asc" : "desc") : "desc";
        const params = {
          orderBy: data.orderBy,
          orderDirection: sortOrder,
          perPage: options.value.perPage,
          page: data.page,
          nftId: chosenNft.value.id,
          networks: chosenNft.value.network,
        };

        if (data.search) {
          params.search = data.search;
        }
        return params;
      },
      filterable: false,
      responseAdapter({ data }) {
        fetchGalleryStats(data.data);
        return {
          data: data.data,
          count: data.total,
        };
      },
      pagination: {
        virtual: true,
        chunk: 8,
      },
    });

    const handleRowClick = async (event) => {
      if (!event.row.nftAlreadySubmitted.isSubmitted) {
        toggleSelectionAndStore(event.row.id, poolListTable.value.data);
      }
    };

    return {
      url,
      goTo,
      columns,
      options,
      loadMore,
      isChecked,
      chosenNft,
      chosenPools,
      poolListTable,
      handleRowClick,
      statusMessages,
      isStringTooLong,
      poolGalleryStats,
      toFixedIfNecessary,
      showLoadMoreButton,
      selectedSubmissions,
      toggleSelectionAndStore,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/poolListTable.scss" />

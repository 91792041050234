import { computed } from "vue";

const useExchangeTokens = (store) => {
  const swapFlag = computed(() =>
    store.getters["exchangeTokens/getByKey"]("swapFlag")
  );

  const toggleSwapFlag = () => store.dispatch("exchangeTokens/toggleSwapFlag");

  const setDefaultInputToken = (address) =>
    store.dispatch("exchangeTokens/commitByKey", {
      defaultInputToken: address,
    });

  const setDefaultOutputToken = (address) =>
    store.dispatch("exchangeTokens/commitByKey", {
      defaultOutputToken: address,
    });

  const setCongratsModalText = (text) =>
    store.dispatch("exchangeTokens/commitByKey", {
      congratsModalText: text,
    });

  const setCongratsModalButtonText = (text) =>
    store.dispatch("exchangeTokens/commitByKey", {
      congratsModalButtonText: text,
    });

  const setOpenModalSrc = (text) =>
    store.dispatch("exchangeTokens/commitByKey", {
      openModalSrc: text,
    });

  const openSwapModal = (
    inputAddress = null,
    outputAddress = null,
    openModalSrc
  ) => {
    setDefaultInputToken(inputAddress);
    setDefaultOutputToken(outputAddress);
    setOpenModalSrc(openModalSrc);
    switch (openModalSrc) {
      case "create_pool":
        setCongratsModalText("and now is time to create your pool!");
        setCongratsModalButtonText("Create pool");
        break;
      case "pool_details":
        setCongratsModalText(null);
        setCongratsModalButtonText("Submit NFT in a pool");
        break;
      default:
        setCongratsModalText("and now is time to buy the NFT!");
        setCongratsModalButtonText("Buy NFT");
    }
    toggleSwapFlag();
  };

  const defaultInputToken = computed(() =>
    store.getters["exchangeTokens/getByKey"]("defaultInputToken")
  );

  const defaultOutputToken = computed(() =>
    store.getters["exchangeTokens/getByKey"]("defaultOutputToken")
  );

  const congratsModalText = computed(() =>
    store.getters["exchangeTokens/getByKey"]("congratsModalText")
  );

  const congratsModalButtonText = computed(() =>
    store.getters["exchangeTokens/getByKey"]("congratsModalButtonText")
  );

  const openModalSrc = computed(() =>
    store.getters["exchangeTokens/getByKey"]("openModalSrc")
  );

  const redirectToSushiSwap = (
    poolTokenAddress = null,
    theosTokenAddress = null
  ) => {
    const url = `https://app.sushi.com/swap?
    inputCurrency=${theosTokenAddress | "ETH"}
    &outputCurrency=${poolTokenAddress}`;
    return window.open(url, "_blank");
  };

  return {
    swapFlag,
    openSwapModal,
    toggleSwapFlag,
    defaultInputToken,
    defaultOutputToken,
    setDefaultInputToken,
    setDefaultOutputToken,
    congratsModalText,
    congratsModalButtonText,
    openModalSrc,
    redirectToSushiSwap,
  };
};

export default useExchangeTokens;

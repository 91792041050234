<template>
  <base-form
    style="width: 100%; height: 100%"
    id="list-nft"
    method="post"
    :handleSubmit="checkForExistingSubmissions"
    :schema="nftListSchema"
  >
    <div class="fixed-panel-wrapper">
      <div class="body">
        <div class="panel-title">
          <span class="panel-title-number">2</span>
          <span class="panel-title-text">SET FIXED PRICE</span>
        </div>
        <app-input-with-select
          style="width: 100%"
          type="number"
          name="MaticPrice"
          labelClass="label"
          labelText="Price *"
          :inputValue="listingPrice"
          :selectedToken="parsedSelectedToken"
          inputClass="form-box-input-with-select"
          placeholder="000.000"
          :floatingText="`≈ $${calculatedNftPriceUsd} USD`"
          bottomLabel="Details min/max"
          infoText="This will be the price shown <br>
                    on Theos Marketplace"
          :selectOptions="tokenOptions"
          :updateValue="setListingPrice"
          :updateSelect="setSelectedToken"
          :enableSelect="false"
          @keydown="filterKey"
        />
        <div class="details" v-if="showDetails">
          <div class="column dark">
            <div class="item">1% THEOS fee to sellers</div>
            <div class="item">
              {{ nft.royaltyPercentage }}% Creator Royalties
            </div>
          </div>
          <div class="column">
            <div class="item">- {{ feeMatic }} {{ selectedToken.token }}</div>
            <div class="item">- {{ royalties }} {{ selectedToken.token }}</div>
          </div>
          <div class="column right dark">
            <div class="item">- {{ feeUsd }} USD</div>
            <div class="item">- {{ royaltiesUsd }} USD</div>
          </div>
        </div>
        <div class="show-price-wrapper">
          <div class="line" />
          <div @click="toggleDetails" class="text">
            {{ showDetails ? "Hide" : "Show" }} price details
          </div>
          <div class="line" />
        </div>

        <app-input-with-select
          style="width: 100%"
          type="number"
          name="Price"
          labelClass="label"
          labelText="When you sell, you will get"
          v-model:selectedToken="parsedSelectedToken"
          :inputValue="
            toFixedIfNecessary(listingPrice - feeMatic - royalties, 2)
          "
          inputClass="form-box-input-with-select"
          placeholder="000.000"
          :floatingText="`≈ $${sellPriceUsd} USD`"
          :disabledFlag="true"
          disabledClass="form-box-input-with-select-wrapper-disabled"
        />
      </div>
      <div class="footer footer-buttons">
        <app-button
          :style="'margin-right: 1rem'"
          text="Cancel"
          buttonClass="app-modal-button"
          @click="closeModal"
          @keypress.enter.prevent
          @submit.prevent
        />
        <app-button
          type="submit"
          text="List fixed price"
          :disabled="listNftErrors.length"
          buttonClass="app-modal-button-inverse"
        />
      </div>
    </div>
  </base-form>
</template>

<script>
import { useStore } from "vuex";
import AppButton from "@/elements/AppButton";
import { onMounted, reactive, computed, ref } from "vue";
import BaseForm from "@/components/BaseForm.vue";
import useListNft from "@/composables/useListNft";
import { nftListSchema } from "@/validationSchemas";
import useUtilities from "@/composables/useUtilities";
import AppInputWithSelect from "@/elements/AppInputWithSelect";

export default {
  name: "FixedPricePanel",
  components: {
    BaseForm,
    AppButton,
    AppInputWithSelect,
  },
  setup() {
    const inputValue = ref(null);
    const showDetails = ref(false);
    const tokenOptions = reactive({});
    const { filterKey, toFixedIfNecessary, parseTokenList } = useUtilities();

    const {
      nft,
      feeUsd,
      feeMatic,
      royalties,
      closeModal,
      sellPriceUsd,
      royaltiesUsd,
      listingPrice,
      listNftErrors,
      selectedToken,
      maticPriceUsd,
      setListingPrice,
      setSelectedToken,
      confirmNftListing,
      calculatedNftPriceUsd,
      checkForExistingSubmissions,
    } = useListNft(useStore());

    const toggleDetails = () => {
      showDetails.value = !showDetails.value;
    };
    const parseTokenSymbol = (selectedToken) => {
      if (selectedToken) {
        let tokenSymbol = "MATIC";
        if (selectedToken.token === "wETH") tokenSymbol = "ETH";
        selectedToken.token = tokenSymbol;
      }
      return selectedToken;
    };

    const parsedSelectedToken = computed(() =>
      parseTokenSymbol(JSON.parse(JSON.stringify(selectedToken.value)))
    );

    onMounted(async () => {
      tokenOptions.value = await parseTokenList();
      setSelectedToken(tokenOptions.value[0]);
    });

    return {
      nft,
      feeUsd,
      feeMatic,
      filterKey,
      royalties,
      closeModal,
      inputValue,
      showDetails,
      sellPriceUsd,
      royaltiesUsd,
      listingPrice,
      tokenOptions,
      listNftErrors,
      nftListSchema,
      maticPriceUsd,
      toggleDetails,
      selectedToken,
      setListingPrice,
      setSelectedToken,
      confirmNftListing,
      toFixedIfNecessary,
      parsedSelectedToken,
      calculatedNftPriceUsd,
      checkForExistingSubmissions,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/auctionModal.scss"></style>

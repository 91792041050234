<template>
  <div class="chyron-wrapper">
    <chyron />
  </div>
  <div class="wrapper">
    <img
      alt="M"
      class="lightning-image"
      :src="require(`@/Common/Icons/ray_of_light.svg`)"
    />
    <slider-background :showSlide="offsetToShowCurrentSlide()" />
    <div class="controls">
      <slider-buttons
        :currentSlide="currentSlide"
        :setCurrentSlide="setCurrentSlideAndResetSlideInterval"
      />
      <slider-tabs
        :totalSlides="TOTAL_SLIDES"
        :currentSlide="currentSlide"
        :setCurrentSlide="setCurrentSlideAndResetSlideInterval"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import Chyron from "@/components/Chyron";
import useChyronData from "@/composables/useChyronData";
import SliderTabs from "@/components/HomePage/SliderTabs";
import SliderButtons from "@/components/HomePage/SliderButtons";
import SliderBackground from "@/components/HomePage/SliderBackground";
export default {
  name: "Home",
  components: {
    Chyron,
    SliderTabs,
    SliderButtons,
    SliderBackground,
  },
  setup() {
    const store = useStore();
    const { fetchMonthlyMintedNfts } = useChyronData(store);

    const TOTAL_SLIDES = 3;
    const currentSlide = ref(1);
    const PADDING_BETWEEN_SLIDES_IN_PIXELS = 320;

    const setCurrentSlideAndResetSlideInterval = (value) => {
      currentSlide.value = value;
    };

    const offsetToShowCurrentSlide = () =>
      `calc(${currentSlide.value * 100}vw + ${
        currentSlide.value * PADDING_BETWEEN_SLIDES_IN_PIXELS
      }px)`;

    onMounted(() => fetchMonthlyMintedNfts());

    return {
      currentSlide,
      TOTAL_SLIDES,
      offsetToShowCurrentSlide,
      setCurrentSlideAndResetSlideInterval,
    };
  },
};
</script>

<style lang="scss" src="@/Common/Styles/homePage.scss" scoped />
<style>
@media screen and (max-width: 1440px), screen and (max-height: 785px) {
  .app-wrapper.Home {
    height: calc(100vh + 250px) !important;
  }
}
</style>

import { computed, reactive } from "vue";
import { useRouter } from "vue-router";
import useToast from "@/composables/useToast";
import useGoogleTag from "@/composables/useGoogleTag";
import { eventBus } from "@/main";

const depositData = reactive({
  price: 0,
  nftId: "",
  poolId: "",
});

const useDepositReviewModal = (store) => {
  const { showToast } = useToast();
  const { registerException } = useGoogleTag();
  const router = useRouter();
  const depositFlag = computed(() =>
    store.getters["depositReview/getByKey"]("depositFlag")
  );
  const toggleDepositFlag = () =>
    store.dispatch("depositReview/toggleDepositFlag");
  const currentStep = computed(() =>
    store.getters["depositReview/getByKey"]("currentStep")
  );
  const currentStepsArray = computed(() =>
    store.getters["depositReview/getByKey"]("currentStepsArray")
  );
  const commitToStore = (data) => {
    store.dispatch("depositReview/commitByKey", data);
  };
  const resetState = () => store.dispatch("depositReview/resetState");
  const incrementStep = () => {
    store.dispatch("depositReview/incrementStep");
  };
  const decrementStep = () => store.dispatch("depositReview/decrementStep");

  const confirmChosenDeposit = async (price, nft, iValuePercentage, pool) => {
    incrementStep();
    const allowed = await store.dispatch("contracts/isAllowedTheos");
    await store.dispatch("depositReview/commitByKey", {
      theosAllowed: allowed,
    });

    if (!allowed) {
      const result = await store.dispatch("contracts/allowTheos");
      if (result === "rejected") {
        registerException("Metamask signature rejected", false);
        await showToast(
          "MetaMask error",
          "Please, allow Theos Protocol to use your THEOS in order to proceed.",
          "Error"
        );
        decrementStep();
        return;
      }
    }
    await store.dispatch("depositReview/commitByKey", {
      theosAllowed: true,
    });
    try {
      const success = await depositNft(pool, nft, iValuePercentage);
      return success ? incrementStep() : decrementStep();
    } catch (error) {
      console.log("Deposit Review Modal Error ", error);
      decrementStep();
    }
  };
  const isAllowedTheos = computed(() =>
    store.getters["depositReview/getByKey"]("theosAllowed")
  );
  const goToPool = async (id) => {
    await router.push({ name: "ViewPool", params: { id } });
    resetState();
  };

  const depositNft = async (pool, nft, iValuePercentage) => {
    iValuePercentage = parseFloat(iValuePercentage);
    console.log("DepositReviewModal I value percentage ", iValuePercentage);
    const submission = computed(() =>
      store.getters["depositReview/getByKey"]("selectedSubmission")
    );
    const result = await store.dispatch("contracts/depositNft", {
      contractAddress: nft.contractAddress,
      contractId: nft.contractId,
      iPrice: iValuePercentage,
      poolAddress: pool.ethAddress,
    });
    eventBus.emit("depositedNft");
    if (result === "rejected") {
      await store.dispatch("pools/withdrawNft", {
        poolId: pool.id,
        nftId: nft.id,
      });
      registerException("Metamask signature rejected", false);
      await showToast(
        "Transaction rejected",
        "Submitting not completed.",
        "Warning"
      );
      return false;
    }
    if (result) {
      await store.dispatch("pools/handleBulkSubmission", {
        status: "deposit",
        submissionIds: [submission.value.id],
      });
      return true;
    }
    return false;
  };
  const nft = computed(() => store.getters["depositReview/getByKey"]("nft"));
  const pool = computed(() => store.getters["depositReview/getByKey"]("pool"));
  return {
    depositFlag,
    toggleDepositFlag,
    currentStep,
    currentStepsArray,
    commitToStore,
    resetState,
    incrementStep,
    decrementStep,
    confirmChosenDeposit,
    nft,
    pool,
    depositData,
    depositNft,
    isAllowedTheos,
    goToPool,
  };
};

export default useDepositReviewModal;

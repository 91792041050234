export const ADDRESSES = {
  137: {
    THEOS: process.env.VUE_APP_THEOS_TOKEN_ADDRESS_POLYGON,
    WMATIC: process.env.VUE_APP_WMATIC_TOKEN_CONTRACT_ADDRESS,
    NftMintContract: process.env.VUE_APP_NFT_MINT_ADDRESS_POLYGON,
    PoolFactory: process.env.VUE_APP_POOL_FACTORY_ADDRESS_POLYGON,
    NftMarketplace: process.env.VUE_APP_LISTING_ADDRESS_POLYGON,
  },
  80001: {
    THEOS: process.env.VUE_APP_THEOS_TOKEN_ADDRESS_MUMBAI,
    WMATIC: process.env.VUE_APP_WMATIC_TOKEN_CONTRACT_ADDRESS_MUMBAI,
    NftMintContract: process.env.VUE_APP_NFT_MINT_ADDRESS_MUMBAI,
    PoolFactory: process.env.VUE_APP_POOL_FACTORY_ADDRESS_MUMBAI,
    NftMarketplace: process.env.VUE_APP_LISTING_ADDRESS_MUMBAI,
  },
  1: {
    THEOS: process.env.VUE_APP_THEOS_TOKEN_ADDRESS_ETH,
    WMATIC: process.env.VUE_APP_WMATIC_TOKEN_CONTRACT_ADDRESS_ETH,
    NftMintContract: process.env.VUE_APP_NFT_MINT_ADDRESS_ETH,
    PoolFactory: process.env.VUE_APP_POOL_FACTORY_ADDRESS_ETH,
    NftMarketplace: process.env.VUE_APP_LISTING_ADDRESS_ETH,
  },
  5: {
    THEOS: process.env.VUE_APP_THEOS_TOKEN_ADDRESS_ETH_TESTNET,
    WMATIC: process.env.VUE_APP_WMATIC_TOKEN_CONTRACT_ADDRESS_ETH_TESTNET,
    NftMintContract: process.env.VUE_APP_NFT_MINT_ADDRESS_ETH_TESTNET,
    PoolFactory: process.env.VUE_APP_POOL_FACTORY_ADDRESS_ETH_TESTNET,
    NftMarketplace: process.env.VUE_APP_LISTING_ADDRESS_ETH_TESTNET,
  },
};

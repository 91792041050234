<template>
  <app-modal
    :closeButton="true"
    :returnToPreviousModal="closeSuccessfulGiftingModal"
  >
    <template v-slot:app-modal-header>
      <img
        alt="TheosLogo"
        class="app-modal-list-logo"
        src="@/Common/Icons/TheosLogo.svg"
      />
      <h3 class="app-modal-listing-title">CONGRATS!</h3>
    </template>
    <template v-slot:app-modal-body>
      <p class="app-modal-body-text">{{ nft.name }} was successfully sent.</p>
    </template>
  </app-modal>
</template>

<script>
import AppModal from "@/elements/AppModal";
import { onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import useGiftNft from "@/composables/useGiftNft";
import useRouter from "@/composables/useRouter";
export default {
  name: "SuccessfulGiftingModal",
  components: {
    AppModal,
  },
  setup() {
    const store = useStore();
    const { goTo } = useRouter();
    const { closeModal, nft } = useGiftNft(store);
    onMounted(() => document.body.classList.add("stop-scroll"));
    onUnmounted(() => document.body.classList.remove("stop-scroll"));
    const closeSuccessfulGiftingModal = () => {
      closeModal();
      goTo({ name: "ViewProfilePrivate" });
    };
    return {
      nft,
      closeSuccessfulGiftingModal,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/giftNftModal.scss"></style>

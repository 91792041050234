<template>
  <div class="profile-description-wrapper pool-wrapper">
    <div class="profile-description-avatar pool-image">
      <img
        alt="Avatar"
        :src="
          pool.coverImage?.thumbnail || require('@/Common/Icons/Avatar.png')
        "
      />
    </div>
    <p class="profile-description-name">
      {{ pool.name }}
    </p>
    <p class="profile-description-description pool-desctiption">
      {{ pool.description }}
    </p>
    <div class="pool-tags">
      <ul>
        <li
          :key="index"
          class="pool-tags-tag"
          v-for="(tag, index) in pool.tags"
        >
          {{ tag }}
        </li>
      </ul>
    </div>
    <div class="originator">
      <p class="title-description">Originator:</p>
      <div
        class="originator-image-wrap"
        :class="pool.originatorId === user.id ? 'avatar-img-border-holo' : ''"
      >
        <img
          @click="goToPublicProfile(pool.originatorId)"
          class="pointer"
          :class="
            pool.originatorId === user.id
              ? 'originator-image'
              : 'originator-image-border'
          "
          :src="
            pool.originator.profileImage?.thumbnail ||
            require('@/Common/Icons/Medusa_avatar.png')
          "
        />
        <p class="originator-name">{{ pool.originator?.displayName }}</p>
      </div>
    </div>
    <div class="contributing-to" v-if="pool.cause?.name">
      <p class="title-description">Contributing to:</p>
      <div>
        <span class="pandemic-shield">
          {{ pool.cause?.name }}
          <span class="cause">{{ pool.contribution }}%</span></span
        >
      </div>
    </div>
    <share-to-social-media
      :shareIcon="true"
      text="Share Pool"
      popupText="Share Pool"
      v-if="user.id && pool.originatorId"
      style="margin-left: -8px; width: 100%"
      :type="user.id === pool.originatorId ? 'personal_pool' : 'public_pool'"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useMeta } from "vue-meta";
import { useRoute } from "vue-router";
import queryString from "query-string";
import { onMounted, computed } from "vue";
import usePools from "@/composables/usePools";
import useRouter from "@/composables/useRouter";
import ShareToSocialMedia from "@/components/Share/ShareToSocialMedia";
export default {
  components: {
    ShareToSocialMedia,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { fetchPool, pool, fetchSubmissions, fetchPoolStatistic, user } =
      usePools(store);

    useMeta(
      computed(() => ({
        title: `${pool.value.name} | THEOS`,
        description: pool.value.description,
        og: {
          title: `${pool.value.name}`,
          description: `${pool.value.description}`,
          image: [`${pool.value.coverImage.path}`],
          url: `${process.env.VUE_APP_BASE_PATH}/pools/${pool.value.id}`,
        },
      }))
    );

    const submissionParams = ref({
      page: 1,
      perPage: 8,
      status: "pending",
      orderBy: "createdAt",
      orderDirection: "DESC",
    });

    const { goTo } = useRouter();

    const goToPublicProfile = (id) =>
      goTo({
        params: { id },
        name: "ViewProfilePublic",
      });

    let poolId = route.params.id;
    store.dispatch("pools/setPoolId", poolId);

    onMounted(async () => {
      await store.dispatch("pools/fetchMe");
      await fetchPool(poolId);
      await fetchPoolStatistic(poolId);
      submissionParams.value.recipientId = user.value.id;
      await fetchSubmissions(
        poolId,
        queryString.stringify(submissionParams.value)
      );
    });

    return {
      user,
      pool,
      fetchPool,
      goToPublicProfile,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/poolDetails.scss" />

<template>
  <div class="app-modal-overlay">
    <div class="app-modal-wrapper">
      <div
        class="app-modal-leave-modal"
        v-if="closeButton"
        @click="returnToPreviousModal()"
      >
        <img src="@/Common/Icons/XmarkModal.png" alt="Logo" />
      </div>
      <div class="app-modal-header">
        <slot name="app-modal-header"></slot>
      </div>
      <div class="app-modal-body">
        <slot name="app-modal-body"></slot>
      </div>
      <div class="app-modal-footer">
        <slot name="app-modal-footer"></slot>
        <span
          class="app-modal-button-transparent"
          v-if="showCancel"
          @click="closeModal"
          style="text-align: center; margin-top: 10px"
          >Cancel</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppDepositNftModal",
  props: {
    closeButton: {
      type: Boolean,
      default: false,
    },
    returnToPreviousModal: {
      type: Function,
      default: () => {},
    },
    closeModal: {
      type: Function,
      default: () => {},
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/appListNft.scss"></style>

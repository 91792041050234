<template>
  <div class="card-icon-wrapper">
    <div class="three-dots-icon">
      <img
        class=""
        ref="iconSize"
        @click.stop="toggle(token.id)"
        src="@/Common/Icons/three_dots_icon.svg"
      />
      <div
        class="popup by-back"
        v-click-outside="hidePopup"
        v-if="showPopup && isPooled(token)"
      >
        Buy Back
      </div>
      <div
        class="popup disabled"
        v-click-outside="hidePopup"
        v-if="showPopup && !isPooled(token)"
      >
        Submit in a pool
      </div>
    </div>
    <div class="card-wrapper" :class="{ pointer: !external }">
      <div class="avatar-owner-img avatar-img-border-holo" ref="ownerAvatar">
        <img :src="externalAvatarImage()" alt="Avatar" />
      </div>
      <div class="card">
        <div class="avatar-img-wrapper" v-if="!external">
          <img
            alt="Thumbnail"
            class="app-card-image"
            :src="coverImage(token)"
          />
        </div>
        <div class="avatar-img-wrapper-external" v-else>
          <img
            alt="Thumbnail"
            class="app-card-image-external"
            :src="require('@/Common/Icons/cloud.png')"
          />
          <div class="external-data-wrapper">
            <app-button
              text="Load NFT"
              v-if="!isLoading"
              @click="loadNFT()"
              class="load-more-button pointer"
            />
            <div v-else class="load-more-button">
              <app-loader :show="true" :isGreen="true" is-small="true" />
              Loading...
            </div>
            <div class="load-nft-text" v-show="!isLoading">
              Load NFT before its usage on THEOS
            </div>
          </div>
        </div>
        <div class="app-card-content" ref="cardContentBox">
          <div class="app-card-content-description">
            <div
              v-if="!external"
              :class="[isStringTooLong(token.name, 20) ? 'is-too-long' : '']"
            >
              <div class="nft-name-tooltip">{{ token.name }}</div>
              <p
                class="app-card-title"
                :class="[isStringTooLong(token.name, 20) ? 'is-too-long' : '']"
              >
                {{ token.name }}
              </p>
            </div>
            <div
              v-else
              :class="[
                isStringTooLong(
                  JSON.parse(token.metadata)?.name || token.name,
                  20
                )
                  ? 'is-too-long'
                  : '',
              ]"
            >
              <div class="nft-name-tooltip">
                {{ JSON.parse(token.metadata)?.name || token.name }}
              </div>
              <p
                ref="cardTitle"
                class="app-card-title"
                :class="[
                  isStringTooLong(
                    JSON.parse(token.metadata)?.name || token.name,
                    20
                  )
                    ? 'is-too-long'
                    : '',
                ]"
              >
                {{ JSON.parse(token.metadata)?.name || token.name }}
              </p>
            </div>
            <div class="app-card-pool" v-if="!external">
              <p class="app-card-pool-name" v-if="token.pool_price">
                {{ token.pool }}
              </p>
              <p class="app-card-pool-price" v-if="isPooled(token)">
                {{
                  toFixedIfNecessary(
                    token.pools[token.pools.length - 1].basePrice,
                    4
                  )
                }}
              </p>
              <div v-else>
                <p class="app-card-pool-price" v-if="!token.contractId">
                  Not available for depositing
                </p>
              </div>
            </div>
          </div>
          <div class="app-card-price" v-if="isPooled(token) && !external">
            <span class="app-card-token-price"
              >{{
                toFixedIfNecessary(
                  token.pools[token.pools.length - 1].basePrice,
                  4
                )
              }}
              {{ token.pools[token.pools.length - 1].tokenSymbol }}</span
            >
          </div>
          <div class="app-card-price" v-else />
        </div>
        <div class="creator-img" v-if="external">
          <div class="creator-tooltip">
            Creator: {{ registeredUser.displayName }}
          </div>
          <img :src="externalAvatarImage()" alt="Creator" />
        </div>
        <div class="creator-img" v-else-if="token.creator">
          <div class="creator-tooltip">
            Creator: {{ token.creator.displayName }}
          </div>
          <img :src="creatorAvatarImage(token.creator)" alt="Creator" />
        </div>
        <div
          class="owner-img"
          v-else-if="!!token.owners && token.owners.length > 0"
        >
          <div class="owner-tooltip">Owner: {{ hasOwners(token) }}</div>
          <img :src="ownerAvatarImage(token)" alt="Owner" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { eventBus } from "@/main";
import AppLoader from "@/elements/AppLoader";
import AppButton from "@/elements/AppButton";
import useToast from "@/composables/useToast";
import vClickOutside from "click-outside-vue3";
import useRouter from "@/composables/useRouter";
import useDeposit from "@/composables/useDeposit";
import useUtilities from "@/composables/useUtilities";
import imageTransformer from "../plugins/imageTransformer";
import useProfilePanels from "@/composables/useProfilePanels";
import { ref, onMounted, computed, onBeforeUnmount } from "vue";

export default {
  components: { AppButton, AppLoader },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    token: {
      type: Object,
      default: () => {},
    },
    external: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const { showToast } = useToast();
    const { toFixedIfNecessary, isStringTooLong } = useUtilities();
    const { theosMinted, storeExternalNft } = useProfilePanels(store);
    const { updateChosenNft } = useDeposit(store);
    const { goTo } = useRouter();
    const showPopup = ref(false);
    const registeredUser = computed(
      () => store.getters["auth/getRegisteredUser"]
    );

    onMounted(async () => {
      await store.dispatch("auth/fetchMe");
    });

    const externalAvatarImage = () => {
      if (!registeredUser.value.profileImage)
        return require("@/Common/Icons/Medusa_avatar_bg.png");
      return imageTransformer.transform(
        registeredUser.value.profileImage?.filename,
        "user.avatar"
      );
    };

    const creatorAvatarImage = (creator) => {
      if (creator.avatarFileName)
        return imageTransformer.transform(
          creator.avatarFileName.filename,
          "user.avatar"
        );
      else if (creator.avatarImageUrl) return creator.avatarImageUrl;
      else return require("@/Common/Icons/Medusa_avatar_bg.png");
    };

    const ownerAvatarImage = (owner) => {
      if (owner.owners)
        return imageTransformer.transform(
          owner.owners[owner.owners.length - 1]?.avatarFileName?.filename,
          "user.avatar"
        );
      else if (owner.owner) return owner.owner.avatarImageUrl;
      else return require("@/Common/Icons/Medusa_avatar_bg.png");
    };

    const coverImage = (token) => {
      if (theosMinted.value)
        return token?.coverImage && token?.coverImage?.filename
          ? imageTransformer.transform(token.coverImage.filename, "nft.list")
          : null;
      return token.coverImageURL;
    };
    const hasOwners = (token) => {
      return token.owners[token.owners.length - 1].displayName;
    };

    const toggle = (value) => {
      showPopup.value = !showPopup.value;
      eventBus.emit("isIdEqual", value);
    };
    const hidePopup = () => {
      showPopup.value = false;
    };
    const isLoading = ref(false);

    const loadNFT = async () => {
      isLoading.value = true;
      try {
        await storeExternalNft(props.token);
        await showToast(
          props.token.name + " is correctly loaded",
          "Now is available to use it on THEOS",
          "Success"
        );
        location.reload();
      } catch (error) {
        await showToast(
          "NFT not loaded",
          "We could not load your NFT",
          "Error"
        );
      }
      isLoading.value = false;
      await store.dispatch("nfts/resetState");
      theosMinted.value = true;
    };
    const depositToPool = () => {
      goTo({
        name: "DepositNft",
        query: { profileNftId: props.token.id },
      });
      updateChosenNft(props.token);
    };

    const cardContentBox = ref(null);
    const cardTitle = ref(null);
    const iconSize = ref(null);
    const ownerAvatar = ref(null);

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const contentBoxSize = Array.isArray(entry.contentBoxSize)
          ? entry.contentBoxSize[0].inlineSize
          : entry.contentBoxSize.inlineSize;

        if (cardTitle.value) {
          cardTitle.value.style.fontSize = `${contentBoxSize * 0.0758}px`;
          cardTitle.value.style.marginBottom = `${contentBoxSize * 0.019}px`;
          cardTitle.value.style.marginTop = `${contentBoxSize * 0.0948}px`;
          cardTitle.value.style.marginLeft = `${contentBoxSize * 0.057}px`;
        }
        iconSize.value.style.width = `${contentBoxSize * 0.157}px`;
        iconSize.value.style.height = `${contentBoxSize * 0.157}px`;
        iconSize.value.style.top = `calc(67.412% + ${
          contentBoxSize * 0.056872
        }px)`;
        iconSize.value.style.right = `${contentBoxSize * 0.056872}px`;
        ownerAvatar.value.style.width = `${contentBoxSize * 0.151658}px`;
        ownerAvatar.value.style.height = `${contentBoxSize * 0.151658}px`;
        ownerAvatar.value.style.left = `${contentBoxSize * 0.056872}px`;
      }
    });

    onMounted(() => {
      resizeObserver.observe(cardContentBox.value);
      eventBus.on("isIdEqual", (val) => {
        if (val !== props.token.id) {
          showPopup.value = false;
        }
      });
    });

    onBeforeUnmount(() => resizeObserver.unobserve(cardContentBox.value));

    const isPooled = (token) =>
      token.status === "deposited" || token.status.includes("deposited");

    return {
      toggle,
      loadNFT,
      iconSize,
      isPooled,
      cardTitle,
      showPopup,
      hidePopup,
      hasOwners,
      isLoading,
      coverImage,
      ownerAvatar,
      depositToPool,
      cardContentBox,
      registeredUser,
      isStringTooLong,
      ownerAvatarImage,
      creatorAvatarImage,
      toFixedIfNecessary,
      externalAvatarImage,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/nftCard.scss" />

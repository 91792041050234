<template>
  <div class="tooltip-info">
    <img
      alt="info"
      class="tooltip-icon tooltip-img"
      src="@/Common/Icons/InfoRoyaltiesIcon.png"
    />
    <div class="tooltip-hover">
      <div v-html="text" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppTooltip",
  props: {
    text: String,
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/appTooltip.scss" />

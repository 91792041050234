<template>
  <div class="profile-description-wrapper">
    <div class="profile-description-avatar">
      <img :src="avatarImage()" alt="Avatar" />
    </div>
    <p class="profile-description-name">
      {{ user.displayName ? user.displayName : shortenedWalletAddress() }}
    </p>
    <p class="profile-description-description">
      {{ user.description }}
    </p>
    <description-copy-link
      :key="desc.info"
      :data="desc.data"
      :info="desc.info"
      :imgSrc="desc.imgSrc"
      :copyText="desc.copyText"
      v-for="desc in descriptionCopyLinks"
    />
    <div class="profile-description-button-controls">
      <edit-button
        v-if="!isPublic"
        text="Edit profile"
        :buttonClass="'edit-profile-button'"
        @click.prevent="goTo({ name: 'EditProfile' })"
      />
      <share-to-social-media
        :text="'Share profile'"
        :shareIcon="true"
        collectAnalytics="true"
        class="share-profile-button"
        popupText="Share this profile"
        :type="isPublic ? 'public_profile' : 'private_profile'"
        :url="url"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted } from "vue";
import useRouter from "@/composables/useRouter";
import EditButton from "@/components/Profile/EditButton";
import useUserProfile from "@/composables/useUserProfile";
import ShareToSocialMedia from "@/components/Share/ShareToSocialMedia";
import DescriptionCopyLink from "@/components/Profile/DescriptionCopyLink";
export default {
  components: {
    EditButton,
    ShareToSocialMedia,
    DescriptionCopyLink,
  },

  props: {
    user: {
      type: Object,
      default: () => {},
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const store = useStore();
    const { goTo } = useRouter();

    const { descriptionCopyLinks, shortenedWalletAddress, avatarImage } =
      useUserProfile(store);

    onMounted(() => {
      window.localStorage.removeItem("me");
    });

    const url = computed(
      () =>
        location.href.substring(0, location.href.lastIndexOf("/") + 1) +
        props.user.id
    );

    return {
      url,
      goTo,
      avatarImage,
      descriptionCopyLinks,
      shortenedWalletAddress,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/profileDescription.scss" />

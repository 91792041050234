<template>
  <div v-if="insufficientFundsModalFlag">
    <teleport to="body">
      <insufficient-funds-modal />
    </teleport>
  </div>
</template>

<script>
import { ethereumService } from "@/main";
import InsufficientFundsModal from "./InsufficientFundsModal";
import useInsufficientFunds from "@/composables/useInsufficientFunds";
export default {
  components: {
    InsufficientFundsModal,
  },
  setup() {
    const { insufficientFundsModalFlag } =
      useInsufficientFunds(ethereumService);
    return {
      insufficientFundsModalFlag,
    };
  },
};
</script>

<template>
  <div class="profile-description-address profile-info" v-if="data">
    <img
      :alt="info"
      @click="copy(data, info)"
      @mouseleave="resetCopyText(info)"
      :src="require(`@/Common/Icons/${imgSrc}.png`)"
      class="profile-description-address-icon address-img"
    />
    <div class="info-hover address-hover">{{ copyText }}</div>
    <p class="profile-description-address-text">
      {{ displayData(data) }}
    </p>
  </div>
</template>

<script>
import { useStore } from "vuex";
import useUserProfile from "@/composables/useUserProfile";
export default {
  props: {
    info: {
      type: String,
      default: "",
    },
    imgSrc: {
      type: String,
      default: "",
    },
    data: {
      type: String,
      default: "",
    },
    copyText: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const displayData = (data) =>
      props.info === "address"
        ? `${data.slice(0, 6)}...${data.slice(-4)}`
        : data;

    const { copy, resetCopyText } = useUserProfile(useStore());

    return {
      copy,
      displayData,
      resetCopyText,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/profileDescription.scss" />

<template>
  <div class="player" :class="fullscreen ? 'fullscreen' : ''">
    <div ref="controlsRef" class="player-wrapper">
      <img
        :class="fullscreen ? 'nft-image-fullscreen' : 'nft-image'"
        :src="coverImage"
        @load="setLoad()"
        id="image"
      />
      <div
        class="audio-buttons"
        :class="fullscreen ? 'audio-buttons-in-fullscreen-mode' : ''"
      >
        <div
          class="player-controls"
          :style="forNftDetail ? 'height: 40px; padding: 0px;' : ''"
        >
          <div
            @click.prevent="playing = !playing"
            :title="playing ? 'Pause' : 'Play'"
            :class="forNftDetail ? 'play-nft-detail' : 'play'"
          >
            <div class="play-shape" v-if="!playing">
              <img
                :src="
                  forNftDetail
                    ? playImg
                    : require('@/Common/Icons/PlayShape.png')
                "
                @mouseover="
                  playImg = require('@/Common/Icons/playControlLight.png')
                "
                @mouseleave="
                  playImg = require('@/Common/Icons/playControlDark.png')
                "
                alt="PlayShape"
                class="play-icon pointer"
              />
            </div>
            <div class="pause-shape" v-else>
              <img
                :src="
                  forNftDetail
                    ? pauseImg
                    : require('@/Common/Icons/PauseShape.png')
                "
                @mouseover="
                  pauseImg = require('@/Common/Icons/pauseControlLight.png')
                "
                @mouseleave="
                  pauseImg = require('@/Common/Icons/pauseControlDark.png')
                "
                alt="PauseShape"
                class="pause-icon pointer"
              />
            </div>
          </div>
          <div class="player-time">
            <div
              class="player-time-current"
              :style="!forNftDetail ? 'padding: 0 15px;' : ''"
            >
              {{ convertTimeHHMMSS(currentSeconds) }}
            </div>
            <div class="player-time-total" v-if="forNftDetail">/</div>
            <div class="seek" v-if="!forNftDetail">
              <div class="player-timeline">
                <div :style="progressStyle" class="player-progress"></div>
                <div v-on:click="seek" class="player-seeker" title="Seek"></div>
              </div>
            </div>

            <div v-if="!forNftDetail" style="padding: 0 12px">
              <div class="player-time-total" v-if="currentSeconds > 0">
                {{ convertTimeHHMMSS(durationLeft) }}
              </div>
              <div class="player-time-total" v-else>
                {{ convertTimeHHMMSS(durationSeconds) }}
              </div>
            </div>
            <div class="player-time-total" v-if="forNftDetail">
              {{ convertTimeHHMMSS(durationSeconds) }}
            </div>
          </div>
          <div
            id="volume"
            @click.prevent="mute"
            :title="volumeTitle"
            :class="forNftDetail ? 'play-nft-detail' : 'play'"
          >
            <div class="volume-shape" v-if="volume > 0">
              <img
                :src="
                  forNftDetail
                    ? volumeOnImg
                    : require('@/Common/Icons/VolumeShape.png')
                "
                @mouseover="
                  volumeOnImg = require('@/Common/Icons/volumeOnControlLight.png')
                "
                @mouseleave="
                  volumeOnImg = require('@/Common/Icons/volumeOnControlDark.png')
                "
                alt="VolumeShape"
                class="volume-icon pointer"
              />
            </div>
            <div class="mute-shape" v-else>
              <img
                :src="
                  forNftDetail
                    ? volumeOffImg
                    : require('@/Common/Icons/MuteShape.png')
                "
                @mouseover="
                  volumeOffImg = require('@/Common/Icons/volumeOffControlLight.png')
                "
                @mouseleave="
                  volumeOffImg = require('@/Common/Icons/volumeOffControlDark.png')
                "
                alt="MuteShape"
                class="mute-icon pointer"
              />
            </div>
          </div>
        </div>
        <img
          v-if="!fullscreen && forNftDetail"
          :src="fullscreenImg"
          @mouseover="
            fullscreenImg = require('@/Common/Icons/fullscreenLight.png')
          "
          @mouseleave="
            fullscreenImg = require('@/Common/Icons/fullscreenDark.png')
          "
          alt="fullScreen"
          @click="toggleFullscreen()"
          class="fullscreen-button pointer"
        />
        <img
          v-else-if="fullscreen && forNftDetail"
          :src="exitFullscreenImg"
          @mouseover="
            exitFullscreenImg = require('@/Common/Icons/exitFullscreenLight.png')
          "
          @mouseleave="
            exitFullscreenImg = require('@/Common/Icons/exitFullscreenDark.png')
          "
          alt="fullScreen"
          @click="toggleFullscreen()"
          class="fullscreen-button pointer button-in-fullscreen-mode"
        />
      </div>
    </div>
    <audio
      ref="audioRef"
      :src="url"
      @timeupdate="update"
      @loadeddata="load"
      @pause="playing = false"
      @play="playing = true"
      preload="auto"
      style="display: none"
    ></audio>
  </div>
</template>
<script>
import { computed, watch, ref } from "vue";
import { eventBus } from "@/main";

export default {
  name: "AppAudio",
  props: {
    autoPlay: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
    forNftDetail: {
      type: Boolean,
      default: false,
    },
    coverImage: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const currentSeconds = ref(0);
    const durationSeconds = ref(0);
    const durationLeft = ref(0);
    const loaded = ref(false);
    const playing = ref(false);
    const previousVolume = ref(35);
    const showVolume = ref(false);
    const volume = ref(100);
    const audioRef = ref("audio");
    const controlsRef = ref("fullscreenRef");
    const fullscreen = ref(false);

    const playImg = ref(require("@/Common/Icons/playControlDark.png"));
    const pauseImg = ref(require("@/Common/Icons/pauseControlDark.png"));
    const volumeOnImg = ref(require("@/Common/Icons/volumeOnControlDark.png"));
    const volumeOffImg = ref(
      require("@/Common/Icons/volumeOffControlDark.png")
    );
    const fullscreenImg = ref(require("@/Common/Icons/fullscreenDark.png"));
    const exitFullscreenImg = ref(
      require("@/Common/Icons/exitFullscreenDark.png")
    );

    const toggleFullscreen = () => {
      if (!document.fullscreenElement) {
        fullscreen.value = true;
        controlsRef.value.requestFullscreen();
      }

      if (document.fullscreenElement == controlsRef.value) {
        document.exitFullscreen();
      }
    };

    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
    document.addEventListener("MSFullscreenChange", exitHandler);

    function exitHandler() {
      if (
        !document.fullscreenElement &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        fullscreen.value = false;
      }
    }

    const setLoad = () => {
      eventBus.emit("setLoad");
    };

    const muted = computed(() => volume.value === 0);

    const percentComplete = computed(() =>
      parseInt((currentSeconds.value / durationSeconds.value) * 100)
    );
    const progressStyle = computed(() => ({
      width: `${percentComplete.value}%`,
    }));
    const volumeTitle = computed(() => {
      return `Volume (${volume.value}%)`;
    });

    watch(playing, (newValue) => {
      newValue ? audioRef.value.play() : audioRef.value.pause();
    });
    watch(volume, (newValue) => {
      audioRef.value.volume = newValue / 100;
    });

    const convertTimeHHMMSS = (val) => {
      let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);
      return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
    };
    const load = () => {
      if (audioRef.value.readyState >= 2) {
        loaded.value = true;
        durationSeconds.value = parseInt(audioRef.value.duration);
        return (playing.value = props.autoPlay);
      }
      throw new Error("Failed to load sound file.");
    };
    const mute = () => {
      if (muted.value) {
        return (volume.value = previousVolume.value);
      }
      previousVolume.value = volume.value;
      volume.value = 0;
    };
    const seek = (e) => {
      if (!loaded.value) return;

      const bounds = e.target.getBoundingClientRect();
      const seekPos = (e.clientX - bounds.left) / bounds.width;

      audioRef.value.currentTime = parseInt(audioRef.value.duration * seekPos);
    };
    const stop = () => {
      playing.value = false;
      audioRef.value.currentTime = 0;
    };
    const update = () => {
      currentSeconds.value = parseInt(audioRef.value.currentTime);
      durationLeft.value = parseInt(
        audioRef.value.duration - audioRef.value.currentTime
      );
    };
    return {
      currentSeconds,
      durationSeconds,
      durationLeft,
      loaded,
      playing,
      previousVolume,
      showVolume,
      volume,
      muted,
      percentComplete,
      progressStyle,
      volumeTitle,
      audioRef,
      convertTimeHHMMSS,
      load,
      mute,
      seek,
      stop,
      update,
      fullscreen,
      toggleFullscreen,
      controlsRef,
      setLoad,
      playImg,
      pauseImg,
      volumeOnImg,
      volumeOffImg,
      fullscreenImg,
      exitFullscreenImg,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/appAudio.scss"></style>

import api from "@/services/api";
import utils from "@/store/utils";
import queryString from "query-string";

const initialState = () => ({
  user: {
    id: null,
    avatar:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
    displayName: "",
    description: "",
    address: "",
    websiteUrl: "",
    twitterUsername: "",
    email: null,
  },
  publicUser: {
    id: null,
    avatar:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
    displayName: "",
    description: "",
    address: "",
    websiteUrl: "",
    twitterUsername: "",
    email: null,
  },
  isWhitelisted: false,
  currentPage: 1,
  nftsList: { total: 0 },
  pooledNftsList: { total: 0 },
  pooledNftDataList: [],
  externalNftsList: { total: 1 },
  poolsList: { total: 0 },
  poolsDataList: [],
  submissionCount: 0,
  nftDataList: [],
  externalNftDataList: [],
  externalNftsToShowList: [],
  errors: [],
  userStats: {
    totalBalance: 0,
    indexPoolTokens: [],
  },
  showSubmissionActionModal: false,
});

const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
  getUser: (state) => state.user,
  getPublicUser: (state) => state.publicUser,
  getUserStatsTotalBalance(state) {
    return state.userStats.totalBalance;
  },
};

const mutations = {
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearNftList(state) {
    state.nftsList = { total: 0 };
  },
  clearExternalNftList(state) {
    state.externalNftsList = { total: 0 };
  },
  setNftDataList(state, list) {
    state.nftDataList = state.nftDataList.concat(list);
  },
  setPooledNftDataList(state, list) {
    state.pooledNftDataList = state.pooledNftDataList.concat(list);
  },
  setExternalNftDataList(state, list) {
    state.externalNftDataList = state.externalNftDataList.concat(list);
  },
  setExternalNftsToShowList(state, list) {
    state.externalNftsToShowList = state.externalNftsToShowList.concat(list);
  },
  clearExternalNftsToShowList(state) {
    state.externalNftsToShowList = [];
  },
  setPoolsDataList(state, list) {
    state.poolsDataList = state.poolsDataList.concat(list);
  },
  clearNftDataList(state) {
    state.nftDataList = [];
  },
  clearPooledNftsDataList(state) {
    state.pooledNftDataList = [];
  },
  clearPoolsDataList(state) {
    state.poolsDataList = [];
  },
  clearExternalNftDataList(state) {
    state.externalNftDataList = [];
  },
  clearPublicUser(state) {
    state.publicUser = {
      id: null,
      avatar:
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
      displayName: "",
      description: "",
      address: "",
      websiteUrl: "",
      twitterUsername: "",
      email: null,
    };
  },
  toggleShowSubmissionActionModal(state) {
    state.showSubmissionActionModal = !state.showSubmissionActionModal;
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
  resetTheosMintedState(state) {
    state.nftsList = { total: 0 };
    state.nftDataList = [];
    state.currentPage = 1;
  },
  resetPooledNftsState(state) {
    state.pooledNftsList = { total: 0 };
    state.pooledNftDataList = [];
    state.currentPage = 1;
  },
  resetPoolsState(state) {
    state.poolsList = { total: 0 };
    state.poolsDataList = [];
    state.currentPage = 1;
  },
};
const actions = {
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  async fetchNftList({ commit }, { params }) {
    try {
      const response = await api.fetchNftList(params);
      commit("commitByKey", { nftsList: response.data });
      commit("setNftDataList", response.data.data);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchPooledNftList({ commit }, { params }) {
    try {
      const response = await api.fetchNftList(params);
      commit("commitByKey", { pooledNftsList: response.data });
      commit("setPooledNftDataList", response.data.data);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchExternalNftList({ commit, state }, parameters) {
    try {
      if (!state.externalNftsList.data || state.externalNftsList.hasNextPage) {
        const response = await api.fetchExternalNftList(parameters);
        commit("commitByKey", { externalNftsList: response.data });
        commit("setExternalNftDataList", response.data.data);
      }
      commit(
        "setExternalNftsToShowList",
        state.externalNftDataList.splice(0, 6)
      );
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchPools({ commit }, { params }) {
    try {
      const response = await api.fetchPools(params);
      commit("commitByKey", { poolsList: response.data });
      commit("setPoolsDataList", response.data.data);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchMe({ commit }) {
    try {
      const response = await api.fetchMe();
      commit("commitByKey", { user: response.data.data });
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },

  async fetchPublicUser({ commit }, id) {
    try {
      const response = await api.fetchUser(id);
      commit("commitByKey", { publicUser: response.data.data });
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },

  async fetchSubmissionCount({ commit }) {
    try {
      const response = await api.fetchMySubmissions();
      commit("commitByKey", { submissionCount: response.data.count });
    } catch (error) {
      commit("pushError", error);
    }
  },

  async fetchPoolsCount({ commit }, userId) {
    try {
      const response = await api.fetchPools(
        queryString.stringify({ originatorId: userId })
      );
      commit("commitByKey", { poolsCount: response.data.total });
    } catch (error) {
      commit("pushError", error);
    }
  },

  async checkExistingPermissions({ commit }, id) {
    try {
      return await api.countNftSubmissions(id);
    } catch (error) {
      commit("pushError", error);
    }
  },

  async isWhitelisted({ commit }, data) {
    try {
      const response = await api.fetchIsWhitelisted(data);
      commit("commitByKey", { isWhitelisted: response.data });
    } catch (error) {
      commit("pushError", error);
    }
  },

  async storeExternalNft({ commit }, nftData) {
    try {
      const result = await api.storeNft(nftData);
      return result.data;
    } catch (error) {
      const isUnauthorized = await utils.handleUnauthorizedRequest(error);
      if (isUnauthorized) return null;
      commit("pushError", error);
    }
  },

  async fetchUsersProfileStats({ commit }, id) {
    try {
      const response = await api.fetchUsersProfileStats(id);
      commit("commitByKey", { userStats: response.data });
    } catch (error) {
      commit("pushError", error);
    }
  },

  resetState({ commit }) {
    commit("resetState");
  },
  clearNftList({ commit }) {
    commit("clearNftList");
  },
  clearNftDataList({ commit }) {
    commit("clearNftDataList");
  },
  clearPooledNftsDataList({ commit }) {
    commit("clearPooledNftsDataList");
  },
  clearPoolsDataList({ commit }) {
    commit("clearPoolsDataList");
  },
  resetPoolsState({ commit }) {
    commit("resetPoolsState");
  },
  resetPooledNftsState({ commit }) {
    commit("resetPooledNftsState");
  },
  clearExternalNftList({ commit }) {
    commit("clearExternalNftList");
  },
  resetTheosMintedState({ commit }) {
    commit("resetTheosMintedState");
  },
  clearExternalNftDataList({ commit }) {
    commit("clearExternalNftDataList");
    commit("clearExternalNftsToShowList");
  },
  clearPublicUser({ commit }) {
    commit("clearPublicUser");
  },
  toggleShowSubmissionActionModal({ commit }) {
    try {
      commit("toggleShowSubmissionActionModal");
    } catch (error) {
      commit("pushError", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

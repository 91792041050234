// import api from "@/services/api";

import api from "@/services/api";

const initialState = () => ({
  nft: {
    id: null,
    name: "",
    royaltyPercentage: null,
    isExplicit: false,
    coverImageURL: "",
    description: "",
    alternativeDescription: "",
    unlockableContent: null,
    tags: [],
    creator: {},
    owners: [],
    contractAddress: "",
    status: "",
    coverImage: {
      path: "",
      fileName: "",
    },
    file: {
      filename: "",
      path: "",
      mimeType: "",
    },
    pools: null,
    sellPrice: 0,
  },
  pool: {},
  userBalance: 0,
  errors: [],
});

const state = initialState();

const getters = {
  getByKey: (state) => (key) => state.nft[key],
  getNft: (state) => state.nft,
  getPool: (state) => state.pool,
  getUserBalance: (state) => state.userBalance,
};

const mutations = {
  commitByKey(state, object) {
    Object.keys(object.nft).forEach((key) => {
      state.nft[key] = object.nft[key];
    });
  },
  commitUserBalance(state, userBalance) {
    state.userBalance = userBalance;
  },
  commitPool(state, pool) {
    state.pool = pool;
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};
const actions = {
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  commitUserBalance({ commit }, userBalance) {
    commit("commitUserBalance", userBalance);
  },
  resetState({ commit }) {
    commit("resetState");
  },
  async fetchNft({ commit }, id) {
    try {
      const response = await api.fetchNft(id);
      commit("commitByKey", { nft: response.data });
      return response.data;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchPool({ commit }, id) {
    try {
      const response = await api.fetchPool(id);
      commit("commitPool", response.data);
    } catch (error) {
      commit("pushError", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

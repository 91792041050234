import { DateTime } from "luxon";
import { unref, ref, onMounted, onUnmounted } from "vue";

let tick = null;

export default () => {
  const TIME_NOW = ref(DateTime.local());
  const TIME_UPDATE_INTERVAL_MILLISECONDS = 1000;

  onMounted(() => {
    if (!tick)
      tick = setInterval(() => {
        TIME_NOW.value = DateTime.local();
      }, TIME_UPDATE_INTERVAL_MILLISECONDS);
  });

  onUnmounted(() => {
    clearInterval(tick);
    tick = null;
  });

  const getDateDiff = (endingTime, fromTime, values) =>
    DateTime.fromISO(endingTime).diff(fromTime, values).values;

  const formatTimeDisplay = (t, sep = "") => {
    let formattedTime = "";
    const keyMap = Object.freeze({
      days: "D",
      hours: "H",
      minutes: "M",
      seconds: "S",
    });
    Object.keys(t).forEach(
      (k) =>
        (formattedTime += `0${Math.floor(t[k])}`.slice(-2) + keyMap[k] + sep)
    );
    return formattedTime.slice(0, -1);
  };

  const isRemainingLessThan = (end, now, diff) => end <= unref(now).plus(diff);

  return {
    TIME_NOW,
    getDateDiff,
    formatTimeDisplay,
    isRemainingLessThan,
  };
};

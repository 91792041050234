<template>
  <div
    class="app-modal-overlay"
    :class="[isOpenConnectAndPolygonModal ? 'begin-modal' : '']"
  >
    <div class="app-modal-wrapper">
      <div
        class="app-modal-leave-modal"
        v-if="closeButton"
        @click="returnToPreviousModal()"
      >
        <img src="@/Common/Icons/XmarkModal.png" alt="Logo" />
      </div>
      <div class="app-modal-header">
        <slot name="app-modal-header"></slot>
      </div>
      <div class="app-modal-body">
        <slot name="app-modal-body"></slot>
      </div>
      <div class="app-modal-footer">
        <slot name="app-modal-footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppModal",
  props: {
    closeButton: {
      type: Boolean,
      default: false,
    },
    returnToPreviousModal: {
      type: Function,
      default: () => {},
    },
    isOpenConnectAndPolygonModal: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/appModal.scss"></style>

import api from "@/services/api";

const initialState = () => ({
  monthlyMintedNfts: {
    monthlyCount: 0,
  },
});
const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
};
const mutations = {
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};
const actions = {
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  async fetchMonthlyMintedNfts({ commit }) {
    try {
      const response = await api.fetchMonthlyMintedNfts();
      commit("commitByKey", { monthlyMintedNfts: response.data });
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
  resetState({ commit }) {
    commit("resetState");
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import * as Yup from "yup";

export const mintNftDescriptionSchema = Yup.object().shape({
  NftTitle: Yup.string()
    .max(75, "The maximum allowed length is 75 characters")
    .required("Nft Title is a required field"),
  Description: Yup.string()
    .max(200, "The maximum allowed length is 200 characters")
    .required(),
});

<template>
  <div
    class="state-details-section"
    v-show="nft.status !== 'approved'"
    @click="toggleContent()"
  >
    <div class="state-details-header">
      <span class="state-details-main-text">
        {{ stateDetailsTexts["main-text"][nft.status] }}
      </span>
      <img width="20" height="10" alt="carret" :src="arrowImage" />
    </div>
    <div style="width: 100%" v-show="isActive" v-on:click.stop>
      <div class="state-details-content" v-if="nft.status === 'deposited'">
        <div class="row">
          <div class="info">
            <p>Started date</p>
            <span>
              {{ parseDateStateDetails(nft.pools[0].NftPoolModel.depositedAt) }}
            </span>
          </div>
          <div class="info">
            <div class="text-with-tooltip">
              <p>NFT Base price</p>
              <app-tooltip
                :text="stateDetailsTexts['tooltips'][nft.status]['base-price']"
              />
            </div>
            <span>
              {{ toFixedIfNecessary(pool.basePrice, 3) }}
              {{ pool.tokenSymbol || "MATIC" }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="info">
            <p>Pool originator</p>
            <span>{{ pool.originator?.displayName }}</span>
          </div>
          <div class="info">
            <div class="text-with-tooltip">
              <p>i price</p>
              <app-tooltip
                :text="stateDetailsTexts['tooltips'][nft.status]['i-price']"
              />
            </div>
            <span>+{{ toFixedIfNecessary(pool.maximumIPrice, 2) }}%</span>
          </div>
        </div>
      </div>
      <div class="state-details-content" v-if="nft.status === 'listed'">
        <div class="row">
          <div class="info">
            <p>List date</p>
            <span>
              {{ parseDateStateDetails(nft.marketplace?.listedAt) }} hs
            </span>
          </div>
        </div>
      </div>
      <div
        class="state-details-content"
        v-if="['auctioned', 'pending-sale'].includes(nft.status)"
      >
        <div class="row">
          <div class="info">
            <p>Started date</p>
            <span
              >{{ parseDateStateDetails(nft.auction?.startingTime) }} hs</span
            >
          </div>
          <div class="info">
            <div class="text-with-tooltip">
              <p>Starting price</p>
              <app-tooltip
                :text="
                  stateDetailsTexts['tooltips'][nft.status]['starting-price']
                "
              />
            </div>
            <span>
              {{ nft.auction?.startingPrice }} {{ nft.auction?.token }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="info">
            <p>End date</p>
            <span>{{ parseDateStateDetails(nft.auction?.endingTime) }} hs</span>
          </div>
          <div class="info">
            <div class="text-with-tooltip">
              <p>Reserve price</p>
              <app-tooltip
                :text="
                  stateDetailsTexts['tooltips'][nft.status]['reserve-price']
                "
              />
            </div>
            <span v-if="!isOwner">
              {{ nft.auction?.reservedPrice ? "Set" : "Not set" }}
            </span>
            <span v-else>
              {{
                nft.auction?.reservedPrice
                  ? `${nft.auction?.reservedPrice} ${nft.auction?.token}`
                  : "Not set"
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <span class="docs-bold-text">
          {{ stateDetailsTexts["documentation"]["default"]["bold-text"] }}
        </span>
        <span class="docs-gray-text">
          {{ stateDetailsTexts["documentation"]["default"]["gray-text"] }}
        </span>
        <a
          :href="stateDetailsTexts.documentation.default.link"
          target="_blank"
          class="docs-link"
        >
          {{ stateDetailsTexts["documentation"]["default"]["link-text"] }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import AppTooltip from "@/elements/AppTooltip";
import useUtilities from "@/composables/useUtilities";
import stateDetailsTexts from "@/json/nftDetailsStateDetails.json";
import useNftDetails from "../../../composables/useNftDetails";
export default {
  name: "StateDetails",
  components: {
    AppTooltip,
  },
  props: {
    nft: {
      type: Object,
      default: () => {},
    },
    pool: {
      type: Object,
      default: () => {},
    },
  },
  setup: function () {
    const isActive = ref(false);
    const toggleContent = () => {
      isActive.value = !isActive.value;
    };
    const store = useStore();
    const { isOwner } = useNftDetails(store);
    const arrowImage = computed(() =>
      isActive.value
        ? require("@/Common/Icons/arrowUp.png")
        : require("@/Common/Icons/arrowDown.png")
    );
    const { toFixedIfNecessary, parseDateStateDetails } = useUtilities();
    return {
      isOwner,
      isActive,
      arrowImage,
      toggleContent,
      stateDetailsTexts,
      toFixedIfNecessary,
      parseDateStateDetails,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/stateDetails.scss" />

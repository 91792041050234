<template>
  <div class="edit">
    <img src="@/Common/Icons/edit_pencil.png" alt="Edit profile" />
    <app-button :text="text" class="edit-button" @click="fn()" />
  </div>
</template>

<script>
import AppButton from "@/elements/AppButton";
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    fn: {
      type: Function,
      default: () => ({}),
    },
  },
  components: {
    AppButton,
  },
  setup() {},
};
</script>

<style lang="scss" scoped src="@/Common/Styles/editButton.scss" />

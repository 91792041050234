import { useRouter as useVueRouter } from "vue-router";

const useRouter = () => {
  const router = useVueRouter();

  const goTo = (route) => {
    if (typeof route === "number") return router.go(route);
    router.push(route);
  };

  const goToNewTab = (route) => {
    let routeData = router.resolve(route);
    window.open(routeData.href, "_blank");
  };

  return {
    goTo,
    goToNewTab,
  };
};

export default useRouter;

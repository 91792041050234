<template>
  <div
    class="owner-img"
    v-if="token.currentOwner"
    @click.stop="goToPublicProfile(token?.currentOwner.id)"
    :style="{ width: `${cardSize}px`, height: `${cardSize}px` }"
    :class="
      token?.currentOwner.id === registeredUser?.id
        ? 'avatar-img-border-holo'
        : ''
    "
  >
    <div class="owner-tooltip">
      Owner: {{ displayName(token.currentOwner) }}
    </div>
    <img :src="ownerAvatarImage(token)" alt="Owner" />
  </div>
  <div
    class="owner-img"
    v-else-if="token.originator"
    :style="{ width: `${cardSize}px`, height: `${cardSize}px` }"
  >
    <div class="owner-tooltip">
      Originator: {{ token.originator.displayName }}
    </div>
    <img :src="creatorAvatarImage(token.originator)" alt="Originator" />
  </div>
</template>

<script>
import { useStore } from "vuex";
import useAuth from "@/composables/useAuth";
import useRouter from "@/composables/useRouter";
import imageTransformer from "@/plugins/imageTransformer";
export default {
  props: {
    token: {
      type: Object,
      default: () => {},
    },
    isForMarketplace: {
      type: Boolean,
      default: false,
    },
    cardSize: {
      type: Number,
      default: 32,
    },
  },
  setup() {
    const store = useStore();
    const { goTo } = useRouter();
    const { registeredUser } = useAuth(store);

    const hasOwners = (token) => {
      return token.owners[token.owners.length - 1].displayName;
    };

    const displayName = (user) => {
      if (user.displayName) return user.displayName;
      return `${user.publicAddress.slice(0, 6)}...${user.publicAddress.slice(
        -4
      )}`;
    };

    const creatorAvatarImage = (creator) => {
      if (creator.avatarFileName)
        return imageTransformer.transform(
          creator.avatarFileName.filename,
          "user.avatar"
        );
      if (creator.avatarImageUrl) return creator.avatarImageUrl;
      if (creator.profileImage)
        return imageTransformer.transform(
          creator.profileImage.filename,
          "user.avatar"
        );
      return require("@/Common/Icons/Medusa_avatar_bg.png");
    };

    const ownerAvatarImage = (token) => {
      if (token.currentOwner?.profileImage.filename)
        return imageTransformer.transform(
          token.currentOwner?.profileImage?.filename,
          "user.avatar"
        );
      if (token.owner) return token.owner.avatarImageUrl;
      return require("@/Common/Icons/Medusa_avatar_bg.png");
    };

    const goToPublicProfile = (id) =>
      goTo({
        params: { id },
        name: "ViewProfilePublic",
      });

    return {
      hasOwners,
      displayName,
      registeredUser,
      ownerAvatarImage,
      goToPublicProfile,
      creatorAvatarImage,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/nftCard.scss" />

<template>
  <div class="wrapper-total">
    <div class="pool-wrapper">
      <div class="pools-top-bar" v-if="pool.originator.id === user.id">
        <div class="pools-panel-group">
          <div
            class="pool-panel"
            :class="{ active: showing === 'nfts' }"
            @click="show('nfts')"
          >
            <p>
              Deposited NFTs
              <span class="pool-panel-total-items">{{
                pooledNftsTotal.total
              }}</span>
            </p>
          </div>
          <div
            class="pool-panel"
            :class="{ active: showing === 'submissions' }"
            @click="show('submissions')"
          >
            <p>
              Submissions
              <span class="pool-panel-total-items">{{
                poolSubmissions.count
              }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="pool-panel-body">
        <component :is="currentShowingPanel(showing)" />
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import usePools from "@/composables/usePools";
import SubmissionsPanel from "./SubmissionsPanel";
import DepositedNftsPanel from "./DepositedNftsPanel";

export default {
  components: {
    DepositedNftsPanel,
    SubmissionsPanel,
  },
  setup() {
    const store = useStore();
    const { pooledNftsTotal, show, showing, poolSubmissions, pool, user } =
      usePools(store);

    const currentShowingPanel = (showing) =>
      showing === "nfts" ? "DepositedNftsPanel" : "SubmissionsPanel";

    onMounted(() => {
      if (useRoute().query.tab) show(useRoute().query.tab);
    });

    return {
      user,
      show,
      pool,
      showing,
      pooledNftsTotal,
      poolSubmissions,
      currentShowingPanel,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/poolPanels.scss" />

<template>
  <div class="slider-wrapper">
    <template v-for="(_, index) in totalSlides" :key="index">
      <div
        class="slide pointer"
        @click="setCurrentSlide(index)"
        :class="{ active: isActive(index) }"
      />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    totalSlides: {
      type: Number,
      default: 0,
    },
    currentSlide: {
      type: Number,
      default: 1,
    },
    setCurrentSlide: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const isActive = (index) => index === props.currentSlide;

    return {
      isActive,
    };
  },
};
</script>

<style lang="scss" src="@/Common/Styles/sliderTabs.scss" scoped />

<template>
  <div class="profile-panel-search">
    <app-input
      class="search-input"
      :disabled="disabled"
      :value="searchString"
      v-model="searchString"
      inputStyle="padding-left: 35px"
      inputClass="app-modal-input-search"
      @keyup.enter="search(searchString)"
      :placeholder="
        isForMarketplace
          ? 'Search by NFT name, tag or username'
          : 'Search by name, pool, tag or cause'
      "
    />
    <img
      alt="search"
      class="profile-panel-search-icon"
      src="@/Common/Icons/search_icon.png"
    />
    <img
      alt="delete"
      v-show="searchString != ''"
      @click="resetSearchString(true)"
      class="profile-panel-search-cross"
      src="@/Common/Icons/search_cross.png"
    />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { eventBus } from "@/main";
import AppInput from "@/elements/AppInput";

export default {
  components: {
    AppInput,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    searchFunction: {
      type: Function,
      default: () => {},
    },
    clearSearchFunction: {
      type: Function,
      default: () => {},
    },
    isForMarketplace: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const searchString = ref("");

    onMounted(() => {
      eventBus.on("resetSearchString", () => {
        resetSearchString();
      });
    });
    const resetSearchString = (triggerClearSearch = false) => {
      searchString.value = "";
      if (triggerClearSearch) {
        props.clearSearchFunction();
      }
    };
    const search = (searchString) => {
      props.searchFunction(searchString);
      eventBus.emit("changeMarketplaceSearchFlag", true);
    };
    return {
      search,
      searchString,
      resetSearchString,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/profilePanels.scss" />

<template>
  <div
    class="auction-timestamp"
    :class="{
      'auction-finished': isFinished,
      'auction-ending-soon': isEndingSoon,
    }"
  >
    <img
      alt="Ending soon"
      v-if="isEndingSoon && !isFinished"
      src="@/Common/Icons/AuctionEndingSoonIcon.svg"
    />
    {{ displayTimeRemaining }}
    <span class="auction-status">
      {{ auctionStatus }}
    </span>
  </div>
</template>

<script>
import { computed } from "vue";
import { DateTime } from "luxon";
import useTimeUtilities from "@/composables/useTimeUtilities";
export default {
  props: {
    token: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const TIME_UNITS_ARRAY = ["days", "hours", "minutes"];
    const ENDING_SOON_THRESHOLD = { days: 0, hours: 4, minutes: 0, seconds: 0 };

    const endingTime = DateTime.fromISO(props.token.auction.endingTime);
    const { getDateDiff, formatTimeDisplay, isRemainingLessThan, TIME_NOW } =
      useTimeUtilities();

    const [isFinished, isEndingSoon, displayTimeRemaining] = [
      computed(() =>
        isRemainingLessThan(endingTime, TIME_NOW.value, { seconds: 1 })
      ),
      computed(() =>
        isRemainingLessThan(endingTime, TIME_NOW.value, ENDING_SOON_THRESHOLD)
      ),
      computed(() =>
        isFinished.value
          ? "00D:00H:00M"
          : formatTimeDisplay(
              getDateDiff(endingTime, TIME_NOW.value, TIME_UNITS_ARRAY),
              ":"
            )
      ),
    ];

    const auctionStatus = computed(() => (isFinished.value ? "ended" : "left"));

    return {
      TIME_NOW,
      isFinished,
      isEndingSoon,
      auctionStatus,
      displayTimeRemaining,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/nftCard.scss" />

<template>
  <div class="choose-pool-wrapper">
    <div class="choose-pool-header-text">
      <span class="pick-odyssey-text">PICK ODYSSEY</span
      ><span class="pools-text">POOLS</span>
    </div>
    <div class="choose-pool-table" v-if="poolGalleryStats">
      <pool-list-table :isCheckbox="true" />
    </div>
    <div class="form-box-buttons-controls form-box-center-content">
      <app-button
        text="Back"
        @click="decrementStep()"
        class="app-modal-button"
        style="width: calc(50% - 10px)"
        v-if="currentStepsArrayLength > 2"
      />
      <app-button
        text="Next"
        @click="incrementStep()"
        style="width: calc(50% - 10px)"
        :disabled="chosenPools.length === 0"
        class="app-modal-button-inverse deposit-next-button"
      />
    </div>
  </div>
</template>
<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
import AppButton from "@/elements/AppButton";
import useDeposit from "@/composables/useDeposit";
import PoolListTable from "./PoolsTable/PoolListTable.vue";
import usePoolsGallery from "@/composables/usePoolsGallery";
export default {
  components: {
    AppButton,
    PoolListTable,
  },
  setup() {
    const store = useStore();

    const { pools, poolGalleryStats, fetchGalleryStats } =
      usePoolsGallery(store);

    const {
      chosenPools,
      incrementStep,
      changeCurrentStepArray,
      currentStepsArrayLength,
    } = useDeposit(store);

    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    const decrementStep = () => {
      changeCurrentStepArray("");
    };

    onMounted(async () => {
      await store.dispatch("poolsGallery/fetchPools");
      await fetchGalleryStats(pools.value);
      scrollToTop();
    });

    return {
      chosenPools,
      incrementStep,
      decrementStep,
      poolGalleryStats,
      currentStepsArrayLength,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/choosePoolToSubmitNft.scss" />

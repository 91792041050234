<template>
  <app-deposit-review
    :closeButton="true"
    :returnToPreviousModal="closeFunction"
  >
    <template v-slot:app-modal-header>
      <img src="@/Common/Icons/TheosLogo.png" alt="TheosLogo" />
      <h3 class="app-modal-deposit-title">NFT PRICE</h3>
    </template>
    <template v-slot:app-modal-body>
      <p class="app-modal-body-text">You will get</p>
      <p class="app-modal-deposit-price">
        <span> {{ calculateTokenPrice }} {{ pool.tokenSymbol }}</span>
        <span class="price-color"> ${{ calculatePriceLpTokensUsd }} </span>
      </p>
      <div class="scrolling-content">
        <p class="app-modal-body-text text-color">
          <span class="app-modal-base-price">
            Base price {{ nftBasePrice }} {{ pool.tokenSymbol }}</span
          >
          <span class="price-color"> ≈ ${{ lpTokensInUsd }} </span>
        </p>
        <div class="app-modal-details-control" @click="toggleDetails()">
          <span class="app-modal-details-control-text">See details</span>
          <img :src="arrowImage" class="arrow-image" alt="Arrow" name="arrow" />
        </div>
        <div class="app-modal-details" v-show="detailsShown">
          <span class="app-modal-details-main-text">For depositing</span>
          <div class="app-modal-details-rows">
            <div>
              <span class="app-modal-details-text">Base price</span>
              <span class="app-modal-details-values"
                >{{ nftBasePrice }} {{ pool.tokenSymbol }}</span
              >
            </div>
            <span class="price-color app-modal-details-price-dollars">
              ≈ ${{ lpTokensInUsd }}
            </span>
            <div>
              <span class="app-modal-details-text">5% for social cause</span>
              <span class="app-modal-details-values"
                >- {{ depositingSocialCauseValue }} {{ pool.tokenSymbol }}</span
              >
            </div>
            <span class="price-color app-modal-details-price-dollars">
              ≈ ${{ valueLpTokensInUsd(depositingSocialCauseValue) }}
            </span>
            <div>
              <span class="app-modal-details-text"
                >{{ nft.royaltyPercentage }} % for Royalty</span
              >
              <span class="app-modal-details-values"
                >- {{ depositingRoyaltyValue }} {{ pool.tokenSymbol }}</span
              >
            </div>
            <span class="price-color app-modal-details-price-dollars">
              ≈ ${{ valueLpTokensInUsd(depositingRoyaltyValue) }}
            </span>
          </div>
          <div class="splitter"></div>
          <div class="app-modal-total">
            <div>
              <span>Total after depositing</span>
              <span class="app-modal-total-values"
                >{{ depositingTotal }} {{ pool.tokenSymbol }}</span
              >
            </div>
            <span class="price-color app-modal-total-price-dollars">
              ≈ ${{ valueLpTokensInUsd(depositingTotal) }}
            </span>
          </div>
          <span class="app-modal-details-main-text">For selling</span>
          <div class="app-modal-details-rows">
            <div>
              <span class="app-modal-details-text">i price</span>
              <span class="app-modal-details-values">
                {{ sellingIPriceValue }} {{ pool.tokenSymbol }}</span
              >
            </div>
            <span class="price-color app-modal-details-price-dollars">
              ≈ ${{ valueLpTokensInUsd(sellingIPriceValue) }}
            </span>
          </div>
          <div class="splitter"></div>
          <div class="app-modal-total">
            <div>
              <span>Total after selling</span>
              <span class="app-modal-total-values">
                {{ sellingTotal }} {{ pool.tokenSymbol }}</span
              >
            </div>
            <span class="price-color app-modal-total-price-dollars">
              ≈ ${{ valueLpTokensInUsd(sellingTotal) }}
            </span>
          </div>
        </div>
        <div class="nft-checkbox">
          <span class="app-modal-body-text app-modal-top-margin"
            >Set a higher price for my NFT
          </span>
          <img
            :src="switchStateImage"
            class="switch-image app-modal-top-margin"
            alt="Switch"
            name="tokenSwitch"
            @click="toggleHigherPrice()"
          />
        </div>
        <div v-if="higherPrice" class="higher-price">
          <p class="app-modal-deposit-bold-text">Increase base price by</p>
          <app-slider
            v-model="currentValue"
            :min="0"
            :max="toFixedIfNecessary(pool.maximumIPrice, 2)"
            class="nft-modal-slider"
          ></app-slider>
          <div class="app-modal-you-will-receive">
            <div class="app-modal-warning-icon">
              <img src="@/Common/Icons/Warning.png" />
            </div>
            <span class="app-modal-receive-text">
              You will receive this extra amount when someone buys your NFT
            </span>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:app-modal-footer>
      <app-button
        @click="confirmDeposit()"
        buttonClass="app-modal-button-inverse"
        text="Confirm deposit"
      />
    </template>
  </app-deposit-review>
</template>

<script>
import { useStore } from "vuex";
import AppButton from "@/elements/AppButton.vue";
import useDepositReviewModal from "@/composables/useDepositReviewModal";
import AppDepositReview from "@/elements/AppDepositReview";
import AppSlider from "@/elements/AppSlider";
import { ref, computed, onMounted, onUnmounted } from "vue";
import useUtilities from "@/composables/useUtilities";

export default {
  name: "ConfirmDeposit",
  props: {
    nft: {
      type: Object,
      default: () => {},
    },
    pool: {
      type: Object,
      default: () => {},
    },
    closeFunction: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    AppDepositReview,
    AppButton,
    AppSlider,
  },
  setup(props, context) {
    const store = useStore();
    const { confirmChosenDeposit } = useDepositReviewModal(store);
    const { toFixedIfNecessary } = useUtilities();
    const higherPrice = ref(false);
    const detailsShown = ref(false);
    const toggleHigherPrice = () => {
      higherPrice.value = !higherPrice.value;
    };
    const toggleDetails = () => {
      detailsShown.value = !detailsShown.value;
    };

    const lpTokensInUsd = computed(() =>
      props.pool.tokenPrice
        ? toFixedIfNecessary(props.pool.basePrice * props.pool.tokenPrice, 2)
        : 0
    );
    const valueLpTokensInUsd = (val) => {
      return toFixedIfNecessary(val * props.pool.tokenPrice, 2);
    };
    const nftBasePrice = computed(() =>
      toFixedIfNecessary(props.pool.basePrice, 4)
    );
    const currentValue = ref(0);
    const confirmDeposit = () => {
      let price = props.pool.basePrice;
      if (higherPrice.value) {
        price = calculatePrice();
      }
      context.emit("update:modelValue", price);
      confirmChosenDeposit(
        price,
        props.nft,
        Number(currentValue.value),
        props.pool
      );
    };
    const calculatePrice = () => {
      return toFixedIfNecessary(
        Number(props.pool.basePrice) +
          (currentValue.value / 100) * props.pool.basePrice,
        4
      );
    };
    const calculateTokenPrice = computed(() => {
      return toFixedIfNecessary(
        Number(props.pool.basePrice) +
          (currentValue.value / 100) * props.pool.basePrice,
        4
      );
    });

    const calculatePriceLpTokensUsd = computed(() => {
      const price = toFixedIfNecessary(
        lpTokensInUsd.value + (currentValue.value / 100) * lpTokensInUsd.value,
        2
      );
      return price;
    });
    const switchStateImage = computed(() =>
      higherPrice.value === true
        ? require("@/Common/Icons/SwitchOn.png")
        : require("@/Common/Icons/SwitchOff.png")
    );
    const arrowImage = computed(() =>
      detailsShown.value === true
        ? require("@/Common/Icons/up_arrow_icon.svg")
        : require("@/Common/Icons/down_arrow_icon.svg")
    );
    const depositingSocialCauseValue = computed(() =>
      toFixedIfNecessary((props.pool.basePrice / 100) * 5, 4)
    );
    const depositingSocialCauseValueUsd = computed(() =>
      toFixedIfNecessary((lpTokensInUsd.value / 100) * 5, 2)
    );
    const depositingRoyaltyValue = computed(() =>
      toFixedIfNecessary(
        ((props.pool.basePrice - depositingSocialCauseValue.value) / 100) *
          props.nft.royaltyPercentage,
        4
      )
    );
    const depositingRoyaltyValueUsd = computed(() =>
      toFixedIfNecessary(
        ((lpTokensInUsd.value -
          valueLpTokensInUsd(depositingSocialCauseValue.value)) /
          100) *
          props.nft.royaltyPercentage,
        2
      )
    );
    const depositingTotal = computed(
      () =>
        props.pool.basePrice -
        depositingRoyaltyValue.value -
        depositingSocialCauseValue.value
    );
    const depositingTotalUsd = computed(
      () =>
        lpTokensInUsd.value -
        depositingRoyaltyValueUsd.value -
        depositingSocialCauseValueUsd.value
    );
    const sellingIPriceValue = computed(() =>
      toFixedIfNecessary((currentValue.value / 100) * props.pool.basePrice, 4)
    );
    const sellingIPriceValueUsd = computed(() =>
      toFixedIfNecessary((currentValue.value / 100) * lpTokensInUsd.value, 2)
    );
    const sellingIPriceSocialCauseValue = computed(() =>
      toFixedIfNecessary(
        (currentValue.value / 100) * props.pool.basePrice * (5 / 100),
        4
      )
    );

    const sellingTotal = computed(() =>
      toFixedIfNecessary(sellingIPriceValue.value, 4)
    );
    const sellingTotalUsd = computed(() =>
      toFixedIfNecessary(sellingIPriceValueUsd.value, 2)
    );
    onMounted(() => document.body.classList.add("stop-scroll"));
    onUnmounted(() => document.body.classList.remove("stop-scroll"));
    return {
      arrowImage,
      higherPrice,
      detailsShown,
      sellingTotal,
      nftBasePrice,
      currentValue,
      lpTokensInUsd,
      toggleDetails,
      confirmDeposit,
      sellingTotalUsd,
      depositingTotal,
      switchStateImage,
      toggleHigherPrice,
      sellingIPriceValue,
      toFixedIfNecessary,
      depositingTotalUsd,
      calculateTokenPrice,
      sellingIPriceValueUsd,
      depositingRoyaltyValue,
      depositingRoyaltyValueUsd,
      calculatePriceLpTokensUsd,
      depositingSocialCauseValue,
      depositingSocialCauseValueUsd,
      sellingIPriceSocialCauseValue,
      valueLpTokensInUsd,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/depositReview.scss"></style>

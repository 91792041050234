<template>
  <teleport to="body">
    <app-modal :closeButton="true" :returnToPreviousModal="closeModal">
      <template v-slot:app-modal-header>
        <img
          src="@/Common/Icons/TheosLogo.png"
          alt="TheosLogo"
          class="header-logo-image"
        />
      </template>
      <template v-slot:app-modal-body>
        <div class="app-modal-body-wrapper">
          <uniswap-vue
            :uniswapDappSharedLogicContext="uniswapDappSharedLogicContext"
            :image="require('@/Common/Icons/arrows.svg')"
            v-if="uniswapDappSharedLogicContext"
            :eventBus="eventBus"
            :theosLogo="require('@/Common/Icons/theosyellowcircle.png')"
            :maticLogo="require('@/Common/Icons/matic.png')"
            :sushiLogo="require('@/Common/Icons/Sushiswap.png')"
            :infoIcon="require('@/Common/Icons/InfoIcon.png')"
            :logos="logos"
            :congratsModalText="congratsModalText"
            :congratsModalButtonText="congratsModalButtonText"
          />
        </div>
      </template>
    </app-modal>
  </teleport>
</template>

<script>
import AppModal from "@/elements/AppModal";
import { onMounted, onUnmounted, ref } from "vue";
//import { ChainId } from "uniswap-dapp-integration-shared";
import usePools from "@/composables/usePools";
import { useStore } from "vuex";
import { eventBus } from "@/main";
import useToast from "@/composables/useToast";
import useExchangeTokens from "@/composables/useExchangeTokens";
import { useRouter } from "vue-router";

export default {
  name: "ExchangeTokensModal",
  components: {
    AppModal,
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const { fetchAllPoolsListTokens } = usePools(store);
    const {
      defaultInputToken,
      defaultOutputToken,
      congratsModalText,
      congratsModalButtonText,
      openModalSrc,
    } = useExchangeTokens(store);
    const { showToast } = useToast();
    const uniswapDappSharedLogicContext = ref(null);

    eventBus.on("closeExchangeTokenModal", () => {
      props.closeModal();
    });

    const router = useRouter();

    eventBus.on("continueFlow", async () => {
      switch (openModalSrc.value) {
        case "create_pool":
          props.closeModal();
          break;
        case "pool_details":
          props.closeModal();
          await router.push({ name: "DepositNft" });
          break;
        default:
          props.closeModal();
      }
    });

    eventBus.on("transactionRejected", async () => {
      props.closeModal();
      await showToast("Rejected", "Transaction rejected", "Error");
    });

    onMounted(async () => {
      const addresses = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const tokenAddressesList = await fetchAllPoolsListTokens();
      const { VUE_APP_ENV } = process.env;

      const chainId = VUE_APP_ENV !== "production" ? 80001 : 137;

      uniswapDappSharedLogicContext.value = {
        supportedNetworkTokens: [
          {
            chainId: chainId,
            defaultInputToken: defaultInputToken,
            defaultOutputToken: defaultOutputToken,
            supportedTokens: tokenAddressesList,
          },
        ],
        ethereumAddress: addresses[0],
        ethereumProvider: window.ethereum,
      };

      document.body.classList.add("stop-scroll");
    });
    onUnmounted(() => document.body.classList.remove("stop-scroll"));
    const logos = [
      { name: "MATIC", path: require("@/Common/Icons/matic.png") },
      { name: "THEOS", path: require("@/Common/Icons/theosyellowcircle.png") },
      { name: "ETH", path: require("@/Common/Icons/eth.png") },
      { name: "WETH", path: require("@/Common/Icons/eth.png") },
      { name: "USDT", path: require("@/Common/Icons/USDT.png") },
      { name: "ADA", path: require("@/Common/Icons/ADA.png") },
      { name: "USDC", path: require("@/Common/Icons/USDC.png") },
      { name: "BTC", path: require("@/Common/Icons/BTC.png") },
      { name: "BTCB", path: require("@/Common/Icons/BTC.png") },
      { name: "DAI", path: require("@/Common/Icons/DAI.png") },
      { name: "WMATIC", path: require("@/Common/Icons/polygon_small.png") },
    ];
    return {
      logos,
      uniswapDappSharedLogicContext,
      eventBus,
      congratsModalText,
      congratsModalButtonText,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/exchangeTokens.scss"></style>

import { ethers } from "ethers";

// Number must be string
export const parseEther = (number) => {
  let decimalsNo;

  const afterE = number.split("e")[1];
  if (afterE) {
    decimalsNo = afterE.replace("-", "");
    decimalsNo = decimalsNo.replace("+", "");
  }
  if (decimalsNo)
    return ethers.utils.parseEther(
      Number(number).toFixed(Number(decimalsNo)).toString()
    );

  return ethers.utils.parseEther(number);
};

<template>
  <app-modal
    showCancel
    :closeButton="true"
    :closeModal="closePreviewModal"
    :returnToPreviousModal="closePreviewModal"
  >
    <template v-slot:app-modal-header>
      <img
        alt="TheosLogo"
        class="app-modal-list-logo"
        src="@/Common/Icons/TheosLogoYellow.svg"
      />
      <h3 class="app-modal-list-title">PREVIEW GIFTING</h3>
      <p class="app-modal-list-text">
        This action cannot be undone. Are you sure you want to gift your NFT?
      </p>
    </template>
    <template v-slot:app-modal-body>
      <img class="nft-image" :src="nftCoverImage" alt="Your NFT" />
      <div class="address-text">You are gifting {{ nft.name }} to:</div>
      <div class="address-subtext">{{ shortGiftingAddress }}</div>
    </template>
    <template v-slot:app-modal-footer>
      <app-button
        text="Confirm"
        style="margin-bottom: 16px"
        @click.prevent="giftNft()"
        buttonClass="app-modal-button-inverse"
      />
      <app-button
        text="Cancel"
        class="app-modal-button"
        @click="closePreviewModal()"
      />
    </template>
  </app-modal>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted, onUnmounted } from "vue";
import AppModal from "@/elements/AppModal";
import AppButton from "@/elements/AppButton";
import useGiftNft from "../../composables/useGiftNft";
import imageTransformer from "@/plugins/imageTransformer";

export default {
  name: "PreviewGiftingModal",
  components: {
    AppModal,
    AppButton,
  },
  setup() {
    const store = useStore();
    const { nft, closePreviewModal, giftNft, shortGiftingAddress } =
      useGiftNft(store);

    const nftCoverImage = computed(() =>
      imageTransformer.transform(
        nft.value.coverImage.filename,
        "nft.show.image"
      )
    );

    onMounted(() => document.body.classList.add("stop-scroll"));
    onUnmounted(() => document.body.classList.remove("stop-scroll"));
    return {
      nft,
      giftNft,
      nftCoverImage,
      shortGiftingAddress,
      closePreviewModal,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/giftNftModal.scss"></style>

<template>
  <div class="app-modal-overlay" v-if="bidFlag">
    <div class="overlay-blur" :style="backgroundImage" />
    <div class="app-modal-wrapper">
      <div
        @click="closeModal()"
        @keypress.enter.prevent
        class="app-modal-leave-modal"
      >
        <img src="@/Common/Icons/XmarkModal.png" alt="Logo" />
      </div>
      <div class="auction-modal-left-panel">
        <div class="right-panel-wrapper">
          <div class="body body-top">
            <div class="panel-title">
              <span class="panel-title-text">AUCTIONED NFT</span>
            </div>
            <img
              alt="nft"
              class="auction-modal-image"
              :src="coverImage(nft.coverImage)"
            />
            <p class="nft-name">{{ nft.name }}</p>

            <div class="auction-details">
              <p class="sold-for">Minimum bid</p>
              <p class="sold-for-price">
                {{ numberHasMoreThanThreeDecimals(minimumBid) ? "~" : "" }}
                {{ toFixedIfNecessary(minimumBid, 6) }}
                {{ selectedToken?.token }}
                <span class="sold-for"> ≈ ${{ minimumBidUsd }} </span>
              </p>
            </div>
            <div class="auction-details">
              <p class="sold-for">Time remaining</p>
              <p class="sold-for-price">{{ remainingTime }} hr</p>
            </div>
            <div
              class="auction-details"
              v-if="
                selectedType === 'time-limited' && timedAuction.reservedPrice
              "
            >
              <p class="sold-for">The least you will receive</p>
              <p class="sold-for-price">
                {{ timedAuction.reservedPrice }} {{ selectedToken?.token }}
                <span class="sold-for"> ≈ ${{ reservedPriceUsd }} </span>
              </p>
            </div>

            <div
              class="auction-details"
              v-if="selectedType === 'fixed-price' && listingPrice"
            >
              <p class="sold-for">Your NFT will be sold for</p>
              H
              <p class="sold-for-price">
                {{ listingPrice }} {{ selectedToken?.token }}
                <span class="sold-for"> ≈ ${{ calculatedNftPriceUsd }} </span>
              </p>
            </div>
          </div>
          <div class="body body-bottom">
            <p class="theos-fee">THEOS fee free</p>

            <div class="info">
              <img
                alt="info"
                class="info-icon info-img"
                src="@/Common/Icons/InfoRoyaltiesIcon.png"
              />
              <div class="info-hover">
                When bidding you won't pay <br />
                any fee.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="auction-modal-center-panel">
        <last-check-bidder v-if="showLastCheckForBid && lastCheckFlag" />
        <bid-panel v-else />
      </div>
    </div>
  </div>
  <completing-modal
    v-if="bidInProgressFlag"
    title="PLACING BID..."
    text="Sign the transaction in your wallet and wait for the success pop up"
  />
</template>

<script>
import { useStore } from "vuex";
import { DateTime } from "luxon";
import BidPanel from "./BidPanel";
import { computed, ref } from "vue";
import useBid from "@/composables/useBid";
import LastCheckBidder from "./LastCheckBidder.vue";
import useUtilities from "@/composables/useUtilities";
import CompletingModal from "../ListNft/CompletingModal.vue";
import imageTransformer from "../../plugins/imageTransformer";
export default {
  name: "BidModal",
  components: {
    BidPanel,
    CompletingModal,
    LastCheckBidder,
  },
  setup() {
    let now = ref(new DateTime.now());
    const { toFixedIfNecessary, numberHasMoreThanThreeDecimals } = useUtilities();

    setInterval(function () {
      now.value = DateTime.now();
    }, 1000);

    const store = useStore();

    const closeModal = async () => {
      document.body.classList.remove("stop-scroll");
      toggleBidFlag();
      await resetState();
    };

    const remainingTime = computed(() => {
      const value = nft?.value?.auction?.endingTime;
      return DateTime.fromISO(value).diff(now.value).toFormat("dd:hh:mm:ss");
    });

    const coverImage = (coverImage) =>
      !!coverImage && !!coverImage.filename
        ? imageTransformer.transform(coverImage.filename, "auction.image")
        : null;

    const backgroundImage = computed(() => {
      return nft?.value?.coverImage?.path !== ""
        ? `background: linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)) , url("${imageTransformer.transform(
            nft?.value?.coverImage?.filename,
            "nft.background"
          )}") no-repeat center center/cover`
        : "";
    });

    const {
      nft,
      bidFlag,
      minimumBid,
      resetState,
      listingPrice,
      selectedType,
      timedAuction,
      lastCheckFlag,
      startPriceUsd,
      selectedToken,
      toggleBidFlag,
      minimumBidUsd,
      setSelectedType,
      reservedPriceUsd,
      bidInProgressFlag,
      calculatedOfferUsd,
      showLastCheckForBid,
      timedAuctionEndTime,
      calculatedNftPriceUsd,
    } = useBid(store);

    return {
      nft,
      bidFlag,
      minimumBid,
      closeModal,
      coverImage,
      timedAuction,
      listingPrice,
      selectedType,
      lastCheckFlag,
      remainingTime,
      selectedToken,
      startPriceUsd,
      toggleBidFlag,
      minimumBidUsd,
      setSelectedType,
      backgroundImage,
      reservedPriceUsd,
      bidInProgressFlag,
      toFixedIfNecessary,
      calculatedOfferUsd,
      timedAuctionEndTime,
      showLastCheckForBid,
      calculatedNftPriceUsd,
      numberHasMoreThanThreeDecimals,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/auctionModal.scss" />

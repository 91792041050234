<template>
  <div
    v-if="!isExternalNftsListLength && !loadingNfts"
    class="deposit-mint-new"
  >
    <p class="deposit-first-text-line">*sigh*</p>
    <p class="deposit-secondtext-line">
      We couldn’t find an NFT.<br />
      Try
      <span class="deposit-to-minting" @click="openMintingForm"
        >minting one.</span
      >
    </p>
  </div>
  <div class="deposit-body-content" v-if="isExternalNftsListLength">
    <div v-for="(card, index) in externalNftsToShowList" :key="index">
      <nft-submit-card
        :token="card"
        :class="{ 'selected-nft': index === selected }"
        @click="selectNft(card, index)"
      ></nft-submit-card>
    </div>
  </div>
  <app-loader v-if="loadingNfts" :isGreen="true"></app-loader>
  <load-more
    v-if="externalNftsList.hasNextPage && !loadingNfts"
    :loadMore="loadMoreExternal"
    currentPanel=""
  />
  <div class="next-wrapper">
    <div class="deposit-next-gradient"></div>
    <div class="deposit-next" v-if="isExternalNftsListLength && !loadingNfts">
      <app-button
        text="Next"
        buttonClass="app-modal-button-inverse"
        class="deposit-next-button"
        :disabled="!selectedNft"
        @click="commitToStore()"
      ></app-button>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted, ref, computed } from "vue";
import NftSubmitCard from "@/components/NftSubmitCard";
import AppButton from "@/elements/AppButton";
import AppLoader from "@/elements/AppLoader";
import useRouter from "@/composables/useRouter";
import useDeposit from "@/composables/useDeposit";
import LoadMore from "@/components/Profile/Panels/LoadMore";
export default {
  components: {
    LoadMore,
    AppButton,
    AppLoader,
    NftSubmitCard,
  },
  setup() {
    const store = useStore();
    const selectedNft = ref(null);
    const selected = ref("");

    const {
      externalNftsList,
      externalNftDataList,
      loadMoreExternal,
      params,
      loadingNfts,
      toggleFilter,
      updateChosenNft,
    } = useDeposit(store);

    const { goTo } = useRouter();
    const openMintingForm = () => {
      store.dispatch("minting/resetState");
      store.dispatch("tagsValues/resetState");
      goTo({ name: "Minting" });
    };
    const selectNft = (nft, index) => {
      selectedNft.value = nft;
      selected.value = index;
    };
    const commitToStore = () => {
      updateChosenNft(selectedNft.value);
    };
    const isExternalNftsListLength = computed(() =>
      externalNftsList.value.data?.length > 0 ? true : false
    );
    const externalNftsToShowList = computed(() =>
      [...externalNftDataList.value].splice(0, 6)
    );
    onMounted(() => {
      store.dispatch("deposit/clearExternalNftList");
      toggleFilter("filterNewest");
    });

    return {
      externalNftsList,
      externalNftDataList,
      externalNftsToShowList,
      openMintingForm,
      loadMoreExternal,
      params,
      selectedNft,
      selected,
      loadingNfts,
      selectNft,
      commitToStore,
      isExternalNftsListLength,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/chooseToDepositNft.scss"></style>

<template>
  <base-form
    style="width: 100%; height: 100%"
    id="list-nft"
    method="post"
    :handleSubmit="confirmNftListing"
    :schema="nftListSchema"
  >
    <div class="fixed-panel-wrapper">
      <last-check-lister v-if="showLastCheckForAuction && lastCheckFlag" />
      <set-auction-panel v-else />
    </div>
  </base-form>
</template>

<script>
import { useStore } from "vuex";
import BaseForm from "@/components/BaseForm.vue";
import useListNft from "@/composables/useListNft";
import LastCheckLister from "./LastCheckLister";
import SetAuctionPanel from "./SetAuctionPanel";
export default {
  name: "FixedPricePanel",
  components: {
    BaseForm,
    SetAuctionPanel,
    LastCheckLister,
  },
  setup() {
    const { nft, showLastCheckForAuction, lastCheckFlag, showLisitingSpinner } =
      useListNft(useStore());

    return {
      nft,
      lastCheckFlag,
      showLisitingSpinner,
      showLastCheckForAuction,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/auctionModal.scss"></style>

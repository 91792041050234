<template>
  <div class="submission-prewiew-wrapper">
    <div class="mint-nft-background" :style="backgroundImage" />
    <div class="submision-header-text">
      <span class="preview-text">PREVIEW</span>
      <span class="your-submission-text">YOUR SUBMISSION</span>
    </div>

    <div class="submission-nft-pools">
      <div class="nft-wrap">
        <div class="submission-nft">
          <div class="card">
            <div class="avatar-img-wrapper">
              <img
                alt="Thumbnail"
                class="app-card-image"
                :src="coverImage(chosenNft)"
              />
            </div>
            <div class="app-card-content">
              <div class="app-card-content-description">
                <p class="app-card-title">{{ chosenNft?.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="submission-pools">
        <div class="info-submission-text">
          You will submit your NFTs into these pools
        </div>
        <div
          :key="index"
          class="chosen-pool-list"
          v-for="(pool, index) in chosenPools"
        >
          <div class="pool-image">
            <img :src="pool?.coverImage.thumbnail" />
          </div>
          <div class="pool-data">
            <div class="pool-name">{{ pool?.name }}</div>
            <div class="pool-base-price">
              {{ toFixedIfNecessary(pool?.basePrice, 2) }}
              {{ pool?.tokenSymbol }}
              <span class="pool-base-price-dark">
                ≈ ${{
                  toFixedIfNecessary(
                    poolGalleryStats?.find((item) => item.id === pool?.id)
                      ?.basePriceUSD,
                    2
                  )
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-box-buttons-controls submission-buttons">
      <app-button
        text="Back"
        class="app-modal-button"
        @click="decrementStep()"
      />
      <app-button
        text="Submit NFT"
        @click="submitNftToPool()"
        class="app-modal-button-inverse"
      />
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, onMounted } from "vue";
import AppButton from "@/elements/AppButton";
import useDeposit from "@/composables/useDeposit";
import useUtilities from "@/composables/useUtilities";
import imageTransformer from "../../plugins/imageTransformer";
import usePoolsGallery from "@/composables/usePoolsGallery";
export default {
  components: {
    AppButton,
  },
  setup() {
    const store = useStore();

    const {
      userId,
      chosenNft,
      chosenPools,
      theosMinted,
      decrementStep,
      submissionToPool,
    } = useDeposit(store);

    const { toFixedIfNecessary } = useUtilities();
    const { poolGalleryStats } = usePoolsGallery(store);

    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    const submitNftToPool = () => {
      let poolsArray = chosenPools.value.map((val) => val.id);
      submissionToPool(userId.value, poolsArray, chosenNft.value.id);
    };

    const coverImage = (token) => {
      if (theosMinted.value)
        return token?.coverImage && token?.coverImage?.filename
          ? imageTransformer.transform(token.coverImage.filename, "nft.list")
          : null;
      return token.coverImageURL;
    };

    const backgroundImage = computed(() => {
      return chosenNft.value.coverImage !== ""
        ? `background: linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)) , url("${imageTransformer.transform(
            chosenNft.value.coverImage.filename,
            "nft.background"
          )}") no-repeat center center/cover`
        : "";
    });

    onMounted(async () => {
      scrollToTop();
    });

    return {
      chosenNft,
      coverImage,
      theosMinted,
      chosenPools,
      decrementStep,
      backgroundImage,
      submitNftToPool,
      poolGalleryStats,

      toFixedIfNecessary,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/depositSubmissionPreview.scss" />

import { computed, reactive } from "vue";
import { ethereumService } from "../main";
import useToast from "./useToast";
import { useRouter } from "vue-router";
import useNetworkData from "./useNetworkData";

const buyData = reactive({
  price: 0,
  nftId: "",
});

const useBuyNftModal = (store) => {
  const router = useRouter();
  const { showToast } = useToast();
  const { isNftOnCurrentNetwork } = useNetworkData(ethereumService);
  const buyFlag = computed(() => store.getters["buyNft/getByKey"]("buyFlag"));
  const acceptOfferFlag = computed(() =>
    store.getters["buyNft/getByKey"]("acceptOfferFlag")
  );
  const toggleBuyFlag = () => store.dispatch("buyNft/toggleBuyFlag");
  const toggleAcceptOfferFlag = (nft) => {
    if (isNftOnCurrentNetwork(nft)) {
      return store.dispatch("buyNft/toggleAcceptOfferFlag");
    }
    return store.dispatch("auth/commitByKey", {
      switchNetworkModalFlag: true,
    });
  };
  const nft = computed(() => store.getters["buyNft/getByKey"]("nft"));
  const pool = computed(() => store.getters["buyNft/getByKey"]("pool"));
  const currentStep = computed(() =>
    store.getters["buyNft/getByKey"]("currentStep")
  );
  const currentStepAcceptOffer = computed(() =>
    store.getters["buyNft/getByKey"]("currentStepAcceptOffer")
  );
  const currentStepsArray = computed(() =>
    store.getters["buyNft/getByKey"]("currentStepsArray")
  );
  const currentStepsArrayAcceptOffer = computed(() =>
    store.getters["buyNft/getByKey"]("currentStepsArrayAcceptOffer")
  );
  const commitToStore = (data) => {
    store.dispatch("buyNft/commitByKey", data);
  };
  const resetState = () => store.dispatch("buyNft/resetState");
  const incrementStep = () => {
    store.dispatch("buyNft/incrementStep");
  };
  const incrementStepAcceptOffer = () => {
    store.dispatch("buyNft/incrementStepAcceptOffer");
  };
  const decrementStepAcceptOffer = () => {
    store.dispatch("buyNft/decrementStepAcceptOffer");
  };
  const closeModal = () => {
    store.dispatch("buyNft/toggleAcceptOfferFlag");
    toggleBuyFlag();
    resetState();
  };

  const decrementStep = () => store.dispatch("buyNft/decrementStep");

  const buyNft = async () => {
    let withdrawalTransaction = null;
    incrementStep();
    try {
      await ethereumService.allowIndexPoolToken(
        pool.value.tokenAddress,
        pool.value.ethAddress
      );
      const data = {
        contractAddress: nft.value.contractAddress,
        poolAddress: pool.value.ethAddress,
        contractId: nft.value.contractId,
      };
      withdrawalTransaction = await store.dispatch(
        "contracts/withdrawNft",
        data
      );
      await withdrawalTransaction.wait();
    } catch (error) {
      await showToast("MetaMask error", "NFT was not purchased", "Error");
      return closeModal();
    }

    await store.dispatch("nftDetails/fetchNft", nft.value.id);
    closeModal();
    return await router.push({
      name: "NftDetails",
      params: { id: nft.value.id },
    });
  };
  return {
    nft,
    pool,
    buyNft,
    buyFlag,
    buyData,
    closeModal,
    resetState,
    currentStep,
    toggleBuyFlag,
    commitToStore,
    decrementStep,
    incrementStep,
    acceptOfferFlag,
    currentStepsArray,
    toggleAcceptOfferFlag,
    currentStepAcceptOffer,
    incrementStepAcceptOffer,
    decrementStepAcceptOffer,
    currentStepsArrayAcceptOffer,
  };
};

export default useBuyNftModal;

<template>
  <div class="profile">
    <div class="profile-cover-image" />
    <div class="profile-content">
      <div class="profile-description">
        <profile-description :user="publicUser" :isPublic="true" />
      </div>
      <div class="profile-data">
        <div class="profile-panels">
          <profile-panels :isPublic="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted } from "vue";
import useProfileView from "@/composables/useProfileView";
import ProfilePanels from "@/components/Profile/ProfilePanels";
import ProfileDescription from "@/components/Profile/ProfileDescription";
import { useRoute } from "vue-router";
import useUserProfile from "@/composables/useUserProfile";

export default {
  components: {
    ProfilePanels,
    ProfileDescription,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const { publicUser } = useUserProfile(store);
    const { initializePanels } = useProfileView(store);

    onMounted(async () => {
      await store.dispatch("userProfile/clearPublicUser");
      await store.dispatch("userProfile/fetchPublicUser", route.params.id);
      await initializePanels(true);
    });
    return {
      publicUser,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/profile.scss"></style>

<template>
  <div class="avatar-img-wrapper-external" v-if="external">
    <img
      alt="Thumbnail"
      class="app-card-image-external"
      :src="require('@/Common/Icons/cloud.svg')"
    />
    <app-button
      text="Load NFT"
      v-if="!isLoading"
      @click="loadNFT()"
      class="load-more-button pointer"
    />
    <div class="load-more-button">
      <app-loader :show="true" :isGreen="true" is-small="true" />
      Loading...
    </div>
    <div class="load-nft-text" v-show="!isLoading">
      Load NFT to use it on THEOS
    </div>
  </div>
  <div
    class="avatar-img-wrapper"
    :style="isBig ? 'min-height: 80%' : ''"
    v-else
  >
    <img :src="coverImage(token)" alt="Thumbnail" class="app-card-image" />
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import AppButton from "@/elements/AppButton";
import AppLoader from "@/elements/AppLoader";
import useProfileView from "@/composables/useProfileView";
import imageTransformer from "@/plugins/imageTransformer";
export default {
  components: { AppButton, AppLoader },
  props: {
    external: {
      type: Boolean,
      default: false,
    },
    token: {
      type: Object,
      default: () => {},
    },
    isBig: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    //TODO: implement me!
    const loadNFT = () => {
      isLoading.value = true;
    };

    const isLoading = ref(false);
    const showPopup = ref(false);

    const { theosMinted } = useProfileView(useStore());

    const coverImage = (token) => {
      if (theosMinted.value || !props.external)
        return token?.coverImage && token?.coverImage?.filename
          ? imageTransformer.transform(token.coverImage.filename, "nft.list")
          : null;
      return token.coverImageURL;
    };

    return {
      loadNFT,
      showPopup,
      isLoading,
      coverImage,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/nftCard.scss" />

<template>
  <div class="requirements-card" v-show="meetsRequirement != null">
    <div class="requirements-check">
      <img v-if="meetsRequirement" :src="require(`@/Common/Icons/Check.svg`)" />
      <img v-else :src="require(`@/Common/Icons/Cross.svg`)" />
    </div>
    <div class="requirements-title">
      <div class="background-text">{{ backgroundTitle }}</div>
      <div class="main-title">{{ title }}</div>
    </div>
    <div class="requirements-description" v-html="description"></div>
    <div v-show="link != null" class="requirements-description">
      You can
      <a :href="link" class="requirements-link">calculate the amount here</a>
    </div>
    <div class="requirements-info" v-if="infoText">
      <div class="info-icon">
        <img
          src="@/Common/Icons/InfoIcon.png"
          alt="Info"
          @mouseover="showInfoBox()"
          @mouseleave="hideInfoBox()"
        />
      </div>
      <div
        class="info-text"
        v-html="infoText"
        v-if="showInfo"
        @mouseover="showInfoBox()"
        @mouseleave="hideInfoBox()"
      ></div>
    </div>
    <div class="placeholder" v-else></div>
    <app-button
      v-show="!meetsRequirement"
      :text="buttonText"
      buttonClass="requirements-button"
      @click="buttonFunction()"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import AppButton from "@/elements/AppButton";
export default {
  props: {
    title: {
      type: String,
      default: "Here goes the title",
    },
    backgroundTitle: {
      type: String,
      default: "BACKGROUND",
    },
    description: {
      type: String,
      default: "Here goes a short description",
    },
    infoText: {
      type: String,
    },
    meetsRequirement: {
      type: Boolean,
      default: null,
    },
    buttonText: {
      type: String,
      default: "",
    },
    buttonFunction: {
      type: Function,
      default: () => {},
    },
    link: {
      type: String,
      default: null,
    },
  },
  components: {
    AppButton,
  },
  setup() {
    const showInfo = ref(false);
    const MILLISECONDS_BEFORE_CLOSING_INFO_BOX = 500;

    const initializeTimeout = () =>
      setTimeout(
        () => (showInfo.value = false),
        MILLISECONDS_BEFORE_CLOSING_INFO_BOX
      );

    let closeInfoBox = initializeTimeout();

    const showInfoBox = () => {
      clearTimeout(closeInfoBox);
      showInfo.value = true;
    };

    const hideInfoBox = () => {
      closeInfoBox = initializeTimeout();
    };

    return {
      showInfo,
      showInfoBox,
      hideInfoBox,
      closeInfoBox,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/poolRequirements.scss"></style>

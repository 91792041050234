<template>
  <div class="featured-pool" v-if="modelValue" @click="goToPool()">
    <div class="featured-pool-content">
      <div class="featured-pool-heading">
        <div class="featured-pool-heading-text">FEATURED POOL</div>
        <div class="featured-pool-heading-icon">
          <img
            :src="require('@/Common/Icons/PoolListTableIcon.png')"
            @click="goToPool()"
            alt=""
          />
        </div>
      </div>
      <div class="featured-pool-divider"></div>
      <div class="featured-pool-details">
        <div class="image-pool-info">
          <div class="featured-pool-details-image">
            <img :src="modelValue.coverImage.path" alt="Pool" />
          </div>
          <div class="featured-pool-details-name">
            <div class="featured-pool-name">{{ modelValue.name }}</div>
            <div class="featured-pool-small-heading">by</div>
            {{ modelValue.originator.displayName }}
          </div>
        </div>
        <div class="featured-pool-details-column">
          <div class="featured-pool-small-heading">Base price</div>
          <div class="featured-pool-big-text">
            ${{ toFixedIfNecessary(stats.basePriceUSD, 2) }}
          </div>
          <div class="featured-pool-small-text">
            {{ toFixedIfNecessary(modelValue.basePrice, 4) }}
            {{ modelValue.tokenSymbol }}
          </div>
        </div>
        <div class="featured-pool-details-column">
          <div class="featured-pool-small-heading">Avg. daily trades</div>
          <div class="featured-pool-big-text">
            ${{ toFixedIfNecessary(stats.avgDailyTradesUSD, 2) }}
          </div>
          <!--          <div class="featured-pool-big-percentage">+3.12%</div>-->
        </div>
        <div class="featured-pool-details-column">
          <div class="featured-pool-small-heading">Total value locked</div>
          <div class="featured-pool-big-text">
            ${{ toFixedIfNecessary(stats.totalValueLockedUSD, 2) }}
          </div>
          <!--          <div class="featured-pool-big-percentage">+0.91%</div>-->
        </div>
        <div class="featured-pool-details-column">
          <div class="featured-pool-small-heading">
            Social cause contribution
          </div>
          <div class="featured-pool-big-text" v-if="modelValue.cause">
            {{ modelValue.contribution }}%
          </div>
          <div class="featured-pool-small-text" v-if="modelValue.cause">
            {{ modelValue.cause.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useRouter from "@/composables/useRouter";
import useUtilities from "@/composables/useUtilities";

export default {
  components: {},
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    stats: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { goTo } = useRouter();
    const { toFixedIfNecessary } = useUtilities();

    const goToPool = () => {
      goTo({ name: "ViewPool", params: { id: props.modelValue.id } });
    };
    return {
      goToPool,
      toFixedIfNecessary,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/featuredPool.scss"></style>

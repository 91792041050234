<template>
  <app-deposit-review
    :closeButton="true"
    :returnToPreviousModal="closeFunction"
  >
    <template v-slot:app-modal-header>
      <img
        :alt="nft.name"
        class="buy-nft-logo"
        src="@/Common/Icons/TheosLogoYellow.png"
      />
      <p class="buy-nft-title">Buy NFT</p>
      <img class="buy-modal-img" :src="nft.coverImage.path" alt="Logo" />
    </template>
    <template v-slot:app-modal-body>
      <p class="app-modal-body-text text-color">You are about to buy</p>
      <p class="app-modal-withdraw-price">
        <span class="nft-name"> {{ nft.name }}</span>
        <span class="price-color">by</span>
        <span class="pool-name"> {{ nft.currentOwner.displayName }} </span>
      </p>

      <p class="app-modal-body-text text-color">
        <span>The cost is</span>
      </p>
      <p class="app-modal-withdraw-price" style="margin-bottom: 0">
        <span class="color-primary">
          {{ toFixedIfNecessary(pool.basePrice, 4) }}
          {{ pool.tokenSymbol }}</span
        >
        <span class="price-color"> ${{ calculatePriceUsd() }} </span>
      </p>
      <p class="app-modal-withdraw-price" style="margin-bottom: 0px">
        <span>+</span>
      </p>
      <p class="app-modal-withdraw-price" style="margin-bottom: 2rem">
        <span>Gas fees</span>
      </p>
      <div class="button-wrapper-withdraw">
        <app-button
          @click="confirmWithdraw()"
          buttonClass="app-modal-button-inverse"
          text="Buy back"
        />
      </div>
      <span class="close-modal" @click="closeFunction"> Cancel </span>
    </template>
  </app-deposit-review>
</template>

<script>
import { useStore } from "vuex";
import AppButton from "@/elements/AppButton.vue";
import useWithdrawModal from "@/composables/useWithdrawModal";
import AppDepositReview from "@/elements/AppDepositReview";
import useUtilities from "@/composables/useUtilities";
import { ref, onMounted, onUnmounted } from "vue";

export default {
  name: "ConfirmWithdraw",
  props: {
    nft: {
      type: Object,
      default: () => {},
    },
    closeFunction: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    AppDepositReview,
    AppButton,
  },
  setup(props) {
    const store = useStore();
    const { confirmWithdrawal } = useWithdrawModal(store);
    const { toFixedIfNecessary } = useUtilities();

    const pool = ref({});
    onMounted(() => {
      pool.value = props.nft.pools[0];
      document.body.classList.add("stop-scroll");
    });
    onUnmounted(() => document.body.classList.remove("stop-scroll"));

    const currentValue = ref(0);
    const confirmWithdraw = () => {
      confirmWithdrawal(props.nft);
    };

    const calculatePriceUsd = () => {
      return toFixedIfNecessary(
        Number(props.nft.pools[0].basePrice) *
          Number(props.nft.pools[0].tokenPrice),
        2
      );
    };

    return {
      confirmWithdraw,
      currentValue,
      calculatePriceUsd,
      toFixedIfNecessary,
      pool,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/withdrawReview.scss"></style>

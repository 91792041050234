import { ref } from "vue";
import useUtilities from "./useUtilities";
import useStoreFunctions from "@/composables/useStoreFunctions";
// import useExchangeTokens from "@/composables/useExchangeTokens";

const insufficientFundsModalFlag = ref(false);

const useInsufficientFunds = (ethereumService) => {
  const { commitStore: commitFunds, computeStore: computeFunds } =
    useStoreFunctions("checkFunds");
  const { selectTokenFromList, parseTokenList } = useUtilities();

  const tokenToBuy = computeFunds("tokenToBuy");
  const bidValue = computeFunds("bidValue");
  const userBalance = computeFunds("userBalance");

  const toggleInsufficientFundsModal = async (
    token,
    userBalance = null,
    bidValue = null
  ) => {
    await commitFunds("setTokenToBuy", token);
    await commitFunds("setUserBalance", userBalance);
    await commitFunds("setBidValue", bidValue);
    insufficientFundsModalFlag.value = !insufficientFundsModalFlag.value;
    if (!insufficientFundsModalFlag.value) commitFunds("resetState");
  };

  const fetchBalance = async (tokenAddress, userAddress) =>
    Number(await ethereumService.fetchUserBalance(tokenAddress, userAddress));

  const checkBalance = async (price, tokenAddress, address) => {
    const balance = await fetchBalance(tokenAddress, address);
    return balance >= price;
  };

  // const { openSwapModal } = useExchangeTokens(store);

  const redirectToSushiSwap = async (poolTokenAddress = null) => {
    insufficientFundsModalFlag.value = !insufficientFundsModalFlag.value;
    if (tokenToBuy.value.status === "auctioned") {
      const tokenOptions = await parseTokenList();
      const token = selectTokenFromList(
        tokenOptions,
        tokenToBuy.value.auction.token
      );
      poolTokenAddress = token.address;
    }
    if (!insufficientFundsModalFlag.value) commitFunds("resetState");
    // openSwapModal(ethereumService.theosTokenContractAddress, poolTokenAddress);
    const url = `https://app.sushi.com/swap?
    inputCurrency=${ethereumService.theosTokenContractAddress | "ETH"}
    &outputCurrency=${poolTokenAddress}`;
    return window.open(url, "_blank");
  };

  return {
    bidValue,
    tokenToBuy,
    userBalance,
    checkBalance,
    redirectToSushiSwap,
    insufficientFundsModalFlag,
    toggleInsufficientFundsModal,
  };
};

export default useInsufficientFunds;

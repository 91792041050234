<template>
  <button
    :style="buttonStyle"
    :disabled="disabled"
    :class="[buttonClass, disabled ? disabledClass : '']"
  >
    <i v-if="iconClass" :class="iconClass" :style="iconStyle"></i>
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "AppButton",
  props: {
    iconClass: {
      type: String,
      default: "",
    },
    text: String,
    buttonClass: {
      type: String,
      default: "",
    },
    disabledClass: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    buttonStyle: {
      type: Object,
      default: () => {},
    },
    iconStyle: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<template>
  <div v-if="buyFlag">
    <teleport to="body">
      <component
        :nft="nft"
        :is="currentStepsArray[currentStep - 1].component"
      />
    </teleport>
  </div>
</template>

<script>
import { useStore } from "vuex";
import BuyNft from "@/components/BuyNft/BuyNft";
import Completing from "@/components/BuyNft/Completing";
import useBuyNftModal from "@/composables/useBuyNftModal";
export default {
  components: {
    BuyNft,
    Completing,
  },
  setup() {
    const store = useStore();
    const { buyFlag, currentStep, currentStepsArray, nft } =
      useBuyNftModal(store);

    return {
      nft,
      buyFlag,
      currentStep,
      currentStepsArray,
    };
  },
};
</script>

<style lang="scss" scoped />

<template>
  <secondary-actions :nft="nft" />
  <div class="nft-media-wrapper">
    <app-loader
      :isGreen="true"
      :show="!isLoaded"
      class="app-loader"
    ></app-loader>
    <app-pdf
      v-if="nft.file.mimeType.includes('pdf')"
      :url="nft.file.path"
      :cover="nftCoverImage"
      class="nft-pdf"
    />
    <div class="media-container">
      <div
        id="image"
        @fullscreenchange="fullScreenChange()"
        :class="{ fullscreen: pdfFullscreen === true }"
      >
        <img
          class="nft-image"
          v-if="nft.file.mimeType.includes('image')"
          @load="loadImage()"
          :src="nftCoverImage"
        />
        <img
          v-if="nft.file.mimeType.includes('image') && !isFullScreen"
          :src="fullscreenImg"
          @mouseover="
            fullscreenImg = require('@/Common/Icons/fullscreenLight.png')
          "
          @mouseleave="
            fullscreenImg = require('@/Common/Icons/fullscreenDark.png')
          "
          alt="fullScreen"
          @click="toggleFullscreen()"
          class="fullscreen-button pointer"
          :class="{ fullscreen: pdfFullscreen === true }"
          :style="
            nft.file.mimeType.includes('video')
              ? 'margin-top: auto; margin-bottom: 3px;'
              : ''
          "
        />
        <img
          v-if="nft.file.mimeType.includes('image') && isFullScreen"
          :src="exitFullscreenImg"
          @mouseover="
            exitFullscreenImg = require('@/Common/Icons/exitFullscreenLight.png')
          "
          @mouseleave="
            exitFullscreenImg = require('@/Common/Icons/exitFullscreenDark.png')
          "
          alt="fullScreen"
          @click="toggleFullscreen()"
          class="fullscreen-button pointer"
          :class="{ fullscreen: pdfFullscreen === true }"
          :style="
            nft.file.mimeType.includes('video')
              ? 'margin-top: auto; margin-bottom: 3px;'
              : ''
          "
        />
      </div>
      <div class="buttons-container" id="buttons">
        <app-audio
          v-if="nft.file.mimeType.includes('audio') && nftCoverImage"
          :url="nft.file.path"
          @load="isLoaded = !isLoaded"
          class="nft-audio"
          :autoPlay="true"
          :forNftDetail="true"
          :coverImage="nftCoverImage"
        />
        <app-video
          v-if="nft.file.mimeType.includes('video')"
          :url="nftVideo"
          :type="nft.file.mimeType"
          @load="isLoaded = !isLoaded"
          class="nft-video"
          :autoPlay="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/main";
import { computed, ref } from "vue";
import AppPdf from "@/components/AppPdf";
import AppAudio from "@/components/AppAudio";
import AppVideo from "@/components/AppVideo";
import AppLoader from "@/elements/AppLoader";
import imageTransformer from "@/plugins/imageTransformer";
import SecondaryActions from "./Sections/SecondaryActions";
import videoTransformer from "@/plugins/videoTransformer";

export default {
  components: {
    AppPdf,
    AppAudio,
    AppVideo,
    AppLoader,
    SecondaryActions,
  },
  props: {
    nft: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const isLoaded = ref(false);
    const pdfFullscreen = ref(false);
    const fullscreenImg = ref(require("@/Common/Icons/fullscreenDark.png"));
    const exitFullscreenImg = ref(
      require("@/Common/Icons/exitFullscreenDark.png")
    );
    const nftCoverImage = computed(() =>
      imageTransformer.transform(
        props.nft.coverImage.filename,
        "nft.show.image"
      )
    );
    const nftVideo = computed(() =>
      videoTransformer.transformVideo(props.nft.file.filename, "nft.show.video")
    );
    eventBus.on("setLoad", () => {
      isLoaded.value = !isLoaded.value;
    });
    const isFullScreen = ref(false);
    const toggleFullscreen = () => {
      if (!document.fullscreenElement) {
        isFullScreen.value = true;
        document.getElementById("image").requestFullscreen();
      }

      if (document.fullscreenElement === document.getElementById("image")) {
        isFullScreen.value = false;
        document.exitFullscreen();
      }
    };

    const loadImage = () => {
      isLoaded.value = !isLoaded.value;
    };

    const fullScreenChange = async () => {
      if (pdfFullscreen.value === false) {
        pdfFullscreen.value = true;
        return;
      }
      pdfFullscreen.value = false;
    };
    return {
      isLoaded,
      loadImage,
      isFullScreen,
      fullscreenImg,
      pdfFullscreen,
      nftCoverImage,
      toggleFullscreen,
      fullScreenChange,
      exitFullscreenImg,
      nftVideo,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/nftMedia.scss"></style>

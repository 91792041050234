<template>
  <div v-if="walletConnectionRequired">
    <teleport to="body">
      <connect-wallet-modal />
    </teleport>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ethereumService } from "@/main";
import useAuth from "@/composables/useAuth";
import ConnectWalletModal from "@/components/ConnectWalletModal";
export default {
  components: {
    ConnectWalletModal,
  },
  setup() {
    const { walletConnectionRequired } = useAuth(useStore(), ethereumService);
    return {
      walletConnectionRequired,
    };
  },
};
</script>

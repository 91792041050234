<template>
  <div :class="[componentClass]" class="app-input-with-select">
    <div class="royalties-icon-wrap">
      <div class="label-switch-wrapper">
        <div :class="labelClass">
          <label :for="id">{{ labelText }}</label>
          <div v-if="infoText" class="royalties-info">
            <img
              class="royalties-icon royalties-img"
              src="@/Common/Icons/InfoRoyaltiesIcon.png"
            />
            <div class="royalties-hover" v-html="infoText"></div>
          </div>
        </div>

        <div v-if="enableToggle" class="enabled-switch">
          <img
            alt="Switch"
            name="tokenSwitch"
            :src="switchStateImage"
            @click="toggleSwitchState()"
          />
        </div>
      </div>
    </div>
    <div :class="inputIcons">
      <i
        v-if="inputIconLeftClass"
        :class="inputIconLeftClass"
        :style="inputIconLeftStyle"
      ></i>
      <i
        v-if="inputIconRightClass"
        :class="inputIconRightClass"
        :style="inputIconRightStyle"
      ></i>
      <div class="input-wrapper" @click="enableInput">
        <p class="floating-text" v-if="floatingText">
          {{ floatingText }}
        </p>
        <p class="floating-text-left" v-if="floatingTextLeft">
          {{ floatingTextLeft }}
        </p>
        <div
          class="form-box-input-with-select-wrapper"
          :class="[
            disabled ? disabledClass : '',
            errorMessage ? 'error-message-border' : '',
          ]"
        >
          <div
            class="select-wrapper"
            :class="disabled || !enableSelect ? 'select-disabled' : ''"
            @click="toggle"
          >
            <div class="selected-token">
              <div class="logo-token">
                <img :src="selectOptionLogo(selectedToken)" alt="" />
                <span class="token-name">{{ selectedToken?.token }}</span>
              </div>
              <i v-if="!disabled && enableSelect" class="dropdown-icon"></i>
            </div>
            <div
              class="filtering-options"
              v-if="showFilters"
              v-click-outside.stop="toggle"
            >
              <div
                v-for="(option, index) in selectOptions.value"
                :key="index"
                class="filtering-option"
                @click="updateSelect(option)"
              >
                <img
                  class="filtering-option-img"
                  :src="selectOptionLogo(option)"
                  alt=""
                />
                <span>{{ option.token }}</span>
              </div>
            </div>
          </div>
          <input
            ref="someInput"
            :id="id"
            :name="name"
            v-bind="$attrs"
            :style="inputStyle"
            :value="inputValue"
            :disabled="!selectedToken || disabled"
            :placeholder="placeholder"
            @focusout="updateValue($event.target.value)"
            @input="updateValue($event.target.value)"
            @keydown.enter.prevent="updateValue($event.target.value)"
            :class="[
              inputClass,
              floatingTextLeft ? 'float-text-left-input' : '',
            ]"
          />
        </div>
      </div>
      <div style="display: flex; justify-content: space-between">
        <div
          v-if="bottomLabel"
          v-show="!errorMessage || meta.valid"
          class="description-num-chars"
          :class="disabled ? disabledClass : ''"
        >
          {{ bottomLabel }}
        </div>
        <div
          class="description-num-chars validation-error"
          :class="messageClass"
          v-show="errorMessage || !meta.valid"
        >
          {{ errorMessage }}
        </div>
      </div>
      <div class="percents-box" v-if="suggestions">
        <p>20% maximum. Suggestions:</p>

        <div v-for="(percent, index) in percents" :key="index" class="percents">
          <div>{{ percent.text }},</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import vClickOutside from "click-outside-vue3";
import { toRefs, watch, ref, computed, onMounted, onUnmounted } from "vue";
import { nextTick } from "process";
import useInputErrors from "../composables/useInputErrors";
import { useStore } from "vuex";

export default {
  name: "AppInputWithSelect",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    id: {
      type: String,
    },
    successMessage: {
      type: String,
      default: "",
    },
    name: {
      type: String,
    },
    disabledFlag: {
      type: Boolean,
      default: false,
    },
    disabledClass: {
      type: String,
      default: "",
    },
    labelText: {
      type: String,
      default: "",
    },
    selectedToken: {
      type: [String, Number],
      default: "",
    },
    inputValue: {
      type: [String, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    inputClass: {
      type: String,
      default: "",
    },
    componentClass: {
      type: String,
      default: "",
    },
    inputStyle: {
      type: Object,
      default: () => {},
    },
    inputIconLeftClass: {
      type: String,
      default: "",
    },
    inputIconLeftStyle: {
      type: Object,
      default: () => {},
    },
    inputIconRightClass: {
      type: Object,
      default: () => {},
    },
    inputIconRightStyle: {
      type: Object,
      default: () => {},
    },
    inputIcons: {
      type: String,
      default: "",
    },
    messageClass: {
      type: String,
      default: "",
    },
    suggestions: {
      type: Boolean,
      default: false,
    },
    bottomLabel: {
      type: String,
      default: "",
    },
    infoText: {
      type: String,
      default: "",
    },
    percents: {
      type: Array,
      default: () => [],
    },
    validationFunction: {
      type: Function,
      default: () => {},
    },
    floatingText: {
      type: String,
      default: "",
    },
    floatingTextLeft: {
      type: String,
      default: "",
    },
    selectOptions: {
      type: Array,
      default: () => [],
    },
    updateValue: {
      type: Function,
      default: () => {},
    },
    updateSelect: {
      type: Function,
      default: () => {},
    },
    enableToggle: {
      type: Boolean,
      default: false,
    },
    enableSelect: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { watchErrorMessage } = useInputErrors(useStore());
    let listNftWatcher, placeBidWatcher;

    const someInput = ref(null);

    const {
      value: inputVal,
      errorMessage,
      meta,
    } = useField(props.name, props.validationFunction() || undefined, {
      initialValue: props.inputValue,
    });

    let { inputValue } = toRefs(props);
    watch(
      () => inputValue,
      (inputValue) => {
        inputVal.value = inputValue.value;
      },
      { deep: true }
    );

    const showFilters = ref(false);
    const toggle = () => {
      if (disabled.value || !props.enableSelect) return;
      showFilters.value = !showFilters.value;
    };

    const disabled = computed(() => {
      return (
        props.disabledFlag ||
        (!props.disabledFlag && !switchState.value && props.enableToggle)
      );
    });

    const selectOptionLogo = (option) => {
      if (!option) return;
      return require(`@/Common/Icons/${option.logo}`);
    };

    const updateInput = (val) => {
      props.updateValue(val);
    };

    const switchState = ref(false);

    const switchStateImage = computed(() =>
      switchState.value === true
        ? require("@/Common/Icons/SwitchOn.png")
        : require("@/Common/Icons/SwitchOff.png")
    );

    const toggleSwitchState = () => {
      switchState.value = !switchState.value;
      if (!switchState.value) {
        props.updateValue(null);
      }
    };

    const enableInput = () => {
      switchState.value = true;
      nextTick(() => {
        someInput.value.focus();
      });
    };

    onMounted(() => {
      listNftWatcher = watchErrorMessage(errorMessage, "listNft");
      placeBidWatcher = watchErrorMessage(errorMessage, "bid");
    });
    onUnmounted(() => {
      listNftWatcher();
      placeBidWatcher();
    });

    return {
      meta,
      toggle,
      disabled,
      someInput,
      enableInput,
      showFilters,
      updateInput,
      errorMessage,
      switchStateImage,
      selectOptionLogo,
      toggleSwitchState,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/appInputWithSelect.scss" />

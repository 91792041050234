<template>
  <div class="toast-background">
    <div class="toast-wrapper">
      <div class="toast-icon">
        <img
          :src="require(`@/Common/Icons/${icon}.png`)"
          alt="Icon"
          class="icon-size"
        />
      </div>
      <div class="toast-content">
        <p class="toast-title" v-if="title">{{ title }}</p>
        <p class="toast-text" v-if="text">{{ text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppToast",
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/appToast.scss"></style>

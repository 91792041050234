<template>
  <div v-if="data.length" class="profile-panel-body-content">
    <div v-for="(card, index) in data" :key="index">
      <nft-card :token="card" :external="true" />
    </div>
  </div>
  <no-data
    v-else
    :title="'Nothing to see here yet'"
    textTop="You don't have NFTs on hold"
    :image="require('@/Common/Icons/cloud.svg')"
  />
</template>

<script>
import { useStore } from "vuex";
import NftCard from "@/components/NftCard";
import useRouter from "@/composables/useRouter";
import useProfilePanels from "@/composables/useProfilePanels";
import NoData from "@/components/Profile/Panels/NoData";
export default {
  components: {
    NftCard,
    NoData,
  },
  props: {
    data: {
      type: Object,
      default: () => ({ data: [], total: 0 }),
    },
  },
  setup() {
    const store = useStore();
    const { goTo } = useRouter();

    const {
      params,
      loadingNfts,
      loadMoreExternal,
      externalNftsList,
      externalNftDataList,
      externalNftsToShowList,
      profilePanelNoItemsText,
      profilePanelNoItemsTextLineTwo,
    } = useProfilePanels(store);

    const openMintingForm = () => {
      store.dispatch("minting/resetState");
      store.dispatch("tagsValues/resetState");
      goTo({ name: "Home" });
    };

    const goToNftDetails = (nftId) => {
      store.dispatch("nftDetails/resetState");
      goTo({ name: "NftDetails", params: { id: nftId } });
    };

    return {
      params,
      loadingNfts,
      goToNftDetails,
      openMintingForm,
      loadMoreExternal,
      externalNftsList,
      externalNftDataList,
      externalNftsToShowList,
      profilePanelNoItemsText,
      profilePanelNoItemsTextLineTwo,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/profilePanels.scss" />

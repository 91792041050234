<template>
  <div v-if="buyListedNftFlag">
    <teleport to="body">
      <component
        :nft="nft"
        :returnToPreviousModal="toggleBuyListedNftFlag"
        :is="currentStepsArray[currentStep - 1].component"
      />
    </teleport>
  </div>
</template>

<script>
import { useStore } from "vuex";
import useBuyListedNft from "@/composables/useBuyListedNft";
import BuyListedNftModal from "@/components/BuyListedNft/BuyListedNftModal";
import BuyListedNftCompletingModal from "@/components/BuyListedNft/BuyListedNftCompletingModal";
export default {
  name: "BuyListedNft",
  components: {
    BuyListedNftModal,
    BuyListedNftCompletingModal,
  },
  setup() {
    const store = useStore();

    const {
      nft,
      currentStep,
      buyListedNftFlag,
      currentStepsArray,
      toggleBuyListedNftFlag,
    } = useBuyListedNft(store);

    return {
      nft,
      currentStep,
      buyListedNftFlag,
      currentStepsArray,
      toggleBuyListedNftFlag,
    };
  },
};
</script>

<style lang="scss" scoped />

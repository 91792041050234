import { DateTime } from "luxon";
import useToast from "./useToast";
import { BigNumber } from "ethers";
import { randomInt } from "mathjs";
import { computed, ref } from "vue";
import { ethereumService } from "@/main";
import useNetworkData from "./useNetworkData";
import useUtilities from "@/composables/useUtilities";

const timedAuctionInit = {
  days: 1,
  hours: 0,
  startPrice: null,
  reservedPrice: null,
};

const timedAuction = ref({
  days: 1,
  hours: 0,
  startPrice: null,
  reservedPrice: null,
});

const lastCheckFlag = ref(false);
const showLisitingSpinner = ref(false);

const useListNft = (store) => {
  const { isNftOnCurrentNetwork } = useNetworkData(ethereumService);
  const { toFixedIfNecessary } = useUtilities();

  const user = computed(() => store.getters["userProfile/getUser"]);

  const selectedType = computed(() =>
    store.getters["listNft/getByKey"]("selectedType")
  );

  const listFlag = computed(() =>
    store.getters["listNft/getByKey"]("listFlag")
  );

  const nft = computed(() => store.getters["listNft/getByKey"]("nft"));

  const showLastCheckForAuction = computed(() =>
    store.getters["listNft/getByKey"]("showLastCheckForAuction")
  );

  const listNftErrors = computed(() =>
    store.getters["listNft/getByKey"]("errors")
  );

  const toggleListFlag = () => {
    store.dispatch("listNft/toggleListFlag");
  };

  const fetchNft = async (id) => {
    return await store.dispatch("listNft/fetchNft", id);
  };

  const listNft = async (id) => {
    const nft = await fetchNft(id);
    if (isNftOnCurrentNetwork(nft.data)) {
      return toggleListFlag();
    }
    return store.dispatch("auth/commitByKey", {
      switchNetworkModalFlag: true,
    });
  };

  const resetState = () => {
    timedAuction.value = timedAuctionInit;
    store.dispatch("listNft/resetState");
  };

  const setSelectedType = (type) => {
    if (type === "time-limited") {
      setSelectedToken(null);
    }
    store.dispatch("listNft/setByKey", {
      selectedType: type,
    });
  };

  const listingPrice = computed(() =>
    store.getters["listNft/getByKey"]("listingPrice")
  );

  const setListingPrice = (price) => {
    store.dispatch("listNft/commitByKey", {
      listingPrice: price,
    });
  };

  const selectedToken = computed(() =>
    store.getters["listNft/getByKey"]("selectedToken")
  );

  const setSelectedToken = (token) =>
    store.dispatch("listNft/commitByKey", {
      selectedToken: token,
    });

  const closeLastCheck = async (remember) => {
    if (remember) {
      localStorage.setItem("showLastCheckForAuction", false);
      store.dispatch("listNft/commitByKey", {
        showLastCheckForAuction: false,
      });
    }

    await confirmTimedAuction();
  };

  const { showToast } = useToast();

  const toggleHasSubmission = () =>
    store.dispatch("listNft/toggleHasSubmission");
  const hasSubmission = computed(() =>
    store.getters["listNft/getByKey"]("hasSubmission")
  );

  const checkNftSubmissions = async (id) =>
    await store.dispatch("listNft/checkNftSubmissions", id);

  const currentStep = computed(() =>
    store.getters["listNft/getByKey"]("currentStep")
  );
  const currentStepsArray = computed(() =>
    store.getters["listNft/getByKey"]("currentStepsArray")
  );
  const incrementStep = () => {
    store.dispatch("listNft/incrementStep");
  };

  const decrementStep = () => store.dispatch("listNft/decrementStep");

  const closeModal = () => {
    toggleListFlag();
    resetState();
  };

  const maticPriceUsd = computed(() =>
    store.getters["contracts/getCoinPrice"]("matic-network", "usd")
  );

  const ethPriceUsd = computed(() =>
    store.getters["contracts/getCoinPrice"]("ethereum", "usd")
  );

  const confirmNftListing = async () => {
    try {
      lastCheckFlag.value = false;
      await toggleListingSpinner();
      const nftId = nft.value.id;

      const approveTxResult = await ethereumService.approveNft(
        nft.value.contractId,
        nft.value.contractAddress,
        ethereumService.marketplaceContractAddress
      );

      if (approveTxResult.reason === "rejected") {
        await showToast("Rejected", "Transaction rejected", "Error");
        toggleListFlag();
        resetState();
        return false;
      }

      const listResult = await ethereumService.listNft(
        nft.value.contractAddress,
        nft.value.contractId,
        listingPrice.value
      );

      if (listResult.reason === "rejected") {
        await showToast("Rejected", "Transaction rejected", "Error");
        await toggleListingSpinner();
        toggleListFlag();
        resetState();
        return false;
      }
      await toggleListingSpinner();
      await showToast("Listed!", "NFT was listed", "Success");
      toggleListFlag();
      resetState();
      window.location.href = "/nft-details/" + nftId;
    } catch (error) {
      decrementStep();
      await showToast("MetaMask error", "NFT was not listed", "Error");
    }
  };

  const checkForExistingSubmissions = async () => {
    const submissionCount = await checkNftSubmissions(nft.value.id);

    if (submissionCount.data > 0) {
      await toggleHasSubmission();
      return;
    }

    if (selectedType.value === "time-limited") {
      await createTimedAuction();
    }

    if (selectedType.value === "fixed-price") {
      await confirmNftListing();
    }
  };

  const toggleListingSpinner = async () => {
    showLisitingSpinner.value = !showLisitingSpinner.value;
  };

  const backToReview = async () => {
    lastCheckFlag.value = false;
  };

  const createTimedAuction = async () => {
    if (showLastCheckForAuction.value) {
      lastCheckFlag.value = true;
      return;
    }
    await confirmTimedAuction();
  };

  const confirmTimedAuction = async () => {
    try {
      lastCheckFlag.value = false;
      await toggleListingSpinner();
      const salt = randomInt(1000000000);
      const saltBN = BigNumber.from(salt.toString());

      const approveTxResult = await ethereumService.approveNft(
        nft.value.contractId,
        nft.value.contractAddress,
        ethereumService.marketplaceContractAddress
      );

      if (approveTxResult.reason === "rejected") {
        await showToast("Rejected", "Transaction rejected", "Error");
        await toggleListingSpinner();
        toggleListFlag();
        resetState();
        return false;
      }

      const signature = await store.dispatch("listNft/signSellerData", {
        nftId: nft.value.contractId,
        nftAddress: nft.value.contractAddress,
        sellingToken: selectedToken.value.address,
        price: timedAuction.value.startPrice.toString(),
        salt: saltBN,
      });
      console.log(signature);
      if (signature === "rejected") {
        showToast("MetaMask error", "User denied message signature.", "Error");
        return;
      }

      const nftId = nft.value.id;
      const auctionData = {
        type: "time-limited",
        startingPrice: timedAuction.value.startPrice,
        token: selectedToken.value.token,
        expirationDate: timedAuctionEndTime.value.toUTC(),
        nftId: nft.value.id,
        auctionerId: user.value.id,
        signature: signature,
        salt,
      };
      if (timedAuction.value.reservedPrice) {
        auctionData.reservePrice = timedAuction.value.reservedPrice;
      }
      await store.dispatch("listNft/createTimedAuction", auctionData);
      await toggleListingSpinner();
      toggleListFlag();
      resetState();
      window.location.href = "/nft-details/" + nftId;
    } catch (error) {
      console.log(error);
      showToast("MetaMask error", "NFT was not listed", "Error");
    }
  };

  const calculatedNftPriceUsd = computed(() => {
    if (["polygon", "mumbai"].includes(nft.value.network)) {
      return toFixedIfNecessary(
        +listingPrice.value * maticPriceUsd.value.value,
        2
      );
    }
    return toFixedIfNecessary(+listingPrice.value * ethPriceUsd.value.value, 2);
  });

  const sellPriceUsd = computed(() => {
    return toFixedIfNecessary(
      calculatedNftPriceUsd.value - feeUsd.value - royaltiesUsd.value,
      2
    );
  });
  const feeUsd = computed(() =>
    toFixedIfNecessary(calculatedNftPriceUsd.value * 0.01, 2)
  );
  const feeMatic = computed(() =>
    toFixedIfNecessary(+listingPrice.value * 0.01, 3)
  );
  const royalties = computed(() => {
    return toFixedIfNecessary(
      ((nft.value.royaltyPercentage || 0) * +listingPrice.value) / 100,
      3
    );
  });
  const royaltiesUsd = computed(() =>
    toFixedIfNecessary(royalties.value * maticPriceUsd.value.value, 2)
  );

  const startPriceUsd = computed(() => {
    if (["polygon", "mumbai"].includes(nft.value.network)) {
      return toFixedIfNecessary(
        +timedAuction.value.startPrice * maticPriceUsd.value.value,
        2
      );
    }
    return toFixedIfNecessary(
      +timedAuction.value.startPrice * ethPriceUsd.value.value,
      2
    );
  });

  const reservedPriceUsd = computed(() => {
    return toFixedIfNecessary(
      +timedAuction.value.reservedPrice * maticPriceUsd.value.value,
      2
    );
  });

  const timedAuctionEndTime = computed(() => {
    return DateTime.local().plus({
      days:
        timedAuction.value.days && !isNaN(timedAuction.value.days)
          ? timedAuction.value.days
          : 0,
      hours:
        timedAuction.value.hours && !isNaN(timedAuction.value.days)
          ? timedAuction.value.hours
          : 0,
    });
  });

  const cancelAuction = async (nft) => {
    if (isNftOnCurrentNetwork(nft)) {
      await store.dispatch("auctions/auctionCancel", nft.auction.auctionId);
      return location.reload();
    }
    return store.dispatch("auth/commitByKey", {
      switchNetworkModalFlag: true,
    });
  };

  const sellToTopBidder = async (nft) => {
    await store.dispatch("auctions/auctionAccept", nft.auction.auctionId);
  };

  return {
    nft,
    feeUsd,
    listNft,
    fetchNft,
    feeMatic,
    listFlag,
    royalties,
    closeModal,
    resetState,
    currentStep,
    timedAuction,
    backToReview,
    royaltiesUsd,
    listingPrice,
    sellPriceUsd,
    selectedType,
    listNftErrors,
    lastCheckFlag,
    cancelAuction,
    startPriceUsd,
    selectedToken,
    incrementStep,
    decrementStep,
    hasSubmission,
    maticPriceUsd,
    closeLastCheck,
    toggleListFlag,
    setSelectedType,
    setListingPrice,
    sellToTopBidder,
    setSelectedToken,
    reservedPriceUsd,
    currentStepsArray,
    confirmNftListing,
    showLisitingSpinner,
    timedAuctionEndTime,
    confirmTimedAuction,
    toggleHasSubmission,
    checkNftSubmissions,
    calculatedNftPriceUsd,
    showLastCheckForAuction,
    checkForExistingSubmissions,
  };
};
export default useListNft;

<template>
  <div class="slider-buttons">
    <template v-for="(button, index) in controlButtonsList" :key="index">
      <app-button
        :text="button.text"
        @click="button.clickHandler"
        @mouseover="setCurrentSlide(index)"
        :buttonClass="
          isActive(index) ? 'app-modal-button-inverse' : 'app-modal-button'
        "
      />
    </template>
  </div>
</template>

<script>
import AppButton from "@/elements/AppButton";
import useRouter from "@/composables/useRouter";
export default {
  components: {
    AppButton,
  },
  props: {
    currentSlide: {
      type: Number,
      default: 1,
    },
    setCurrentSlide: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const { goTo } = useRouter();

    const goToMinting = () => goTo({ name: "Minting" });

    const goToDeposit = () => goTo({ name: "DepositNft" });

    const goToMarketplace = () => goTo({ name: "Marketplace" });

    const controlButtonsList = [
      { text: "Buy and sell NFT", clickHandler: goToMarketplace },
      { text: "Create NFT", clickHandler: goToMinting },
      { text: "Submit NFT", clickHandler: goToDeposit },
    ];

    const isActive = (index) => index === props.currentSlide;

    return {
      isActive,
      controlButtonsList,
    };
  },
};
</script>

<style lang="scss" src="@/Common/Styles/sliderButtons.scss" scoped />

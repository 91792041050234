<template>
  <div class="nft-description-wrapper">
    <name-and-title :nft="nft" :registeredUser="registeredUser" />

    <div class="nft-description-scroll">
      <main-actions :pool="pool" />
      <state-details :nft="nft" :pool="pool" />
      <about :nft="nft" :="registeredUser" />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import About from "./Sections/About";
import { ethereumService } from "@/main";
import useAuth from "@/composables/useAuth";
import MainActions from "./Sections/MainActions";
import NameAndTitle from "./Sections/NameAndTitle";
import StateDetails from "./Sections/StateDetails";
import useNftDetails from "@/composables/useNftDetails";
import { ref, onMounted } from "vue";

export default {
  components: {
    About,
    MainActions,
    NameAndTitle,
    StateDetails,
  },

  setup: function () {
    const top = ref("0px !important");
    const store = useStore();
    const { nft, pool } = useNftDetails(store);
    const { registeredUser } = useAuth(store, ethereumService);

    const positionTop = () => {
      const scrollDemo = document.querySelector(".nft-description-scroll");

      scrollDemo.addEventListener(
        "scroll",
        () => {
          top.value = `-${scrollDemo.scrollTop}px`;
        },
        { passive: true }
      );
    };
    onMounted(() => {
      positionTop();
    });

    return {
      nft,
      pool,
      top,
      positionTop,
      registeredUser,
    };
  },
};
</script>
<style scoped>
::v-deep .nft-description-scroll .tooltip-info .tooltip-hover > div,
::v-deep .pool-name-text .tooltip-wrap > p {
  top: v-bind(top);
}
</style>
<style lang="scss" scoped src="@/Common/Styles/nftDescription.scss" />

import api from "@/services/api";
import { giftNftSchema } from "../../validationSchemas/giftNftSchema";

const initialState = () => ({
  giftFlag: false,
  previewGiftingFlag: false,
  giftListedNftFlag: false,
  giftingAddress: null,
  nft: {
    id: null,
    royaltyPercentage: null,
    name: String,
  },
  currentStep: 1,
  currentStepsArray: [
    {
      component: "GiftNftModal",
      scheme: giftNftSchema,
    },
    {
      component: "PreviewGiftingModal",
      scheme: "",
    },
    {
      component: "FinishGiftingNftModal",
      scheme: "",
    },
    {
      component: "SuccessfulGiftingModal",
      scheme: "",
    },
  ],
  errors: [],
});

const state = initialState();
const getters = {
  getByKey: (state) => (key) => state[key],
};
const mutations = {
  setByKey(state, obj) {
    state[obj.key] = obj.value;
  },
  toggleGiftFlag(state) {
    state.giftFlag = !state.giftFlag;
  },
  toggleGiftListedNftFlag(state) {
    state.giftListedNftFlag = !state.giftListedNftFlag;
  },
  togglePreviewGiftingFlag(state) {
    state.previewGiftingFlag = !state.previewGiftingFlag;
  },
  incrementStep(state) {
    state.currentStep++;
  },
  decrementStep(state) {
    state.currentStep--;
  },
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
};

const actions = {
  incrementStep({ commit }) {
    commit("incrementStep");
  },
  decrementStep({ commit }) {
    commit("decrementStep");
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  resetState({ commit }) {
    commit("resetState");
  },
  toggleGiftFlag({ commit }) {
    try {
      commit("toggleGiftFlag");
    } catch (error) {
      commit("pushError", error);
    }
  },
  toggleGiftingListedNftFlag({ commit }) {
    try {
      commit("toggleGiftListedNftFlag");
    } catch (error) {
      commit("pushError", error);
    }
  },
  togglePreviewGiftingFlag({ commit }) {
    try {
      commit("togglePreviewGiftingFlag");
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchNft({ commit }, id) {
    try {
      const response = await api.fetchNft(id);
      commit("commitByKey", { nft: response.data });
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async sendEmail({ commit }, { nftId, receiverAddress }) {
    try {
      return await api.sendEmailWhenNftGifted(nftId, receiverAddress);
    } catch (error) {
      commit("pushError", error);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

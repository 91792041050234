import { computed, onMounted, ref } from "vue";

const useSocialCauses = (store, eventBus) => {
  onMounted(async () => await store.dispatch("socialCauses/fetchSocialCauses"));

  const socialCausesArray = computed(() =>
    store.getters["socialCauses/getByKey"]("socialCausesArray")
  );
  const filteredCauses = computed(() => {
    return socialCausesArray?.value?.filter((cause) =>
      cause.name.toUpperCase().includes(searchString.value.toUpperCase())
    );
  });
  const selectSocialCause = (cause) => {
    eventBus.emit("changingSelectedCause", cause);
    store.dispatch("socialCauses/setSelectedSocialCause", cause);
  };

  const searchString = ref("");

  const showDropdown = ref(false);

  const toggleShowDropdown = () => (showDropdown.value = !showDropdown.value);

  return {
    socialCausesArray,
    filteredCauses,
    selectSocialCause,
    searchString,
    showDropdown,
    toggleShowDropdown,
  };
};

export default useSocialCauses;

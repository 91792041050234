import api from "@/services/api";
import queryString from "query-string";

const initialState = () => ({
  pool: {
    id: null,
    name: "",
    description: "",
    tags: [],
    coverImage: {
      thumbnail: "",
    },
    originator: {
      displayName: "",
    },
    cause: null,
  },
  poolStatistics: {
    basePriceUSD: "",
    tokenPriceUSD: "",
    totalValueLockedUSD: "",
    volume24hUSD: "",
    tokenSupply: "",
    depositedNFTsCount: "",
    slots: "",
  },
  user: {
    id: "",
  },
  poolId: null,
  poolSubmissions: { count: 0 },
  pooledNftsTotal: { total: 0 },
  pooledNftList: [],
  allPoolsList: [],
  errors: [],
});
const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
  getPool: (state) => state.pool,
  getAllPools: (state) => state.allPoolsList,
  getUser: (state) => state.user,
  getErrors(state) {
    return state.errors;
  },
};

const mutations = {
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  setPool(state, pool) {
    state.pool = pool;
  },
  resetState(state) {
    Object.assign(state, initialState());
  },

  pushError(state, error) {
    state.errors.push(error);
  },

  setPooledNftList(state, list) {
    state.pooledNftList = state.pooledNftList.concat(list);
  },
  setPoolId(state, id) {
    state.poolId = id;
  },
  clearPooledNftList(state) {
    state.pooledNftList = [];
  },
  clearPooledNftsTotal(state) {
    state.pooledNftsTotal = { total: 0 };
  },
  clearPoolSubmissions(state) {
    state.poolSubmissions = { count: 0 };
  },
};

const actions = {
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  async fetchPool({ commit }, id) {
    try {
      const result = await api.fetchPool(id);
      commit("setPool", result.data);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchPoolDepositedNfts({ commit }, { poolId, params }) {
    try {
      const response = await api.fetchPoolDepositedNfts(poolId, params);
      commit("commitByKey", { pooledNftsTotal: response.data });
      commit("setPooledNftList", response.data.data);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchPoolStatistic({ commit }, poolId) {
    try {
      const response = await api.fetchPoolStatistic(poolId);
      commit("commitByKey", { poolStatistics: response.data });
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchSubmissions({ commit }, { poolId, params }) {
    try {
      const result = await api.fetchSubmissions(poolId, params);
      commit("commitByKey", { poolSubmissions: result.data });
    } catch (error) {
      commit("pushError", error);
    }
  },
  async handleBulkSubmission({ commit }, { status, submissionIds }) {
    try {
      return api.bulkUpdateSubmissionStatus(status, submissionIds);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async depositNft({ commit }, data) {
    try {
      return api.depositNftToPool(data);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async withdrawNft({ commit }, data) {
    try {
      return api.withdrawNftFromPool(data);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchMe({ commit }) {
    try {
      const response = await api.fetchMe();
      commit("commitByKey", { user: response.data.data });
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchAllPools({ commit }) {
    try {
      const params = {
        orderBy: "id",
        orderDirection: "DESC",
        status: ["approved"],
      };
      const response = await api.fetchPools(queryString.stringify(params));
      commit("commitByKey", { allPoolsList: response.data.data });
    } catch (error) {
      commit("pushError", error);
    }
  },
  setPoolId({ commit }, id) {
    commit("setPoolId", id);
  },
  resetState({ commit }) {
    commit("resetState");
  },
  clearPooledNftList({ commit }) {
    commit("clearPooledNftList");
  },
  clearPooledNftsTotal({ commit }) {
    commit("clearPooledNftsTotal");
  },
  clearPoolSubmissions({ commit }) {
    commit("clearPoolSubmissions");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { computed } from "vue";

export default (token) => {
  const isAuctioned = computed(() => {
    return !!token.auction.type;
  });

  const isEnded = computed(() => token.auction.status === "ended");

  const isRPMet = computed(() => {
    if (!token.price || token.price === 0) return false;
    if (token.auction.reservedPrice == null) return true;
    return token.price >= token.auction.reservedPrice;
  });

  const hasBids = computed(
    () => token.auction.nextOffer && token.auction.nextOffer !== 0
  );

  return {
    isRPMet: isRPMet.value,
    isEnded: isEnded.value,
    hasBids: hasBids.value,
    isAuctioned: isAuctioned.value,
  };
};

<template>
  <div v-if="nftsList.total === 0 && !loadingNfts" class="deposit-mint-new">
    <p class="deposit-first-text-line">*sigh*</p>
    <p class="deposit-secondtext-line">
      We couldn’t find an NFT.<br />
      Try
      <span class="deposit-to-minting" @click="openMintingForm"
        >minting one.</span
      >
    </p>
  </div>

  <div class="scrolling-content" ref="nftListScroll">
    <div class="deposit-body-content" v-if="nftsList.total > 0">
      <div v-for="(card, index) in parsedNftList" :key="index">
        <nft-submit-card
          :token="card"
          @click="selectNft(card)"
          :class="{ 'selected-nft': card.id === chosenNft?.id }"
        />
      </div>
    </div>
    <app-loader v-if="loadingNfts" :isGreen="true" />
    <div class="next-wrapper">
      <div class="deposit-next-gradient"></div>
      <div class="deposit-next" v-if="nftsList.total > 0 && !loadingNfts">
        <app-button
          text="Next"
          @click="commitToStore()"
          :disabled="!chosenNft.id"
          class="deposit-next-button"
          buttonClass="app-modal-button-inverse"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import AppButton from "@/elements/AppButton";
import AppLoader from "@/elements/AppLoader";
import useRouter from "@/composables/useRouter";
import useDeposit from "@/composables/useDeposit";
import NftSubmitCard from "@/components/NftSubmitCard";
import { onBeforeMount, onMounted, onUnmounted, ref, computed } from "vue";
export default {
  components: {
    AppButton,
    AppLoader,
    NftSubmitCard,
  },
  setup() {
    const store = useStore();

    const {
      params,
      nftsList,
      loadMore,
      chosenNft,
      nftDataList,
      loadingNfts,
      toggleFilter,
      incrementStep,
      updateChosenNft,
    } = useDeposit(store);

    const selectedNft = ref({});
    const nftListScroll = ref(null);
    const { goTo } = useRouter();

    const openMintingForm = () => {
      store.dispatch("minting/resetState");
      store.dispatch("tagsValues/resetState");
      goTo({ name: "Minting" });
    };

    if (chosenNft.value.id) selectedNft.value = chosenNft.value;

    const selectNft = (nft) => {
      if (selectedNft.value.id === null || selectedNft.value.id !== nft.id) {
        selectedNft.value = nft;
        updateChosenNft(nft);
      } else {
        selectedNft.value = {};
        updateChosenNft({});
      }
    };

    const commitToStore = () => {
      incrementStep();
    };

    onBeforeMount(() => {
      toggleFilter("filterNewest");
    });

    onMounted(async () => {
      window.addEventListener("scroll", handleScroll);
      store.dispatch("nftDetails/resetState");
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    const isPooledNftsLength = computed(
      () => nftsList.value.total >= nftDataList.value.length + 1
    );

    const handleScroll = (element) => {
      element = nftListScroll.value;
      if (element.getBoundingClientRect().bottom < window.innerHeight) {
        if (loadingNfts.value) {
          return;
        }
        if (isPooledNftsLength.value) loadMore();
      }
    };

    const parsedNftList = computed(() =>
      nftDataList.value?.filter((nft) => nft.contractId)
    );

    return {
      params,
      nftsList,
      loadMore,
      chosenNft,
      selectNft,
      nftDataList,
      loadingNfts,
      selectedNft,
      parsedNftList,
      nftListScroll,
      commitToStore,
      incrementStep,
      openMintingForm,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/chooseToDepositNft.scss" />

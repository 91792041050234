<template>
  <div class="name-and-title-container">
    <div class="back-button">
      <img alt="Back" @click="goBack()" src="@/Common/Icons/BackButton.svg" />
    </div>
    <div class="creator-owner-image">
      <div
        class="img-container"
        :class="
          nft.currentOwner?.id === registeredUser?.id
            ? 'avatar-img-border-holo'
            : 'avatar-img-gray'
        "
      >
        <img
          class="pointer"
          alt="CreatorImage"
          :src="avatarImage(nft.currentOwner)"
          @click="goToProfile(nft)"
        />
      </div>
      <span class="by-text">Owned by</span>
      <p class="name pointer" @click="goToProfile(nft)">
        {{ displayName(nft.currentOwner) }}
      </p>
    </div>
    <p class="nft-name">
      {{ nft.name }}
    </p>
  </div>
</template>

<script>
import { useStore } from "vuex";
import useNftDetails from "@/composables/useNftDetails";

export default {
  name: "NameAndTitle",
  props: {
    nft: {
      type: Object,
      default: () => {},
    },
    registeredUser: {
      type: Object,
      default: () => {},
    },
  },
  setup: function () {
    const store = useStore();
    const { goBack, avatarImage, goToUserProfile, goToProfile } =
      useNftDetails(store);

    const displayName = (user) => {
      if (user?.displayName) return user.displayName;
      if (!user?.publicAddress) return "";
      return `${user.publicAddress?.slice(0, 6)}...${user.publicAddress.slice(
        -4
      )}`;
    };

    return {
      goBack,
      displayName,
      avatarImage,
      goToProfile,
      goToUserProfile,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/NameAndTitle.scss" />

import { createToast, withProps } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import AppToast from "@/elements/AppToast.vue";

const useToast = () => {
  const showToast = (title, text, icon) => {
    const allTypes = ["Error", "Warning", "Success", "Attention", "Beta"];
    icon = icon.charAt(0).toUpperCase() + icon.slice(1);

    if (!allTypes.includes(icon)) {
      icon = "Warning";
    }

    const toastOptions = {
      position: "top-center",
      type: "default",
      transition: "slide",
      toastBackgroundColor: "#000000",
      showIcon: false,
      hideProgressBar: true,
      timeout: 5000,
      swipeClose: true,
      showCloseButton: true,
    };

    const appToastProps = {
      icon: icon,
      title: title,
      text: text,
    };

    createToast(withProps(AppToast, appToastProps), toastOptions);
  };
  return {
    showToast,
  };
};

export default useToast;

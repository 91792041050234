<template>
  <div class="profile-panel-filter-search">
    <search
      :searchFunction="searchFunction"
      :isForMarketplace="isForMarketplace"
      :clearSearchFunction="clearSearchFunction"
      :disabled="currentPanel === 'nfts' && !theosMinted"
    />
    <Filter v-if="currentPanel !== 'external' && !isForMarketplace" />
    <sort
      :sortOptions="sortOptions"
      :sortFunction="sortFunction"
      v-if="currentPanel !== 'external' && !isForMarketplace"
    />
    <sort-marketplace v-if="isForMarketplace" :sortFunction="sortFunction" />
  </div>
</template>

<script>
import { inject } from "vue";
import { useStore } from "vuex";

import Sort from "./Sort.vue";
import Search from "./Search.vue";
import Filter from "./Filter.vue";
import SortMarketplace from "./SortMarketplace.vue";
import useProfileView from "@/composables/useProfileView";

export default {
  props: {
    currentPanel: {
      type: String,
      default: "",
    },
    isForMarketplace: {
      type: Boolean,
      default: false,
    },
    searchFunction: {
      type: Function,
      default: () => {},
    },
    sortFunction: {
      type: Function,
      default: () => {},
    },
    sortOptions: {
      type: Array,
      default: () => [],
    },
    clearSearchFunction: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    Filter,
    Sort,
    Search,
    SortMarketplace,
  },
  setup() {
    const isPublic = inject("isPublic");
    const { theosMinted } = useProfileView(useStore());

    return {
      isPublic,
      theosMinted,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/profilePanels.scss" />

<template>
  <div class="cover-wrapper">
    <div class="box">
      <div class="input-box-wrap-text">
        <span class="first-text-line">Pool</span>
        <span class="second-text-line">Avatar</span>
      </div>
    </div>
    <file-input
      id="poolCoverImg"
      name="poolCoverImg"
      :inputVariant="'light'"
      v-model="localCoverImg"
      :on-change="onFileChanged"
      :shouldPickCoverImage="true"
      :crossStyle="'cross_grey.svg'"
      :isPool="true"
      accept="image/jpeg,image/jpg,image/png,image/gif,image/webp"
    />
    <div class="form-box-buttons-controls" v-if="localCoverImg.type">
      <app-button
        text="Back"
        @click="goBack()"
        buttonClass="app-modal-button"
      />
      <app-button
        text="Next"
        type="submit"
        form="create-pool"
        buttonClass="app-modal-button-inverse"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import usePool from "@/composables/usePool";
import AppButton from "@/elements/AppButton";
import FileInput from "@/elements/FileInput";
export default {
  components: {
    AppButton,
    FileInput,
  },
  setup() {
    const localCoverImg = ref({});

    const goBack = () => {
      setBackgroundUrl("");
      decrementStep();
    };
    const {
      poolCoverImg,
      decrementStep,
      updateCoverImageFile,
      setBackgroundUrl,
    } = usePool(useStore());

    const onFileChanged = async (file) => {
      if (file.type) {
        setBackgroundUrl(URL.createObjectURL(file));
        updateCoverImageFile(file);
      }
    };

    return {
      goBack,
      poolCoverImg,
      decrementStep,
      onFileChanged,
      localCoverImg,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/pools.scss" />

<template>
  <div class="connect-text">
    <p>Connect your wallet to view your NFTs</p>
  </div>
  <div class="form-box-buttons-controls deposit-connect">
    <app-button
      text="Connect wallet"
      class="app-modal-button-inverse"
      @click="loginOrSignUp()"
    />
  </div>
</template>
<script>
import { useStore } from "vuex";
import useAuth from "@/composables/useAuth";
import { ethereumService } from "@/main";
import AppButton from "@/elements/AppButton";
export default {
  components: {
    AppButton,
  },
  setup() {
    const store = useStore();
    const { loginOrSignUp } = useAuth(store, ethereumService);
    return {
      loginOrSignUp,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/chooseToDepositNft.scss"></style>

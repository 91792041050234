<template>
  <div v-if="acceptOfferFlag">
    <teleport to="body">
      <component
        :nft="nft"
        :is="currentStepsArrayAcceptOffer[currentStepAcceptOffer - 1].component"
      />
    </teleport>
  </div>
</template>

<script>
import { useStore } from "vuex";

import useNftDetails from "@/composables/useNftDetails";
import useBuyNftModal from "@/composables/useBuyNftModal";
import AcceptOffer from "@/components/Auctions/AcceptOffer";
import AcceptOfferCompleting from "@/components/Auctions/AcceptOfferCompleting";

export default {
  components: {
    AcceptOffer,
    AcceptOfferCompleting,
  },

  setup() {
    const store = useStore();
    const {
      acceptOfferFlag,
      currentStepAcceptOffer,
      currentStepsArrayAcceptOffer,
    } = useBuyNftModal(store);
    const { nft } = useNftDetails(store);
    return {
      nft,
      acceptOfferFlag,
      currentStepAcceptOffer,
      currentStepsArrayAcceptOffer,
    };
  },
};
</script>

<style lang="scss" scoped />

<template>
  <app-deposit-review
    :closeButton="true"
    :returnToPreviousModal="toggleDepositFlag"
  >
    <template v-slot:app-modal-header>
      <img src="@/Common/Icons/TheosLogoYellow.svg" alt="TheosLogo" />
      <h1 class="modal-holo-title">Deposited NFT</h1>
    </template>
    <template v-slot:app-modal-body>
      <img
        :alt="pool.name"
        class="deposit-modal-img"
        :src="pool.coverImage.path"
      />
      <p class="app-modal-body-text text-color">You deposited</p>
      <p class="app-modal-deposit-bold-text-big">
        {{ nft.name }}
        <span class="text-color">into </span>
        {{ pool.name }}
      </p>
      <p class="app-modal-body-text text-color">You received</p>
      <p class="app-modal-deposit-price">
        <span> {{ depositingTotal }} {{ pool.tokenSymbol }} </span>
        <span class="price-color"> ≈${{ depositingTotalInUsd }} </span>
      </p>
      <div class="button-wrapper-done-depositing">
        <app-button
          :text="buttonText"
          buttonClass="app-modal-button-inverse"
          @click="addTokenToMetamask(pool.tokenSymbol)"
        />
        <app-button
          style="margin-top: 16px"
          @click="goToPool(pool.id)"
          :text="`Go to ${pool.name}`"
          buttonClass="app-modal-button"
        />
      </div>
    </template>
  </app-deposit-review>
</template>

<script>
import { useStore } from "vuex";
import { ethereumService } from "@/main";
import AppButton from "@/elements/AppButton";
import useUtilities from "@/composables/useUtilities";
import { computed, onMounted, onUnmounted } from "vue";
import AppDepositReview from "@/elements/AppDepositReview";
import useDepositReviewModal from "@/composables/useDepositReviewModal";
export default {
  name: "DepositingInProcess",
  components: {
    AppButton,
    AppDepositReview,
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    nft: {
      type: Object,
      default: () => {},
    },
    pool: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const { toFixedIfNecessary } = useUtilities();
    const { goToPool, toggleDepositFlag } = useDepositReviewModal(store);
    const buttonText = computed(
      () => "Add " + props.pool.tokenSymbol + " to your wallet"
    );

    const depositingSocialCauseValue = computed(() =>
      toFixedIfNecessary((props.pool.basePrice / 100) * 5, 4)
    );

    const numberOfLpTokens = computed(() => props.pool.basePrice * 0.95);

    const depositingRoyaltyValue = computed(() =>
      toFixedIfNecessary(
        ((props.pool.basePrice - depositingSocialCauseValue.value) / 100) *
          props.nft.royaltyPercentage,
        4
      )
    );

    const addTokenToMetamask = (token) =>
      ethereumService.addTokenToMetamask(token, props.pool.tokenAddress);

    const depositingTotalInUsd = computed(() =>
      props.pool.tokenPrice
        ? toFixedIfNecessary(depositingTotal.value * props.pool.tokenPrice, 2)
        : 0
    );

    const depositingTotal = computed(
      () =>
        props.pool.basePrice -
        depositingRoyaltyValue.value -
        depositingSocialCauseValue.value
    );

    onMounted(() => document.body.classList.add("stop-scroll"));

    onUnmounted(() => document.body.classList.remove("stop-scroll"));

    return {
      goToPool,
      buttonText,
      depositingTotal,
      numberOfLpTokens,
      toggleDepositFlag,
      addTokenToMetamask,
      depositingTotalInUsd,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/depositReview.scss" />

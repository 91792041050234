<template>
  <section>
    <div v-show="!isFileSelected" class="head-headline">
      <div class="headline">
        <span class="tok">TOKENIZE</span>
        <span class="second-headline-word">ANYTHING,</span>
        <span class="tok" style="padding-left: 8px">ON</span>
        <span class="second-headline-word">
          {{ parsedNetworkName?.toUpperCase() }}
        </span>
      </div>
      <div class="headline-undertext">
        From a media file to a birth certificate. Just tokenize it.
      </div>
    </div>
    <div v-if="isLoading" class="spinner file-input new">
      <app-loader :show="isLoading" :text="loadingText" :isGreen="true" />
    </div>
    <div v-if="fileType === 'audio'">
      <span class="image-head-text"
        >YOU’LL TOKENIZE AN {{ fileExtension }}</span
      >
    </div>
    <div
      class="inside-wrap"
      :class="[fileType === 'audio' ? 'audio-box-wrapper' : '']"
    >
      <div v-if="fileType === 'audio'" class="audio-selected-file-name">
        {{ localSelectedFile.name }}
      </div>

      <file-input
        v-model="localSelectedFile"
        id="fileInput"
        name="selectedFile"
        :isImageOnly="false"
        :on-change="onFileChanged"
        accept="image/png,image/jpeg,image/gif,video/mp4,video/webm,audio/mp3,audio/webm,audio/mpeg,audio/wav,application/pdf"
      />
      <div
        class="form-box-buttons-controls"
        v-show="isFileSelected && !isLoading"
      >
        <app-button
          text="Back"
          @click.prevent="clearFile()"
          buttonClass="app-modal-button"
        />
        <app-button
          text="Next"
          type="submit"
          form="mint-nft"
          buttonClass="app-modal-button-inverse"
        />
      </div>
    </div>
    <div v-if="fileType === 'image'" class="image-name">
      {{ localSelectedFile.name }}
    </div>
  </section>
</template>

<script>
import { useStore } from "vuex";
import { eventBus } from "@/main";
import { ethereumService } from "@/main";
import { ref, computed, onMounted } from "vue";
import FileInput from "@/elements/FileInput.vue";
import AppButton from "@/elements/AppButton.vue";
import AppLoader from "@/elements/AppLoader.vue";
import useMinting from "@/composables/useMinting";
import useGoogleTag from "@/composables/useGoogleTag";
import useUtilities from "@/composables/useUtilities";
import useNetworkData from "@/composables/useNetworkData";

export default {
  name: "MintNftFile",
  components: {
    FileInput,
    AppButton,
    AppLoader,
  },
  setup() {
    const store = useStore();
    const {
      selectedFile,
      incrementStep,
      isFileSelected,
      setIsLoading,
      setBackgroundUrl,
      updateSelectedFile,
      updateCoverImageFile,
      changeCurrentStepArray,
      autogenerateCoverImage,
      setIsCoverAutogenerated,
      setStartTime,
      setGlobalStartTime,
    } = useMinting(store);

    const { parsedNetworkName } = useNetworkData(ethereumService);

    const { getFileType, makeFileObject, getFileExtension, getMimeType } =
      useUtilities();

    onMounted(() => {
      registerEvent("nft-minting-started");
      setGlobalStartTime();
      setStartTime();
    });

    const { registerEvent } = useGoogleTag();

    const localSelectedFile = ref({});
    const isLoading = ref(false);
    const loadingText = ref("Uploading video...");

    const fakeImage = ref(require(`@/Common/Icons/TheosLogoYellow.png`));
    if (selectedFile.value.name) localSelectedFile.value = selectedFile.value;
    const fileType = computed(() => getFileType(localSelectedFile.value));
    const fileExtension = computed(() =>
      getFileExtension(localSelectedFile.value)
    );
    const mimeType = computed(() => getMimeType(localSelectedFile.value));

    const onFileChanged = async (file, valid) => {
      if (!valid) return;
      updateSelectedFile(file);
      setIsCoverAutogenerated(false);
      changeCurrentStepArray(fileType.value);

      if (fileType.value === "image") {
        setBackgroundUrl(URL.createObjectURL(file));
      }
      if (mimeType.value === "gif") {
        setBackgroundUrl("");
      }
      if (fileType.value === "application" && mimeType.value !== "pdf")
        incrementStep();
      if (fileType.value === "video") {
        isLoading.value = true;
        setIsLoading(isLoading.value);
        const agCover = await autogenerateCoverImage();
        const coverImage = await makeFileObject(
          agCover.thumbnailPath,
          "coverImage"
        );
        updateCoverImageFile(coverImage);
        isLoading.value = false;
        setIsLoading(isLoading.value);
        incrementStep();
        setIsCoverAutogenerated(true);
        const backgroundImageUrl = agCover.thumbnailPath.replace("gif", "png");
        setBackgroundUrl(backgroundImageUrl);
      }
    };

    const clearFile = () => {
      store.dispatch("minting/resetState");
      store.dispatch("tagsValues/resetState");
      store.dispatch("auth/commitByKey", {
        isBeginConnectionModalOpen: false,
      });
      eventBus.emit("clearFile:selectedFile");
    };

    return {
      mimeType,
      fileType,
      fakeImage,
      clearFile,
      isLoading,
      loadingText,
      onFileChanged,
      fileExtension,
      isFileSelected,
      parsedNetworkName,
      localSelectedFile,
      updateCoverImageFile,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/fileInput.scss"></style>

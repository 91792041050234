<template>
  <app-buy-listed-nft :showCloseLink="false">
    <template v-slot:app-modal-header>
      <div class="spinner file-input new">
        <app-loader :show="true" :isGreen="true" />
      </div>
      <h3 class="app-modal-buy-listed-title">COMPLETING</h3>
    </template>
    <template v-slot:app-modal-body>
      <p class="app-modal-body-text">
        To purchase the NFT complete this <br />
        final transaction
      </p>
    </template>
  </app-buy-listed-nft>
</template>

<script>
import AppBuyListedNft from "@/elements/AppBuyListedNft";
import AppLoader from "@/elements/AppLoader";
export default {
  name: "BuyListedNftCompletingModal",
  components: {
    AppBuyListedNft,
    AppLoader,
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/buyListedNft.scss"></style>

<template>
  <app-loader v-show="loadingMore" :isGreen="true" />
  <div v-show="!loadingMore">
    <div
      v-show="
        currentPanel == '' ||
        data[currentPanel].data.length < data[currentPanel].total
      "
      id="loadMoreDiv"
    ></div>
  </div>
</template>

<script>
import AppLoader from "@/elements/AppLoader";
import { onMounted } from "vue";
export default {
  components: {
    AppLoader,
  },
  props: {
    currentPanel: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => ({ data: [], total: 0 }),
    },
    loadMore: {
      type: Function,
      default: () => {},
    },
    loadingMore: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    onMounted(() => {
      const loadMoreDiv = document.getElementById("loadMoreDiv");
      const laodMoreObserver = new IntersectionObserver(
        (entries) => {
          const div = entries[0];
          if (div.isIntersecting && !props.loadingMore) {
            props.loadMore();
          }
        },
        {
          threshold: 1,
          rootMargin: "-100px",
        }
      );

      laodMoreObserver.observe(loadMoreDiv);
    });
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/profilePanels.scss"></style>

<template>
  <div class="filter-wrapper">
    <label for="filter" class="label" v-if="!noLabel">Social cause</label>
    <div name="filter" class="filter-button">
      <div class="filter-button-overlay" @click="toggleShowDropdown()" />
      <div class="filter-button-content">
        <p class="text">{{ placeholderText }}</p>
        <i class="chevron-icon-free" :class="{ active: showDropdown }" />
      </div>
      <div class="filters-dropdown" v-if="showDropdown">
        <div class="causes-list">
          <nft-card
            :nft="nft"
            :key="index"
            @click="selectNft(nft)"
            v-for="(nft, index) in filteredNfts"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { eventBus } from "@/main";
import usePool from "@/composables/usePool";
import NftCard from "@/components/Pools/NftCard";

export default {
  components: {
    NftCard,
  },
  props: {
    noLabel: {
      type: Boolean,
      default: false,
    },
    placeholderText: {
      type: String,
    },
  },
  setup() {
    const {
      selectNft,
      loadAllNfts,
      filteredNfts,
      searchString,
      showDropdown,
      toggleShowDropdown,
    } = usePool(useStore(), eventBus);

    onMounted(async () => {
      // loads all nfts for filtered nfts
      await loadAllNfts();
    });

    return {
      selectNft,
      searchString,
      showDropdown,
      filteredNfts,
      toggleShowDropdown,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/selectMyNft.scss" />

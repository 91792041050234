<template>
  <div v-if="show" class="loader-wrapper">
    <div
      :class="[
        isGreen ? 'change-border' : '',
        isSmall ? 'loader-small' : 'loader',
      ]"
    ></div>
    <div class="loader-text" :class="[isGreen ? 'change-font' : '']">
      {{ text }}
    </div>
  </div>
</template>
<script>
export default {
  name: "Loader",
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
      default: "",
    },
    isGreen: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/appLoader.scss"></style>

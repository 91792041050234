<template>
  <teleport to="body">
    <app-modal :closeButton="true" :returnToPreviousModal="closeModal">
      <template v-slot:app-modal-body>
        <div class="app-modal-body-wrapper">
          <img src="@/Common/Icons/TheosLogo.svg" alt="Logo" class="logo-gif" />
          <div class="text-wrap">
            <p class="text-polygon">
              CONNECT <br />
              YOUR WALLET
            </p>
            <p class="info-text">You need to connect your wallet to continue</p>
          </div>
          <div class="modal-buttons">
            <a
              class="app-modal-button-gray-thick-border"
              href="https://metamask.io/"
              target="_blank"
            >
              <div class="metamask-button">
                <img
                  src="@/Common/Icons/MetaMaskLogo.svg"
                  alt="Metamask"
                  class="logo-metamask"
                />
                <span class="metamask-text">Add MetaMask extension</span>
              </div>
            </a>
          </div>
        </div>
      </template>
    </app-modal>
  </teleport>
</template>
<script>
import { useStore } from "vuex";
import { onMounted, onUnmounted } from "vue";
import { ethereumService } from "../main";
import useAuth from "@/composables/useAuth";
import AppModal from "@/elements/AppModal";
export default {
  components: {
    AppModal,
  },
  setup() {
    const store = useStore();
    const { isMetamaskNotInstalled, installMetamaskFlag } = useAuth(
      store,
      ethereumService
    );

    const closeModal = () => {
      store.dispatch("auth/commitByKey", {
        installMetamaskFlag: false,
      });
    };
    onMounted(async () => {
      document.body.classList.add("stop-scroll");
    });
    onUnmounted(() => document.body.classList.remove("stop-scroll"));
    return {
      closeModal,
      installMetamaskFlag,
      isMetamaskNotInstalled,
    };
  },
};
</script>
<style
  lang="scss"
  scoped
  src="@/Common/Styles/connectionAndPolygonModal.scss"
></style>

import api from "@/services/api";
import queryString from "query-string";

const initialQueries = () => ({
  page: 1,
  perPage: 12,
  orderBy: "id",
  orderDirection: "DESC",
  priceRange: [],
  media: [],
  networks: [],
});

const initialData = () => ({ data: [], total: 0 });

const initialState = () => ({
  searchString: "",
  loadingMore: true,
  loadingNfts: false,
  loadingPanels: false,
  queries: initialQueries(),

  data: {
    all: initialData(),
    pooled: initialData(),
    listed: initialData(),
  },

  errors: [],
});

const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
};

const mutations = {
  pushError(state, error) {
    state.errors.push(error);
  },

  resetDataArray(state, dataName) {
    state.data[dataName].data = [];
  },

  resetState(state) {
    Object.assign(state, initialState());
  },

  incrementPage(state) {
    state.queries.page = state.queries.page + 1;
  },

  resetQueries(state) {
    Object.assign(state.queries, initialQueries());
    state.searchString = "";
  },

  resetPage(state) {
    state.queries.page = 1;
  },

  resetData(state, dataName) {
    Object.assign(state.data[dataName], initialData());
  },

  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
};

const actions = {
  async fetchAllNfts({ commit, state }, params) {
    try {
      const response = await api.fetchNftList(
        queryString.stringify({
          ...params,
          ...state.queries,
          media: state.queries.media.toString(),
          priceRange: state.queries.priceRange.toString(),
          search: state.searchString,
          status: "deposited,listed,auctioned",
        })
      );
      const { data, total } = response.data;
      commit("commitByKey", {
        data: {
          ...state.data,
          all: {
            data: state.data.all.data.concat(data),
            total,
          },
        },
      });
    } catch (error) {
      commit("pushError", error);
    }
  },

  async setFilter({ commit, state }, params) {
    try {
      commit("commitByKey", {
        queries: {
          ...state.queries,
          ...params,
        },
      });
    } catch (error) {
      commit("pushError", error);
    }
  },

  async setSort({ commit, state }, params) {
    try {
      commit("commitByKey", {
        queries: {
          ...state.queries,
          ...params,
        },
      });
    } catch (error) {
      commit("pushError", error);
    }
  },

  async clearSearch({ commit }) {
    try {
      commit("commitByKey", {
        searchString: "",
      });
    } catch (error) {
      commit("pushError", error);
    }
  },

  async fetchPooledNfts({ commit, state }, params) {
    try {
      const response = await api.fetchNftList(
        queryString.stringify({
          ...params,
          ...state.queries,
          search: state.searchString,
          status: "deposited",
        })
      );
      const { data, total } = response.data;
      commit("commitByKey", {
        data: {
          ...state.data,
          pooled: {
            data: state.data.pooled.data.concat(data),
            total,
          },
        },
      });
    } catch (error) {
      commit("pushError", error);
    }
  },

  async fetchListedNfts({ commit, state }, params) {
    try {
      const response = await api.fetchNftList(
        queryString.stringify({
          ...params,
          ...state.queries,
          search: state.searchString,
          status: "listed,auctioned",
        })
      );
      const { data, total } = response.data;
      commit("commitByKey", {
        data: {
          ...state.data,
          listed: {
            data: state.data.listed.data.concat(data),
            total,
          },
        },
      });
    } catch (error) {
      commit("pushError", error);
    }
  },

  resetState({ commit }) {
    commit("resetState");
  },

  resetQueries({ commit }) {
    commit("resetQueries");
  },

  incrementPage({ commit }) {
    commit("incrementPage");
  },

  pushError({ commit }, error) {
    commit("pushError", error);
  },

  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },

  resetData({ commit }, dataName) {
    commit("resetData", dataName);
  },

  resetDataArray({ commit }, dataName) {
    commit("resetDataArray", dataName);
  },

  resetPage({ commit }) {
    commit("resetPage");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};

import { isRef } from "vue";
import { DateTime } from "luxon";
import { ethereumService } from "@/main";
import useGoogleTag from "@/composables/useGoogleTag";
import tokenOptions from "@/json/tokenSelectOptions.json";

const useUtilities = () => {
  const toggle = (target) =>
    isRef(target) ? (target.value = !target.value) : (target = !target);

  const getFileType = (file) => {
    return file.type?.split("/")[0];
  };

  const getMimeType = (file) => {
    return file.type?.split("/")[1];
  };
  const toFixedIfNecessary = (value, dp) => {
    if (!value) return 0;
    if (value < 0.001) return 0.001;
    return +parseFloat(value).toFixed(dp);
  };

  const numberHasMoreThanThreeDecimals = (number) => {
    if (number === 0) return false;
    return number < 0.001;
  };
  const getFileExtension = (file) => {
    return file.name?.substring(
      file.name?.lastIndexOf(".") + 1,
      file.name?.length
    );
  };
  const makeFileObject = async (url, fileName = "test") => {
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type: "image/gif",
    };
    return new File([data], fileName, metadata);
  };

  const copyToClipboard = (data) => {
    const { registerEvent } = useGoogleTag();
    navigator.clipboard.writeText(data);
    registerEvent("nft link copied to clipboard");
  };

  const parseDate = (value) => {
    return (
      DateTime.fromISO(value).toFormat("dd/LL/yyyy") +
      " at " +
      DateTime.fromISO(value).toFormat("hh:mm a")
    );
  };

  const parseDateStateDetails = (value) => {
    return DateTime.fromISO(value).toFormat("LL/dd/yy HH:mm");
  };

  const submissionsTimeRemaining = (value) => {
    let time = DateTime.fromISO(value).plus({ days: 5 });
    const timeDiff = time.diffNow(["days", "hours", "minutes"]).toObject();
    return (
      timeDiff.days +
      "d " +
      timeDiff.hours +
      ":" +
      (timeDiff.minutes | 0) +
      "hs"
    );
  };
  const scrollRight = (val) => {
    let content = document.querySelector(val);
    content.scrollLeft += 80;
  };
  const hasScrollBar = (val) => {
    let content = document.querySelector(val);
    if (content) {
      return content.scrollWidth > content.clientWidth;
    } else return false;
  };
  const isStringTooLong = (str, len) => {
    return str.length > len;
  };

  const getRandomNumbers = (min, max, num) => {
    let result = [];
    if (max < num) return result;
    while (result.length < num) {
      let number = Math.floor(Math.random() * max) + min;
      if (!result.includes(number)) {
        result.push(number);
      }
    }
    return result;
  };

  const parseTokenList = async () => {
    const network = await ethereumService.getNetwork();
    return tokenOptions[network.chainId];
  };

  const selectTokenFromList = (list, tokenName) => {
    return list.find((token) => token.token === tokenName);
  };

  const filterKey = (e) => {
    const key = e.key;
    const keyCode = e.keyCode;
    if (
      (keyCode < 48 || keyCode > 57) &&
      (keyCode < 96 || keyCode > 105) &&
      key !== "." &&
      key !== "Backspace" &&
      key !== "Delete"
    ) {
      e.preventDefault();
    }
  };

  return {
    toggle,
    parseDate,
    filterKey,
    scrollRight,
    getMimeType,
    getFileType,
    hasScrollBar,
    makeFileObject,
    parseTokenList,
    copyToClipboard,
    isStringTooLong,
    getRandomNumbers,
    getFileExtension,
    toFixedIfNecessary,
    selectTokenFromList,
    parseDateStateDetails,
    submissionsTimeRemaining,
    numberHasMoreThanThreeDecimals,
  };
};

export default useUtilities;

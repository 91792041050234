<template>
  <base-form
    style="width: 100%; height: 100%"
    id="bid-on-nft"
    method="post"
    :handleSubmit="placeBid"
    :schema="validationSchema"
  >
    <div class="fixed-panel-wrapper">
      <div class="body">
        <div class="panel-title">
          <span class="panel-title-number">1</span>
          <span class="panel-title-text">SET BID</span>
        </div>
        <app-input-with-select
          style="width: 100%"
          type="number"
          name="bid"
          labelClass="label"
          labelText="Your bid *"
          :inputValue="offer"
          :selectedToken="selectedToken"
          inputClass="form-box-input-with-select"
          placeholder="000.000"
          :floatingText="`≈ $${calculatedOfferUsd} USD`"
          infoText="The bid should be at least the <br>
                  starting price and if there is other bid made<br>
                  it should be 5% over it."
          :selectOptions="tokenOptions"
          :updateValue="setOffer"
          :updateSelect="setSelectedToken"
          :enableSelect="false"
          @keydown="filterKey"
        />
        <input type="hidden" :value="minimumBid" />
      </div>
      <div class="footer">
        <div class="how-it-works">
          How it works? <span class="dark">Find out more in our </span
          ><a
            href="https://theos.fi/faq"
            target="_blank"
            style="text-decoration: none"
          >
            <span class="link">documentation page</span></a
          >
        </div>
        <div class="footer-buttons">
          <app-button
            :style="'margin-right: 1rem'"
            text="Cancel"
            buttonClass="app-modal-button"
            @click="closeModal"
            @keypress.enter.prevent
            @submit.prevent
          />
          <app-button
            type="submit"
            text="Place bid"
            :disabled="placeBidErrors.length"
            buttonClass="app-modal-button-inverse"
          />
        </div>
      </div>
    </div>
  </base-form>
</template>

<script>
import * as Yup from "yup";
import { useStore } from "vuex";
import { ref, computed, reactive, onMounted } from "vue";
import useBid from "@/composables/useBid";
import AppButton from "@/elements/AppButton";
import BaseForm from "@/components/BaseForm.vue";
import { nftListSchema } from "@/validationSchemas";
import useUtilities from "@/composables/useUtilities";
import AppInputWithSelect from "@/elements/AppInputWithSelect";

export default {
  name: "BidPanel",
  components: {
    BaseForm,
    AppButton,
    AppInputWithSelect,
  },
  setup() {
    const inputValue = ref(null);
    const showDetails = ref(false);

    const {
      filterKey,
      toFixedIfNecessary,
      parseTokenList,
      selectTokenFromList,
    } = useUtilities();

    const minimumBidValidationMessage = computed(() => {
      return nft?.value?.auction?.highestBidder.id === null
        ? "Type a number above or equal to the NFT starting price"
        : "Your bid should be 5% over than the last one";
    });

    const validationSchema = computed(() => {
      return Yup.object().shape({
        bid: Yup.number()
          .min(minimumBid.value, `${minimumBidValidationMessage.value}`)
          .lessThan(99999999, "Bid must be lower than 99999999")
          .typeError("An offer should be made in order to bid"),
      });
    });

    const {
      nft,
      offer,
      setOffer,
      placeBid,
      closeModal,
      minimumBid,
      confirmBid,
      selectedToken,
      placeBidErrors,
      setSelectedToken,
      calculatedOfferUsd,
    } = useBid(useStore());

    const toggleDetails = () => {
      showDetails.value = !showDetails.value;
    };

    const tokenOptions = reactive({});

    onMounted(async () => {
      tokenOptions.value = await parseTokenList();

      const auctionToken = selectTokenFromList(
        tokenOptions.value,
        nft.value.auction.token
      );
      setSelectedToken(auctionToken);
    });

    return {
      nft,
      offer,
      setOffer,
      placeBid,
      filterKey,
      confirmBid,
      closeModal,
      inputValue,
      minimumBid,
      showDetails,
      tokenOptions,
      nftListSchema,
      toggleDetails,
      selectedToken,
      placeBidErrors,
      validationSchema,
      setSelectedToken,
      calculatedOfferUsd,
      toFixedIfNecessary,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/auctionModal.scss"></style>

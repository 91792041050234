<template>
  <teleport to="body">
    <app-modal :closeButton="true" :returnToPreviousModal="toggleModalFlag">
      <template v-slot:app-modal-header>
        <img
          src="@/Common/Icons/TheosLogo.png"
          alt="TheosLogo"
          class="header-logo-image"
        />
      </template>
      <template v-slot:app-modal-body>
        <div class="app-modal-body-wrapper">
          <p class="app-modal-title">CONFIRMATION</p>
          <p class="app-modal-text">{{ modalTextTop }}</p>
          <div>
            <p class="app-modal-nft-pool-name">
              <span class="app-modal-additional-text">
                {{ modalTextBottom }}
              </span>
            </p>
          </div>
          <div class="button-wrapper-submissions">
            <app-button
              @click="buttonAction"
              buttonClass="app-modal-button-inverse"
              :text="'Proceed'"
            />
            <app-button
              @click="toggleModalFlag"
              buttonClass="app-modal-button-transparent"
              :text="'Cancel'"
            />
          </div>
        </div>
      </template>
    </app-modal>
  </teleport>
</template>

<script>
import { useStore } from "vuex";
import AppModal from "@/elements/AppModal";
import { onMounted, onUnmounted, ref } from "vue";
import AppButton from "@/elements/AppButton";
import usePools from "@/composables/usePools";
export default {
  components: {
    AppModal,
    AppButton,
  },
  props: {
    poolProp: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const modalTextTop = ref("");
    const modalTextBottom = ref("");
    const { toggleModalFlag } = usePools(store);

    if (props.poolProp.value.submitType === "single") {
      modalTextTop.value = `${props.poolProp.value.status} submission request`;
      modalTextBottom.value = "You’re about to accept 1 NFTs submissions.";
    }
    if (props.poolProp.value.submitType === "bulk") {
      modalTextTop.value = `${props.poolProp.value.status} submission request`;
      modalTextBottom.value = `You’re about to accept ${props.poolProp.value.submission.length} NFTs submissions.`;
    }
    const buttonAction = async () => {
      if (props.poolProp.value.submitType === "single") {
        await store.dispatch("pools/handleBulkSubmission", {
          status: props.poolProp.value.status.toLowerCase(),
          submissionIds: [props.poolProp.value.submission.id],
        });
      }
      if (props.poolProp.value.submitType === "bulk") {
        await store.dispatch("pools/handleBulkSubmission", {
          status: props.poolProp.value.status.toLowerCase(),
          submissionIds: props.poolProp.value.submission,
        });
      }
      toggleModalFlag();
    };

    onMounted(() => document.body.classList.add("stop-scroll"));
    onUnmounted(() => document.body.classList.remove("stop-scroll"));
    return {
      modalTextTop,
      buttonAction,
      modalTextBottom,
      toggleModalFlag,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/previewModal.scss"></style>

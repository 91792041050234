<template>
  <input-form-box
    formTitle="nft"
    formTitleBold="deposit"
    :inputFormBoxStyle="inputFormBoxStyle"
  >
    <div class="sections-wrapper">
      <section class="row-section">
        <div class="heading-wrapper">
          <p class="heading">Choose an NFT from your wallet</p>
          <p class="subheading">
            Make sure that your NFTs are loaded on
            <router-link class="deposit-link" to="/profile/me"
              >your profile</router-link
            >
            or go
            <router-link class="deposit-link" to="/minting"
              >mint one!</router-link
            >
          </p>
        </div>
        <div class="tags-royalties-wrap column">
          <select-my-nft
            :noLabel="true"
            v-if="!selectedNft.name"
            placeholderText="Select NFT from your wallet"
          />
          <div v-else class="selected-nft-wrapper">
            <img
              alt="IMG"
              class="selected-nft-img"
              :src="selectedNft.file.path"
            />
            <div class="selected-nft-text">
              <p class="selected-nft-title">
                {{ selectedNft.name }}
              </p>
              <div class="pool-index-token">
                <p class="token">
                  {{ pool.nftBasePrice }}{{ pool.tokenSymbol }}
                </p>
                <p class="price-usd">≈${{ pool.nftBasePrice }}</p>
              </div>
              <p class="selected-nft-set-price" @click="toggleSetPriceModal()">
                Set your price
              </p>
            </div>
            <img
              alt="Cancel"
              class="cancel"
              @click="removeSelectedNft()"
              src="@/Common/Icons/Cancel.png"
            />
          </div>
        </div>
      </section>
    </div>
    <div class="form-box-buttons-controls">
      <app-button
        text="Back"
        @click="decrementStep()"
        buttonClass="app-modal-button"
      />
      <app-button
        type="button"
        text="Pool Preview"
        @click="togglePoolPreviewModal()"
        buttonClass="app-modal-button-inverse"
        :disabled="!togglePreviewModalEnabled"
      />
    </div>
  </input-form-box>
  <set-price-modal
    :pool="pool"
    :nft="selectedNft"
    v-if="showSetPriceModal"
    :closeFunction="closeSetPriceModal"
  />
</template>
<script>
import { useStore } from "vuex";
import { computed } from "@vue/reactivity";
import usePool from "@/composables/usePool";
import AppButton from "@/elements/AppButton.vue";
import InputFormBox from "@/elements/InputFormBox.vue";
import SelectMyNft from "@/components/Pools/SelectMyNft";
import SetPriceModal from "@/components/Pools/SetPriceModal";
export default {
  name: "MintDetails",
  components: {
    AppButton,
    SelectMyNft,
    InputFormBox,
    SetPriceModal,
  },
  setup() {
    const inputFormBoxStyle = {
      background: "#0B0B0B",
      width: "calc(100% - 160px)",
      borderRadius: "0 36px 36px 36px",
    };

    const {
      pool,
      tokenId,
      // loadAllNfts,
      selectedNft,
      decrementStep,
      contractAddress,
      showSetPriceModal,
      removeSelectedNft,
      closeSetPriceModal,
      toggleSetPriceModal,
      togglePoolPreviewModal,
    } = usePool(useStore());

    // onMounted(async () => {
    //   await loadAllNfts();
    // });

    const togglePreviewModalEnabled = computed(() => {
      if (selectedNft.value.name) return true;
      if (contractAddress.value && tokenId.value) {
        selectedNft.value.contractId = tokenId.value;
        selectedNft.value.contractAddress = contractAddress.value;
        return true;
      }
      return false;
    });

    return {
      pool,
      tokenId,
      selectedNft,
      decrementStep,
      contractAddress,
      inputFormBoxStyle,
      showSetPriceModal,
      removeSelectedNft,
      closeSetPriceModal,
      toggleSetPriceModal,
      togglePoolPreviewModal,
      togglePreviewModalEnabled,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/depositNft.scss" />

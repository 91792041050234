<template>
  <div class="app-wrapper" v-if="isMobileDevice">
    <mobile-not-supported />
  </div>
  <div class="app-wrapper" :class="route.name" v-else>
    <metainfo>
      <template v-slot:title="{ content }">{{ content }}</template>
      <template v-slot:og(title)="{ content }">
        {{ content }} | THEOS
      </template>
    </metainfo>
    <app-menu-bar />
    <errors />
    <gift-nft />
    <exchange-tokens />
    <list-nft />
    <app-alert />
    <router-view />
    <buy-nft-modal />
    <has-submission />
    <withdraw-modal />
    <buy-listed-nft />
    <auction-modal />
    <connect-wallet />
    <accept-offer-modal />
    <insufficient-funds />
    <registration-modal v-if="registrationFlag" />
    <deposit-review-modal />
    <gift-listed-nft-modal />
    <connect-and-polygon-modal v-if="shouldRenderWeUsePolygonModal" />
    <add-metamask v-if="isMetamaskNotInstalled && installMetamaskFlag" />
    <bid-modal />
    <app-footer v-if="!isMobileDevice" v-show="user.address !== ''" />
    <switch-network-modal />
  </div>
</template>
<script>
import { useStore } from "vuex";
import { useMeta } from "vue-meta";
import { computed, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import Errors from "@/components/Errors";
import { ethereumService } from "@/main";
import AppAlert from "@/elements/AppAlert";
import useAuth from "@/composables/useAuth";
import AppFooter from "@/components/AppFooter";
import AppMenuBar from "@/components/AppMenuBar";
import AddMetamask from "@/components/AddMetamask";
import GiftNft from "@/components/GiftNft/GiftNft";
import { startWebSocket } from "@/services/webSocket";
import BidModal from "@/components/Auctions/BidModal";
import ConnectWallet from "@/components/ConnectWallet";
import BuyNftModal from "@/components/BuyNft/BuyNftModal";
import useUserProfile from "@/composables/useUserProfile";
import AuctionModal from "@/components/Auctions/AuctionModal";
import ExchangeTokens from "@/components/Swap/ExchangeTokens";
import InsufficientFunds from "@/components/InsufficientFunds";
import HasSubmission from "@/components/ListNft/HasSubmission";
import WithdrawModal from "@/components/Withdraw/WithdrawModal";
import MobileNotSupported from "@/components/MobileNotSupported";
import SwitchNetworkModal from "@/components/SwitchNetworkModal";
import BuyListedNft from "@/components/BuyListedNft/BuyListedNft";
import AcceptOfferModal from "@/components/Auctions/AcceptOfferModal";
import useRegistrationModal from "@/composables/useRegistrationModal";
import ConnectAndPolygonModal from "@/components/ConnectAndPolygonModal";
import GiftListedNftModal from "@/components/GiftNft/GiftListedNftModal";
import RegistrationModal from "@/components/Registration/RegistrationModal";
import DepositReviewModal from "@/components/DepositReview/DepositReviewModal";

export default {
  components: {
    Errors,
    GiftNft,
    BidModal,
    AppAlert,
    AppFooter,
    AppMenuBar,
    AddMetamask,
    BuyNftModal,
    BuyListedNft,
    AuctionModal,
    ConnectWallet,
    HasSubmission,
    WithdrawModal,
    ExchangeTokens,
    AcceptOfferModal,
    RegistrationModal,
    InsufficientFunds,
    MobileNotSupported,
    GiftListedNftModal,
    DepositReviewModal,
    SwitchNetworkModal,
    ConnectAndPolygonModal,
  },
  setup: function () {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isMobileDevice = ref(false);
    const { isBeginConnectionModalOpen } = useAuth(store, ethereumService);
    const { registrationFlag } = useRegistrationModal(store);
    const isMetamaskConnected = async () =>
      await ethereumService.isMetaMaskConnected();
    const shouldRenderWeUsePolygonModal = computed(() => {
      if (!isMetamaskConnected()) return false;
      return (
        !(isMetamaskNotInstalled() && installMetamaskFlag.value) &&
        isBeginConnectionModalOpen.value
      );
    });

    const handleWindowResize = () =>
      (isMobileDevice.value = window.innerWidth < 989);

    window.addEventListener("resize", handleWindowResize);

    const { meta } = useMeta({
      title: "THEOS",
      description: "DeFi Paradise for NFTs",
      og: {
        image: [process.env.VUE_APP_BASE_PATH + "/favicon.png"],
      },
    });

    const { user } = useUserProfile(store, router);

    const { isMetamaskNotInstalled, installMetamaskFlag } = useAuth(
      store,
      ethereumService
    );

    onMounted(async () => {
      handleWindowResize();
      const { handleAccountChange, handleChainChange } = useAuth(
        store,
        ethereumService,
        router
      );
      const isValidNetwork = await ethereumService.isValidNetwork();

      await store.dispatch("contracts/fetchNetworkName");
      await ethereumService.onAccountChanged(handleAccountChange);
      await ethereumService.onChainChanged(handleChainChange);
      await store.dispatch("userProfile/fetchMe");
      if (user.value?.publicAddress) {
        startWebSocket();
        if (!isValidNetwork) {
          await handleChainChange("Selected network is not supported.");
        }
        await ethereumService.setContractData();
        await store.dispatch("userProfile/isWhitelisted", {
          address: user.value.publicAddress,
        });
      }
      await store.dispatch("contracts/fetchCoinPrice", {});
      const { toggleRegistrationFlag } = useRegistrationModal(store);
      const isUserRegistered = async () => {
        await store.dispatch("auth/fetchMe");
        const me = store.getters["auth/getRegisteredUser"];
        return Boolean(me.displayName);
      };
      const registered = await isUserRegistered();
      if ((await ethereumService.isProviderConnected()) && !registered) {
        toggleRegistrationFlag();
      }
      // isMenuBarDataLoaded.value = true;
    });

    return {
      user,
      meta,
      route,
      isMobileDevice,
      registrationFlag,
      // isMenuBarDataLoaded,
      installMetamaskFlag,
      isMetamaskConnected,
      isMetamaskNotInstalled,
      isBeginConnectionModalOpen,
      shouldRenderWeUsePolygonModal,
    };
  },
};
</script>

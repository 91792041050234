import * as Yup from "yup";
import api from "../../services/api";
export const tokenSetupSchema = Yup.object().shape({
  tokenName: Yup.string().required("Token name is required"),
  tokenSymbol: Yup.string()
    .required("Token symbol is required")
    .min(3, "Must be 3 or 4 characters")
    .max(4, "Must be 3 or 4 characters")
    .test(
      "WHITESPACE",
      "This field cannot contain blankspaces",
      (value) => !/\s/.test(value)
    )
    .test("SYMBOL", "This symbol already exists.", async (value) => {
      let isUnique = false;
      if (value.length > 0) {
        try {
          isUnique = (await api.uniqueSymbol(value)).data?.unique;
        } catch (error) {
          isUnique = false;
        }
      }
      return !value || isUnique;
    }),
  nftBasePrice: Yup.number("Must be a number")
    .integer("Must be a whole number")
    .typeError("Must be a number")
    .nullable()
    .required("NFT Base price is required")
    .positive("Must be greater than 0")
    .max(1000000000, "Must not be greater than 1000000000"),
  maximumIPrice: Yup.number("Must be a number")
    .typeError("Must be a number")
    .nullable()
    .required("Maximum i price is required")
    .min(0, "Must be greater than 0")
    .max(50, "Must not be greater than 50"),
  slots: Yup.number("Must be a number")
    .integer("Should not have decimals")
    .typeError("Must be a number")
    .nullable()
    .positive("Must be greater than 0"),
  tokensPerNft: Yup.number("Must be a number")
    .typeError("Must be a number")
    .nullable()
    .positive("Must be greater than 0"),
});

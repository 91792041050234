<template>
  <div class="card-wrapper pointer">
    <img
      src="@/Common/Icons/Checkbox.svg"
      alt="Checkbox"
      class="selected-nft-checkbox"
    />
    <div class="card">
      <network-badge :token="token" :cardSize="40" />
      <div class="avatar-img-wrapper">
        <img :src="coverImage(token)" alt="Thumbnail" class="app-card-image" />
      </div>
      <div class="app-card-content">
        <div class="app-card-content-description">
          <div :class="[isStringTooLong(token.name, 20) ? 'is-too-long' : '']">
            <div class="nft-name-tooltip">{{ token.name }}</div>
            <p
              class="app-card-title"
              :class="[isStringTooLong(token.name, 20) ? 'is-too-long' : '']"
            >
              {{ token.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import useDeposit from "@/composables/useDeposit";
import useUtilities from "@/composables/useUtilities";
import imageTransformer from "../plugins/imageTransformer";
import NetworkBadge from "@/components/Profile/PanelCard/NetworkBadge";
export default {
  components: {
    NetworkBadge,
  },
  props: {
    token: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const store = useStore();
    const { theosMinted } = useDeposit(store);
    const { isStringTooLong } = useUtilities();

    const coverImage = (token) => {
      if (theosMinted.value)
        return token?.coverImage && token?.coverImage?.filename
          ? imageTransformer.transform(token.coverImage.filename, "nft.list")
          : null;
      return token.coverImageURL;
    };
    return {
      coverImage,
      isStringTooLong,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/nftSubmitCard.scss" />

import { Cloudinary, Transformation } from "@cloudinary/base";
import { fill } from "@cloudinary/base/actions/resize";
import { format } from "@cloudinary/base/actions/delivery";
import byRadius from "@cloudinary/base/actions/roundCorners/byRadius";
import { ifCondition } from "@cloudinary/base/actions/conditional";

const CLOUD_NAME = process.env.VUE_APP_CLOUDINARY_CLOUD_NAME || "theos-fi";
// Docs on how to format image using Cloudinary:
// https://cloudinary.com/documentation/sdks/js/cloudinary-js-base/Actions.Delivery.html#.format
// https://cloudinary.com/documentation/resizing_and_cropping
// https://cloudinary.com/documentation/javascript2_quick_start
// https://cloudinary.com/documentation/javascript2_image_transformations
const imageStyles = {
  original: function (image) {
    return image;
  },
  "user.avatar": function (image) {
    return image.resize(fill().height(400).width(400));
  },
  "user.profile": function (image) {
    return image.resize(fill().height(400).width(400));
  },
  "nft.background": function (image) {
    return image.delivery(format("png"));
  },
  "nft.show.image": function (image) {
    return image
      .delivery(format("auto"))
      .conditional(
        ifCondition(
          "width = height",
          new Transformation().resize(fill().height(1000).aspectRatio("1:1"))
        )
      )
      .conditional(
        ifCondition(
          "width < height",
          new Transformation().resize(fill().height(1000).aspectRatio("4:5"))
        )
      )
      .conditional(
        ifCondition(
          "width > height",
          new Transformation().resize(fill().width(1000).aspectRatio("16:9"))
        )
      )
      .roundCorners(byRadius(14));
  },
  "auction.image": function (image) {
    return image.resize(fill().height(400).width(400));
  },
  "nft.list": function (image) {
    return image.delivery(format("auto")).resize(fill().height(275).width(275));
  },
};

class ImageTransformer {
  constructor(styleObject, cloudName) {
    this.styleObject = styleObject;
    this.cloudinary = new Cloudinary({ cloud: { cloudName: cloudName } });
  }

  transform(filename, style) {
    return this.styleObject[style](this.cloudinary.image(filename)).toURL();
  }
  transformVideo(filename, style) {
    return this.styleObject[style](this.cloudinary.video(filename)).toURL();
  }
}

const cloudinaryInstance = new ImageTransformer(imageStyles, CLOUD_NAME);
export default cloudinaryInstance;

import queryString from "query-string";
import { ethereumService } from "../main";
import { ref, computed, reactive } from "vue";
import useUtilities from "@/composables/useUtilities";

const showing = ref("nfts");
const theosMinted = ref(true);
const loadingNfts = ref(false);
const loadingPools = ref(false);
const loadingPooled = ref(false);

const externalNftDataListToShow = ref([]);

const useProfilePanels = (store) => {
  const userId = computed(() => store.getters["userProfile/getUser"].id);
  const walletAddress = computed(() => store.getters["contracts/getAddress"]);
  const publicAddress = computed(() => store.getters["contracts/getAddress"]);

  const showSubmissionActionModal = computed(() =>
    store.getters["userProfile/getByKey"]("showSubmissionActionModal")
  );
  const toggleShowSubmissionActionModal = () =>
    store.dispatch("userProfile/toggleShowSubmissionActionModal");

  let params = {
    owner: walletAddress.value,
    page: 1,
    perPage: 8,
    orderBy: "id",
    orderDirection: "DESC",
  };

  const { toggle } = useUtilities();

  const filterStyle = {
    background: "#0B0B0B",
    border: "2px solid #2C2C2C",
  };

  const showFilters = ref(false);

  const filters = reactive({
    filterOwned: false,
    filterPooled: false,
    filterCreated: false,
    filterNotPooled: false,
    filterSlotsAvailable: false,
    filterLowestFloorPrice: false,
    filterSlotsNotAvailable: false,
    filterHighestFloorPrice: false,
  });

  const toggleFilter = async (filter) => {
    filters[filter] = !filters[filter];
    const publicAddress = await store.dispatch("contracts/fetchAddress");
    params = {
      page: 1,
      perPage: 8,
      orderBy: "id",
      orderDirection: "DESC",
      status: ["approved"],
    };
    let isAnythingSelected = false;
    for (const [key, value] of Object.entries(filters)) {
      isAnythingSelected = isAnythingSelected || value;
      if (value) {
        switch (key) {
          case "filterNotPooled":
            break;
          case "filterLowestFloorPrice":
            break;
          case "filterHighestFloorPrice":
            break;
          case "filterPooled":
            params.status = "deposited";
            params.owner = publicAddress;
            break;
          case "filterOwned":
            params.owner = publicAddress;
            break;
          case "filterCreated":
            params.creator = publicAddress;
            break;
        }
      }
    }
    if (!isAnythingSelected) params.owner = publicAddress;
    store.dispatch("userProfile/clearNftDataList");
    store.dispatch("userProfile/clearPoolsDataList");
    store.dispatch("userProfile/clearExternalNftDataList");
    store.dispatch("userProfile/clearPooledNftsDataList");
    if (filter === "filterPools") {
      delete params["status"];
      delete params["owner"];
      params.originatorId = userId.value;
      await fetchPoolsList(queryString.stringify(params));
    } else if (filter === "filterPooled") {
      await fetchPooledNftList(queryString.stringify(params));
    } else if (theosMinted.value) {
      await fetchNftList(queryString.stringify(params));
    } else await fetchExternalNftList({ id: userId.value });
  };

  const searchString = ref("");

  const profilePanelNoItemsText = computed(() =>
    showing.value === "nfts"
      ? "You're not the owner of any NFT at the moment."
      : "You're not part of any pool at the moment. Try creating your own."
  );

  const profilePanelNoItemsTextLineTwo = computed(
    () => "Try minting your own or purchasing one from the Pools Gallery."
  );

  const show = (value) => {
    if (!loadingNfts.value) showing.value = value;
  };

  const nftsList = computed(() =>
    store.getters["userProfile/getByKey"]("nftsList")
  );
  const externalNftsList = computed(() =>
    store.getters["userProfile/getByKey"]("externalNftsList")
  );

  const pooledNftsList = computed(() =>
    store.getters["userProfile/getByKey"]("pooledNftsList")
  );

  const pooledNftDataList = computed(() =>
    store.getters["userProfile/getByKey"]("pooledNftDataList")
  );

  const poolsList = computed(() =>
    store.getters["userProfile/getByKey"]("poolsList")
  );
  const nftDataList = computed(() =>
    store.getters["userProfile/getByKey"]("nftDataList")
  );
  const poolsDataList = computed(() =>
    store.getters["userProfile/getByKey"]("poolsDataList")
  );
  const externalNftDataList = computed(() =>
    store.getters["userProfile/getByKey"]("externalNftDataList")
  );

  const storeExternalNft = async (token) => {
    try {
      const { fileResponse, blob, data } = await fetchMetadata(token);
      const nftData = {
        name: data.name,
        description: data.description.substring(0, 200),
        isExplicit: false,
        unlockableContent: null,
        creatorWalletAddress: await ethereumService.getAddress(),
        fileId: fileResponse.data.id,
        coverImageId: fileResponse.data.id,
        fileType: blob.type,
        metaData: { ...data },
      };

      const nft = await store.dispatch("userProfile/storeExternalNft", nftData);
      await store.dispatch("nfts/updateNft", {
        id: nft.id,
        nftData: {
          contractAddress: token.token_address,
          contractId: token.token_id,
          name: nft.name,
          description: nft.description,
          status: "approved",
        },
      });
      return nft;
    } catch (err) {
      throw new Error("Could not load metadata");
    }
  };

  const storeCoverImageFile = async (file) => {
    const fd = new FormData();
    fd.append("file", file);
    fd.append("type", "cover");
    return store.dispatch("nfts/storeCoverImageFile", fd);
  };

  const ipfsToHttpsLink = (url) => {
    return `https://ipfs.io/ipfs/${url
      .split("/")
      .slice(2)
      .join("/")
      .replace("ipfs/", "")}`;
  };

  const openSeaToHttpLink = (url, id) => url.replace("0x{id}", id);

  const isIpfsUrl = (url) => url?.includes("ipfs://");

  const isOpenSeaUrl = (url) => url?.includes("opensea.io");

  const getMetadata = async (metadataUrl, id) => {
    // is ipfs
    let url;
    if (isIpfsUrl(metadataUrl)) {
      url = ipfsToHttpsLink(metadataUrl);
    }
    // is opensea
    if (isOpenSeaUrl(metadataUrl)) {
      url = openSeaToHttpLink(metadataUrl, id);
    }

    const result = await fetch(url);
    if (result.status !== 200) throw new Error("Could not fetch nft data");

    const data = await result.json();
    return {
      ...data,
      metadata: metadataUrl,
      image: data.image_url || data.image,
    };
  };

  const getImage = async (url, name) => {
    if (isIpfsUrl(url)) url = ipfsToHttpsLink(url);

    const imageResponse = await fetch(url);
    if (imageResponse.status !== 200)
      throw new Error("Could not fetch nft data");

    const blob = await imageResponse.blob();
    return {
      file: new File([blob], name, {
        type: blob.type,
        lastModified: new Date().getTime(),
      }),
      blob,
    };
  };

  const fetchMetadata = async (token) => {
    let metadataUrl = await ethereumService.fetchNftMetadata(
      token.token_id,
      token.token_address,
      token.contract_type
    );
    const metadata = await getMetadata(metadataUrl, token.token_id);
    const { name, image } = metadata;
    const { file, blob } = await getImage(image, name);
    const fileResponse = await storeCoverImageFile(file);
    return { fileResponse, blob, data: metadata };
  };

  const externalNftsToShowList = computed(() =>
    store.getters["userProfile/getByKey"]("externalNftsToShowList")
  );

  const submissionCount = computed(() =>
    store.getters["userProfile/getByKey"]("submissionCount")
  );
  const poolsCount = computed(() =>
    store.getters["userProfile/getByKey"]("poolsCount")
  );

  const fetchSubmissionCount = async () => {
    await store.dispatch("userProfile/fetchSubmissionCount");
  };

  const fetchPoolsCount = async (id) => {
    await store.dispatch("userProfile/fetchPoolsCount", id);
  };

  const checkExistingPermissionsForNft = async (nftId) => {
    const permissionsCount = await store.dispatch(
      "userProfile/checkExistingPermissions",
      nftId
    );
    return permissionsCount.data >= 2;
  };

  const fetchNftList = async (params) => {
    if (loadingNfts.value) return;
    loadingNfts.value = true;
    await store.dispatch("userProfile/fetchNftList", { params });
    loadingNfts.value = false;
  };

  const fetchPooledNftList = async (params) => {
    if (loadingPooled.value) return;
    loadingPooled.value = true;
    setTimeout(async () => {
      await store.dispatch("userProfile/fetchPooledNftList", { params });
      loadingPooled.value = false;
    }, 500);
  };

  const externalPage = ref(1);

  const setExternalPage = (value) => (externalPage.value = value);

  const fetchExternalNftList = async () => {
    loadingNfts.value = true;
    setTimeout(async () => {
      await store.dispatch("userProfile/fetchExternalNftList", {
        id: userId.value,
        page: externalPage.value,
      });
      loadingNfts.value = false;
    }, 500);
  };

  const fetchPoolsList = async (params) => {
    if (loadingPools.value) return;
    loadingPools.value = true;
    setTimeout(async () => {
      await store.dispatch("userProfile/fetchPools", { params });
      loadingPools.value = false;
    }, 500);
  };

  const clearNftList = () => store.dispatch("userProfile/clearNftList");

  const search = async (isPooled = false) => {
    if (params.search !== searchString.value) {
      params = {
        owner: walletAddress.value,
        page: 1,
        perPage: 8,
        orderBy: "id",
        orderDirection: "DESC",
        status: ["approved"],
      };
      store.dispatch("userProfile/clearNftDataList");
      store.dispatch("userProfile/clearPooledNftsDataList");
      store.dispatch("userProfile/clearPoolsDataList");
      params.search = searchString.value;
      if (searchString.value === "") params.creator = walletAddress.value;
      if (showing.value === "pools") {
        delete params["status"];
        delete params["creator"];
        delete params["owner"];
        params.originatorId = userId.value;
        await fetchPoolsList(queryString.stringify(params));
      } else if (isPooled) {
        params.status = [isPooled];
        await fetchPooledNftList(queryString.stringify(params));
      } else if (theosMinted.value) {
        await fetchNftList(queryString.stringify(params));
      } else if (!theosMinted.value) {
        await fetchExternalNftList(userId.value);
      }
    }
  };

  const loadMorePools = async () => {
    params.page += 1;
    await fetchPoolsList(queryString.stringify(params));
  };

  const loadMore = async (isPooled = false) => {
    params.page += 1;
    !isPooled
      ? await fetchNftList(queryString.stringify(params))
      : await fetchPooledNftList(queryString.stringify(params));
  };

  const loadMoreExternal = async () => {
    externalPage.value += 1;
    await fetchExternalNftList({ id: userId.value, page: externalPage.value });
  };

  const theosMintedCheckboxIcon = () =>
    theosMinted.value
      ? require("@/Common/Icons/radio-minting-checked.png")
      : require("@/Common/Icons/radio-minting-unchecked.png");
  const otherPlatformsMintedCheckboxIcon = () =>
    theosMinted.value
      ? require("@/Common/Icons/radio-minting-unchecked.png")
      : require("@/Common/Icons/radio-minting-checked.png");

  const setTheosMinted = (val = true) => {
    if (!loadingNfts.value) theosMinted.value = val;
  };

  const checkIfNftWhitelisted = async (nftAddress, nftId, poolAddress) => {
    if (!nftAddress || !nftId || !poolAddress) return false;

    try {
      return await ethereumService.isWhitelisted(
        nftAddress,
        nftId,
        poolAddress
      );
    } catch (error) {
      return false;
    }
  };

  return {
    filtersFlag: {
      showFilters,
    },
    show,
    userId,
    params,
    search,
    toggle,
    filters,
    showing,
    loadMore,
    nftsList,
    poolsList,
    poolsCount,
    loadingNfts,
    theosMinted,
    nftDataList,
    filterStyle,
    loadingPools,
    externalPage,
    clearNftList,
    fetchNftList,
    toggleFilter,
    searchString,
    loadingPooled,
    publicAddress,
    poolsDataList,
    fetchMetadata,
    loadMorePools,
    pooledNftsList,
    setTheosMinted,
    fetchPoolsList,
    fetchPoolsCount,
    submissionCount,
    setExternalPage,
    loadMoreExternal,
    storeExternalNft,
    externalNftsList,
    pooledNftDataList,
    fetchPooledNftList,
    externalNftDataList,
    fetchSubmissionCount,
    fetchExternalNftList,
    checkIfNftWhitelisted,
    externalNftsToShowList,
    theosMintedCheckboxIcon,
    profilePanelNoItemsText,
    externalNftDataListToShow,
    showSubmissionActionModal,
    checkExistingPermissionsForNft,
    profilePanelNoItemsTextLineTwo,
    toggleShowSubmissionActionModal,
    otherPlatformsMintedCheckboxIcon,
  };
};

export default useProfilePanels;

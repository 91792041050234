import * as Yup from "yup";

const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "image/gif",
  "video/mp4",
  "video/webm",
  "audio/mp3",
  "audio/webm",
  "audio/mpeg",
  "video/mpeg",
  "audio/wav",
  "application/pdf",
];
const MAX_FILE_SIZE = 41943040;

export const mintNftFileScheme = Yup.object().shape({
  selectedFile: Yup.mixed()
    .nullable()
    .required()
    .test(
      "FILE_SIZE",
      "The File Size should be less then 40MB",
      (value) => value.size <= MAX_FILE_SIZE
    )
    .test(
      "FILE_FORMAT",
      "Unsupported file, try again",
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    ),
});

<template>
  <div class="profile-panel-body-wrapper">
    <div class="filters-search">
      <div class="profile-panel-search">
        <app-input
          v-model="searchString"
          inputStyle="padding-left: 35px"
          @keydown.enter.stop="searchFilter"
          inputClass="app-modal-input-search"
          placeholder="Search by name, pool or tag"
        />
        <img
          alt="search"
          class="submissions-search-icon"
          src="@/Common/Icons/search_icon.png"
        />
        <img
          alt="delete"
          v-show="searchString != ''"
          @click="resetSearchString()"
          class="submissions-search-cross"
          src="@/Common/Icons/search_cross.png"
        />
      </div>
      <div class="profile-panel-filter">
        <div class="profile-panel-filter-wrapper">
          <div
            class="profile-panel-filter-overlay"
            @click="toggle(filtersFlag.showFilters)"
          />
          <app-input
            disabled
            inputClass="app-modal-input-sort"
            placeholder="Filter and sort"
            inputIconLeftClass="filter-icon"
            :inputStyle="'padding-left: 35px;'"
            :inputIconRightClass="{
              'chevron-icon': true,
              active: filtersFlag.showFilters.value,
            }"
          />
        </div>
        <teleport to="body">
          <div
            class="filtering-options-overlay"
            v-if="filtersFlag.showFilters.value"
            @click="toggle(filtersFlag.showFilters)"
          />
        </teleport>
        <div class="filtering-options" v-if="filtersFlag.showFilters.value">
          <h3 class="filtering-options-heading">Sort by</h3>
          <p
            class="filtering-option"
            :class="{ active: activeTab === null }"
            @click="toggleFilter(null, null)"
          >
            All
          </p>
          <p
            class="filtering-option"
            :class="{ active: activeTab === 'pending' }"
            @click="toggleFilter('submissions', 'pending')"
          >
            Pendings
          </p>
          <p
            class="filtering-option"
            :class="{ active: activeTab === 'non-pending' }"
            @click="toggleFilter('submissions', 'non-pending')"
          >
            No Pendings
          </p>
        </div>
      </div>
    </div>
    <div v-if="poolsCount < 1">
      <no-data
        title="Nothing to see here yet"
        :buttonClickHandler="explorePools"
        textBot="This user isn't managing any pool"
        :image="require('@/Common/Icons/cloudNoData.png')"
      />
    </div>
    <div v-else style="width: 100%">
      <div class="container" v-if="!isPublic">
        <v-server-table
          :url="tableUrl"
          ref="poolsTablePrivate"
          :columns="columnsPrivate"
          :options="optionsPrivate"
          @row-click="handleRowClick"
        >
          <template v-slot:name="{ row }">
            <div class="field-with-thumbnail">
              <div class="pool-thumbnail">
                <img :src="row.coverImage.path" alt="" />
              </div>
              <div class="pool-panel-column-name">
                <div
                  class="pool-panel-name"
                  :class="[isStringTooLong(row.name, 30) ? 'is-too-long' : '']"
                >
                  <div class="tooltip">{{ row.name }}</div>
                  {{ row.name.substring(0, 30) }}
                </div>
                <div class="pool-panel-originator">
                  By <a class="link" href="/profile/me">You</a>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:nfts="{ row }">
            <div class="pool-panel-text-light">
              {{ row.depositedNFTsCount }}
            </div>
          </template>
          <template v-slot:tvl="{ row }">
            <div class="pool-panel-text">
              ${{ toFixedIfNecessary(row.tvl, 2) }}
            </div>
          </template>
          <template v-slot:tokenValue="{ row }">
            <div class="pool-panel-text">
              <div class="pool-panel-text-yellow">
                {{ toFixedIfNecessary(row.basePrice, 2) }} {{ row.tokenSymbol }}
              </div>
              <div>≈ ${{ toFixedIfNecessary(row.basePriceUSD, 2) }}</div>
            </div>
          </template>
          <template v-slot:submissions="{ row }">
            <div
              class="pools-panel-column-with-icon"
              v-if="row.hasPendingSubmission"
            >
              <div class="pools-panel-icon">
                <img :src="require(`@/Common/Icons/Warning.svg`)" alt="" />
              </div>
              <div class="pool-panel-text-small">Pendings</div>
            </div>
            <div class="pools-panel-column-with-icon" v-else>
              <div class="pools-panel-icon">
                <img :src="require(`@/Common/Icons/Success.png`)" alt="" />
              </div>
              <div class="pool-panel-text-small">No pendings</div>
            </div>
          </template>
        </v-server-table>
        <load-more
          currentPanel=""
          :loadMore="loadMore"
          :loadingMore="false"
          v-if="showLoadMoreButton"
        />
      </div>
      <div class="container" v-else>
        <v-server-table
          :url="tableUrl"
          ref="poolsTablePublic"
          :columns="columnsPublic"
          :options="optionsPublic"
          @row-click="handleRowClick"
        >
          <template v-slot:name="{ row }">
            <div class="field-with-thumbnail">
              <div class="pool-thumbnail">
                <img :src="row.coverImage.thumbnail" alt="" />
              </div>
              <div class="pool-panel-column-name">
                <div
                  class="pool-panel-name"
                  :class="[isStringTooLong(row.name, 20) ? 'is-too-long' : '']"
                >
                  <div class="tooltip">{{ row.name }}</div>
                  {{ row.name.substring(0, 20) }}
                </div>
                <div class="pool-panel-originator">
                  By
                  <a class="link" :href="`/profile/${user.id}`">{{
                    user.displayName
                  }}</a>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:basePrice="{ row }">
            <div class="pool-panel-text-light">
              ${{ toFixedIfNecessary(row.basePriceUSD, 2) }}
            </div>
          </template>
          <template v-slot:maxPrice="{ row }">
            <div class="pool-panel-text">
              +{{ toFixedIfNecessary(row.maximumIPrice, 2) }}%
            </div>
          </template>
          <template v-slot:tvl="{ row }">
            <div class="pool-panel-text">
              ${{ toFixedIfNecessary(row.tvl, 2) }}
            </div>
          </template>
          <template v-slot:socialCause="{ row }">
            <div class="pools-panel-column-with-icon">
              <div class="pools-panel-icon-social">
                <img
                  :src="require(`@/Common/Icons/SocialCauseAvatar.png`)"
                  alt=""
                  style="width: 24px"
                />
              </div>
              <div class="pool-panel-text-small">{{ row.contribution }}%</div>
            </div>
          </template>
          <template v-slot:submitButton="{}">
            <div class="pools-table-submit-button">
              <app-button
                text="Submit NFT"
                @click="handleSubmit()"
                class="app-modal-button-transparent"
              />
            </div>
          </template>
        </v-server-table>
        <load-more
          currentPanel=""
          :loadMore="loadMore"
          :loadingMore="false"
          v-if="showLoadMoreButton"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import AppInput from "@/elements/AppInput";
import AppButton from "@/elements/AppButton";
import useRouter from "@/composables/useRouter";
import useUtilities from "@/composables/useUtilities";
import NoData from "@/components/Profile/Panels/NoData";
import useUserProfile from "@/composables/useUserProfile";
import useProfileView from "@/composables/useProfileView";
import LoadMore from "@/components/Profile/Panels/LoadMore";
import useProfilePanels from "@/composables/useProfilePanels";
export default {
  components: {
    NoData,
    AppInput,
    LoadMore,
    AppButton,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: true,
    },
    currentPanel: {
      type: String,
      default: "",
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({ data: [], total: 0 }),
    },
  },
  setup: function (props) {
    const store = useStore();
    const mouseOver = ref(null);
    const loading = ref(false);
    const poolsTablePrivate = ref(null);
    const poolsTablePublic = ref(null);
    const showLoadMoreButton = ref(false);
    const { getUser } = useUserProfile(store);
    const user = getUser();

    const tableUrl = `/pools`;

    const optionsPrivate = ref({
      perPage: 8,
      perPageValues: [],
      resizableColumns: false,
      orderBy: {
        ascending: false,
      },
      params: {
        originatorId: user.value.id,
      },
      pagination: {
        virtual: true,
        chunk: 8,
      },
      requestAdapter(data) {
        const params = {
          page: data.page,
          perPage: optionsPrivate.value.perPage,
          orderBy: "createdAt",
          originatorId: user.value.id,
          orderDirection: data.ascending ? "ASC" : "DESC",
        };
        if (data.search) {
          params.search = data.search;
        }
        if (data.filterBy) {
          params.filterBy = data.filterBy;
          params.filterValue = data.filterValue;
        }
        return params;
      },
      responseAdapter({ data }) {
        // showLoadMoreButton.value = optionsPrivate.value.perPage < data.total;
        store.dispatch("profilePanels/commitPoolData", {
          data: data.data,
          total: data.total,
        });
        return {
          data: data.data,
          count: data.total,
        };
      },
      filterable: false,
      headings: {
        name: "Pool Name",
        nfts: "NFTs",
        tvl: "TVL",
        tokenValue: "TokenValue",
        submissions: "Submissions",
      },
      sortable: ["name"],
    });

    const optionsPublic = ref({
      perPage: 8,
      perPageValues: [],
      resizableColumns: false,
      orderBy: {
        ascending: false,
      },
      params: {
        originatorId: user.value.id,
      },
      pagination: {
        virtual: true,
        chunk: 8,
      },
      requestAdapter(data) {
        const params = {
          page: data.page,
          perPage: optionsPublic.value.perPage,
          orderBy: "createdAt",
          originatorId: user.value.id,
          orderDirection: data.ascending ? "ASC" : "DESC",
        };
        if (data.search) {
          params.search = data.search;
        }
        if (data.filterBy) {
          params.filterBy = data.filterBy;
          params.filterValue = data.filterValue;
        }
        return params;
      },
      responseAdapter({ data }) {
        // showLoadMoreButton.value = optionsPublic.value.perPage < data.total;
        store.dispatch("profilePanels/commitPoolData", {
          data: data.data,
          total: data.total,
        });
        return {
          data: data.data,
          count: data.total,
        };
      },
      filterable: false,
      headings: {
        name: "Pool Name",
        basePrice: "Base price",
        maxPrice: "Max price",
        tvl: "TVL",
        socialCause: "Social Cause",
        submitButton: "",
      },
      sortable: ["name"],
    });
    const columnsPrivate = ["name", "nfts", "tvl", "tokenValue", "submissions"];
    const columnsPublic = [
      "name",
      "basePrice",
      "maxPrice",
      "tvl",
      "socialCause",
      "submitButton",
    ];

    const disabled = ref(true);
    const status = ref("");
    const {
      list,
      searchString,
      filtersFlag,
      filtersStyle,
      toggle,
      filters,
      profilePanelNoItemsText,
      // fetchPoolsCount,
      poolsCount,
    } = useProfilePanels(store);

    // fetchPoolsCount(user.value.id);

    const { submissionsTimeRemaining, isStringTooLong } = useUtilities();

    const resetDropdown = () => {
      status.value = "";
      disabled.value = true;
    };

    const avatarImage = (user) =>
      user.coverImage ? user.coverImage : require("@/Common/Icons/Avatar.png");
    const onChange = () => {
      if (status.value === "accept" || status.value === "reject") {
        disabled.value = false;
      }
    };

    const searchFilter = () => {
      if (poolsCount.value !== 0) {
        let options = props.isPublic ? optionsPublic : optionsPrivate;
        let table = props.isPublic ? poolsTablePublic : poolsTablePrivate;
        options.value.params.search = searchString.value;
        options.value.perPage = 8;
        table.value.refresh();
        resetDropdown();
      }
    };

    const explorePools = () => {
      console.log("implement me");
    };

    let activeTab = ref(null);

    const toggleFilter = (type, value) => {
      if (poolsCount.value !== 0) {
        let options = props.isPublic ? optionsPublic : optionsPrivate;
        let table = props.isPublic ? poolsTablePublic : poolsTablePrivate;
        options.value.params.filterBy = type;
        options.value.params.filterValue = value;
        activeTab.value = value;
        table.value.refresh();
        resetDropdown();
      }
    };

    const statusImage = (status) => {
      return status + "Status.png";
    };

    const loadMore = () => {
      let options = props.isPublic ? optionsPublic : optionsPrivate;
      let table = props.isPublic ? poolsTablePublic : poolsTablePrivate;
      options.value.perPage += 5;
      table.value.getData();
    };

    const { goTo } = useRouter();

    const handleRowClick = async (event) => {
      if (!props.isPublic && event.row.hasPendingSubmission) {
        await goTo({
          name: "ViewPool",
          params: { id: event.row.id },
          query: { tab: "submissions" },
        });
      } else {
        if (!submitClicked.value) {
          await goTo({ name: "ViewPool", params: { id: event.row.id } });
        }
      }
    };

    let submitClicked = ref(false);

    const handleSubmit = async () => {
      submitClicked.value = true;
      await goTo({ name: "ViewProfilePrivate" });
      await setCurrentPanel("nfts");
    };

    const { setCurrentPanel } = useProfileView(useStore());

    const { toFixedIfNecessary } = useUtilities();

    const resetSearchString = () => {
      searchString.value = "";
    };
    return {
      user,
      list,
      goTo,
      toggle,
      status,
      loading,
      filters,
      tableUrl,
      disabled,
      onChange,
      loadMore,
      activeTab,
      mouseOver,
      poolsCount,
      statusImage,
      filtersFlag,
      avatarImage,
      searchString,
      filtersStyle,
      explorePools,
      searchFilter,
      toggleFilter,
      handleSubmit,
      optionsPublic,
      columnsPublic,
      optionsPrivate,
      columnsPrivate,
      handleRowClick,
      isStringTooLong,
      setCurrentPanel,
      poolsTablePublic,
      poolsTablePrivate,
      resetSearchString,
      toFixedIfNecessary,
      showLoadMoreButton,
      profilePanelNoItemsText,
      submissionsTimeRemaining,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/submissionsPanel.scss" />

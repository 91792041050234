<template>
  <app-modal :showCloseLink="false" :showCancel="false">
    <template v-slot:app-modal-header>
      <div class="spinner file-input new">
        <app-loader :show="true" :isGreen="true" />
      </div>
      <h3 class="app-modal-listing-title">SENDING...</h3>
    </template>
    <template v-slot:app-modal-body>
      <p class="app-modal-body-text">The transaction is processing...</p>
    </template>
  </app-modal>
</template>

<script>
import AppModal from "@/elements/AppModal";
import AppLoader from "@/elements/AppLoader";
import { onMounted, onUnmounted } from "vue";
export default {
  name: "FinishGiftingNftModal",
  components: {
    AppModal,
    AppLoader,
  },
  setup() {
    onMounted(() => document.body.classList.add("stop-scroll"));
    onUnmounted(() => document.body.classList.remove("stop-scroll"));
    return {};
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/listNftModal.scss"></style>

<template>
  <div class="nft-detail">
    <div class="nft-detail-background" :style="backgroundImage" />
    <div class="nft-description">
      <nft-description />
    </div>
    <div class="nft-media">
      <nft-media :nft="nft" />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, onMounted } from "vue";
import useNftDetails from "@/composables/useNftDetails";
import NftMedia from "@/components/NftDetails/NftMedia";
import imageTransformer from "@/plugins/imageTransformer";
import NftDescription from "@/components/NftDetails/NftDescription";

export default {
  components: {
    NftMedia,
    NftDescription,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    onMounted(async () => {
      const response = await store.dispatch(
        "nftDetails/fetchNft",
        route.params.id
      );
      if (response.status === "deposited") {
        await store.dispatch("nftDetails/fetchPool", response.pools[0].id);
      }
    });

    const { nft } = useNftDetails(store);
    const backgroundImage = computed(() => {
      return nft.value.coverImage.path !== ""
        ? `background: linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)) , url("${imageTransformer.transform(
            nft.value.coverImage.filename,
            "nft.background"
          )}") no-repeat center center/cover`
        : "";
    });
    return {
      nft,
      backgroundImage,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/nftDetails.scss" />

import * as Yup from "yup";

export const socialCauseSchema = Yup.object().shape({
  royalties: Yup.number()
    .transform((number) => (isNaN(number) ? undefined : number))
    .test(
      "MUST_BE_A_NUMBER",
      "Royalties must be a number",
      (number) => !isNaN(number)
    )
    .positive()
    .min(0, "Min value must be larger then 0")
    .max(50, "Max value must be less than 50")
    .nullable()
    .test(
      "MAX_DECIMALS",
      "Royalties field must have 2 decimals or less",
      (number) =>
        new RegExp(/^[0-9]\d*?(?:\.\d{0,2})?$|^null$|^undefined$/g).test(number)
    ),
});

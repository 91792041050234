import useAuth from "../composables/useAuth";
import store from "../store";
import { ethereumService } from "@/main";

export default {
  async handleUnauthorizedRequest(error) {
    if (error.response.status === 401) {
      const { loginOrSignUp, isLoggedIn } = useAuth(store, ethereumService);
      if (isLoggedIn.value) {
        await store.dispatch("auth/disconnect");
        await loginOrSignUp(true);
      }
      return true;
    }
    return false;
  },
};

import { computed } from "vue";

const useNetworkData = (ethereumService) => {
  const networkParams = computed(() => ethereumService.networkParams);

  const parsedNetworkName = computed(() =>
    ["0x1", "0x5"].includes(networkParams.value.chainId)
      ? "Ethereum"
      : "Polygon"
  );

  const parseNetworkNameFromNft = (nft) => {
    return ["mumbai", "polygon"].includes(nft.network) ? "Polygon" : "Ethereum";
  };

  const isNftOnCurrentNetwork = (nft) => {
    const nftNetwork = parseNetworkNameFromNft(nft);
    return parsedNetworkName.value === nftNetwork;
  };

  const networkLogo = computed(() => {
    return require(parsedNetworkName.value === "Polygon"
      ? "@/Common/Icons/polygon-logo.svg"
      : "@/Common/Icons/eth.svg");
  });

  return {
    networkLogo,
    networkParams,
    parsedNetworkName,
    isNftOnCurrentNetwork,
  };
};

export default useNetworkData;

import useAuth from "./useAuth";
import useToast from "./useToast";
import { BigNumber } from "ethers";
import { computed, ref } from "vue";
import { ethereumService } from "@/main";
import useNetworkData from "./useNetworkData";
import useUtilities from "@/composables/useUtilities";
import useInsufficientFunds from "./useInsufficientFunds";

const bidInProgressFlag = ref(false);
const lastCheckFlag = ref(false);

const useBid = (store) => {
  const { showToast } = useToast();
  const { toFixedIfNecessary, selectTokenFromList, parseTokenList } =
    useUtilities();
  const { toggleInsufficientFundsModal } =
    useInsufficientFunds(ethereumService);
  const { toggleWalletConnectionRequired } = useAuth(store, ethereumService);
  const { isNftOnCurrentNetwork } = useNetworkData(ethereumService);

  const user = computed(() => store.getters["userProfile/getUser"]);
  const me = computed(() => store.getters["auth/getRegisteredUser"]);

  const bidFlag = computed(() => store.getters["bid/getByKey"]("bidFlag"));

  const selectedType = computed(() =>
    store.getters["listNft/getByKey"]("selectedType")
  );
  const nft = computed(() => store.getters["bid/getByKey"]("nft"));

  const showLastCheckForBid = computed(() =>
    store.getters["bid/getByKey"]("showLastCheckForBid")
  );

  const placeBidErrors = computed(() =>
    store.getters["bid/getByKey"]("errors")
  );

  const toggleBidFlag = () => store.dispatch("bid/toggleBidFlag");

  const fetchNft = async (id) => {
    return await store.dispatch("bid/fetchNft", id);
  };

  const bidOnNft = async (nft) => {
    if (!user.value.publicAddress) {
      toggleWalletConnectionRequired();
      return;
    }
    await fetchNft(nft.id);

    if (isNftOnCurrentNetwork(nft)) {
      return toggleBidFlag();
    }
    return store.dispatch("auth/commitByKey", {
      switchNetworkModalFlag: true,
    });
  };

  const resetState = () => {
    store.dispatch("bid/resetState");
  };

  const setSelectedType = (type) => {
    store.dispatch("bid/commitByKey", {
      selectedType: type,
    });
  };

  const offer = computed(() => store.getters["bid/getByKey"]("offer"));

  const setOffer = (price) => {
    store.dispatch("bid/commitByKey", {
      offer: price,
    });
  };

  const selectedToken = computed(() =>
    store.getters["bid/getByKey"]("selectedToken")
  );

  const setSelectedToken = (token) =>
    store.dispatch("bid/commitByKey", {
      selectedToken: token,
    });

  const closeLastCheck = async (remember) => {
    if (remember) {
      localStorage.setItem("showLastCheckForBid", false);

      store.dispatch("bid/commitByKey", {
        showLastCheckForBid: false,
      });
    }
    const { hasEnoughBalance, userBalance } = await checkBalance();
    if (hasEnoughBalance) return await confirmBid();
    return await toggleInsufficientFundsModal(
      nft.value,
      userBalance,
      offer.value
    );
  };

  const backToReview = async () => {
    lastCheckFlag.value = false;
  };

  const minimumBid = computed(() => {
    return nft?.value?.auction?.highestBidder?.id === null
      ? nft?.value?.auction?.startingPrice
      : nft?.value?.auction?.nextOffer;
  });

  const minimumBidUsd = computed(() => {
    if (["polygon", "mumbai"].includes(nft.value.network)) {
      return toFixedIfNecessary(
        minimumBid.value * maticPriceUsd.value.value,
        3
      );
    }
    return toFixedIfNecessary(minimumBid.value * ethPriceUsd.value.value, 3);
  });

  const checkBalance = async () => {
    const tokenOptions = await parseTokenList();
    const token = selectTokenFromList(tokenOptions, nft.value.auction.token);
    const userBalance = await ethereumService.fetchUserBalance(
      token.address,
      me.value.publicAddress
    );
    return {
      hasEnoughBalance: Number(userBalance) >= Number(offer.value),
      userBalance,
    };
  };

  const placeBid = async () => {
    if (showLastCheckForBid.value) {
      lastCheckFlag.value = true;
      return;
    }
    const { hasEnoughBalance, userBalance } = await checkBalance();
    if (hasEnoughBalance) return await confirmBid();
    return await toggleInsufficientFundsModal(
      nft.value,
      userBalance,
      offer.value
    );
  };

  const closeModal = () => {
    toggleBidFlag();
    resetState();
  };

  const maticPriceUsd = computed(() =>
    store.getters["contracts/getCoinPrice"]("matic-network", "usd")
  );

  const ethPriceUsd = computed(() =>
    store.getters["contracts/getCoinPrice"]("ethereum", "usd")
  );

  const calculatedOfferUsd = computed(() => {
    if (["polygon", "mumbai"].includes(nft.value.network)) {
      return toFixedIfNecessary(+offer.value * maticPriceUsd.value.value, 2);
    }
    return toFixedIfNecessary(+offer.value * ethPriceUsd.value.value, 2);
  });

  const confirmBid = async () => {
    lastCheckFlag.value = false;
    bidInProgressFlag.value = true;
    const salt = await store.dispatch("bid/getSalt", {
      auctionId: nft.value.auction.auctionId,
    });

    const approveTxResult = await ethereumService.increaseAllowance(
      selectedToken.value.address,
      ethereumService.marketplaceContractAddress,
      offer.value
    );
    console.log(approveTxResult);
    if (approveTxResult.reason === "rejected") {
      bidInProgressFlag.value = false;
      await showToast("Rejected", "Transaction rejected", "Error");
      resetState();
      return false;
    }

    const saltBn = BigNumber.from(salt.toString());

    const signature = await store.dispatch("bid/signBidderData", {
      nftId: nft.value.contractId,
      nftAddress: nft.value.contractAddress,
      price: +offer.value,
      sellingToken: selectedToken.value.address,
      salt: saltBn,
    });

    bidInProgressFlag.value = false;
    if (signature === "rejected") {
      await showToast(
        "MetaMask error",
        "User denied message signature.",
        "Error"
      );
      return;
    }

    const bidData = {
      signedTx: signature,
      amount: +offer.value,
      userId: user.value.id,
      auctionId: nft.value.auction.auctionId,
      salt: salt,
    };

    await store.dispatch("bid/createBid", bidData);
    await store.dispatch("nftDetails/fetchNft", nft.value.id);
    const bidToastMessages = {
      aboveReserve: {
        title:
          "The bid has been submitted and it has reached the reserve price.",
        text: "If the auction ends and you still being the highest bid the NFT will be automatically moved to your address.",
        icon: "Success",
      },
      belowReserve: {
        title: "The bid has been submitted",
        text: "If the auction ends and you still being the highest bid, the auctioneer will be able to accept your bid and you get the NFT.",
        icon: "Success",
      },
      noReservePrice: {
        title: "Your bid has been submitted",
        text: "If the auction ends and you still being the highest bid, the auctioneer will be able to accept your bid and you get the NFT.",
        icon: "Success",
      },
    };

    let toastMessage = bidToastMessages.noReservePrice;
    if (nft.value.auction.reservedPrice) {
      if (bidData.amount >= nft.value.auction.reservedPrice) {
        toastMessage = bidToastMessages.aboveReserve;
      } else {
        toastMessage = bidToastMessages.belowReserve;
      }
    }
    await showToast(toastMessage.title, toastMessage.text, toastMessage.icon);
    toggleBidFlag();
    resetState();
  };

  return {
    nft,
    offer,
    bidFlag,
    bidOnNft,
    setOffer,
    fetchNft,
    placeBid,
    confirmBid,
    closeModal,
    minimumBid,
    resetState,
    selectedType,
    backToReview,
    minimumBidUsd,
    selectedToken,
    lastCheckFlag,
    toggleBidFlag,
    maticPriceUsd,
    placeBidErrors,
    closeLastCheck,
    setSelectedType,
    setSelectedToken,
    bidInProgressFlag,
    calculatedOfferUsd,
    showLastCheckForBid,
  };
};
export default useBid;

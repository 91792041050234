<template>
  <div class="chyron">
    <div class="text">
      <span>|</span>
      <span class="info info-theos">
        Try our Minting Platform and Genesis Odyssey Pools now!</span
      >
      <span>|</span>
      <span class="info">
        Get THEOS tokens on Uniswap — Gate.io — MEXC — ProBit.</span
      >
      <span>|</span>
      <span class="info">
        THEOS Price:
        <span class="theos-price">USD {{ coinPriceTHEOS }}</span></span
      >
      <span>|</span>
      <span class="info">
        There are {{ monthlyMintedNfts.monthlyCount }} freshly minted NFTs this
        month!</span
      >
      <span>|</span>
    </div>
    <div class="duplicate-text">
      <span class="info info-theos">
        Try our Minting Platform and Genesis Odyssey Pools now!</span
      >
      <span>|</span>
      <span class="info">
        Get THEOS tokens on Uniswap — Gate.io — MEXC — ProBit.</span
      >
      <span>|</span>
      <span class="info">
        THEOS Price:
        <span class="theos-price">USD {{ coinPriceTHEOS }}</span></span
      >
      <span>|</span>
      <span class="info">
        There are {{ monthlyMintedNfts.monthlyCount }} freshly minted NFTs this
        month!</span
      >
      <span>&nbsp;</span>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { onMounted, computed } from "vue";
import useChyronData from "@/composables/useChyronData";
export default {
  setup() {
    const store = useStore();
    const { monthlyMintedNfts } = useChyronData(store);

    onMounted(async () => {
      await store.dispatch("contracts/fetchCoinPrice", {});
    });

    const coinPriceTHEOS = computed(() => {
      const price = store.getters["contracts/getCoinPrice"]("theos", "usd");
      return price?.value;
    });
    return {
      coinPriceTHEOS,
      monthlyMintedNfts,
    };
  },
};
</script>
<style lang="scss" src="@/Common/Styles/chyron.scss" scoped></style>

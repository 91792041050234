import { ref } from "vue";
import api from "@/services/api";

const email = ref("");
const submitted = ref(false);

const useFooter = () => {
  const submitEmail = () => {
    api.postFooterEmail({ email: email.value });
    submitted.value = true;
  };

  return {
    email,
    submitEmail,
    submitted,
  };
};

export default useFooter;

<template>
  <div class="share-nft-button-wrapper">
    <share-button
      :text="text"
      :type="type"
      :fn="toggleShare"
      :shareIcon="shareIcon"
    />
    <share-popup
      :type="type"
      :show="showShare"
      :fn="toggleShare"
      :text="popupText"
      :collectAnalytics="collectAnalytics"
      :url="url"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import SharePopup from "./SharePopup";
import ShareButton from "./ShareButton";
export default {
  props: {
    popupText: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    shareIcon: {
      type: Boolean,
      default: false,
    },
    collectAnalytics: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: null,
    },
  },
  components: {
    SharePopup,
    ShareButton,
  },
  setup() {
    const showShare = ref(false);
    const toggleShare = () => (showShare.value = !showShare.value);

    return {
      showShare,
      toggleShare,
    };
  },
};
</script>

<style scoped>
.share-nft-button-wrapper {
  flex: 1;
  width: 100%;
  position: relative;
}
</style>

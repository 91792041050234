<template>
  <div class="error404-wrapper">
    <div class="error-404-heading">
      <div class="error-404-title">
        <p><span class="emphasize">PAGE NOT FOUND</span></p>
      </div>
      <div class="error-404-text">
        <p>The page you were looking for could not be found on this server</p>
      </div>
      <div style="width: 200px">
        <app-button
          @click="$router.push({ name: 'Home' })"
          class="app-modal-button-inverse"
          text="Visit Homepage"
          :buttonStyle="btnStyle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@/elements/AppButton";
export default {
  components: {
    AppButton,
  },
  setup() {
    const btnStyle = {
      fontWeight: "bold",
      padding: "1.1rem 0",
      fontFamily: "aktiv-grotesk",
    };
    return {
      btnStyle,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/error404.scss"></style>

<template>
  <div></div>
</template>

<script>
import { computed, watch } from "vue";
import store from "../store";
import useToast from "../composables/useToast";

const metamaskCodeErrors = [
  3200, 32001, 32002, 32003, 32004, 32005, 32700, 32600, 32601, 32602, 32603,
  4001, 4100, 4200, 4900, 4901,
];
const errorMessage = "Something went wrong.";

export default {
  name: "Errors",
  setup() {
    const { showToast } = useToast();
    const modules = ["nfts", "contracts", "auth", "registration"];
    let errors = {};
    modules.forEach((module) => {
      errors[module] = computed(() => store.getters[`${module}/getErrors`]);
    });

    const lastElemOf = (arr) => {
      return arr.slice(-1)[0];
    };

    for (let key of Object.keys(errors)) {
      watch(errors[key].value, (newValue) => {
        // for metamask errors
        if (
          typeof lastElemOf(newValue) === "object" &&
          "code" in lastElemOf(newValue) &&
          metamaskCodeErrors.includes(lastElemOf(newValue).code)
        ) {
          showToast("Metamask error", lastElemOf(newValue).message, "Error");
          // other errors
        } else {
          showToast("Error", errorMessage, "Error");
        }
      });
    }
  },
};
</script>

<style scoped></style>

<template>
  <div class="file-input-box" :class="{ light: inputVariant === 'light' }">
    <div
      @drop="dropImage"
      @dragenter.prevent=""
      v-if="!isFileSelected"
      @dragover.prevent="onDragOver"
      @dragleave.prevent="onDragLeave"
      :class="[
        'file-input',
        state.showSpinner ? 'new' : '',
        shouldPickCoverImage ? 'not-image' : '',
        state.isDraggedOver && state.overInput ? 'over' : '',
      ]"
    >
      <input
        hidden
        :id="id"
        type="file"
        :name="name"
        ref="inputRef"
        v-bind="$attrs"
        :accept="accept"
        @change="onFileChange"
        @click="checkWalletConnection"
      />
      <label :for="id" style="width: 100%">
        <div
          class="file-input-wrap"
          :class="[
            state.showSpinner ? 'hide' : '',
            isImageOnly ? 'not-image-wrap' : '',
          ]"
        >
          <span>
            <img :src="crossImage" />
          </span>
          <span class="drag-text">Drag & Drop File</span>
          <span class="browse-text" @click.prevent="onPickFile"
            >or
            <span
              class="browse-underline"
              :class="[isImageOnly ? 'no-image-browse' : '']"
            >
              browse media on your device</span
            ></span
          >
          <div class="file-types">
            <span v-if="isImageOnly"
              >Files type suported: {{ acceptedMimeTypes }}
            </span>
            <span v-else>Files type suported: {{ acceptedMimeTypes }} </span>
            <span>Max size: 40MB</span>
          </div>
        </div>
      </label>
    </div>
    <div
      class="stack-top stack-top-base"
      v-if="!isFileSelected && state.isDraggedOver && state.overInput"
    ></div>
    <div
      class="stack-top stack-top-dim"
      v-if="!isFileSelected && state.isDraggedOver && state.overInput"
    ></div>
    <div
      class="stack-top"
      style="z-index: 3"
      v-if="!isFileSelected && state.isDraggedOver && state.overInput"
    >
      <span class="drag-and-drop-text">Drop File here</span>
    </div>
  </div>
  <file-preview
    :isPool="isPool"
    :url="state.url"
    :message="previewMessage"
    :mimeType="state.mimeType"
    :fileType="state.fileType"
    :hasError="Boolean(errorMessage)"
    :hasCoverImage="shouldPickCoverImage"
    v-show="isFileSelected && state.fileType !== 'video'"
  />
  <p
    class="input-file-error-message"
    v-show="errorMessage && isValidNetwork && value.name"
  >
    {{ errorMessage }}
  </p>
</template>
<script>
import { useStore } from "vuex";
import { useField } from "vee-validate";
import useAuth from "@/composables/useAuth";
import FilePreview from "@/elements/FilePreview";
import { ethereumService, eventBus } from "@/main";
import useFileInput from "@/composables/useFileInput";
import useUtilities from "@/composables/useUtilities";
import { computed, reactive, ref, toRef, watch } from "vue";

export default {
  name: "FileInput",
  components: {
    FilePreview,
  },
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    accept: {
      type: String,
      default: "",
    },
    shouldPickCoverImage: {
      type: Boolean,
      default: false,
    },
    isImageOnly: {
      type: Boolean,
      default: false,
    },
    showPreviewMessage: {
      type: Boolean,
      default: true,
    },
    previewMessagePrefix: {
      type: String,
      default: "YOU WILL TOKENIZE A",
    },
    onChange: {
      type: Function,
    },
    crossStyle: {
      type: String,
      default: "",
    },
    inputVariant: {
      type: String,
      default: "",
    },
    isPool: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, context) {
    const store = useStore();
    const { parseAccepted } = useFileInput(store, props);
    const { getFileType, getMimeType } = useUtilities();
    const {
      loginOrSignUp,
      isBeginConnectionModalOpen,
      toggleWalletConnectionRequired,
    } = useAuth(store, ethereumService);

    const crossImage = computed(() =>
      props.crossStyle
        ? require(`@/Common/Icons/${props.crossStyle}`)
        : require("@/Common/Icons/InputPlus.png")
    );

    const checkWalletConnection = (e) => {
      const address = computed(() => store.getters["contracts/getAddress"]);
      if (!address.value) {
        toggleWalletConnectionRequired();
        e.preventDefault();
      }
    };

    const isValidNetwork = computed(
      () => store.getters["contracts/getIsValidNetwork"]
    );
    const hasSigned = computed(() => store.getters["contracts/getHasSigned"]);

    const initialState = () => ({
      url: "",
      overInput: false,
      showSpinner: false,
      isOverOthers: false,
      isDraggedOver: false,
      mimeType: "", // jpg, png, webm
      fileType: null, // audio, video, image
    });
    const state = reactive(initialState());

    const dragRef = ref("other");
    const inputRef = ref("dropFile");
    const showErrorMessage = ref(true);
    const acceptedMimeTypes = parseAccepted();
    const { value, errorMessage, meta, setErrors } = useField(
      props.name,
      undefined,
      {
        initialValue: props.modelValue,
      }
    );

    watch(meta, (meta) => {
      if (!meta.pending) {
        props.onChange(value.value, meta.valid);
      }
      if (!meta.valid && !meta.dirty) {
        setErrors("");
      }
    });

    if (props.modelValue?.name) {
      value.value = toRef(props.modelValue)._object;
      state.fileType = getFileType(props.modelValue);
      state.mimeType = getMimeType(props.modelValue);
      state.url = URL.createObjectURL(props.modelValue);
    }

    const isFileSelected = computed(() => {
      if (!meta.valid) {
        return false;
      }
      return Boolean(state.url);
    });
    const previewMessage = computed(() => {
      if (!["audio"].includes(state.fileType) && props.showPreviewMessage) {
        return `${props.previewMessagePrefix} ${state.mimeType}`;
      }
      return "";
    });
    const onFileChange = () => {
      const address = computed(() => store.getters["contracts/getAddress"]);
      if (!address.value) {
        toggleWalletConnectionRequired();
        return;
      }

      const files = inputRef.value.files;
      if (files.length === 0) {
        clearFile();
        return;
      }
      state.showSpinner = true;
      state.mimeType = files[0].type.split("/")[1];
      value.value = files[0];
      state.showSpinner = false;

      state.fileType = value.value.type.split("/")[0];
      state.url = URL.createObjectURL(files[0]);
      showErrorMessage.value = true;
      context.emit("update:modelValue", value.value);
    };
    const clearFile = () => {
      // Clear actual input filed so
      // same file can be added twice in a row
      inputRef.value ? (inputRef.value.value = null) : null;
      const initState = initialState();
      for (const key in initialState()) {
        state[key] = initState[key];
      }
      context.emit("update:modelValue", {});
      value.value = {};
      errorMessage._value = undefined;
    };
    eventBus.on(`clearFile:${props.name}`, clearFile);

    const onDragOver = (e) => {
      if (e.target === dragRef.value) state.isOverOthers = true;
      else {
        state.overInput = true;
        state.isDraggedOver = true;
      }
    };

    const onDragLeave = (e) => {
      if (e.target === dragRef.value) state.isOverOthers = false;
      else if (!state.isOverOthers) {
        state.overInput = false;
        state.isDraggedOver = false;
      }
    };

    const dropImage = (e) => {
      e.preventDefault();
      inputRef.value.files = e.dataTransfer.files;
      onFileChange();
      state.overInput = false;
    };

    const onPickFile = () => {
      inputRef.value.click();
    };

    return {
      meta,
      value,
      state,
      dragRef,
      inputRef,
      hasSigned,
      dropImage,
      onPickFile,
      crossImage,
      onDragOver,
      onDragLeave,
      onFileChange,
      errorMessage,
      loginOrSignUp,
      isValidNetwork,
      isFileSelected,
      previewMessage,
      showErrorMessage,
      acceptedMimeTypes,
      checkWalletConnection,
      isBeginConnectionModalOpen,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/fileInput.scss" />

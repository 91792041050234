<template>
  <teleport to="body">
    <app-modal
      :closeButton="true"
      :returnToPreviousModal="backButton"
      v-if="shouldShowNftPreview"
    >
      <template v-slot:app-modal-body>
        <div class="app-modal-body-wrapper">
          <img src="@/Common/Icons/greenLogo.svg" alt="TheosLogo" />

          <h3 class="app-modal-welcome-text">NFT Preview</h3>
          <img id="preview-image" :src="previewSrc" alt="Preview" />

          <div class="audio-preview-in-modal" v-if="audioSrc">
            <app-audio :url="audioSrc"></app-audio>
          </div>
          <div class="preview-description">
            <p class="preview-title">
              {{ name
              }}<span class="video-time" v-show="isVideo"
                >{{ convertTimeHHMMSS(videoDuration) }} min</span
              >
            </p>
            <p class="preview-text">{{ description }}</p>
          </div>
          <div class="preview-description">
            <!-- <p class="preview-text">Tags</p> -->
            <ul>
              <li class="preview-tag" v-for="(tag, index) in tags" :key="index">
                {{ tag }}
              </li>
            </ul>
          </div>
          <div class="preview-royalties-wrapper">
            <div class="preview-royalties">
              <p class="preview-text">Royalties</p>
              <span class="royalties">{{ royalties || 0 }}%</span>
            </div>
          </div>
          <div class="preview-royalties-wrapper">
            <div class="preview-royalties">
              <p class="preview-text">Network</p>
              <span class="royalties">
                <img :src="networkLogo" alt="NETWORK" />
                {{ parsedNetworkName }}
              </span>
            </div>
          </div>
          <div class="app-modal-button-controls">
            <app-button
              @click="createButton()"
              buttonClass="app-modal-button-inverse"
              text="Mint"
              type="create"
            />
            <app-button
              @click.prevent="backButton()"
              buttonClass="app-modal-button"
              text="Back"
            />
          </div>
        </div>
      </template>
    </app-modal>
    <app-modal
      v-if="isMinting"
      :closeButton="true"
      :returnToPreviousModal="returnToProfile"
    >
      <template v-slot:app-modal-body>
        <div class="app-modal-body-wrapper">
          <loader :isGreen="true" />
          <p class="app-modal-minting-text">MINTING</p>
          <p class="app-modal-info-text">
            Sending transaction to create your NFT...
          </p>
        </div>
      </template>
    </app-modal>
    <app-modal
      v-if="isMintingFinished && !listClicked"
      :closeButton="true"
      :returnToPreviousModal="redirectToProfilePage"
    >
      <template v-slot:app-modal-body>
        <div class="app-modal-body-wrapper">
          <img src="@/Common/Icons/LOGO_GIF.gif" alt="Logo" class="logo-gif" />
          <div class="congrats">
            <h3 class="app-modal-welcome-text">CONGRATS!</h3>
            <p class="app-modal-description-text">
              {{ name }} has been successfully created and now is time to share
              it with the world!
            </p>
          </div>
          <img id="preview-image" :src="previewSrc" alt="Preview" />
          <div class="audio-preview-in-modal" v-if="audioSrc">
            <app-audio :url="audioSrc"></app-audio>
          </div>
          <div class="preview-description">
            <p class="preview-title">{{ name }}</p>
            <p class="preview-text">{{ description }}</p>
          </div>
          <div class="preview-description">
            <ul>
              <li class="preview-tag" v-for="(tag, index) in tags" :key="index">
                {{ tag }}
              </li>
            </ul>
          </div>
          <div class="preview-royalties-wrapper">
            <div class="preview-royalties">
              <p class="preview-text">Royalties</p>
              <span class="royalties">{{ royalties || 0 }}%</span>
            </div>
          </div>
          <div class="preview-royalties-wrapper">
            <div class="preview-royalties">
              <p class="preview-text">Network</p>
              <span class="royalties">
                <img :src="networkLogo" alt="NETWORK" />
                {{ parsedNetworkName }}
              </span>
            </div>
          </div>
          <div class="app-modal-button-controls">
            <app-button
              @click="listNft()"
              buttonClass="app-modal-button-inverse"
              text="List"
            />
            <app-button
              @click="depositToPool()"
              buttonClass="app-modal-button"
              text="Submit in a Pool"
            />
          </div>
        </div>
      </template>
    </app-modal>
  </teleport>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { ethereumService } from "@/main";
import AppModal from "@/elements/AppModal";
import AppAudio from "@/components/AppAudio";
import AppButton from "@/elements/AppButton";
import Loader from "@/elements/AppLoader.vue";
import useRouter from "@/composables/useRouter";
import useMinting from "@/composables/useMinting";
import useDeposit from "@/composables/useDeposit";
import useListNft from "@/composables/useListNft";
import useUtilities from "@/composables/useUtilities";
import useGoogleTag from "@/composables/useGoogleTag";
import useNetworkData from "@/composables/useNetworkData";

export default {
  components: {
    AppModal,
    AppButton,
    AppAudio,
    Loader,
  },
  props: {
    txHash: {
      type: String,
    },
    handleSubmit: {
      type: Function,
    },
  },
  setup(props) {
    const store = useStore();
    const {
      name,
      tags,
      isMinting,
      royalties,
      description,
      setIsMinting,
      selectedFile,
      coverImageFile,
      showNftPreview,
      hideNftPreview,
      isMintingFinished,
      showMintNftStepper,
      shouldShowNftPreview,
    } = useMinting(store);
    const { parsedNetworkName, networkLogo } = useNetworkData(ethereumService);
    const { updateChosenNft } = useDeposit(store);
    const { toggleListFlag, fetchNft } = useListNft(store);
    const { registerEvent } = useGoogleTag();
    let mintedNft = computed(() => store.getters["nfts/getNftData"]);
    const previewSrc = ref("");
    const audioSrc = ref("");
    const videoDuration = ref(0);
    const isVideo = ref(false);
    const fileType = (file) => file.type?.split("/")[0];
    const makeUrl = (file) => URL.createObjectURL(file);
    const { copyToClipboard } = useUtilities();
    const { goTo } = useRouter();
    const listClicked = ref(false);

    const listNft = () => {
      hideNftPreview();
      listClicked.value = true;
      fetchNft(mintedNft.value.id);
      toggleListFlag();
    };
    //this is not working--missing nft contract id--not shure if can be done
    const depositToPool = async () => {
      const nftFile = store.getters["nfts/getNftFile"];
      const nftCoverImageFile = store.getters["nfts/getNftCoverImageFile"];
      mintedNft.value.coverImage = nftCoverImageFile.id
        ? nftCoverImageFile
        : nftFile;
      goTo({
        name: "DepositNft",
        query: { profileNftId: mintedNft.value.id },
      });
      await store.dispatch("nfts/clearFileData");
      updateChosenNft(mintedNft.value);
    };
    //
    previewSrc.value = coverImageFile.value.type
      ? makeUrl(coverImageFile.value)
      : makeUrl(selectedFile.value);

    audioSrc.value =
      fileType(selectedFile.value) === "audio"
        ? makeUrl(selectedFile.value)
        : "";

    const getVideoDuration = (file) => {
      let videoUrl = URL.createObjectURL(file);
      let audioElement = new Audio(videoUrl);
      audioElement.onloadedmetadata = () => {
        videoDuration.value = parseInt(audioElement.duration);
      };
    };

    if (fileType(selectedFile.value) === "video") {
      getVideoDuration(selectedFile.value);
      isVideo.value = true;
    }

    const convertTimeHHMMSS = (val) => {
      let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);
      return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
    };

    const backButton = () => showMintNftStepper();

    const createButton = () => {
      hideNftPreview();
      setIsMinting(true);
      props.handleSubmit();
    };

    const returnToPreviousModal = () => {
      showNftPreview();
      setIsMinting(false);
    };
    const returnToProfile = async () => {
      await goTo({ name: "ViewProfilePrivate" });
      setIsMinting(false);
    };

    const redirectToProfilePage = async () => {
      await goTo({ name: "ViewProfilePrivate" });
      await store.dispatch("nfts/clearFileData");
      await store.dispatch("minting/resetState");
      await store.dispatch("tagsValues/resetState");
    };

    const analytics = (name) => {
      registerEvent(`nft shared on ${name}`);
    };

    onMounted(() => document.body.classList.add("stop-scroll"));
    onUnmounted(() => document.body.classList.remove("stop-scroll"));

    return {
      name,
      tags,
      makeUrl,
      audioSrc,
      fileType,
      royalties,
      previewSrc,
      backButton,
      description,
      createButton,
      listClicked,
      selectedFile,
      coverImageFile,
      redirectToProfilePage,
      videoDuration,
      isVideo,
      depositToPool,
      listNft,
      convertTimeHHMMSS,
      shouldShowNftPreview,
      isMinting,
      isMintingFinished,
      returnToPreviousModal,
      copyToClipboard,
      mintedNft,
      analytics,
      returnToProfile,
      parsedNetworkName,
      networkLogo,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/previewModal.scss"></style>

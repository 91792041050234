import api from "@/services/api";

const initialState = () => ({
  auctionsFlag: false,
  selectedType: null,
  nft: {
    id: null,
    royaltyPercentage: null,
    name: String,
  },
  errors: [],
});

const state = initialState();
const getters = {
  getByKey: (state) => (key) => state[key],
};
const mutations = {
  setByKey(state, obj) {
    Object.keys(obj).forEach((key) => (state[key] = obj[key]));
  },
  toggleAuctionsFlag(state) {
    state.auctionsFlag = !state.auctionsFlag;
    if (state.auctionsFlag) {
      document.body.classList.add("stop-scroll");
      return;
    }
    document.body.classList.remove("stop-scroll");
  },
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
};
const actions = {
  setByKey({ commit }, obj) {
    commit("setByKey", obj);
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  resetState({ commit }) {
    commit("resetState");
  },
  toggleAuctionsFlag({ commit }) {
    try {
      commit("toggleAuctionsFlag");
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchNft({ commit }, id) {
    try {
      const response = await api.fetchNft(id);
      commit("commitByKey", { nft: response.data });
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },

  async auctionCancel({ commit }, auctionId) {
    try {
      const response = await api.auctionCancel(auctionId);
      return response.data;
    } catch (error) {
      commit("pushError", error);
    }
  },

  async auctionAccept({ commit }, auctionId) {
    try {
      return await api.auctionAccept(auctionId);
    } catch (error) {
      commit("pushError", error);
    }
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};

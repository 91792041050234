<template>
  <app-list-nft :showCloseLink="false" :showCancel="false">
    <template v-slot:app-modal-header>
      <div class="spinner file-input new">
        <app-loader :show="true" :isGreen="true" />
      </div>
      <h3 class="app-modal-listing-title" v-if="isUnlisting">UNLISTING...</h3>
      <h3 class="app-modal-listing-title" v-else>
        {{ title }}
      </h3>
    </template>
    <template v-slot:app-modal-body>
      <p class="app-modal-body-text">
        {{ text }}
      </p>
    </template>
  </app-list-nft>
</template>

<script>
import AppLoader from "@/elements/AppLoader";
import { onMounted, onUnmounted } from "vue";
import AppListNft from "@/elements/AppListNft";
export default {
  name: "CompletingModal",
  components: {
    AppListNft,
    AppLoader,
  },
  props: {
    isUnlisting: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "LISTING...",
    },
    text: {
      type: String,
      default: "Sign the transaction in your wallet.",
    },
  },
  setup() {
    onMounted(() => document.body.classList.add("stop-scroll"));
    onUnmounted(() => document.body.classList.remove("stop-scroll"));
    return {};
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/listNftModal.scss" />

import { Cloudinary, Transformation } from "@cloudinary/base";
import { fill } from "@cloudinary/base/actions/resize";
import { format } from "@cloudinary/base/actions/delivery";
import byRadius from "@cloudinary/base/actions/roundCorners/byRadius";
import { ifCondition } from "@cloudinary/base/actions/conditional";
const CLOUD_NAME = process.env.VUE_APP_CLOUDINARY_CLOUD_NAME || "theos-fi";
const imageStyles = {
  original: function (image) {
    return image;
  },
  "nft.show.video": function (video) {
    return video
      .delivery(format("auto"))
      .conditional(
        ifCondition(
          "width = height",
          new Transformation().resize(fill().height(1000).aspectRatio("1:1"))
        )
      )
      .conditional(
        ifCondition(
          "width < height",
          new Transformation().resize(fill().height(1000).aspectRatio("4:5"))
        )
      )
      .conditional(
        ifCondition(
          "width > height",
          new Transformation().resize(fill().width(1000).aspectRatio("16:9"))
        )
      )
      .roundCorners(byRadius(14));
  },
};

class ImageTransformer {
  constructor(styleObject, cloudName) {
    this.styleObject = styleObject;
    this.cloudinary = new Cloudinary({ cloud: { cloudName: cloudName } });
  }
  transformVideo(filename, style) {
    return this.styleObject[style](this.cloudinary.video(filename)).toURL();
  }
}

const cloudinaryInstance = new ImageTransformer(imageStyles, CLOUD_NAME);
export default cloudinaryInstance;

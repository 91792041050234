<template>
  <div class="profile-panel-body-wrapper">
    <div class="filters-search">
      <div class="profile-panel-search">
        <app-input
          v-model="searchString"
          @keydown.enter.stop="searchFilter"
          inputClass="app-modal-input-search full-width"
          placeholder="Search by name, pool or tag"
        />
        <img
          alt="search"
          class="submissions-search-icon"
          src="@/Common/Icons/search_icon.png"
        />
        <img
          alt="delete"
          v-show="searchString !== ''"
          @click="resetSearchString()"
          class="submissions-search-cross"
          src="@/Common/Icons/search_cross.png"
        />
      </div>
      <div class="profile-panel-filter">
        <div class="profile-panel-filter-wrapper">
          <div
            class="profile-panel-filter-overlay"
            @click="toggle(filtersFlag.showFilters)"
          />
          <app-input
            disabled
            inputStyle="padding-left: 35px"
            inputClass="app-modal-input-sort"
            placeholder="Filter and sort"
            inputIconLeftClass="filter-icon"
            :inputIconRightClass="{
              'chevron-icon': true,
              active: filtersFlag.showFilters.value,
            }"
          />
        </div>
        <teleport to="body">
          <div
            class="filtering-options-overlay"
            v-if="filtersFlag.showFilters.value"
            @click="toggle(filtersFlag.showFilters)"
          />
        </teleport>
        <div class="filtering-options" v-if="filtersFlag.showFilters.value">
          <h3 class="filtering-options-heading">Sort by</h3>
          <p
            class="filtering-option"
            :class="{ active: activeTab === null }"
            @click="toggleFilter(null)"
          >
            All
          </p>
          <p
            class="filtering-option"
            :class="{ active: activeTab === 'accepted' }"
            @click="toggleFilter('accepted')"
          >
            Accepted
          </p>
          <p
            class="filtering-option"
            :class="{ active: activeTab === 'pending' }"
            @click="toggleFilter('pending')"
          >
            Pending
          </p>
          <p
            class="filtering-option"
            :class="{ active: activeTab === 'rejected' }"
            @click="toggleFilter('rejected')"
          >
            Rejected
          </p>
        </div>
      </div>
    </div>
    <div class="container">
      <div v-if="submissionCount < 1">
        <no-data
          :buttonText="'Submit NFT'"
          :buttonClickHandler="submitNft"
          :title="'Nothing to see here yet'"
          :image="require('@/Common/Icons/cloud.svg')"
          :textTop="'You currently have no pending submissions. Why don\'t you try submitting one?'"
        />
      </div>
      <v-server-table
        v-else
        :url="tableUrl"
        :columns="columns"
        :options="options"
        ref="submissionsTable"
      >
        <template v-slot:nft="{ row }">
          <div class="field-with-thumbnail">
            <div class="ntf-thumbnail">
              <img :src="row.nft.coverImage.thumbnail" alt="" />
            </div>
            <div
              :class="[
                isStringTooLong(row.nft.name, 20) ? 'nft-table-name' : '',
              ]"
            >
              {{ row.nft.name }}
              <div class="nft-name-tooltip">{{ row.nft.name }}</div>
            </div>
          </div>
        </template>
        <template v-slot:pool="{ row }">
          <div class="field-with-thumbnail">
            <div class="pool-thumbnail">
              <img :src="row.pool.coverImage.thumbnail" alt="" />
            </div>
            <div
              :class="[
                isStringTooLong(row.pool.name, 25) ? 'pool-table-name' : '',
              ]"
            >
              {{ row.pool.name }}
              <div class="nft-name-tooltip">{{ row.pool.name }}</div>
            </div>
          </div>
        </template>
        <!--        Removed for now-->
        <!--        <template v-slot:remaining="{ row }">-->
        <!--          <div class="date-time">-->
        <!--            {{ submissionsTimeRemaining(row.createdAt) }}-->
        <!--          </div>-->
        <!--        </template>-->
        <template v-slot:status="{ row }">
          <div class="field-with-status">
            <div class="status-image">
              <img
                alt=""
                :src="require(`@/Common/Icons/${statusImage(row.status)}`)"
              />
            </div>
            <div>
              <p class="status-text">
                {{ row.status.charAt(0).toUpperCase() + row.status.slice(1) }}
              </p>
            </div>
          </div>
        </template>
        <template v-slot:action="{ row }">
          <div class="action-field">
            <app-button
              style="padding: 10px 0"
              @click="handleAction(row)"
              :text="
                actionTexts.find(
                  (action) => action.currentStatus === row.status
                ).buttonText
              "
              :buttonClass="
                actionTexts.find(
                  (action) => action.currentStatus === row.status
                ).buttonClass
              "
            />
          </div>
        </template>
      </v-server-table>
      <load-more
        v-if="showLoadMoreButton"
        :data="[]"
        :loadMore="loadMore"
        :loadingMore="false"
        currentPanel=""
      />
    </div>
    <submission-action-modal
      :submission="chosenSubmission"
      :returnToPreviousModal="toggleShowSubmissionActionModal"
      v-if="showSubmissionActionModal && chosenSubmission != null"
    />
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import AppInput from "@/elements/AppInput";
import AppButton from "@/elements/AppButton";
import useToast from "@/composables/useToast";
import useRouter from "@/composables/useRouter";
import { eventBus, ethereumService } from "@/main";
import useUtilities from "@/composables/useUtilities";
import actionTexts from "@/json/submissionActions.json";
import NoData from "@/components/Profile/Panels/NoData";
import useProfileView from "@/composables/useProfileView";
import useNetworkData from "@/composables/useNetworkData";
import LoadMore from "@/components/Profile/Panels/LoadMore";
import useProfilePanels from "@/composables/useProfilePanels";
import SubmissionActionModal from "@/components/SubmissionActionModal";
import useDepositReviewModal from "@/composables/useDepositReviewModal";

export default {
  components: {
    NoData,
    LoadMore,
    AppInput,
    AppButton,
    SubmissionActionModal,
  },
  setup: function () {
    const store = useStore();
    const loading = ref(false);
    const mouseOver = ref(null);
    const { goTo } = useRouter();
    const { showToast } = useToast();
    const submissionsTable = ref(null);
    const showLoadMoreButton = ref(false);

    const { toggleDepositFlag } = useDepositReviewModal(store);

    const tableUrl = `/users/me/submissions`;
    const submitNft = () => goTo({ name: "DepositNft" });

    const { refreshPanels } = useProfileView(store);

    eventBus.on("refresh-user-submission-table", async () => {
      await refreshPanels();
    });

    eventBus.on("depositedNft", () => {
      submissionsTable.value?.refresh();
    });

    const options = ref({
      perPage: 8,
      perPageValues: [],
      resizableColumns: false,
      orderBy: {
        ascending: false,
      },
      params: {},
      requestAdapter(data) {
        const params = {
          page: data.page,
          perPage: options.value.perPage,
          orderBy: "createdAt",
          orderDirection: data.ascending ? "ASC" : "DESC",
        };
        if (data.search) {
          params.search = data.search;
        }
        if (data.status) {
          params.status = data.status;
        }
        return params;
      },
      responseAdapter({ data }) {
        store.dispatch("profilePanels/commitSubmissionsData", {
          data: data.data,
          total: data.count,
        });
        return {
          data: data.data,
          count: data.count,
        };
      },
      filterable: false,
      headings: {
        id: "Id",
        nft: "NFT",
        pool: "Pool",
        action: "Action",
      },
      sortable: ["nft", "pool", "remaining", "status"],
      pagination: {
        virtual: true,
        chunk: 8,
      },
    });
    const columns = ["nft", "pool", "status", "action"];

    const disabled = ref(true);
    const status = ref("");
    const {
      list,
      toggle,
      filters,
      filtersFlag,
      filtersStyle,
      searchString,
      submissionCount,
      fetchSubmissionCount,
      checkIfNftWhitelisted,
      profilePanelNoItemsText,
      showSubmissionActionModal,
      checkExistingPermissionsForNft,
      toggleShowSubmissionActionModal,
    } = useProfilePanels(useStore());

    const { isNftOnCurrentNetwork } = useNetworkData(ethereumService);

    fetchSubmissionCount();
    const { submissionsTimeRemaining, isStringTooLong } = useUtilities();

    const resetDropdown = () => {
      status.value = "";
      disabled.value = true;
    };

    const handleSubmission = async (status, submission) => {
      disabled.value = true;
      await store.dispatch("pools/handleBulkSubmission", {
        status,
        submissionIds: [submission],
      });
      resetDropdown();
      submissionsTable.value.getData();
    };

    const avatarImage = (user) =>
      user.coverImage ? user.coverImage : require("@/Common/Icons/Avatar.png");

    const onChange = () => {
      if (status.value === "accept" || status.value === "reject") {
        disabled.value = false;
      }
    };

    const searchFilter = () => {
      if (submissionCount.value !== 0) {
        options.value.params.search = searchString.value;
        options.value.perPage = 8;
        submissionsTable.value.refresh();
        resetDropdown();
      }
    };

    let activeTab = ref(null);

    const toggleFilter = (status) => {
      if (submissionCount.value !== 0) {
        options.value.params.status = status;
        activeTab.value = status;
        submissionsTable.value.refresh();
        resetDropdown();
      }
    };

    const statusImage = (status) => {
      return status + "Status.png";
    };

    const loadMore = () => {
      options.value.perPage += 8;
      submissionsTable.value.getData();
    };

    const toggleDepositModalFlag = async (submission) => {
      if (isNftOnCurrentNetwork(submission.value.nft)) {
        const isWhitelisted = await checkIfNftWhitelisted(
          submission.value.nft.contractAddress,
          submission.value.nft.contractId,
          submission.value.pool.ethAddress
        );
        if (!isWhitelisted || submission.value.status !== "accepted") {
          return await showToast(
            "NFT is not whitelisted.",
            "Whitelist transaction can take some time.",
            "Error"
          );
        }
        await store.dispatch("depositReview/fetchNft", submission.value.nftId);
        await store.dispatch("depositReview/fetchPool", submission.value.poolId);
        await store.dispatch("depositReview/commitByKey", {
          selectedSubmission: submission.value,
        });
        return toggleDepositFlag();
      }
      return store.dispatch("auth/commitByKey", {
        switchNetworkModalFlag: true,
      });
    };

    let chosenSubmission = ref(null);
    const handleAction = async (row) => {
      chosenSubmission.value = row;
      const permissionsExist = await checkExistingPermissionsForNft(row.nftId);
      if (permissionsExist || chosenSubmission.value.status !== "accepted") {
        return toggleShowSubmissionActionModal();
      }
      return toggleDepositModalFlag(chosenSubmission);
    };

    const resetSearchString = () => {
      searchString.value = "";
    };

    return {
      list,
      toggle,
      status,
      loading,
      columns,
      filters,
      options,
      tableUrl,
      disabled,
      onChange,
      loadMore,
      activeTab,
      mouseOver,
      submitNft,
      actionTexts,
      statusImage,
      filtersFlag,
      avatarImage,
      searchString,
      filtersStyle,
      handleAction,
      searchFilter,
      toggleFilter,
      submissionCount,
      isStringTooLong,
      handleSubmission,
      chosenSubmission,
      submissionsTable,
      resetSearchString,
      showLoadMoreButton,
      profilePanelNoItemsText,
      submissionsTimeRemaining,
      showSubmissionActionModal,
      toggleShowSubmissionActionModal,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/submissionsPanel.scss" />

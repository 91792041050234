<template>
  <div v-if="swapFlag">
    <teleport to="body">
      <exchange-tokens-modal :closeModal="toggleSwapFlag">
      </exchange-tokens-modal>
    </teleport>
  </div>
</template>

<script>
import { useStore } from "vuex";
import useExchangeTokens from "@/composables/useExchangeTokens";
import ExchangeTokensModal from "@/components/Swap/ExchangeTokensModal";
export default {
  components: {
    ExchangeTokensModal,
  },
  setup() {
    const store = useStore();
    const { swapFlag, toggleSwapFlag } = useExchangeTokens(store);

    return {
      swapFlag,
      toggleSwapFlag,
    };
  },
};
</script>

<style lang="scss" scoped />

<template>
  <div class="mint-network">
    <div class="mint-network-wrap" v-if="!switchToMint">
      <div class="head-headline">
        <div class="headline">
          <span class="tok">FIRST, CHOOSE A</span>
          <span class="second-headline-word">BLOCKCHAIN</span>
        </div>
        <div class="headline-undertext">
          From a media file to a birth certificate. Just tokenize it.
        </div>
      </div>
      <div class="switch-buttons">
        <div
          class="networkButton"
          :class="[isEthereumNetwork ? 'active' : 'pointer']"
          @click="isEthereumNetwork ? '' : networkSwitch()"
        >
          <img
            alt="Ethereum"
            class="network-logo"
            src="@/Common/Icons/mintNetworkLogoETH.svg"
          />
          <div class="network-name">Ethereum</div>
        </div>
        <div
          class="networkButton"
          :class="[!isEthereumNetwork ? 'active' : 'pointer']"
          @click="!isEthereumNetwork ? '' : networkSwitch()"
        >
          <img
            alt="Polygon"
            class="network-logo"
            src="@/Common/Icons/mintNetworkLogoPOLYGON.svg"
          />
          <div class="network-name">Polygon</div>
        </div>
      </div>
      <div class="mint-network-button">
        <app-button
          @click.prevent="historyBack()"
          buttonClass="app-modal-button"
          text="Back"
        />
        <app-button
          @click="switchToMintStep()"
          buttonClass="app-modal-button-inverse"
          :text="isEthereumNetwork ? 'Mint in Ethereum' : 'Mint in Polygon'"
        />
      </div>
    </div>
    <div v-else-if="switchToMint">
      <div class="mint-nft-class" v-if="shouldShowMintNftStepper">
        <stepper
          v-if="selectedFile.type"
          :steps="currentStepsArray"
          :currentStep="currentStep"
        />
        <div class="mint-nft-background" :style="backgroundImage"></div>
        <div class="mint-nft-content">
          <div class="form-wrapper">
            <base-form
              id="mint-nft"
              method="post"
              :handleSubmit="validateAndSubmit"
              :schema="currentStepsArray[currentStep - 1].scheme"
            >
              <component :is="currentStepsArray[currentStep - 1].component" />
            </base-form>
          </div>
        </div>
      </div>
      <preview-modal v-else :txHash="txHash" :handleSubmit="handleSubmit" />
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import useAuth from "@/composables/useAuth";
import { computed, onMounted, onUnmounted, ref } from "vue";
import Stepper from "@/components/Stepper.vue";
import BaseForm from "@/components/BaseForm.vue";
import AppButton from "@/elements/AppButton";
import useMinting from "@/composables/useMinting";
import { ethereumService, eventBus } from "@/main";
import PreviewModal from "@/components/MintNft/PreviewModal";
import MintDetails from "@/components/MintNft/MintDetails";
import MintNftFile from "@/components/MintNft/MintNftFile";
import MintCoverImage from "@/components/MintNft/MintCoverImage";
import MintDescription from "@/components/MintNft/MintDescription";
import ConnectAndPolygonModal from "@/components/ConnectAndPolygonModal";

export default {
  name: "MintNft",
  components: {
    Stepper,
    BaseForm,
    AppButton,
    MintDetails,
    MintNftFile,
    PreviewModal,
    MintCoverImage,
    MintDescription,
    ConnectAndPolygonModal,
  },
  setup() {
    const store = useStore();
    const errorsNfts = computed(() => store.getters["nfts/getErrors"]);
    const switchToMint = ref(false);
    const { isBeginConnectionModalOpen } = useAuth(store, ethereumService);
    const walletAddress = computed(() => store.getters["contracts/getAddress"]);

    const {
      mintNft,
      currentStep,
      selectedFile,
      incrementStep,
      backgroundUrl,
      currentStepsArray,
      hideMintNftStepper,
      shouldShowMintNftStepper,
    } = useMinting(store);

    const txHash = ref("");

    const fileType = (file) => file.type?.split("/")[0];
    const handleSubmit = async () => {
      txHash.value = await mintNft(errorsNfts);
    };
    const validateAndSubmit = () => {
      currentStep.value === currentStepsArray.value.length
        ? hideMintNftStepper()
        : incrementStep();
    };
    const backgroundImage = computed(() => {
      return backgroundUrl.value !== ""
        ? `background: linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)) , url(${backgroundUrl.value}) no-repeat center center/cover`
        : "";
    });
    const switchToMintStep = () => {
      if (!walletAddress.value) {
        store.dispatch("auth/commitByKey", {
          isBeginConnectionModalOpen: true,
        });
        return (switchToMint.value = false);
      }
      return (switchToMint.value = true);
    };

    const networkParams = ref(ethereumService.networkParams);
    const isEthereumNetwork = computed(() =>
      ["0x5", "0x1"].includes(networkParams.value.chainId)
    );
    const networkSwitch = () => {
      return ethereumService.switchNetwork();
    };
    const historyBack = () => history.back();

    onMounted(() => {
      !walletAddress.value
        ? store.dispatch("auth/commitByKey", {
            isBeginConnectionModalOpen: true,
          })
        : "";
      if (!isBeginConnectionModalOpen.value) {
        store.dispatch("minting/resetState");
        store.dispatch("tagsValues/resetState");
        eventBus.emit("clearFile:selectedFile");
      }
    });

    onUnmounted(() => {
      store.dispatch("minting/resetState");
    });

    return {
      txHash,
      fileType,
      historyBack,
      currentStep,
      selectedFile,
      handleSubmit,
      switchToMint,
      incrementStep,
      backgroundUrl,
      networkSwitch,
      backgroundImage,
      switchToMintStep,
      validateAndSubmit,
      isEthereumNetwork,
      currentStepsArray,
      shouldShowMintNftStepper,
      isBeginConnectionModalOpen,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/mintNft.scss" />

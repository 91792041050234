<template>
  <div class="mobile-wrapper">
    <img src="@/Common/Icons/logo.svg" alt="TheosLogo" />
    <img
      alt="Lightning"
      class="lightning-image"
      src="@/Common/Icons/Lightning-mobile.png"
    />
    <img
      alt="Cloud"
      class="cloud-image"
      src="@/Common/Icons/cloud-mobile.png"
    />
    <div class="mobile-heading">
      <div class="mobile-title">
        <div class="background-text">THEOS</div>
        <p>TRY OUR,<span class="emphasize"> DESKTOP APP </span></p>
      </div>
      <div class="mobile-text-primary">
        <p>→ We don't support mobile devices yet ←</p>
      </div>
      <!--      TODO: change url-->
      <div class="mobile-text-secondary">
        <p>
          Try opening
          <a
            style="text-decoration: none"
            href="https://theos.fi/"
            target="_blank"
            >app.theos.fi</a
          >
          computer
        </p>
      </div>
    </div>
    <div class="mobile-body">
      <app-button
        text="Visit Theos.fi"
        buttonClass="app-modal-button-inverse mobile-button"
        @click="goToTheosFi()"
      />
      <p class="documentation-text">
        Find out more in our
        <a href="https://theos.fi/faq" target="_blank"> documentation page</a>
      </p>
    </div>
  </div>
</template>

<script>
import AppButton from "@/elements/AppButton";

export default {
  components: {
    AppButton,
  },
  setup() {
    const goToTheosFi = () => {
      window.open("https://theos.fi", "_blank");
    };
    return {
      goToTheosFi,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/mobileNotSupported.scss"></style>

<template>
  <div v-if="giftFlag">
    <teleport to="body">
      <base-form
        id="gift-nft"
        method="post"
        :handleSubmit="validateAndSubmit"
        :schema="currentStepsArray[currentStep - 1].scheme"
      >
        <component
          :nft="nft"
          :is="currentStepsArray[currentStep - 1].component"
        />
      </base-form>
    </teleport>
  </div>
</template>

<script>
import { useStore } from "vuex";
import BaseForm from "@/components/BaseForm.vue";
import useGiftNft from "@/composables/useGiftNft";
import GiftNftModal from "@/components/GiftNft/GiftNftModal";
import SuccessfulGiftingModal from "@/components/GiftNft/SuccessfulGiftingModal";
import PreviewGiftingModal from "@/components/GiftNft/PreviewGiftingModal";
import FinishGiftingNftModal from "@/components/GiftNft/FinishGiftingNftModal";
export default {
  components: {
    BaseForm,
    GiftNftModal,
    PreviewGiftingModal,
    FinishGiftingNftModal,
    SuccessfulGiftingModal,
  },
  setup() {
    const store = useStore();
    const { nft, giftFlag, currentStep, incrementStep, currentStepsArray } =
      useGiftNft(store);
    const validateAndSubmit = async () => {
      incrementStep();
    };

    return {
      nft,
      giftFlag,
      currentStep,
      currentStepsArray,
      validateAndSubmit,
    };
  },
};
</script>

<style lang="scss" scoped />

<template>
  <app-buy-listed-nft
    :closeButton="true"
    :showCloseLink="true"
    :closeModal="closeModal"
  >
    <template v-slot:app-modal-header>
      <img
        :alt="nft.name"
        class="buy-listed-nft-logo"
        src="@/Common/Icons/TheosLogoYellow.png"
      />
      <p class="buy-listed-title">Buy NFT</p>
      <img class="nft-cover-image" :src="nft.coverImage.path" :alt="nft.name" />
    </template>
    <template v-slot:app-modal-body>
      <p class="app-modal-body-text text-color">You are about to buy</p>
      <p class="app-modal-listed-nft-data">
        <span class="nft-name"> {{ nft.name }}</span>
        <span class="price-color">by</span>
        <span class="owner-name"> {{ nft.currentOwner.displayName }}</span>
      </p>
      <p class="app-modal-body-text text-color" style="margin-bottom: 4px">
        Total cost is
      </p>
      <p class="app-modal-listed-price">
        <span class="color-primary"
          >{{ toFixedIfNecessary(nft.marketplace.price, 4) }}
          {{ getToken(nft.network) }}</span
        >
        <span class="price-color"
          >≈${{ toFixedIfNecessary(nft.marketplace.priceUSD, 2) }}</span
        >
      </p>
      <div class="high-royalties" v-if="nft.royaltyPercentage >= 10">
        <div class="alert-wrapper">
          <div class="alert-icon">
            <img alt="Icon" src="@/Common/Icons/Warning.png" />
          </div>
          <div class="alert-content">
            <p class="alert-title">High royalties</p>
            <p class="alert-text">
              This NFT has more than 10% royalties (fee for the creator for each
              transaction)
            </p>
          </div>
        </div>
      </div>
      <div class="button-wrapper-listed">
        <app-button
          text="Buy"
          @click="buyListedNft(nft)"
          buttonClass="app-modal-button-inverse"
        />
      </div>
    </template>
  </app-buy-listed-nft>
</template>

<script>
import { useStore } from "vuex";
import AppButton from "@/elements/AppButton";
import { onMounted, onUnmounted } from "vue";
import useUtilities from "@/composables/useUtilities";
import AppBuyListedNft from "@/elements/AppBuyListedNft";
import useBuyListedNft from "@/composables/useBuyListedNft";
export default {
  name: "BuyListedNftModal",
  components: {
    AppButton,
    AppBuyListedNft,
  },
  setup() {
    const store = useStore();

    const { closeModal, maticPriceUsd, buyListedNft, nft } =
      useBuyListedNft(store);

    const { toFixedIfNecessary } = useUtilities();

    const getToken = (network) => {
      console.log(network);
      if (["mumbai", "polygon"].includes(network)) return "MATIC";
      if (["goerli", "ethereum"].includes(network)) return "ETH";
    };

    onMounted(() => document.body.classList.add("stop-scroll"));
    onUnmounted(() => document.body.classList.remove("stop-scroll"));

    return {
      nft,
      getToken,
      closeModal,
      buyListedNft,
      maticPriceUsd,
      toFixedIfNecessary,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/buyListedNft.scss" />

<template>
  <div v-if="depositFlag">
    <teleport to="body">
      <component
        :nft="nft"
        :pool="pool"
        v-model="price"
        :closeFunction="toggleDepositFlag"
        :is="currentStepsArray[currentStep - 1].component"
      />
    </teleport>
  </div>
</template>

<script>
import { useStore } from "vuex";
import ConfirmDeposit from "@/components/DepositReview/ConfirmDeposit";
import DoneDepositing from "@/components/DepositReview/DoneDepositing";
import useDepositReviewModal from "@/composables/useDepositReviewModal";
import DepositingInProcess from "@/components/DepositReview/DepositingInProcess";

export default {
  components: {
    ConfirmDeposit,
    DoneDepositing,
    DepositingInProcess,
  },
  setup() {
    const price = 0;

    const store = useStore();
    const {
      nft,
      pool,
      depositFlag,
      currentStep,
      currentStepsArray,
      toggleDepositFlag,
    } = useDepositReviewModal(store);

    return {
      nft,
      pool,
      price,
      currentStep,
      depositFlag,
      currentStepsArray,
      toggleDepositFlag,
    };
  },
};
</script>

<style lang="css" scoped>
body {
  overflow: hidden;
}
</style>

<template>
  <div class="edit-profile-wrapper">
    <div class="edit-profile-heading">
      <div class="edit-profile-title">
        <p class="emphasize">Edit Profile</p>
      </div>
      <p class="edit-profile-text">Manage your personal settings</p>
    </div>
    <edit-profile-form class="edit-profile-form" />
  </div>
</template>

<script>
import EditProfileForm from "@/components/Profile/EditProfileForm";
export default {
  components: {
    EditProfileForm,
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/editProfile.scss" />

<template>
  <div class="pool">
    <div class="pool-cover-image" />
    <div class="pool-content">
      <div class="pool-description">
        <single-pool-details />
      </div>
      <div class="pool-data">
        <pool-description-data />
        <div class="pool-panels">
          <pool-panels />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PoolPanels from "../components/Pools/SinglePool/PoolPanels";
import SinglePoolDetails from "../components/Pools/SinglePool/SinglePoolDetails.vue";
import PoolDescriptionData from "../components/Pools/SinglePool/PoolDescriptionData.vue";
export default {
  components: {
    PoolPanels,
    SinglePoolDetails,
    PoolDescriptionData,
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/viewPools.scss" />

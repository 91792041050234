<template>
  <div class="pool-desc-button-wrapper">
    <div class="pool-description">
      <div class="pool-info-data">
        <span class="info-text">Base Price</span>
        <span class="info-data"
          >${{ poolStatistics.basePriceUSD.toLocaleString() }}</span
        >
      </div>
      <div class="pool-info-data">
        <span class="info-text">{{ pool.tokenName }} Price</span>
        <span class="info-data"
          >${{ poolStatistics.tokenPriceUSD?.toLocaleString() }}</span
        >
      </div>
      <div class="pool-info-data">
        <span class="info-text">Total Value Locked</span>
        <span class="info-data"
          >${{ poolStatistics.totalValueLockedUSD.toLocaleString() }}</span
        >
      </div>
      <div class="pool-info-data">
        <span class="info-text">Volume 24h</span>
        <span class="info-data"
          >${{ poolStatistics.volume24hUSD.toLocaleString() }}</span
        >
      </div>
      <div class="pool-info-data">
        <span class="info-text">Pool Token Supply</span>
        <span class="info-data">{{ tokenSupply }}</span>
      </div>
      <div class="pool-info-data">
        <span class="info-text">Deposited NFTs</span>
        <span class="info-data"
          ><span :class="[isPoolFull ? 'pool-full' : '']">{{
            poolStatistics.depositedNFTsCount
          }}</span
          ><span v-if="poolStatistics.slots">/</span
          ><span :class="[isPoolFull ? 'pool-full' : '']">{{
            poolStatistics.slots || ""
          }}</span></span
        >
      </div>
    </div>
    <div class="link-button-wrapper">
      <div class="wrapper-of-wrapper">
        <app-button
          class="trade-pool-indx"
          :text="`Add ${pool.tokenName} to Metamask`"
          @click="addTokenToMetamask()"
        />
        <app-button
          :text="tradeTokenName"
          class="trade-pool-indx"
          @click="goToBuyTokens()"
        />
        <app-button
          class="go-to-deposit"
          text="Go to submit"
          @click="goToDeposit()"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { ethereumService } from "@/main";
import useAuth from "@/composables/useAuth";
import AppButton from "@/elements/AppButton";
import usePools from "@/composables/usePools";
import useRouter from "@/composables/useRouter";
import useDeposit from "@/composables/useDeposit";
import useUtilities from "@/composables/useUtilities";
import useNetworkData from "@/composables/useNetworkData";
import useExchangeTokens from "@/composables/useExchangeTokens";

export default {
  components: {
    AppButton,
  },
  setup() {
    const store = useStore();
    const { goTo } = useRouter();
    const { toFixedIfNecessary } = useUtilities();
    const { updateChosenPools } = useDeposit(store);
    const { pool, user, poolStatistics } = usePools(store);
    const { isNftOnCurrentNetwork } = useNetworkData(ethereumService);
    const theosAddress = ref(ethereumService.theosTokenContractAddress);
    const { toggleWalletConnectionRequired } = useAuth(store, ethereumService);

    const isPoolFull = computed(
      () =>
        poolStatistics.value.depositedNFTsCount === poolStatistics.value.slots
    );

    const isUserOriginator = computed(
      () => pool.value.originator.id === user.value.id
    );

    const showDepositButton = computed(() => {
      return !(isPoolFull.value && !isUserOriginator.value);
    });

    const tradeTokenName = computed(
      () => "Trade " + `${pool.value.tokenName}`.toUpperCase()
    );

    const tokenSupply = computed(() =>
      toFixedIfNecessary(
        Number(pool.value.basePrice) * poolStatistics.value.depositedNFTsCount,
        4
      )
    );

    const addTokenToMetamask = () => {
      if (isNftOnCurrentNetwork(pool.value)) {
        return ethereumService.addTokenToMetamask(
          pool.value.tokenSymbol,
          pool.value.tokenAddress
        );
      }
      return store.dispatch("auth/commitByKey", {
        switchNetworkModalFlag: true,
      });
    };

    const goToDeposit = () => {
      const address = computed(() => store.getters["contracts/getAddress"]);
      if (!address.value) {
        toggleWalletConnectionRequired();
        return;
      }
      goTo({
        name: "DepositNft",
        query: { poolPollId: pool.value.id },
      });
      updateChosenPools(pool.value);
    };

    const { redirectToSushiSwap } = useExchangeTokens(store);
    const goToBuyTokens = () => {
      redirectToSushiSwap(
        pool.value.tokenAddress,
        ethereumService.theosTokenContractAddress
      );
    };

    return {
      pool,
      isPoolFull,
      tokenSupply,
      goToDeposit,
      theosAddress,
      tradeTokenName,
      poolStatistics,
      goToBuyTokens,
      isUserOriginator,
      showDepositButton,
      addTokenToMetamask,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/poolDescriptionData.scss" />

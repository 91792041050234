<template>
  <input-form-box
    :inputFormBoxStyle="inputFormBoxStyle"
    formTitleBold="contribute to a"
    formTitle="social cause"
  >
    <p class="heading">Share a part of your pool's profits</p>
    <p class="subheading">
      You can contribute to a social cause by sharing a percentage of your
      profits through a royalties system.
    </p>
    <section class="row-section">
      <div class="tags-royalties-wrap column" v-if="!selectedSocialCause.name">
        <select-social-causes
          placeholderText="Choose or search a social cause"
        />
      </div>
      <div class="tags-royalties-wrap-column selected-cause" v-else>
        <p class="label">Social cause</p>
        <div class="selected-social-cause-wrapper">
          <img
            alt="Social Cause"
            :src="require(`@/Common/Icons/viralcure-logo.svg`)"
            class="selected-social-cause-img"
          />
          <p class="selected-social-cause-title">
            {{ selectedSocialCause.name }}
          </p>
          <img
            alt="Cancel"
            class="cancel"
            src="@/Common/Icons/Cancel.png"
            @click="removeSelectedSocialCause()"
          />
        </div>
      </div>
      <div class="tags-royalties-wrap column">
        <app-input
          type="number"
          placeholder="0"
          name="royalties"
          labelClass="label"
          :floatingText="'%'"
          :suggestions="true"
          labelText="Contribution"
          class="tags-royalties"
          v-model="pool.royalties"
          inputClass="form-box-input"
          :percents="[
            { value: 3, text: '3%' },
            { value: 5, text: '5%' },
            { value: 10, text: '10%' },
          ]"
        />
      </div>
    </section>
    <div class="form-box-buttons-controls">
      <app-button
        text="Back"
        @click="decrementStep()"
        buttonClass="app-modal-button"
      />
      <app-button
        text="Next"
        type="submit"
        form="create-pool"
        buttonClass="app-modal-button-inverse"
        v-if="selectedSocialCause.name || isCauseCreated"
      />
      <app-button
        v-else
        type="submit"
        form="create-pool"
        text="Skip this step"
        @click="clearRoyalties"
        buttonClass="app-modal-button-inverse"
      />
    </div>
  </input-form-box>
</template>

<script>
import { useStore } from "vuex";
import { eventBus } from "@/main";
import usePool from "@/composables/usePool";
import AppInput from "@/elements/AppInput.vue";
import AppButton from "@/elements/AppButton.vue";
import InputFormBox from "@/elements/InputFormBox.vue";
import SelectSocialCauses from "@/components/Pools/SelectSocialCauses";
export default {
  name: "MintDetails",
  components: {
    AppInput,
    AppButton,
    InputFormBox,
    SelectSocialCauses,
  },
  setup() {
    const {
      pool,
      decrementStep,
      isCauseCreated,
      createSocialCause,
      selectedSocialCause,
      removeSelectedSocialCause,
    } = usePool(useStore(), eventBus);

    const coverImage = () =>
      !!selectedSocialCause.value && !!selectedSocialCause.value.coverImage
        ? selectedSocialCause.value.coverImage.thumbnail
        : require("@/Common/Icons/Medusa_avatar.png");

    const inputFormBoxStyle = {
      background: "#0B0B0B",
      width: "calc(100% - 160px)",
      borderRadius: "0 36px 36px 36px",
    };

    const clearRoyalties = () => {
      pool.royalties = 0;
    };

    return {
      pool,
      coverImage,
      decrementStep,
      isCauseCreated,
      clearRoyalties,
      createSocialCause,
      inputFormBoxStyle,
      selectedSocialCause,
      removeSelectedSocialCause,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/socialCause.scss" />

import { ref } from "vue";
import { ethereumService } from "@/main";
import useToast from "@/composables/useToast";
import useNetworkData from "./useNetworkData";

const useBuyNft = (store) => {
  const { showToast } = useToast();
  const { isNftOnCurrentNetwork } = useNetworkData(ethereumService);
  const isUnlisting = ref(false);

  const unlistNft = async (nft) => {
    try {
      if (isNftOnCurrentNetwork(nft)) {
        isUnlisting.value = true;
        const tx = await ethereumService.unlistNft(
          nft.contractAddress,
          nft.contractId
        );

        isUnlisting.value = false;
        if (tx.reason === "rejected") {
          await showToast("Rejected", "Transaction rejected", "Error");
          return false;
        }
        await showToast(
          "NFT Unlisted",
          "Your NFT has been successfully unlisted",
          "Success"
        );
        return location.reload();
      }
      return store.dispatch("auth/commitByKey", {
        switchNetworkModalFlag: true,
      });
    } catch (error) {
      await showToast("MetaMask error", "NFT was not unlisted", "Error");
    }
  };

  const buyListedNft = async (nft) => {
    try {
      const tx = await ethereumService.buyListedNft(
        nft.marketplace.price,
        nft.contractAddress,
        nft.contractId
      );

      if (tx.reason === "rejected") {
        await showToast("Rejected", "Transaction rejected", "Error");
        return false;
      }

      await showToast(
        "NFT Bought",
        "You have successfully bought the NFT",
        "Success"
      );
      location.reload();
    } catch (error) {
      console.log(error);
      await showToast("MetaMask error", "NFT was not purchased", "Error");
    }
  };

  return {
    unlistNft,
    isUnlisting,
    buyListedNft,
  };
};

export default useBuyNft;

import { reactive, toRef, ref } from "vue";
import useUtilities from "@/composables/useUtilities";
import { eventBus } from "@/main";

const { toggle } = useUtilities();

const useAlert = (alertEventBus = eventBus, toggleFunction = toggle) => {
  const showAlert = (title, text, icon) => {
    alertEventBus.emit("toggleAlert", {
      title,
      text,
      icon,
    });
  };

  const alertFlag = ref(false);

  const alertProps = reactive({
    title: "",
    text: "",
    icon: "",
  });

  const closeAlert = (target) => {
    toggleFunction(target);
    setAlertProps();
  };

  const setAlertProps = (e = "") =>
    Object.keys(alertProps).forEach(
      (prop) => (toRef(alertProps, prop).value = e[prop] || "")
    );

  eventBus.on("toggleAlert", (e) => {
    setAlertProps(e);
    toggleFunction(alertFlag);
  });

  return {
    alertFlag,
    alertProps,
    closeAlert,
    setAlertProps,
    showAlert,
  };
};

export default useAlert;

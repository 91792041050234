<template>
  <div class="pool-panel-body-wrapper">
    <div class="filters-search">
      <div style="display: flex; justify-content: center; align-items: center">
        <div class="custom-select" id="custom-sel" style="width: 200px">
          <app-select
            v-model="status"
            labelText="Bulk Actions"
            selectBoxStyle="app-select"
            :options="bulkActionOptions"
            @update:modelValue="disabled = false"
            :disabled="selectedSubmissions.length === 0"
          />
        </div>
        <div>
          <app-button
            text="Apply"
            class="apply-button"
            :disabled="disabled"
            @click.prevent="handleBulkSubmission()"
          />
        </div>
      </div>
      <div class="profile-panel-search">
        <app-input
          v-model="searchString"
          inputClass="app-modal-input-search-pool-detail fixed-width"
          @keydown.enter.stop="searchFilter"
          placeholder="Search by name, pool, tag or cause"
          inputIconLeftClass="search-icon-2"
        />
        <img
          alt="search"
          v-show="searchString === '' || searchString == null"
          class="submissions-search-icon"
          src="@/Common/Icons/search_icon.png"
        />
        <img
          v-show="searchString !== ''"
          alt="delete"
          @click="resetSearchString()"
          class="submissions-search-cross"
          src="@/Common/Icons/search_cross.png"
        />
      </div>
      <div class="profile-panel-filter">
        <div class="profile-panel-filter-wrapper">
          <div
            class="profile-panel-filter-overlay"
            @click="toggle(filtersFlag.showFilters)"
          />
          <app-input
            disabled
            :inputStyle="filterStyle"
            inputClass="app-modal-input-sort app-modal-input-filter"
            placeholder="Filter and sort"
            inputIconLeftClass="filter-icon"
            :inputIconRightClass="{
              'chevron-icon': true,
              active: filtersFlag.showFilters.value,
            }"
          />
        </div>
        <teleport to="body">
          <div
            class="filtering-options-overlay"
            v-if="filtersFlag.showFilters.value"
            @click="toggle(filtersFlag.showFilters)"
          />
        </teleport>
        <div class="filtering-options" v-if="filtersFlag.showFilters.value">
          <h3 class="filtering-options-heading">Sort by</h3>
          <p
            class="filtering-option"
            @click="toggleFilter('createdAt', 'DESC')"
            :class="{ active: options.params.orderDirection === 'DESC' }"
          >
            Newest first
          </p>
          <p
            class="filtering-option"
            @click="toggleFilter('createdAt', 'ASC')"
            :class="{ active: options.params.orderDirection === 'ASC' }"
          >
            Oldest first
          </p>
          <!-- <p
            class="filtering-option"
            @click="toggleFilter('filterHighestPrice')"
          >
            Highest price
          </p>
          <p
            class="filtering-option"
            @click="toggleFilter('filterLowestPrice')"
          >
            Lowest price
          </p> -->
        </div>
      </div>
    </div>
    <div class="container">
      <v-server-table
        v-if="user.id"
        :url="tableUrl"
        :columns="columns"
        :options="options"
        ref="submissionsTable"
      >
        <template v-slot:select="{ row }">
          <div class="checkbox-slot">
            <app-checkbox
              :value="row.id"
              :checked="isChecked(row.id)"
              @click="toggleSelection(row.id)"
            />
          </div>
        </template>
        <template v-slot:nft="{ row }">
          <div
            class="field-with-thumbnail"
            @mouseleave="mouseOver = null"
            @mouseenter="mouseOver = row.id"
            @click="
              goToNewTab({
                name: 'NftDetails',
                params: { id: row.nft.id },
              })
            "
          >
            <div class="ntf-thumbnail ntf-thumbnail-pool">
              <img :src="row.nft.coverImage.thumbnail" alt="" />
            </div>
            <!-- <div class="submission-buttons"> -->
            <div :class="[row.nft.name.length > 20 ? 'nft-table-name' : '']">
              {{ row.nft.name }}
              <div class="nft-name-tooltip">{{ row.nft.name }}</div>
            </div>
            <!-- </div> -->
          </div>
        </template>
        <template v-slot:owner="{ row }">
          <div
            class="field-with-thumbnail"
            @click="
              goToNewTab({
                name: 'ViewProfilePublic',
                params: { id: row.sender.id },
              })
            "
          >
            <div class="owner-thumbnail owner-thumbnail-pool">
              <img :src="row.sender.profileImage?.thumbnail" alt="" />
            </div>
            <div>
              {{
                row.sender.displayName
                  ? row.sender.displayName
                  : row.sender.ethAddress
              }}
            </div>
          </div>
        </template>
        <!--          Removed for now-->
        <!--        <template v-slot:remaining="{ row }">-->
        <!--          <div class="date-time">-->
        <!--            {{ submissionsTimeRemaining(row.createdAt) }}-->
        <!--          </div>-->
        <!--        </template>-->
        <template v-slot:buttons="{ row }">
          <div class="buttons">
            <app-button
              text="Reject"
              @click="handleSubmission('Reject', row)"
              buttonClass="app-modal-button app-modal-button-pool"
            />
            <app-button
              text="Accept"
              @click="handleSubmission('Accept', row)"
              buttonClass="app-modal-button-inverse app-modal-button-pool"
            />
          </div>
        </template>
      </v-server-table>
      <load-more v-if="showLoadMoreButton" :loadMore="loadMore" />
    </div>
  </div>
  <confirmation-submission-modal v-if="modalFlag" :poolProp="poolInfo" />
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import AppInput from "@/elements/AppInput";
import AppSelect from "@/elements/AppSelect";
import AppButton from "@/elements/AppButton";
import usePools from "@/composables/usePools";
import useRouter from "@/composables/useRouter";
import AppCheckbox from "@/elements/AppCheckbox";
import useUtilities from "@/composables/useUtilities";
import { onMounted, ref, h, computed, reactive } from "vue";
import LoadMore from "@/components/Profile/Panels/LoadMore";
import useProfilePanels from "@/composables/useProfilePanels";
import ConfirmationSubmissionModal from "./ConfirmationSubmissionModal";
import useMultiSelectCheckbox from "@/composables/useMultiSelectCheckbox";
export default {
  components: {
    LoadMore,
    AppInput,
    AppButton,
    AppSelect,
    AppCheckbox,
    ConfirmationSubmissionModal,
  },
  setup: function () {
    const status = ref("");
    const store = useStore();
    const route = useRoute();
    const disabled = ref(true);
    const loading = ref(false);
    const mouseOver = ref(null);
    const poolInfo = reactive({});
    const user = computed(() => store.getters["userProfile/getUser"]);

    const submissionsTable = ref(null);
    const showLoadMoreButton = ref(false);
    const columns = ["select", "nft", "owner", "buttons"];

    const allValues = () => {
      return submissionsTable.value.data.map((row) => row.id);
    };

    const { parseDate, submissionsTimeRemaining } = useUtilities();
    const { goToNewTab } = useRouter();

    // const { showToast } = useToast();

    const {
      list,
      searchString,
      filtersFlag,
      filtersStyle,
      toggle,
      filters,
      clearNftList,
      profilePanelNoItemsText,
    } = useProfilePanels(useStore());

    const {
      allSelected,
      selectedSubmissions,
      resetSelectedList,
      selectAll,
      isChecked,
      toggleSelection,
    } = useMultiSelectCheckbox();

    const { toggleModalFlag, modalFlag } = usePools(store);

    let poolId = route.params.id;
    //Genesis pool hotfix
    if (parseInt(poolId) === 22) poolId = 28;
    const tableUrl = `/pools/${poolId}/submissions`;

    const options = ref({
      perPage: 5,
      perPageValues: [],
      orderBy: {
        ascending: false,
      },
      params: {},
      requestAdapter(data) {
        resetSelectedList();
        const params = {
          page: data.page,
          perPage: options.value.perPage,
          orderBy: "createdAt",
          orderDirection: data.ascending ? "ASC" : "DESC",
          recipientId: user.value.id,
          status: ["pending"],
        };
        if (data.search) {
          params.search = data.search;
        }
        return params;
      },
      responseAdapter({ data }) {
        return {
          data: data.data,
          count: data.count,
        };
      },
      filterable: false,
      headings: {
        select: function () {
          return h(
            "div",
            {
              class: "vue-table-checkbox-slot",
            },
            [
              h(AppCheckbox, {
                onClick: () => {
                  selectAll(allValues());
                },
                checked: allSelected.value,
              }),
            ]
          );
        },

        id: "Id",
        name: "Name",
        nft: "NFT",
        // remaining: "Time remaining",
        buttons: "",
      },
      sortable: ["nft", "owner"],
      pagination: {
        virtual: true,
        chunk: 5,
      },
    });

    onMounted(() => clearNftList());

    const resetDropdown = () => {
      status.value = "";
      disabled.value = true;
    };

    const handleSubmission = async (status, submission) => {
      poolInfo.value = {
        submitType: "single",
        status: status,
        submission: submission,
      };
      toggleModalFlag();
      disabled.value = true;

      resetSelectedList();
      resetDropdown();
      submissionsTable.value.getData();
    };

    const handleBulkSubmission = async () => {
      disabled.value = true;
      allSelected.value = false;
      poolInfo.value = {
        submitType: "bulk",
        status: status.value,
        submission: selectedSubmissions.value,
      };
      toggleModalFlag();

      resetSelectedList();
      resetDropdown();
      submissionsTable.value.getData();
    };

    const avatarImage = (user) =>
      user.coverImage
        ? user.coverImage
        : require("@/Common/Icons/Medusa_avatar.png");
    const onChange = () => {
      if (status.value === "Accept" || status.value === "Reject") {
        disabled.value = false;
      }
    };

    const searchFilter = () => {
      options.value.params.search = searchString.value;
      options.value.perPage = 5;
      submissionsTable.value.refresh();
      resetSelectedList();
      resetDropdown();
    };

    const toggleFilter = (orderBy, orderDirection) => {
      options.value.params.orderBy = orderBy;
      options.value.params.orderDirection = orderDirection;
      submissionsTable.value.refresh();
      resetSelectedList();
      resetDropdown();
    };

    const bulkActionOptions = ["Accept", "Reject"];

    const loadMore = () => {
      options.value.perPage += 5;
      submissionsTable.value.getData();
    };

    const resetSearchString = () => {
      searchString.value = "";
    };

    return {
      list,
      poolInfo,
      user,
      toggle,
      status,
      loading,
      columns,
      filters,
      options,
      tableUrl,
      disabled,
      loadMore,
      onChange,
      selectAll,
      modalFlag,
      mouseOver,
      parseDate,
      isChecked,
      allSelected,
      filtersFlag,
      avatarImage,
      searchString,
      searchFilter,
      toggleFilter,
      filtersStyle,
      toggleSelection,
      submissionsTable,
      handleSubmission,
      bulkActionOptions,
      resetSearchString,
      showLoadMoreButton,
      selectedSubmissions,
      handleBulkSubmission,
      profilePanelNoItemsText,
      submissionsTimeRemaining,
      goToNewTab,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/submissionsPanel.scss" />

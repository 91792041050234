<template>
  <div v-if="withdrawFlag">
    <teleport to="body">
      <component
        :nft="nft"
        :pool="pool"
        v-model="price"
        :closeFunction="toggleWithdrawFlag"
        :is="currentStepsArray[currentStep - 1].component"
      />
    </teleport>
  </div>
</template>

<script>
import { useStore } from "vuex";
import useWithdrawModal from "@/composables/useWithdrawModal";
import ConfirmWithdraw from "@/components/Withdraw/ConfirmWithdraw";
import WithdrawingInProcess from "@/components/Withdraw/WithdrawingInProcess";

export default {
  components: {
    ConfirmWithdraw,
    WithdrawingInProcess,
  },
  setup() {
    const price = 0;
    const store = useStore();

    const {
      nft,
      pool,
      currentStep,
      withdrawFlag,
      currentStepsArray,
      toggleWithdrawFlag,
    } = useWithdrawModal(store);

    return {
      nft,
      pool,
      price,
      currentStep,
      withdrawFlag,
      currentStepsArray,
      toggleWithdrawFlag,
    };
  },
};
</script>

<style lang="css" scoped>
body {
  overflow: hidden;
}
</style>

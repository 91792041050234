import * as Yup from "yup";

const URL =
  "(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})";
const MAX_FILE_SIZE = 41943040;

export const editProfileSchema = Yup.object().shape({
  displayName: Yup.string()
    .required("You need to choose a Display Name")
    .nullable(),
  email: Yup.string().email().nullable(),
  description: Yup.string().max(200).nullable(),
  twitterUsername: Yup.string().nullable(),
  websiteUrl: Yup.string()
    .notRequired()
    .matches(URL, {
      message: "Please enter a valid url",
      excludeEmptyString: true,
    })
    .nullable(),
  avatarProfileImage: Yup.mixed()
    .nullable()
    .test(
      "FILE_SIZE",
      "The File Size should be less then 40mb",
      (value) => !value || (value && value.size <= MAX_FILE_SIZE)
    ),
});

<template>
  <div class="slider-container">
    <div class="slider-min-max">
      <p class="min-text">{{ min }}%</p>
      <p class="max-text">{{ max }}%</p>
    </div>
    <div class="slider-component">
      <div class="slidecontainer">
        <input
          ref="input"
          v-model="currentValue"
          type="range"
          :min="min"
          :max="max"
          step="0.1"
          class="slider"
          @input="onInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  emits: ["input:modelValue"],
  setup(props, context) {
    const currentValue = ref(0);
    const onInput = () => {
      context.emit("update:modelValue", currentValue.value);
    };
    return {
      currentValue,
      onInput,
    };
  },
};
</script>

<style scoped>
.slider-component .slidecontainer {
  width: 100%;
}

.slider-component .slidecontainer .slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 0px;
  border: 2px solid #434343;
  border-radius: 2px;
}

.slider-component .slidecontainer .slider:hover {
  opacity: 1;
}

.slider-component .slidecontainer .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #caff2c;
  border: 2px solid rgba(11, 11, 11, 0.8);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 50%;
}

.slider-component .slidecontainer .slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #caff2c;
  border: 2px solid rgba(11, 11, 11, 0.8);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 50%;
}
.slider-min-max {
  display: flex;
}
.min-text,
.max-text {
  font-family: "Aktiv-grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #85877d;
}

.max-text {
  margin-left: auto;
  margin-right: 0;
}
input[type="range"]::-moz-range-progress {
  background-color: #caff2c;
}
input[type="range"]::-ms-fill-lower {
  background-color: #caff2c;
}
/*TODO: not working for chrome!*/
</style>

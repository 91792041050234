import { computed } from "vue";
import router from "../router";

import useToast from "@/composables/useToast";
import useProfilePanels from "./useProfilePanels";
import queryString from "query-string";
import useGoogleTag from "@/composables/useGoogleTag";
import { ref } from "vue";
import EthereumService from "../services/ethereum";

let startTime = ref(0);
let endTime = ref(0);
let globalStartTime = ref(0);

const useMinting = (store) => {
  const { showToast } = useToast();
  const { registerEvent } = useGoogleTag();
  const { fetchNftList } = useProfilePanels(store);
  const currentStep = computed(() =>
    store.getters["minting/getByKey"]("currentStep")
  );

  const ethereumService = new EthereumService();

  const resetMintingState = () => store.dispatch("minting/resetState");
  const resetTagsValuesState = () => store.dispatch("tagsValues/resetState");
  const resetState = () => {
    resetMintingState();
    resetTagsValuesState();
  };
  const backgroundUrl = computed(() =>
    store.getters["minting/getByKey"]("backgroundUrl")
  );
  const tagsArray = computed(() =>
    store.getters["tagsValues/getByKey"]("tags")
  );
  const selectedFile = computed(() =>
    store.getters["minting/getByKey"]("selectedFile")
  );
  const coverImageFile = computed(() =>
    store.getters["minting/getByKey"]("coverImageFile")
  );
  const name = computed(() => store.getters["minting/getByKey"]("name"));

  const description = computed(() =>
    store.getters["minting/getByKey"]("description")
  );
  const tags = computed(() => store.getters["minting/getByKey"]("tags"));
  const royalties = computed(() =>
    store.getters["minting/getByKey"]("royalties")
  );
  const mintingMessage = computed(() =>
    store.getters["minting/getByKey"]("mintingMessage")
  );
  const requiresCoverImage = computed(() =>
    store.getters["minting/getByKey"]("requiresCoverImage")
  );
  const steppersSteps = computed(() =>
    store.getters["minting/getByKey"]("steppersSteps")
  );
  const fileTypeCover = computed(() =>
    store.getters["minting/getByKey"]("fileTypeCover")
  );
  const alternativeText = computed(() =>
    store.getters["minting/getByKey"]("alternativeText")
  );
  const stepsForImages = computed(() =>
    store.getters["minting/getByKey"]("stepsForImages")
  );
  const stepsForOtherMimeTypes = computed(() =>
    store.getters["minting/getByKey"]("stepsForOtherMimeTypes")
  );
  const stepsDefault = computed(() =>
    store.getters["minting/getByKey"]("stepsDefault")
  );
  const shouldShowMintNftStepper = computed(() =>
    store.getters["minting/getByKey"]("shouldShowMintNftStepper")
  );
  const shouldShowNftPreview = computed(() =>
    store.getters["minting/getByKey"]("shouldShowNftPreview")
  );
  const isMinting = computed(() =>
    store.getters["minting/getByKey"]("isMinting")
  );
  const isMintingFinished = computed(() =>
    store.getters["minting/getByKey"]("isMintingFinished")
  );

  const mintingSetToPool = computed(() =>
    store.getters["minting/getByKey"]("mintingSetToPool")
  );

  const isCoverAutogenerated = computed(() =>
    store.getters["minting/getByKey"]("isCoverAutogenerated")
  );

  const errors = computed(() => store.getters["minting/getByKey"]("errors"));

  const duration = computed(() =>
    store.getters["minting/getByKey"]("duration")
  );

  const currentStepsArray = computed(() =>
    store.getters["minting/getByKey"]("currentStepsArray")
  );

  const isFileSelected = computed(
    () => store.getters["minting/isFileSelected"]
  );

  const selectedFileMimeType = computed(
    () => store.getters["minting/getSelectedFileMimeType"]
  );

  const selectedFileType = computed(
    () => store.getters["minting/getSelectedFileType"]
  );

  const selectedFileExtension = computed(
    () => store.getters["minting/getSelectedFileExtension"]
  );
  const updateSelectedFile = (file) => {
    store.dispatch("minting/commitByKey", {
      selectedFile: file,
    });
  };

  const updateBulk = (object) => {
    store.dispatch("minting/commitByKey", object);
  };

  const updateCoverImageFile = (file) => {
    store.dispatch("minting/commitByKey", { coverImageFile: file });
  };

  const changeCurrentStepArray = (fileType) => {
    store.dispatch("minting/changeCurrentStepsArray", fileType);
  };

  const finishMinting = async (to) => {
    const mintedNft = await store.getters["nfts/getNftData"];
    mintedNft.status = "approved";
    const nftData = {
      name: mintedNft.name,
      description: mintedNft.description,
      status: "approved",
      contractAddress: to,
    };
    // Ideally we would like to move this to a minting store.
    await store.dispatch("nfts/updateNft", { id: mintedNft.id, nftData });
    store.dispatch("minting/commitByKey", { isMinting: false });
    store.dispatch("minting/commitByKey", { isMintingFinished: true });
    const walletAddress = computed(() => store.getters["contracts/getAddress"]);
    const params = {
      owner: walletAddress.value,
      page: 1,
      perPage: 8,
      orderBy: "id",
      orderDirection: "DESC",
    };
    await store.dispatch("userProfile/clearNftList");
    await store.dispatch("userProfile/clearNftDataList");
    await fetchNftList(queryString.stringify(params));
    await store.dispatch("tagsValues/resetState");
  };

  const incrementStep = (commitToStore = null, inc = true, step = 1) => {
    setEndTime();
    const delta = endTime.value - startTime.value;
    registerEvent(`nft-minting-step-${currentStep.value}-finished`);
    registerEvent(`nft-minting-step-${currentStep.value}-time`, {
      time: delta / 1000,
    });
    if (commitToStore) store.dispatch("minting/commitByKey", commitToStore);

    setStartTime();
    if (inc) {
      store.dispatch("minting/incrementStep", step);
      registerEvent(`nft-minting-step-${currentStep.value}-started`);
    } else {
      finishMinting();
    }
    // inc ? store.dispatch("minting/incrementStep", step) : finishMinting();
  };

  const decrementStep = (step = 1) => {
    store.dispatch("minting/decrementStep", step);
    registerEvent(`nft-minting-returned-to-step-${currentStep.value}`);
    setStartTime();
  };

  const dismiss = () => {
    resetState();
  };

  const pinFile = async (file, name, description) => {
    return store.dispatch("nfts/pinFile", {
      file,
      name,
      description,
    });
  };

  const storeNftFile = async () => {
    const fd = new FormData();
    fd.append("file", selectedFile.value);
    fd.append("type", "nft");
    const ipfsData = store.getters["nfts/getIpfsData"];
    fd.append("ipfsHash", ipfsData.IpfsHash);
    return await store.dispatch("nfts/storeNftFile", fd);
  };

  const storeCoverImageFile = async () => {
    const fd = new FormData();
    fd.append("file", coverImageFile.value);
    fd.append("type", "cover");
    return store.dispatch("nfts/storeCoverImageFile", fd);
  };

  const setIsCoverAutogenerated = (isAutogenerated) =>
    store.dispatch("minting/commitByKey", {
      isCoverAutogenerated: isAutogenerated,
    });

  const autogenerateCoverImage = async () => {
    const fd = new FormData();
    fd.append("file", selectedFile.value);
    fd.append("type", "cover");
    return store.dispatch("nfts/autogenerateCoverImage", fd);
  };

  const storeNft = async () => {
    const ipfsData = store.getters["nfts/getIpfsData"];
    const userPublicAddress = store.getters["contracts/getAddress"];
    const nftFile = store.getters["nfts/getNftFile"];
    const coverImageFile = store.getters["nfts/getNftCoverImageFile"];

    const network = await ethereumService.getNetworkName();
    console.log(`Network: ${network}`);
    const nftData = {
      name: name.value,
      description: description.value,
      tags: tags.value,
      royaltyPercentage: +royalties.value,
      isExplicit: false,
      unlockableContent: null,
      alternativeDescription: alternativeText.value,
      creatorWalletAddress: userPublicAddress,
      fileId: nftFile.id,
      coverImageId: coverImageFile.id ? coverImageFile.id : nftFile.id,
      fileType: nftFile.mimetype,
      metaData: {
        name: name.value,
        description: description.value,
        image: `ipfs://${ipfsData.IpfsHash}`,
      },
      network: network,
    };
    return await store.dispatch("nfts/storeNft", nftData);
  };

  const mintNftOnContract = async () => {
    const ipfsData = store.getters["nfts/getIpfsData"];
    if (!ipfsData.IpfsHash) return;
    const error = await store.dispatch("contracts/mintNft", {
      nftMetadata: `ipfs://${ipfsData.IpfsHash}`,
      royalties: +royalties.value,
    });
    return error ? { hash: error } : store.getters["contracts/getNftData"];
  };

  const handleErrors = async (isRejected = false) => {
    await store.dispatch("minting/commitByKey", { isMinting: false });
    await store.dispatch("minting/commitByKey", {
      isMintingFinished: true,
    });
    await store.dispatch("users/updatePendingTransaction", false);
    await store.dispatch("minting/resetState");
    await store.dispatch("tagsValues/resetState");
    if (isRejected) return;
    return await router.push({ name: "Home" });
  };

  const mintNft = async (errorsNfts) => {
    let delta = (Date.now() - startTime.value) / 1000;
    registerEvent(`nft-minting-step-${currentStep.value}-finished`);
    registerEvent(`nft-minting-step-${currentStep.value}-time`, {
      time: delta,
    });
    registerEvent("nft-minting-submited-for-minting");
    setStartTime();
    await store.dispatch("users/updatePendingTransaction", true);
    await pinFile(selectedFile.value, name.value, description.value);
    await storeNftFile();
    if (errorsNfts.value && errorsNfts.value.length > 0) {
      return await handleErrors();
    }
    if (coverImageFile.value.type) {
      await storeCoverImageFile();
    }
    await storeNft();
    if (errorsNfts.value && errorsNfts.value.length > 0) {
      return await handleErrors();
    }
    const { hash, to } = await mintNftOnContract();

    if (hash === "rejected" || hash === undefined) {
      showToast("Transaction rejected", "Minting not completed.", "Warning");
      registerEvent("nft-minting-transaction-rejected");
      const mintedNft = await store.getters["nfts/getNftData"];
      await store.dispatch("nfts/deleteNft", mintedNft.id);
      return await handleErrors(true);
    }
    await finishMinting(to);
    setEndTime();
    delta = (endTime.value - startTime.value) / 1000;
    registerEvent("nft-minting-time", { time: delta });

    if (hash) {
      registerEvent("nft-minting-successfully-minted");
      delta = (Date.now() - globalStartTime.value) / 1000;
      registerEvent("nft-minting-total-time", { time: delta });
    }
    await store.dispatch("users/updatePendingTransaction", false);
    return hash;
  };

  const showNftPreview = () => {
    store.dispatch("minting/commitByKey", { shouldShowNftPreview: true });
  };

  const hideNftPreview = () => {
    store.dispatch("minting/commitByKey", { shouldShowNftPreview: false });
  };

  const showMintNftStepper = () => {
    store.dispatch("minting/commitByKey", { shouldShowMintNftStepper: true });
  };

  const hideMintNftStepper = () => {
    store.dispatch("minting/commitByKey", { shouldShowMintNftStepper: false });
  };
  const setBackgroundUrl = (url) => {
    store.dispatch("minting/setBackgroundUrl", url);
  };
  const setIsLoading = (val) => {
    store.dispatch("minting/setIsLoading", val);
  };
  const setIsMinting = (bool) => {
    store.dispatch("minting/commitByKey", { isMinting: bool });
  };

  const setIsMintingFinished = (bool) => {
    store.dispatch("minting/commitByKey", { isMintingFinished: bool });
  };

  const setStartTime = () => {
    startTime.value = Date.now();
  };
  const setEndTime = () => {
    endTime.value = Date.now();
  };

  const setGlobalStartTime = () => {
    globalStartTime.value = Date.now();
  };

  return {
    name,
    tags,
    errors,
    duration,
    dismiss,
    mintNft,
    pinFile,
    storeNft,
    tagsArray,
    isMinting,
    royalties,
    setEndTime,
    updateBulk,
    currentStep,
    description,
    setStartTime,
    storeNftFile,
    setIsMinting,
    stepsDefault,
    selectedFile,
    setIsLoading,
    finishMinting,
    fileTypeCover,
    steppersSteps,
    incrementStep,
    decrementStep,
    backgroundUrl,
    coverImageFile,
    mintingMessage,
    isFileSelected,
    stepsForImages,
    showNftPreview,
    hideNftPreview,
    alternativeText,
    globalStartTime,
    setBackgroundUrl,
    selectedFileType,
    mintingSetToPool,
    mintNftOnContract,
    isMintingFinished,
    currentStepsArray,
    setGlobalStartTime,
    requiresCoverImage,
    updateSelectedFile,
    hideMintNftStepper,
    showMintNftStepper,
    storeCoverImageFile,
    selectedFileMimeType,
    setIsMintingFinished,
    selectedFileExtension,
    shouldShowNftPreview,
    updateCoverImageFile,
    isCoverAutogenerated,
    stepsForOtherMimeTypes,
    changeCurrentStepArray,
    autogenerateCoverImage,
    setIsCoverAutogenerated,
    shouldShowMintNftStepper,
  };
};

export default useMinting;

<template>
  <app-modal
    v-if="giftListedNftFlag"
    showCancel
    :closeButton="true"
    :returnToPreviousModal="closeGiftingListedNftModal"
  >
    <template v-slot:app-modal-header>
      <img
        alt="TheosLogo"
        class="app-modal-list-logo"
        src="@/Common/Icons/TheosLogoYellow.svg"
      />
      <h3 class="app-modal-list-title">UNLIST TO GIFT</h3>
      <p class="app-modal-list-text">
        The NFT must be unlisted before it can be gifted.
      </p>
    </template>
    <template v-slot:app-modal-footer>
      <app-button
        text="Got it"
        class="app-modal-button-inverse"
        @click="closeGiftingListedNftModal()"
      />
    </template>
  </app-modal>
</template>

<script>
import { useStore } from "vuex";
import AppModal from "@/elements/AppModal";
import AppButton from "@/elements/AppButton.vue";
import useGiftNft from "../../composables/useGiftNft";

export default {
  name: "GiftListedNftModal",
  props: {
    nft: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    AppButton,
    AppModal,
  },
  setup() {
    const store = useStore();
    const { closeGiftingListedNftModal, ethereumAddress, giftListedNftFlag } =
      useGiftNft(store);

    return {
      ethereumAddress,
      giftListedNftFlag,
      closeGiftingListedNftModal,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/giftNftModal.scss" />

<template>
  <teleport to="body">
    <app-modal
      :closeButton="true"
      :returnToPreviousModal="returnToPreviousModal"
    >
      <template v-slot:app-modal-header>
        <img
          src="@/Common/Icons/TheosLogo.png"
          alt="TheosLogo"
          class="header-logo-image"
        />
      </template>
      <template v-slot:app-modal-body>
        <div class="app-modal-body-wrapper">
          <p class="app-modal-title">
            {{
              actionTexts.find(
                (action) => action.currentStatus === submission.status
              ).modalTitle
            }}
          </p>
          <p class="app-modal-text cancel-submission-text">
            {{
              actionTexts.find(
                (action) => action.currentStatus === submission.status
              ).modalText
            }}
          </p>
          <img
            :src="submission.nft.coverImage.thumbnail"
            alt="Thumbnail"
            class="app-modal-image"
          />
          <div>
            <p class="app-modal-additional-text">
              {{
                actionTexts.find(
                  (action) => action.currentStatus === submission.status
                ).modalText2
              }}
            </p>
            <p
              class="app-modal-nft-pool-name"
              v-if="
                actionTexts.find(
                  (action) => action.currentStatus === submission.status
                ).modalText2 != ''
              "
            >
              {{ submission.nft.name }}
              <span class="app-modal-additional-text"> into </span>
              {{ submission.pool.name }}
            </p>
          </div>
          <div class="button-wrapper-submissions">
            <app-button
              @click="buttonAction()"
              buttonClass="app-modal-button-inverse"
              :text="
                actionTexts.find(
                  (action) => action.currentStatus === submission.status
                ).modalButtonText
              "
            />
            <app-button
              @click="returnToPreviousModal()"
              buttonClass="app-modal-button-transparent"
              :text="
                actionTexts.find(
                  (action) => action.currentStatus === submission.status
                ).modalCancelButtonText
              "
            />
          </div>
        </div>
      </template>
    </app-modal>
  </teleport>
</template>

<script>
import { useStore } from "vuex";
import { eventBus } from "@/main";
import { ethereumService } from "@/main";
import AppModal from "@/elements/AppModal";
import { onMounted, onUnmounted } from "vue";
import AppButton from "@/elements/AppButton";
import useToast from "@/composables/useToast";
import actionTexts from "@/json/submissionActions.json";
import useNetworkData from "@/composables/useNetworkData";
import useProfilePanels from "@/composables/useProfilePanels";
import useDepositReviewModal from "@/composables/useDepositReviewModal";

export default {
  components: {
    AppModal,
    AppButton,
  },
  props: {
    submission: {
      type: Object,
      default: () => {},
    },
    returnToPreviousModal: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const { showToast } = useToast();
    const { toggleDepositFlag } = useDepositReviewModal(store);
    const { toggleShowSubmissionActionModal, checkIfNftWhitelisted } =
      useProfilePanels(store);
    const { isNftOnCurrentNetwork } = useNetworkData(ethereumService);

    const buttonAction = async () => {
      if (isNftOnCurrentNetwork(props.submission.nft)) {
        const isWhitelisted = await checkIfNftWhitelisted(
          props.submission.nft.contractAddress,
          props.submission.nft.contractId,
          props.submission.pool.ethAddress
        );
        switch (props.submission.status) {
          case "accepted":
            if (!isWhitelisted || props.submission.status !== "accepted") {
              await showToast(
                "NFT is not whitelisted.",
                "Whitelist transaction can take some time.",
                "Error"
              );
              return toggleShowSubmissionActionModal();
            }
            await store.dispatch(
              "depositReview/fetchNft",
              props.submission.nftId
            );
            await store.dispatch(
              "depositReview/fetchPool",
              props.submission.poolId
            );
            await store.dispatch("depositReview/commitByKey", {
              selectedSubmission: props.submission,
            });
            toggleShowSubmissionActionModal();
            toggleDepositFlag();
            break;
          case "pending":
            await store.dispatch("pools/handleBulkSubmission", {
              status: "cancel",
              submissionIds: [props.submission.id],
            });
            toggleShowSubmissionActionModal();
            eventBus.emit("refresh-user-submission-table");
            break;
          case "rejected":
            await store.dispatch("pools/handleBulkSubmission", {
              status: "remove",
              submissionIds: [props.submission.id],
            });
            toggleShowSubmissionActionModal();
            eventBus.emit("refresh-user-submission-table");
            break;
        }
        return;
      }
      toggleShowSubmissionActionModal();
      return store.dispatch("auth/commitByKey", {
        switchNetworkModalFlag: true,
      });
    };
    onMounted(() => document.body.classList.add("stop-scroll"));
    onUnmounted(() => document.body.classList.remove("stop-scroll"));
    return {
      actionTexts,
      buttonAction,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/previewModal.scss"></style>

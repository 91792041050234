import { watch } from "vue";

const useInputErrors = (store) => {
  const watchErrorMessage = (errorMessage, dispatchModuleString) => {
    return watch(errorMessage, async (newError, oldError) => {
      if (!newError && dispatchModuleString === "bid") {
        await clearErrorMessages(dispatchModuleString);
      }
      newError
        ? await store.dispatch(`${dispatchModuleString}/pushError`, newError)
        : await store.dispatch(`${dispatchModuleString}/removeError`, oldError);
    });
  };

  const clearErrorMessages = async (dispatchModuleString) => {
    await store.dispatch(`${dispatchModuleString}/clearErrors`);
  };

  return {
    watchErrorMessage,
    clearErrorMessages,
  };
};

export default useInputErrors;

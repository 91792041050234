<template>
  <app-buy-nft
    :closeButton="true"
    :showCloseLink="true"
    :closeModal="closeModal"
  >
    <template v-slot:app-modal-header>
      <img class="buy-nft-logo" src="@/Common/Icons/TheosLogoYellow.png" />
      <p class="buy-nft-title">ACCEPT OFFER</p>
      <img class="buy-modal-img" :src="nft.coverImage.path" :alt="nft.name" />
    </template>
    <template v-slot:app-modal-body>
      <p class="app-modal-body-text text-color text-margin">
        You are accepting a bid from
      </p>
      <p class="app-modal-listed-price">
        <span class="nft-name"> {{ nft.name }}</span>
      </p>
      <p class="app-modal-body-text text-color text-margin">The top bid is</p>
      <p class="app-modal-buy-price">
        <span>
          {{ toFixedIfNecessary(nft.sellPrice, 3) }}
          {{ nft.auction?.token }}
        </span>
        <span class="price-color">
          ≈ ${{ toFixedIfNecessary(nft.sellPriceUSD, 3) }} USD
        </span>
      </p>
      <div class="button-wrapper-buy">
        <app-button
          text="Accept top bid"
          buttonClass="app-modal-button-inverse"
          @click="acceptOffer"
        />
      </div>
    </template>
  </app-buy-nft>
</template>

<script>
import { useStore } from "vuex";
import { eventBus } from "@/main";
import { onMounted, onUnmounted } from "vue";
import AppBuyNft from "@/elements/AppBuyNft";
import AppButton from "@/elements/AppButton";
import useToast from "@/composables/useToast";
import useRouter from "@/composables/useRouter";
import useListNft from "@/composables/useListNft";
import useUtilities from "@/composables/useUtilities";
import useBuyNftModal from "@/composables/useBuyNftModal";

export default {
  name: "BuyNft",
  components: {
    AppBuyNft,
    AppButton,
  },
  props: {
    nft: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const { goTo } = useRouter();
    const { showToast } = useToast();
    const { toFixedIfNecessary } = useUtilities();
    const { sellToTopBidder } = useListNft(store);
    const { closeModal, incrementStepAcceptOffer, decrementStepAcceptOffer } =
      useBuyNftModal(store);
    const acceptOffer = async () => {
      incrementStepAcceptOffer();
      try {
        await sellToTopBidder(props.nft);
      } catch (err) {
        console.error(err);
        decrementStepAcceptOffer();
      }
    };
    onMounted(() => {
      document.body.classList.add("stop-scroll");
      eventBus.on("AUCTION_OFFER_MATCHED", async () => {
        closeModal();
        await goTo({ name: "ViewProfilePrivate" });
        await showToast(
          `Sold NFT to Top bidder`,
          `Congratulations!`,
          "Success"
        );
      });

      eventBus.on("TRANSACTION_FAILED", async () => {
        decrementStepAcceptOffer();
      });
    });
    onUnmounted(() => document.body.classList.remove("stop-scroll"));

    return {
      closeModal,
      acceptOffer,
      toFixedIfNecessary,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/buyNft.scss" />

<template>
  <app-modal :closeButton="true" :returnToPreviousModal="toggleHasSubmission">
    <template v-slot:app-modal-header>
      <img alt="Warning" class="warning-svg" src="@/Common/Icons/Warning.svg" />
      <h3 class="app-modal-welcome-title-smaller">
        You will lose your NFT pool submissions
      </h3>
    </template>
    <template v-slot:app-modal-body>
      <p class="app-modal-body-text" style="margin-bottom: 29px">
        If you continue with the listing process, all your NFT submissions will
        be cancelled.
      </p>
      <div class="app-modal-button-controls-column">
        <app-button
          text="List anyway"
          @click="listAnyway()"
          buttonClass="app-modal-button-inverse"
        />
        <app-button
          text="Cancel"
          class="app-modal-button"
          @click="toggleHasSubmission()"
        />
      </div>
    </template>
  </app-modal>
</template>

<script>
import { useStore } from "vuex";
import AppButton from "@/elements/AppButton.vue";
import useListNft from "@/composables/useListNft";
import AppModal from "@/elements/AppModal";
export default {
  components: {
    AppButton,
    AppModal,
  },
  setup() {
    const store = useStore();
    const {
      toggleHasSubmission,
      hasSubmission,
      confirmTimedAuction,
      showLastCheckForAuction,
      lastCheckFlag,
      selectedType,
      confirmNftListing,
    } = useListNft(store);

    const listAnyway = async () => {
      await toggleHasSubmission();
      if (selectedType.value == "fixed-price") {
        await confirmNftListing();
        return;
      }
      if (!showLastCheckForAuction.value) {
        await confirmTimedAuction();
        return;
      }

      lastCheckFlag.value = true;
    };

    return {
      listAnyway,
      hasSubmission,
      confirmTimedAuction,
      toggleHasSubmission,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/commonModal.scss" />

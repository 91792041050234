import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ethereumService } from "@/main";
import { computed, onMounted, ref } from "vue";

import useAuth from "@/composables/useAuth";
import useToast from "@/composables/useToast";
import useNetworkData from "./useNetworkData";
import useRouter from "@/composables/useRouter";
import useUtilities from "@/composables/useUtilities";
import imageTransformer from "@/plugins/imageTransformer";
import useWithdrawModal from "@/composables/useWithdrawModal";
import useInsufficientFunds from "@/composables/useInsufficientFunds";
import useExchangeTokens from "@/composables/useExchangeTokens";

const useNftDetails = (store) => {
  const { goTo } = useRouter();
  const { showToast } = useToast();
  const { openSwapModal } = useExchangeTokens(useStore());
  const { toggleWithdrawFlag } = useWithdrawModal(store);
  const { toggleInsufficientFundsModal } =
    useInsufficientFunds(ethereumService);
  const { isNftOnCurrentNetwork } = useNetworkData(ethereumService);
  const route = useRoute();

  const { toggleWalletConnectionRequired, registeredUser } = useAuth(
    store,
    ethereumService
  );

  onMounted(async () => {
    await store.dispatch("auth/fetchMe");
  });

  const nft = computed(() => store.getters["nftDetails/getNft"]);
  const pool = computed(() => store.getters["nftDetails/getPool"]);
  const me = computed(() => store.getters["auth/getRegisteredUser"]);

  const contractAddress = computed(() =>
    store.getters["nftDetails/getByKey"]("contractAddress")
  );

  const contractId = computed(() =>
    store.getters["nftDetails/getByKey"]("contractId")
  );

  const fetchUserBalance = async (tokenAddress, userAddress) => {
    const userBalance = await ethereumService.fetchUserBalance(
      tokenAddress,
      userAddress
    );
    store.dispatch("nftDetails/commitUserBalance", userBalance);
    return userBalance;
  };
  const userBalance = computed(
    () => store.getters["nftDetails/getUserBalance"]
  );

  const shortenedContractAddress = () => {
    return contractAddress.value
      ? `${contractAddress.value.slice(0, 6)}...${contractAddress.value.slice(
          -4
        )}`
      : "...";
  };

  const shortenedContractId = () => {
    if (!contractId.value) return contractId.value;
    if (contractId?.value.length < 12) return contractId.value;
    return contractId.value
      ? `${contractId.value.slice(0, 6)}...${contractId.value.slice(-4)}`
      : "...";
  };

  const { copyToClipboard } = useUtilities();
  let copyAddressText = ref("Copy address");
  const copy = (data) => {
    copyToClipboard(data);
    copyAddressText.value = "Copied!";
  };
  const resetCopyText = () => {
    copyAddressText.value = "Copy address";
  };

  const insufficientFunds = ref(false);

  const showBackButton = computed(() => window.history.state.back !== null);

  const goBack = () => {
    store.dispatch("nftDetails/resetState");
    goTo(-1);
  };

  const goToUserProfile = (id) =>
    goTo({ name: "ViewProfilePublic", params: { id } });

  const goToPrivateProfile = (id) =>
    goTo({ name: "ViewProfilePrivate", params: { id } });

  const redirectToSushiSwap = () => {
    openSwapModal(
      ethereumService.theosTokenContractAddress,
      nft.value.pools[0].tokenAddress
    );
  };

  const goToProfile = (nft) => {
    if (registeredUser.value.id === nft.currentOwner.id) {
      return goToPrivateProfile(registeredUser.value.id);
    }
    return goToUserProfile(nft.currentOwner.id);
  };

  const goToProfileOfTopBidder = (nft) => {
    if (registeredUser.value.id === nft.auction?.highestBidderId) {
      return goToPrivateProfile(registeredUser.value.id);
    }
    return goToUserProfile(nft.auction?.highestBidderId);
  };

  const showNotEnoughAssetsToast = async () => {
    await showToast(
      "Not enough assets.",
      "You don't have enough assets to buy NFT.",
      "Error"
    );
  };
  const isOwner = computed(() => nft.value.currentOwner?.id === me.value.id);

  const isListed = computed(() => nft.value.status === "listed");

  const price = computed(() => {
    if (nft.value.status === "deposited") {
      return isOwner.value ? nft.value.pools[0].basePrice : nft.value.sellPrice;
    }
    if (nft.value.status === "listed") {
      return nft.value.marketplace?.price || 0;
    }
    return 0;
  });

  const avatarImage = (user) =>
    user?.avatarFileName?.filename
      ? imageTransformer.transform(user.avatarFileName.filename, "user.avatar")
      : require("@/Common/Icons/Medusa_avatar_bg.png");

  const topBidderImage = (user) =>
    user.profileImage?.filename
      ? imageTransformer.transform(user.profileImage?.filename, "user.avatar")
      : require("@/Common/Icons/Medusa_avatar_bg.png");

  const isNftPooled = () => nft.value.status === "deposited";
  const isDeposited = computed(() => nft.value.status === "deposited");
  const isAuctioned = computed(() => nft.value.status === "auctioned");
  const isPendingSale = computed(() => nft.value.status === "pending-sale");

  const hasBids = computed(() => Boolean(nft.value.auction?.highestBidderId));

  const poolName = computed(() =>
    nft.value.pools !== null && nft.value.pools.length !== 0
      ? nft.value.pools[0].name
      : null
  );

  const basePrice = computed(() =>
    nft.value.pools !== null && nft.value.pools.length !== 0
      ? nft.value.pools[0].basePrice
      : null
  );

  const tokenSymbol = computed(() =>
    nft.value.pools !== null && nft.value.pools.length !== 0
      ? nft.value.pools[0].tokenSymbol
      : null
  );

  const isCreator = () => {
    return nft.value.creator.id === me.value.id;
  };

  const tokenName = computed(() => {
    if (nft.value.pools != null && nft.value.pools[0] != null)
      return nft.value.pools[0].tokenName;
    else return null;
  });

  const sellPriceUSD = computed(() => {
    if (nft.value.status === "deposited") {
      return isOwner.value ? nft.value.basePriceUSD : nft.value.sellPriceUSD;
    }

    if (nft.value.status === "listed") return nft.value.marketplace.priceUSD;
    return 0;
  });

  const isBuyClicked = ref(false);

  const buyNft = async (token) => {
    if (isNftOnCurrentNetwork(token)) {
      if (!me.value.publicAddress) {
        toggleWalletConnectionRequired();
        return;
      }
      const userBalance = await fetchUserBalance(
        token.pools[0].tokenAddress,
        me.value.publicAddress
      );

      const isOwner = token.currentOwner?.id === me.value.id;
      const price = isOwner ? token.pools[0].basePrice : token.sellPrice;

      await fetchUserBalance(
        token.pools[0].tokenAddress,
        me.value.publicAddress
      );

      if (Number(userBalance) >= Number(price)) {
        await store.dispatch("buyNft/fetchNft", token.id);
        return await store.dispatch("buyNft/toggleBuyFlag");
      }
      return await toggleInsufficientFundsModal(token, userBalance);
    }
    return store.dispatch("auth/commitByKey", {
      switchNetworkModalFlag: true,
    });
  };

  const withdrawNft = async () => {
    await fetchUserBalance(
      nft.value.pools[0].tokenAddress,
      me.value.publicAddress
    );
    console.log(userBalance.value);
    console.log(price.value);
    if (Number(userBalance.value) >= Number(price.value)) {
      await store.dispatch("withdraw/fetchNft", route.params.id);
      toggleWithdrawFlag();
    } else {
      insufficientFunds.value = true;
      await showNotEnoughAssetsToast();
    }
  };

  return {
    me,
    nft,
    copy,
    pool,
    price,
    buyNft,
    goBack,
    hasBids,
    isOwner,
    isListed,
    poolName,
    isCreator,
    basePrice,
    tokenName,
    goToProfile,
    isAuctioned,
    isDeposited,
    isNftPooled,
    tokenSymbol,
    withdrawNft,
    avatarImage,
    userBalance,
    isBuyClicked,
    sellPriceUSD,
    isPendingSale,
    resetCopyText,
    topBidderImage,
    showBackButton,
    copyAddressText,
    contractAddress,
    goToUserProfile,
    fetchUserBalance,
    insufficientFunds,
    goToPrivateProfile,
    shortenedContractId,
    redirectToSushiSwap,
    goToProfileOfTopBidder,
    showNotEnoughAssetsToast,
    shortenedContractAddress,
  };
};

export default useNftDetails;

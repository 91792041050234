<template>
  <div class="create-pool-spinner" v-if="!isLoaded">
    <app-loader :isGreen="true"></app-loader>
  </div>
  <div class="pool-wrapper" v-else>
    <div class="pool-title">
      <div class="pool-title-main-text">
        <p class="holo-title holo-bold">Create</p>
        <p class="holo-title">Odyssey pool</p>
      </div>
      <p class="subtitle">
        Before creating a new pool - you have to meet two requirements.
      </p>
    </div>
    <div class="pool-requirements">
      <requirements
        title="Own a NFT"
        backgroundTitle="First"
        buttonText="Mint an NFT"
        :meetsRequirement="hasUserNft"
        :buttonFunction="() => goTo({ name: 'Minting' })"
        description="You are required to provide an NFT to originate the pool. This NFT will also be available for buying once the pool is created."
      />
      <requirements
        title="Have THEOS tokens"
        backgroundTitle="Second"
        buttonText="Buy THEOS tokens"
        :buttonFunction="goToBuyTheos"
        :meetsRequirement="hasUserTheos"
        infoText="These tokens will be used to create a liquidity pool for the index tokens on a secondary market (DEX)."
        description="The amount of THEOS tokens will depend on the price you want to set for the NFTs deposited in the pool."
      />
    </div>
    <div class="pool-agree">
      <app-button
        text="Continue"
        @click="agree(true)"
        :buttonStyle="buttonStyle"
        v-show="hasUserNft && hasUserTheos"
        buttonClass="app-modal-button-inverse"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import Requirements from "./Requirements";
import usePool from "@/composables/usePool";
import AppButton from "@/elements/AppButton";
import AppLoader from "@/elements/AppLoader";
import useRouter from "@/composables/useRouter";
import useExchangeTokens from "@/composables/useExchangeTokens";
import { ethereumService } from "@/main";
export default {
  components: {
    AppButton,
    AppLoader,
    Requirements,
  },
  setup() {
    let isLoaded = ref(false);
    onMounted(async () => {
      await fetchUserBalanceAndNftList();
      isLoaded.value = true;
    });
    const { goTo } = useRouter();
    const buttonStyle = {
      fontWeight: "bold",
      padding: "10px 45px",
    };
    const { agree, hasUserNft, hasUserTheos, fetchUserBalanceAndNftList } =
      usePool(useStore());

    const { openSwapModal } = useExchangeTokens(useStore());

    const goToBuyTheos = () => {
      openSwapModal(
        null,
        ethereumService.theosTokenContractAddress,
        "create_pool"
      );
    };
    const goToMinting = () => {
      goTo({ name: "Minting" });
    };
    return {
      goTo,
      agree,
      isLoaded,
      hasUserNft,
      buttonStyle,
      goToMinting,
      hasUserTheos,
      goToBuyTheos,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/createPool.scss" />

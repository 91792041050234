import store from "./store";
import App from "./App.vue";
import router from "./router";
import { createApp } from "vue";
import VueSocialSharing from "vue-social-sharing";
import EthereumService from "./services/ethereum";
import { createMetaManager } from "vue-meta";
import { ServerTable } from "v-tables-3";
import VueGtag from "vue-gtag-next";
import CustomSortControl from "@/elements/CustomSortControl";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Hotjar from "vue-hotjar";
import UniswapVue from "uniswap-vue/src";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: "http://theos-314414.firebaseapp.com",
  projectId: "theos-314414",
  storageBucket: "theos-314414.appspot.com",
  messagingSenderId: "127022292800",
  appId: "1:127022292800:web:4f4f1f8435a34a3afa24a5",
  measurementId: "G-0VYXPW1TEB",
};
import "@/Common/Styles/base.scss";

import mitt from "mitt";
export const eventBus = mitt();

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueSocialSharing)
  .use(createMetaManager())
  .use(Hotjar, {
    id: process.env.VUE_APP_HOTJAR_ID || "",
    isProduction: true,
    snippetVersion: 6,
  })
  .use(VueGtag, {
    property: {
      id: process.env.VUE_APP_GTAG_ID,
    },
  })
  .use(ServerTable, {}, "", {
    sortControl: CustomSortControl,
  })
  .use(UniswapVue);
const CHAIN_ID = -1;
export const ethereumService = new EthereumService(CHAIN_ID);

if (process.env.VUE_APP_FIREBASE_API_KEY) {
  initializeApp(firebaseConfig);
  getAnalytics(app);
}

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}

app.provide("eventBus", eventBus);
app.provide("ethereumService", ethereumService);
app.mount("#app");

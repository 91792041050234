import { NFTStorage } from "nft.storage";

const client = new NFTStorage({
  token: process.env.VUE_APP_NFT_STORAGE_API_KEY,
});

export default {
  async store(file, name, description, properties = {}) {
    return await client.store({
      name,
      description,
      image: file,
      properties,
    });
  },
  createIPFSLink(metadata) {
    return `https://ipfs.io/ipfs/${metadata.data.image.host}${metadata.data.image.pathname}`;
  },
};

import { useGtag } from "vue-gtag-next";

const useGoogleTag = () => {
  const { event, exception } = useGtag();

  const registerEvent = (name, data) => {
    event(name, data);
  };

  const registerException = (description, isFatal) => {
    exception({
      description: description,
      fatal: isFatal,
    });
  };

  return {
    registerEvent,
    registerException,
  };
};

export default useGoogleTag;

<template>
  <div class="preview-wrap-box">
    <div class="image-type-choose" v-if="!hasError && !isCoverAutogenerated">
      <span v-if="!isPool">{{ message }}</span>
      <img v-if="fileType == 'image' && !isPool" class="image" :src="url" />
      <div
        v-if="isPool"
        class="pool-avatar-wrapper"
        :style="backgroundImage"
      ></div>
      <img v-if="isPool" class="pool-image" :src="url" />
    </div>
    <div class="image-type-video-cover" v-if="isCoverAutogenerated">
      <div class="recomended-cover-image">
        <img v-if="fileType == 'image'" class="image-video-cover" :src="url" />
        <div class="span-text" v-if="isCoverAutogenerated">
          <span class="auto-span">Autogenerated cover</span>
          <span class="cover-span">Want to set a different cover?</span>
        </div>
        <app-button
          text="Upload cover image"
          @click.prevent="removeSugestionCoverImage()"
          buttonClass="upload-cover-button"
        />
        <div class="info-wrapper">
          <div class="flie-name">
            <span>File</span>
            <span class="video-name">{{ fileName }}</span>
          </div>
          <div class="file-duration">
            <span>Duration</span>
            <span class="video-time">{{
              convertTimeHHMMSS(fileDuration)
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <video v-if="fileType == 'video'" controls width="400" height="250">
      <source :src="url" />
    </video>
    <app-audio v-if="fileType == 'audio'" :url="url" />
  </div>
</template>

<script>
import AppAudio from "@/components/AppAudio.vue";
import AppButton from "@/elements/AppButton.vue";
import useMinting from "@/composables/useMinting";
import { useStore } from "vuex";
import { eventBus } from "@/main";
import { ref, computed } from "vue";
import imageTransformer from "@/plugins/imageTransformer";

export default {
  name: "FilePreview",
  components: {
    AppAudio,
    AppButton,
  },
  props: {
    mimeType: {
      type: String,
      default: "jpg",
    },
    fileType: {
      type: String,
      default: "image",
    },
    url: {
      type: String,
      required: true,
    },
    hasCoverImage: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
    isPool: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const {
      selectedFile,
      updateCoverImageFile,
      coverImageFile,
      isCoverAutogenerated,
      setIsCoverAutogenerated,
    } = useMinting(store);
    const isFileVideo = ref(false);
    const fileDuration = ref(0);
    const fileName = ref("");
    const fileType = (file) => file.type?.split("/")[0];
    fileType(selectedFile.value) === "video"
      ? (isFileVideo.value = true)
      : (isFileVideo.value = false);

    const getVideoDuration = (file) => {
      let videoUrl = URL.createObjectURL(file);
      let audioElement = new Audio(videoUrl);
      audioElement.onloadedmetadata = () => {
        fileDuration.value = parseInt(audioElement.duration);
        fileName.value = file.name;
      };
    };
    if (fileType(selectedFile.value) === "video") {
      getVideoDuration(selectedFile.value);
    }
    const convertTimeHHMMSS = (val) => {
      let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);
      return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
    };
    const removeSugestionCoverImage = () => {
      updateCoverImageFile({});
      setIsCoverAutogenerated(false);
      eventBus.emit("clearFile:coverImageFile");
    };
    const poolImage = computed(() =>
      imageTransformer.transform(props.url, "user.avatar")
    );

    const backgroundImage = computed(() => {
      return props.url !== ""
        ? `background: linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)) , url(${props.url}) no-repeat center center/cover`
        : "";
    });

    return {
      selectedFile,
      isFileVideo,
      fileDuration,
      fileName,
      poolImage,
      backgroundImage,
      convertTimeHHMMSS,
      removeSugestionCoverImage,
      coverImageFile,
      isCoverAutogenerated,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/fileInput.scss"></style>

<template>
  <div class="deposit-nft" v-if="!submissionSuccess">
    <stepper :steps="currentStepsArray" :currentStep="currentStep"></stepper>
    <component :is="currentStepsArray[currentStep - 1].component" />
  </div>
  <request-sent v-else />
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Stepper from "@/components/Stepper.vue";
import { ref, onMounted, onUnmounted } from "vue";
import useDeposit from "@/composables/useDeposit";
import NftChooseToSubmit from "@/components/DepositNft/NftChooseToSubmit";
import RequestSent from "@/components/DepositNft/RequestSentModal/RequestSent";
import PoolChooseToSubmitNft from "@/components/DepositNft/PoolChooseToSubmitNft";
import DepositSubmissionPreview from "@/components/DepositNft/DepositSubmissionPreview";

export default {
  components: {
    Stepper,
    RequestSent,
    NftChooseToSubmit,
    PoolChooseToSubmitNft,
    DepositSubmissionPreview,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const routeQuery = ref({});

    const {
      currentStep,
      currentStepsArray,
      submissionSuccess,
      changeCurrentStepArray,
    } = useDeposit(store);
    routeQuery.value = route?.query;
    changeCurrentStepArray(routeQuery.value);
    onMounted(() => {
      store.dispatch("deposit/fetchMe");
    });
    onUnmounted(() => {
      store.dispatch("deposit/resetState");
    });
    return {
      routeQuery,
      currentStep,
      currentStepsArray,
      submissionSuccess,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/depositNft.scss"></style>

<template>
  <div class="profile-panel-no-items">
    <img :src="image" />
    <h3 class="profile-panel-no-items-title">{{ title }}</h3>
    <p class="profile-panel-no-items-text">
      {{ textTop }}
    </p>
    <p class="profile-panel-no-items-text" v-if="!isPublic">
      {{ textBot }}
    </p>
    <app-button
      v-if="buttonText != ''"
      :text="buttonText"
      buttonClass="create-nft-button"
      @click.prevent="buttonClickHandler()"
    />
  </div>
</template>

<script>
import { inject } from "vue";
import AppButton from "@/elements/AppButton";
export default {
  components: {
    AppButton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    textTop: {
      type: String,
      default: "",
    },
    textBot: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    buttonClickHandler: {
      type: Function,
      default: () => ({}),
    },
  },
  setup() {
    const isPublic = inject("isPublic");
    return { isPublic };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/profilePanels.scss" />

import api from "@/services/api";
const initialState = () => ({
  buyListedNftFlag: false,
  currentStep: 1,
  currentStepsArray: [
    {
      component: "BuyListedNftModal",
    },
    {
      component: "BuyListedNftCompletingModal",
    },
  ],
  nft: {
    id: null,
    name: String,
    coverImage: {
      path: String,
    },
    currentOwner: {
      displayName: String,
    },
  },
  errors: [],
});

const state = initialState();
const getters = {
  getByKey: (state) => (key) => state[key],
};
const mutations = {
  setByKey(state, obj) {
    state[obj.key] = obj.value;
  },
  toggleBuyListedNftFlag(state) {
    state.buyListedNftFlag = !state.buyListedNftFlag;
  },
  incrementStep(state) {
    state.currentStep++;
  },
  decrementStep(state) {
    state.currentStep--;
  },
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
};
const actions = {
  setByKey({ commit }, obj) {
    commit("setByKey", obj);
  },
  incrementStep({ commit }) {
    commit("incrementStep");
  },
  decrementStep({ commit }) {
    commit("decrementStep");
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  resetState({ commit }) {
    commit("resetState");
  },
  toggleBuyListedNftFlag({ commit }) {
    try {
      commit("toggleBuyListedNftFlag");
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchNft({ commit }, id) {
    try {
      const response = await api.fetchNft(id);
      commit("commitByKey", { nft: response.data });
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
